<template>
  <form @submit.prevent="createValue" :class="`select${multi?' multi':' single'}`" ref="select">

    <input v-model="search" type="text"/>

    <div ref="dropdown">

      <div class="dropdown-option">
        <a href="" v-for="option in calc_options"
           :class="`dropdown-item${getActive(option)?' active':''}`"
           v-on:click.prevent="setValue(option)"
           v-html="option[fname]?.replace(new RegExp(search, 'ig'), `<span class='text-bg-warning'>${search}</span>`)">
        </a></div>
    </div>
    <div v-if="modelValue && modelValue.length" class="tags">
          <span class="tag" v-for="m in modelValue">
            {{ m[field_name] }} <a href="" v-on:click.prevent="setValue(getOption(m))"><i class="bi bi-x"></i></a>
          </span>
    </div>

  </form>
</template>

<script>
export default {
  name: "AutoComplete",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    modelValue: undefined,
    options: Object,
    multi: Boolean,
    addValue: Function,
    removeValue: Function,
    field_id: String,
    field_name: String,
  },
  data() {
    return {
      search: "",
      fid: this.field_id || "id",
      fname: this.field_name || "name",
      required_val: this.required || false,
      width: null
    }
  },
  mounted() {
    this.width = this.$refs.dropdown.offsetWidth;
  },
  computed: {
    calc_options() {
      let qs = [];
      if (this.search) qs = this.options.filter((option) => {
        return (option[this.fname].toLowerCase().indexOf(this.search.toLowerCase()) + 1)
      })
      return qs
    }
  },
  methods: {
    createValue(){
      this.setValue({id: null, theme: this.search});
      this.search = "";
    },
    setValue(option) {
      if (this.multi) {
        let value = option;
        let index = 0, current = this.modelValue.filter(x => x[this.fid] === value[this.fid]);
        if (current) current = current[0];

        if (current) {
          index = this.modelValue.indexOf(current);
          if (index + 1) this.modelValue.splice(index, 1);
          if (this.removeValue) this.removeValue(current)
        } else {
          if (this.max && this.modelValue.length >= this.max) this.$emit('update:model_error', `Максимальное количество выбранных элементов ${this.max}`)
          else {
            this.modelValue.push(value)
            if (this.addValue) this.addValue(value)
          }
        }
      } else {
        this.$emit('update:modelValue', option);
        if (this.addValue) this.addValue(option)
        this.$refs['button_drop'].click()
      }

    },
    getActive(option) {
      if (this.multi && this.modelValue) {
        return !!this.modelValue.filter(x => x[this.fid] === option[this.fid]).length
      } else {
        return !!this.modelValue && (this.modelValue[this.fid] === option[this.fid])
      }
    },
    getOption(value) {
      return value;
    }
  }
}
</script>

<style scoped lang="scss">

.select {
  border-radius: 10px;
  border: 1px solid rgba(139, 139, 139, 1);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10000;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  width: 100%;

  button {
    width: 100%;
    border: none;
    background: transparent;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  }

  input {
    width: 100%;
    border-radius: 10px;
    border-color: #eee;
    box-shadow: none;
  }

  .placeholder {
    color: rgba(139, 139, 139, 1);
  }

  .value {
    font-weight: 500;
  }

  .dropdown-toggle {
    padding: 5px 20px;

    &::after {
      display: none;
    }

  }

  .dropdown-option {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .dropdown-item {
    min-height: 25px;

    &:active, &:focus, &:hover, &.active {
      background: rgba(234, 234, 234, 1);
      border-radius: 10px;
      text-decoration: none;
      color: inherit;
    }
  }

  .dropdown-menu {
    width: max-content;
    border-radius: 0 0 15px 15px;
    //transition: 0.5s;
    display: block !important;
    opacity: 0;
    pointer-events: none;

    &.show {
      opacity: 1;
      width: 100%;
      pointer-events: inherit;
    }

    input {
      width: 100%;
    }
  }

  .tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .tag {
      display: flex;
      gap: 8px;
      padding: 5px;
      font-weight: 500;

      &:hover {
        background: rgba(234, 234, 234, 1);
        border-radius: 5px;
      }

      i {
        background: rgba(254, 66, 45, 1);
        color: #fff;
        border-radius: 3px;
        display: flex;
      }
    }
  }

}
</style>
