<script setup>
import {computed, watch, ref} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from "vue-router";
import {HTTP} from '@/api/common'

const route = useRoute()

const store = useStore()

const project_id = computed(() => {
  return route.params.id
})

const project_types = ref([
  {name: "Активный", value: "active"},
  {name: "Аудит", value: "audit"},
  {name: "Черновик", value: "draft"},
  {name: "Архив", value: "archive"}
])
const menu_list = ref([
  {
    name: 'Ссылки', path: {name: 'project_detail', params: {id: project_id}}, ico: "search", children: [
      {name: 'Скриншоты выдачи', path: {name: 'search_screen', params: {id: project_id}}},
      {name: 'Уникальные ссылки', path: {name: 'links_info', params: {id: project_id}}},

      {name: 'Блок картинки', path: {name: 'project_pictures', params: {id: project_id}}},
      {name: 'Блок видео', path: {name: 'project_video', params: {id: project_id}}},
      {name: 'Блок знаний', path: {name: 'project_knowledge_block', params: {id: project_id}}},
      {name: 'Похожие запросы', path: {name: 'project_related_requests', params: {id: project_id}}},
      {name: 'Так же спрашивают', path: {name: 'project_also_ask', params: {id: project_id}}},
    ]
  },
  {name: 'Подсказки', path: {name: 'project_hints', params: {id: project_id}}, ico: "list-ul"},

  {name: 'Wikipedia', path: {name: 'project_wikipedia', params: {id: project_id}}, ico: "wikipedia"},

  {
    name: 'Изображения',
    path: {name: 'project_images_info', params: {id: project_id}}, ico: "images"
  },
  // {
  //   name: '5. Доноры',
  //   path: {name: 'donors', params: {id: project_id}}
  // },
  // {name: '5. Видео', path: {name: 'dev'}},


  {name: 'Отчёты', path: {name: 'project_reports', params: {id: project_id}}, ico: "journal-richtext"},
  {name: 'Backlinks', path: {name: 'project_backlinks', params: {id: project_id}}, ico: "link-45deg"},
  // {
  //   name: 'Внутренние ссылки',
  //   path: {name: 'project_backlinks', params: {id: project_id, type: 'inner'}}
  // },
  {
    name: 'План / Факт',
    path: {name: 'project_prognoz', params: {id: project_id}}, ico: "graph-up"
  }
// {name: 'Юр.работы', path: {name: 'dev'}},

  // {name: 'IBot', path: {name: 'ibot', params: {id: project_id}}},
])

const filters = computed({
      get() {
        return store.getters.project_filters || []
      },
      set(val) {
        store.dispatch("updateFilters", val)
      }
    }
)

function toggleMenu(_id, e) {
  e.preventDefault()
  e.target.classList.toggle('bi-chevron-down')
  e.target.classList.toggle('bi-chevron-up')
  document.getElementById(_id).classList.toggle('open')
}

let project = ref(Object);

watch(project_id, (newValue) => {
  if (!!newValue) HTTP.get(`projects/search_by_q/?id=${newValue}`).then(resp => {
    project.value = resp.data
  })
});


</script>
<template>
  <nav :class="`pb-4 text-white sidebar ${$store.getters.sidebar_state?'':'collapse'}`">
    <div class="hide-sidebar" @click="$store.dispatch('toggle_sidebar')">
      <i :class="`bi bi-chevron-double-${$store.getters.sidebar_state?'left':'right'}`"></i>

    </div>
    <div class="menu-group" v-if="$store.getters.user?.permission.actions.indexOf('create_project')+1">

      <router-link :to="{name: 'add_project'}" active-class="active" class="btn btn-menu">
        Создать проект <i class="bi bi-plus-circle ms-1"></i>
      </router-link>
    </div>
    <div class="menu-group">
      <router-link active-class="active" class="btn btn-menu" to="/" exact>Проекты
        <i class="bi bi-chevron-down" @click="toggleMenu('project_types', $event)"></i>
      </router-link>
      <div id="project_types" :class="'filter' + (project_id?'':' open')">
        <div class="checkbox mb-3">
          <input type="checkbox" id="filter_all"
                 @change="filters = $event.target.checked?project_types.map(x=>x.value):[];">
          <label class="text-white" for="filter_all">
            Все
          </label>
        </div>
        <div class="checkbox mb-3" v-for="project_type in project_types">
          <input :id="`type_${project_type.value}`" type="checkbox" v-model="filters" :value="project_type.value">
          <label class="text-white" :for="`type_${project_type.value}`">
            {{ project_type.name }}
          </label>
        </div>

      </div>
      <router-link active-class="active" class="btn btn-menu" to="/projects/stats/" exact>
        Сводные данные
      </router-link>
      <router-link active-class="active" class="btn btn-menu" to="/projects/forecast/stat/" exact>
        План/Факт
      </router-link>
      <router-link active-class="active" class="btn btn-menu" to="/clients/" exact
                   v-if="$store.getters.user.permission.sections.indexOf('clients')+1">
        Организации
      </router-link>
      <router-link active-class="active" class="btn btn-menu" to="/search_quake/" exact
                   v-if="$store.getters.user.permission.sections.indexOf('search_quake')+1">
        SearchQuake
      </router-link>
    </div>
    <div class="menu-group" v-if="project_id">

      <router-link active-class="active" class="btn btn-menu" :to="{name: 'project_detail', params:{id: project_id}}"
                   exact>
        <span :title="project?.name" class="text-truncate">{{ project?.name }}</span> <i class="bi bi-chevron-down"
                                                                                         @click="toggleMenu('project_menu', $event)"></i>
      </router-link>
      <div id="project_menu" class="filter open">
        <ul>
          <li v-for="menu in menu_list">
            <router-link :class="($route.name === menu.path.name)?'active':''" :to="menu.path">
              <i v-if="menu.ico" :class="'bi bi-'+menu.ico"></i> {{ menu.name }}
            </router-link>
            <ul v-if="menu.children" class="child-menu">
              <li v-for="child in menu.children">
                <router-link :class="($route.name === child.path.name)?'active':''" :to="child.path">
                  <i v-if="child.ico" :class="'bi bi-'+child.ico"></i> {{ child.name }}
                </router-link>

              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="menu-group">
      <p class="text-secondary">Избранные проекты</p>
      <router-link v-for="project in $store.getters.user.favorites" active-class="active" class="btn btn-menu"
                   :to="{name: 'project_detail', params:{id: project.id}}">
        {{ project.name }}
      </router-link>
    </div>
  </nav>
</template>
<style>
.child-menu {
  border-left: 1px solid #363535;
  padding-left: 5px!important;
  margin-left: 5px;
  padding-top: 10px;
}
</style>
