<template>
  <div class="div" v-if="diff_date.show">
    <button class="btn btn-sm btn-outline-primary" @click="diff_date.show=false;"><i
        class="bi bi-arrow-left-short me-1"></i>
      Назад
    </button>
    <div class="table-responsive d-flex position-relative search-results"
         style="height: 80vh; overflow: scroll; align-items: start">
      <table class="table sticky table-head">
        <thead>
        <tr>
          <th scope="col" class="text-center no-wrap">#</th>
        </tr>
        </thead>
        <tbody>
        <tr class="text-center engine_info sticky-top table-sub-head">
          <td><i>-</i></td>
        </tr>
        <tr>
          <td class="engine_info">
            <table class="table result" style="margin-top: 15px;">
              <tr class="num" v-for='index in state'>
                <td class="no-wrap link_info engine_info">{{ index }}</td>
              </tr>
            </table>

          </td>
        </tr>
        </tbody>
      </table>
      <table class="table" v-for="(key, key_id, index) in start_result">
        <thead class="sticky-top table-head">
        <tr>
          <th scope="col" class="text-center no-wrap border-end" :colspan="engines.filter(x=>x.active).length*2+1">
            {{ key.name }}
          </th>
        </tr>
        </thead>

        <tbody>
        <tr class="text-center sticky-top table-sub-head no-wrap ">
          <template v-for="(engine, index) in engines.filter(x=>x.active)">
            <td v-for="(date, idx) in [start, end]"
                :class="engines.filter(x=>x.active).length === index+1&&idx===1?'border-end':''">
              <div class="heads">
                <i :class="engine.name"></i>

                <span class="badge text-bg-secondary">{{ date }}</span>
              </div>
            </td>
          </template>
        </tr>
        <tr>
          <template v-for="(engine, index) in engines.filter(x=>x.active)">
            <td>
              <table class="table result">

                <tbody>
                <template v-for="(link, l_index) in key[engine.name]">
                  <tr v-if="l_index < state">
                    <td :class="`no-wrap link_info engine_info${bold_id===link.link_obj_id?' bold':''}`">

                      <span :class="`link`">
                     <a
                         :href="link.link"
                         target="_blank"
                         :style="`${$func.getClass(link)}`" :ref="`link-${link.link_obj_id}`"
                     >{{ link.domain }}
                            </a>

                      </span>
                    </td>

                  </tr>
                </template>

                </tbody>
              </table>
            </td>
            <td :class="engines.filter(x=>x.active).length === index+1?'border-end':''">
              <table class="table result" v-if="end_result[key_id] && end_result[key_id][engine.name]">

                <tbody>
                <template v-for="(link, l_index) in end_result[key_id][engine.name]">
                  <tr v-if="l_index < state">
                    <td :class="`no-wrap link_info engine_info${bold_id===link.link_obj_id?' bold':''}`">

                      <span :class="`link`">
                     <a
                         :href="link.link"
                         target="_blank"
                         :style="`${$func.getClass(link)}`" :ref="`link-${link.link_obj_id}`"
                     >{{ link.domain }}
                            </a>

                      </span>
                    </td>
                    <td class="text-center"
                        @click="bold_id=!bold_id||bold_id!==link.link_obj_id?link.link_obj_id:null"
                        style="cursor: pointer">
                      <span v-html="placeDiff(link, key_id, engine.name)">

                      </span>
                    </td>

                  </tr>
                </template>

                </tbody>
              </table>
            </td>
          </template>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import InfoBlock from "@/components/InfoBlock.vue";
import {ProjectApi} from "@/api/project";
import moment from "moment/moment";

export default {
  name: "DiffDates",
  props: ['diff_date', 'project_id', 'active_country', 'state', 'start', 'end', "engines"],
  components: {
    'info_block': InfoBlock,
  },
  data() {
    return {
      start_result: [],
      end_result: [],
      bold_id: null
    }
  },
  watch: {
    active_country() {
      this.showDiff()
    },
    start() {
      this.showDiff()
    },
    end() {
      this.showDiff()
    }
  },
  mounted() {
    this.showDiff()
  },
  methods: {
    showDiff: function () {

      if (this.diff_date.start && this.diff_date.end) {
        let $this = this;
        let start = $this.diff_date.start;
        ProjectApi.keywords($this.project_id, 100, start, $this.active_country || 'RU', false, 20).then(response => {
          $this.start_result = Object.assign({}, ...response.results.map(x => {
            return {[x.id]: {name: x.name, yandex: x.searches["yandex"], google: x.searches["google"]}}
          }));
        })
        let end = $this.diff_date.end;
        ProjectApi.keywords($this.project_id, 100, end, $this.active_country || 'RU', false, 20).then(response => {
          $this.end_result = Object.assign({}, ...response.results.map(x => {
            return {[x.id]: {name: x.name, yandex: x.searches["yandex"], google: x.searches["google"]}}
          }));
        })

        $this.diff_date.show = true;
      }
    },

    placeDiff(link, key_id, engine) {
      let diff = '<b class="text-success fz10" style="font-size: 10px;"><i class="bi bi-circle-fill"></i></b>'
      let prev_place = this.start_result[key_id][engine].find(x => x.link_obj_id === link.link_obj_id)?.place
      if (moment().format("YYYY-MM-DD") === (link.link_obj__date_create__date || "").split("T")[0]) {
        diff = "<span class=\"badge text-bg-success\" title='Новая ссылка' style='font-size: 10px'>New</span>"
      }
      if (prev_place) {
        diff = prev_place - link.place
      }
      if (diff > 0) diff = `<b class="text-success">+${diff}</b>`
      if (diff < 0) diff = `<b class="text-danger">${diff}</b>`
      if (diff === 0) diff = `<b class="text-warning">${diff}</b>`
      return diff
    }
  }
}
</script>

<style scoped>
.table-sub-head .heads {
  width: 100%;
  white-space: nowrap;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>

<!--.table-sub-head {-->
<!--  top: 40px;-->
<!--  background: #fff;-->
<!--  z-index: 10 !important;-->
<!--}-->

<!--.table-head {-->
<!--  background: #fff;-->
<!--  z-index: 10 !important;-->
<!--}-->

<!--table {-->
<!--  border-collapse: separate-->
<!--}-->

<!--.link_info a {-->
<!--  max-width: 300px;-->
<!--  display: block;-->
<!--}-->

<!--.bold {-->
<!--  background: #dedede;-->
<!--}-->

<!--.fz10 {-->
<!--  font-size: 10px;-->
<!--}-->

<!--.link {-->
<!--  cursor: pointer;-->
<!--}-->

<!--td {-->
<!--  max-width: 400px;-->
<!--  height: 20px;-->
<!--}-->

<!--.table.sticky {-->
<!--  position: sticky;-->
<!--  left: 0;-->
<!--  display: flex;-->
<!--  flex-direction: column;-->
<!--  flex: 1;-->
<!--}-->

<!--.table.sticky td {-->
<!--  background: #fff;-->
<!--  font-weight: bold;-->
<!--}-->

<!--.head {-->
<!--  width: 105px;-->
<!--  flex: 0 0 105px;-->
<!--}-->

<!--.place {-->
<!--  width: 40px;-->
<!--  text-align: left;-->
<!--  margin-left: 0;-->
<!--  flex: 0 0 40px;-->
<!--}-->

<!--.card-body {-->
<!--  padding: 15px;-->
<!--}-->

<!--.scroll-y {-->
<!--  height: 250px;-->
<!--  overflow-y: scroll;-->
<!--}-->

<!--.no-wrap.full-url {-->
<!--  white-space: normal;-->
<!--  height: 130px;-->
<!--}-->

<!--.card-header-tabs li a {-->
<!--  white-space: nowrap;-->
<!--  height: 30px;-->
<!--}-->

<!--.popper.text-start {-->
<!--  max-width: 300px;-->
<!--  white-space: normal;-->
<!--}-->

<!--.engine_info {-->
<!--  height: 20px;-->
<!--  padding: 7px;-->
<!--}-->

<!--.domain-max {-->
<!--  max-width: 374px;-->
<!--  overflow: hidden;-->
<!--  display: block;-->
<!--}-->

<!--.text-right {-->
<!--  text-align: right;-->
<!--}-->

<!--.link_info button.copy {-->
<!--  display: none;-->
<!--}-->

<!--.link_info:hover button.copy {-->
<!--  display: inline-block;-->
<!--}-->

<!--.link_info.full-url button.copy {-->
<!--  display: inline-block;-->
<!--}-->

<!--.link_info button.copy {-->
<!--  position: absolute;-->
<!--  top: 0;-->
<!--  right: 0;-->
<!--  z-index: 10;-->
<!--}-->

<!--.link_info {-->
<!--  position: relative;-->
<!--}-->

<!--.link_display {-->
<!--  background: rgba(13, 110, 253, .25);-->
<!--}-->

<!--.popper .col-4 {-->
<!--  max-width: 60px !important;-->
<!--  width: 60px !important;-->
<!--}-->

<!--.table-sub-head {-->
<!--  top: 40px;-->
<!--  background: #fff;-->
<!--  z-index: 10 !important;-->
<!--}-->

<!--.table-head {-->
<!--  background: #fff;-->
<!--  z-index: 10 !important;-->
<!--}-->

<!--table {-->
<!--  border-collapse: separate-->
<!--}-->

<!--.table.result {-->
<!--  margin-top: 20px;-->
<!--}-->
<!--</style>-->
