<template>
  <main class="dashboard links">
    <div class="mb-4 row">
      <h2 class="col">Wikipedia: {{ project.name }}</h2>

    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive d-flex" style="height: auto; overflow: visible">
          <table class="table">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col"> Ссылка на Wikipedia</th>
              <th scope="col">Отслеживание</th>
              <th scope="col">Страны</th>
              <th scope="col">Последнее обновление</th>
              <th scope="col">Подписка</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(wiki, index) in wikis">
              <WikipediaItem :wiki="wiki"
                             :index="index"
                             :countries="project.countries"
                             :pushWiki="pushWiki"
              ></WikipediaItem>
            </template>
            </tbody>
          </table>
        </div>
        <button title="Добавить запись" class="btn btn-success" @click="wikis.push({edit: true, country: []})">+</button>
      </div>
    </div>


  </main>
</template>

<script>
import {ProjectApi} from "@/api/project";
import {WikiApi} from "@/api/wiki";
import WikipediaItem from "@/views/projects/wikipedia/WikipediaItem.vue";

export default {
  name: "WikipediaView",
  components: {
    WikipediaItem
  },

  data() {
    return {
      project: {},
      wikis: [],
    }
  },
  mounted: function () {
    this.getProject()
  },
  methods: {
    getProject: function () {
      let $this = this;
      ProjectApi.get_simple($this.$route.params.id).then(response => {
        $this.project = response;
        WikiApi.list($this.$route.params.id).then(response => {
          $this.wikis = response;
          $this.wikis.sort((x, y) => Number(y.collect) - Number(x.collect));
        })
      })
    },
    pushWiki: function (old, response){
     this.wikis.splice(this.wikis.indexOf(old), 1, response)
    }

  },
}
</script>

