<script setup>
import {onMounted, getCurrentInstance, computed} from "vue";
import Header from "@/components/Header.vue";
import MainMenu from "@/components/Menu.vue";
import TextProjects from "@/text/includes/TextProjects.vue";
import 'bootstrap-icons/font/bootstrap-icons.css'
import {useStore} from 'vuex'
import axios from "axios";

const store = useStore()

import(`@/assets/scss/desktop/main.scss`)
import("bootstrap/js/index.esm")
import {state} from "@/socket";

const connected = computed(() => state);

const $modal = getCurrentInstance().appContext.config.globalProperties.$modal;

function getVersion(compare = false) {
  const axiosInstance = axios.create({
    baseURL: location.origin,
  });
  axiosInstance({
    url: `/version.json?v=${+new Date()}`,
    method: "get",
    params: {},
  }).then((response) => {
    if (compare) {
      const local_version = localStorage.getItem("version")
      if (response.data?.version && local_version !== response.data.version) {
        $modal('show', 'new_version')
      }
    }
    if (response.data?.version) localStorage.setItem("version", response.data.version)
  }).catch(() => {
    console.warn("Can't get current version")
  });
  // }
}

function reloadPage() {
  location.reload(true);
}

onMounted(() => {
  getVersion();
  setInterval(function () {
    store.dispatch('refresh_token');
  }, 1000 * 60 * 5);

  setInterval(function () {
    getVersion(true)
  }, 1000 * 60 * 3);

})


</script>
<template>
  <div>
    <Header></Header>
    <notifications/>
    <template v-if="$store.getters.user.permission || $route.meta.notAuth">
      <div class="wrapper">
        <template v-if="$store.getters.isLoggedIn">
          <template v-if="$route.meta.base_name">
            <TextProjects></TextProjects>
          </template>
          <template v-else>
            <MainMenu></MainMenu>
          </template>
        </template>

        <section :class="$store.getters.isLoggedIn?' bg-white':''">
          <router-view/>
        </section>
      </div>

      <span class="loader" v-if="$store.getters.load">
<!--                   <span class="loader">-->
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                        </span>


    </template>
    <template v-else-if="$route.name === 'PageNotFound'">
      <div class="wrapper">
        <section class="bg-white">
          <router-view/>
        </section>
      </div>
    </template>
    <template v-else>
      <div class="load-logo">
        <svg viewBox="0 0 41 22" fill="black" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="left-to-right">
              <stop offset="0" stop-color="#000000">
                <animate dur="0.5s" attributeName="offset" fill="freeze" from="0" to="1"/>
              </stop>
              <stop offset="0" stop-color="#fff">
                <animate dur="0.5s" attributeName="offset" fill="freeze" from="0" to="1"/>
              </stop>

            </linearGradient>
          </defs>
          <path
              d="M16.9412 21.9956H20.8394L28.9299 7.35218L35.1366 18.5852H28.9299V21.9956H37.0204H38.6145H40.9208L28.9299 -3.05176e-05L16.9412 21.9956Z"
              fill="url(#left-to-right)"/>
          <path
              d="M14.8133 13.1299C15.5538 12.4395 16.133 11.6294 16.5534 10.6995C16.9737 9.77173 17.1849 8.77648 17.1849 7.7159C17.1849 6.65532 16.9671 5.66007 16.5316 4.73233C16.096 3.80459 15.5037 2.9901 14.7567 2.29321C14.0097 1.59414 13.1342 1.04316 12.1281 0.638092C11.122 0.233023 10.044 0.030489 8.8919 0.030489H8.77648C8.50208 0.0130667 8.2255 0 7.94239 0H0V2.8137H8.37795C9.11186 2.8137 9.7848 2.95308 10.3968 3.23401C11.0087 3.51495 11.5379 3.87864 11.9822 4.32944C12.4265 4.78024 12.7836 5.30509 13.0493 5.90616C13.3172 6.50723 13.45 7.11701 13.45 7.73768C13.45 8.35835 13.3172 8.99208 13.0493 9.58444C12.7814 10.1746 12.4265 10.6995 11.9822 11.1612C11.5379 11.6207 11.0087 11.9909 10.3968 12.2718C9.7848 12.5528 9.11186 12.6921 8.37795 12.6921H3.57157L0 12.5811V22H3.57157V15.5058H7.94457C8.25599 15.5058 8.54564 15.495 8.81133 15.4754C9.07919 15.4558 9.34488 15.4253 9.61275 15.3861L13.3847 22H17.1566L12.8881 14.4627C13.609 14.1033 14.2514 13.6591 14.8133 13.1342V13.1299Z"
              fill="url(#left-to-right)"/>
        </svg>
      </div>
    </template>
    <modal modal_id="new_version">
      <template v-slot:header>
        <h2>
          Обнаружена новая версия сервиса!
        </h2>
      </template>

      <template v-slot:body>
        <p>Рекомендуется обновить страницу через Ctrl + F5</p>
      </template>
      <template v-slot:footer>
        <button class="btn btn-sm btn-success" @click="reloadPage()">Обновить страницу</button>
        <button class="btn btn-sm btn-secondary" @click="$modal('hide', 'new_version')">Закрыть</button>
      </template>
    </modal>
  </div>

</template>
