import {HTTP} from "./common";

export const DonorApi = {
    list(filter) {
        return HTTP.get(`donors/?${filter}`).then((response) => {
            return response.data;
        });
    },
    get(id) {
        return HTTP.get(`donors/${id}/`).then((response) => {
            return response.data;
        });
    },
    save(id, object) {
        if (!id) return HTTP.post('donors/', object).then(response => {
            return response.data
        });
        else return HTTP.put(`donors/${id}/`, object).then(response => {
            return response.data
        })
    },
    placement_list() {
        return HTTP.get('donors/placement_list/').then((response) => {
            return response.data;
        });
    },

};
