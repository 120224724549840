<template>

<modal :modal_id="'success_save'">
  <template v-slot:header>
   <p> Проект успешно сохранён</p>
  </template>

  <template v-slot:body>
    <div v-if="save_errors.length">
            <small><strong>При сохранении произошли следующие ошибки:</strong></small>
          <ul class="form-group__message">
            <li v-for="error in save_errors">{{error}}</li>
          </ul>
            </div>
  </template>

  <template v-slot:footer>
    <button type="button" class="btn btn-secondary" @click="$modal('hide', 'success_save');"
                  data-bs-dismiss="modal">Продолжить редактирование
          </button>
          <button type="button" class="btn btn-secondary" v-on:click="$router.push(`/projects/${project.id}`)">Перейти к просмотру</button>
  </template>
</modal>
</template>

<script>

export default {
  name: "ModalSave",
  props: ["save_errors", "project"],
  data(){
    return{
      isModalVisible: false
    }
  },
  mounted() {
    this.$modal['showed']
  //   this.$vfm.open("modalId")
  }
}
</script>

