<template>
  <main class="dashboard links">
    <div class="mb-2">
      <h2>План/факт</h2>
    </div>
    <div class="filters d-flex">
      <date-picker
          v-model="date"
          locale="ru"
          :clearable="false"
          format="dd.MM.yyyy"
          model-type="yyyy-MM-dd"
          auto-apply
          :enable-time-picker="false"
      ></date-picker>
        <CountryChoice v-bind:active_country.sync="active_country"
                     v-bind:countries="countries"
                     v-bind:changeCountry="(country) => active_country = country"></CountryChoice>


    </div>
    <div class="card">

      <div class="card-body">
        <div class="row header">
          <div class="col"><b>Проект</b></div>
          <div class="col-2 text-center"><b>Отчётная дата</b></div>
          <div class="col-2 text-center"><b>Факт за выбранный Топ</b></div>
          <div class="col-1 text-center"><b>План</b></div>
        </div>

        <div class="row forecast" v-for="(forecast, index) in forecasts">
          <div class="col p-1">
            <router-link :to="`/projects/${forecast.id}`">{{ forecast.name }}</router-link>
            (ТОП{{ forecast.state }})
          </div>
          <div class="col-2 text-center">
            {{ forecast.report_date }}
          </div>
          <div class="col-2 p-1 text-center">
            {{ forecast.fact }}
          </div>
          <div class="col-1 p-1 text-center">
            <span :class="forecast.fact > forecast.plan ? 'text-danger' : 'text-success'">
            {{ forecast.plan }}
              </span>
          </div>


        </div>
      </div>
    </div>

  </main>
</template>

<script>
import {ProjectApi} from "@/api/project";
import moment from 'moment';

export default {
  name: "EditForecast",
  components: {},
  data() {
    let date = new Date();
    return {
      date: moment().format("YYYY-MM-DD"),
      countries: [],
      state: 20,
      active_country: null,
      forecasts: [],
    };
  },
  watch: {
    active_country: function () {
      this.getPlan();
    },
    date: function () {
      this.getPlan();
    }
  },
  mounted: function () {
    let $this = this;
    ProjectApi.countries().then(resp => {
      $this.countries = resp.results;
      $this.active_country = $this.countries[0];
      this.getPlan()
    });

  },
  methods: {
    getPlan: function () {
      let $this = this;
      let filters = {
        state: $this.state,
        country: $this.active_country ? $this.active_country.code : "RU",
        date: $this.date
      };
      let f_string = new URLSearchParams(filters).toString();
      ProjectApi.planData(f_string).then(resp => {
        $this.forecasts = resp;
      });
    }
  }
}
</script>

<style scoped>
.row.header {
  font-size: 12px;
  background: #eeeeee;
  position: sticky;
  top: 0;
}

.row.forecast {
  border-bottom: 1px solid #eee;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
</style>
