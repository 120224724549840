<template>

  <main class="form-signin">
    <form @submit.prevent="changePassword" class="">
      <h3 class="h3 mb-3 text-white text-center">Изменение пароля</h3>
      <!--          <transition name="fade">-->
      <div class="card" v-if="reg_end">

        <div class="card-body text-center">
          <h4 class="card-text mb-3">
            Пароль успешно изменён
          </h4>
          <p>Можете авторизоваться в сервисе используя свой email и пароль</p>
          <small>
            Вы будете автоматически перенаправлены на вход через
            <strong>{{ currentTime }}</strong>
          </small>
        </div>

      </div>
      <!--          </transition>-->
      <!--          <transition name="fade">-->
      <div class="card" v-if="errors.length">

        <div class="card-body text-center text-danger border border-danger form-group__message">
          <div class="error" v-for="error in errors">
            {{ error }}
          </div>

        </div>

      </div>
      <!--          </transition>-->

      <template v-if="can_change && !errors.length && !reg_end">
        <div class="mb-3 password">
          <input class="form-control grey" :type="show_pass?'text':'password'" name="" required
                 v-model="newPassword" placeholder="Пароль">
           <i v-on:click="show_pass=!show_pass"
             :class="`bi bi-eye${show_pass?' active':''}`"></i>
          <div class="form-group__message text-danger">
            <div class="error" v-for="error in newErrors">- {{ error }}</div>
             <div class="error" v-for="error in baseErrors">- {{ error }}</div>
          </div>
        </div>
        <div class="mb-3 password">
          <input class="form-control grey" :type="show_pass?'text':'password'" name="" required
                 v-model="repeatPassword" placeholder="Повторите новый пароль">
          <i v-on:click="show_pass=!show_pass"
             :class="`bi bi-eye${show_pass?' active':''}`"></i>
          <div class="form-group__message text-danger">
            <div class="error" v-for="error in repeatErrors">- {{ error }}</div>
          </div>
        </div>
         <button class="w-100 btn btn-lg btn-secondary text-white" type="submit">Восстановить</button>
      </template>
    </form>

  </main>

</template>
<script>
import passwordMixin from "./password_mixins";
import axios from 'axios'
import {UserApi} from "@/api/user";

export default {
  name: 'SetPassword',
  mixins: [passwordMixin],
  data() {
    return {
      errors: [],
      can_change: false,
      currentTime: 5,
      timer: null,
      reg_end: false,
    }
  },
  destroyed() {
    this.stopTimer()
  },
  mounted: function () {
    let $this = this;
    UserApi.check_token(this.$route.params.uid, this.$route.params.token).then(response => {
      if (response.data['errors']) {
        $this.errors = response.data.errors;
      } else $this.can_change = true;
    });
  },
  methods: {
    changePassword: function (e) {
      let $this = this;
      if (!this.repeatErrors.length && !this.repeatErrors.length) {
        this.baseErrors = [];
        axios.post('/auth/staff/forget_password/', {
          token: $this.$route.params.token,
          uid: $this.$route.params.uid,
          newp: $this.newPassword,
          repeat: $this.repeatPassword
        }).then((response) => {
           if (response.data['errors']) {
            $this.baseErrors = response.data['errors'];
          } else {
            this.$notify({
              type: 'success ',
              text: 'Пароль обновлён'
            });
              $this.reg_end = true;
            $this.startTimer();
          }
        }).catch(err => {
      $this.$notify({
        type: 'error ',
        text: err.response.data
      })
    })}},

    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer();
        this.$router.push({name: "login"});
      }
    }
  },
}
</script>
