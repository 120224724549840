<template>
  <main class="dashboard">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h2>{{ name }}</h2>
      <BackButton></BackButton>
    </div>


    <div class="card">
      <div class="card-header">
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="input-group mb-3">
            <div class="form-group">
              <label>Тональность</label>
              <MultiSelect v-if="filter_tones.length"
                           v-bind:model.sync="filter.tone"
                           v-bind:options="filter_tones"
                           v-bind:multi="true"
                           v-bind:only_value="true"
                           v-bind:name="''"></MultiSelect>
            </div>
            <div class="form-group ps-sm-2 col-4">
              <label for="" class="text-white">Поиск</label>
              <button class="btn btn-outline-primary form-control" v-on:click.prevent="getData()" type="button"
                      id="button-query"><i class="bi bi-search"></i>Показать
              </button>
            </div>

            <div class="form-group ms-3">
              <label>Запрос</label><br>
              <CountryChoice v-if="active_country.code" v-bind:active_country.sync="active_country"
                             v-bind:project_id="$route.params.id"
                             v-bind:countries="project.countries"
                             v-bind:changeCountry="changeCountry"></CountryChoice>
            </div>


          </div>
        </div>
        <div class="col">

        </div>
      </div>
      <div class="card-body">

        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">№ п/п</th>
              <th scope="col" v-if="!query.uniq">Ссылка<i v-on:click="changeOrder('link')"
                                                          :class="`link ${getOrderClass('link')}`"></i>
              </th>

              <th scope="col" v-if="!query.uniq">Тон ссылки<i v-on:click="changeOrder('tone_name');"
                                                              :class="`link ${getOrderClass('tone_name')}`"></i></th>
              <th scope="col">Изображение<i v-on:click="changeOrder('domain')"
                                            :class="`link ${getOrderClass('domain')}`"></i>
              </th>
              <th scope="col">Тон<i v-on:click="changeOrder('tone_name');"
                                    :class="`link ${getOrderClass('tone_name')}`"></i></th>
              <th scope="col" v-if="!query.uniq">Тип изображения</th>
              <th scope="col">Запрос/Позиции</th>

            </tr>
            </thead>
            <tbody>
            <template v-for="(link, index) in table">
              <tr>
                <td>{{ index + 1 + (page - 1) * size }}</td>
                <td v-if="!query.uniq">
                  <a :href="link.url" target="_blank" :style="getClass(link.link_tone)">{{ link.url_domain }}</a>
                </td>

                <td v-if="!query.uniq"><span
                  :style="getClass(link.link_tone)">{{ link.get_link_tone_display || "Неразмечено" }}</span>
                </td>
                <td>
                  <a v-if="!link.image.startsWith('https://')"  :href="`https://papi.reputation-analytics.ru${link.image}`" target="_blank">
                  <img :src="`https://papi.reputation-analytics.ru${link.image}`" alt="" width="150">
                </a>
                  <a v-else :href="link.image" target="_blank">
                  <img :src="link.image" alt="" width="150">
                </a>
                </td>
                <td>
                  <div class="dropdown no-wrap">
                    <span :style="getClass(link.tone)">{{
                        link.get_tone_display ? link.get_tone_display : 'Неразмечена'
                      }}</span>
                    <template v-if="project.project_type !== 'archive'">
                      <a class="dropdown-toggle p-0" href="#" id="dropdownMenuLink" data-bs-toggle="dropdown"
                         aria-expanded="false">
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li v-for="(tone, key) in  tones(link)"
                            v-on:click="link.get_tone_display=tone.name;
                          link.tone=key;
                          changeTone(link);"
                            class="dropdown-item link">{{ tone.name }}
                        </li>
                      </ul>
                    </template>
                  </div>
                </td>
                <td v-if="!query.uniq">{{ link.get_type_picture_display }}</td>
                <td>
                  <div class="keys" v-for="keyword in link.keywords">
                    <div class="key">{{ keyword.key }}:</div>
                    <div class="place"> {{ keyword.place }}<i :class="keyword.engine"></i>
                    </div>
                    <br>
                  </div>
                </td>


              </tr>
            </template>
            </tbody>
          </table>
          <nav v-if="pager.length > 1 ">
            <ul class="pagination justify-content-center fz-9" v-if="pager.length">
              <li :class="`page-item${p==page?' active':''}`" v-for="p in pager">
                <a class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
              </li>
            </ul>
          </nav>


        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {ProjectApi, LinkApi} from '@/api/project'
import {PictureApi} from '@/api/pictures'
import CountryChoice from '@/components/CountryChoice';
import MultiSelect from '@/plugins/Select';

export default {
  name: 'LinksInfo',
  components: {CountryChoice, MultiSelect},

  data() {
    return {
      links: [],
      project: {},
      filter: {tone: [],},
      active_country: {code: null, yandex: true, google: true},
      keywords: [],
      order_by: '',
      page: 1,
      show_all: false,
      size: 50,
      all_count: 0,
      q: '',
    }
  },
  computed: {
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      let pages = Array.from({length: page_count}, (_, i) => i + 1);
      let array = [];
      if (this.page > 5) {
        array = array.concat(pages.slice(0, this.page - 3 > 3 ? 3 : this.page - 3));
        array.push('...')
      }
      array = array.concat(pages.slice(this.page - 4 > 0 ? this.page - 4 : 0, this.page + 3));
      if (this.page < (pages.length - 5)) {
        array.push('...');
        array = array.concat(pages.slice(pages.length - 3, pages.length));
      }
      return array;
    },
    query: function () {
      return this.$route.query
    },
    name: function () {
      let name = `${this.project.name}: `
      if (this.query.page_name) name += this.query.page_name;
      else {
        if (this.query.date) name += `${this.query.date} `
        name += `${this.query.uniq ? 'Уникальные изображения ' : 'Неуникальные изображения '}`
        if (this.query.state) name += `ТОП${this.query.state} `
        if (this.query.country_code) name += `${this.query.country_code} `
        if (this.query.engine) name += `${this.query.engine} `
        if (this.query.tone && this.query.tone !== 'null' && this.query.tone.indexOf('null') + 1) {
          let tones = this.query.tone;
          if (typeof tones === 'string') tones = [tones];
          tones.forEach(tone => name += `${this.$TONES[tone] ? this.$TONES[tone].name : ''} `)
        }

      }
      return name
    },
    filter_tones: function () {
      return Object.keys(this.$TONES).map(x => {
        return {id: x, name: this.$TONES[x].name}
      })
    },
    filter_type_tones: function () {
      let types_name = {};
      Object.values(this.$TONES).map(x => Object.assign(types_name, x.type_tones));
      return Object.keys(types_name).map(x => {
        return {id: x, name: types_name[x]}
      })
    },
    tones: function () {
      let vm = this;
      return function (link) {
        return vm.$func.getTones(link.type_tone);
      };
    },
    type_tones: function () {
      let vm = this;
      return function (link) {
        return vm.$getTypes(link.tone);
      };
    },
    table() {
      let links = this.links, order = this.order_by, $this = this;

      if (order) {
        if (order.indexOf('-') + 1) {
          order = order.slice(1);
          links.sort(function (a, b) {
            return $this.compareObjects(b, a, order);
          });
        } else {
          links.sort(function (a, b) {
            return $this.compareObjects(a, b, order);
          });
        }
      }
      return links;
    }
  },
  watch: {

    page: {
      deep: true,
      handler(val) {
        this.getData();
      }
    }
  },
  mounted: function () {
    if (this.$route.query.tone) {
      let tone = this.$route.query.tone
      if (typeof tone === 'string') tone = [tone]
      this.filter.tone = tone;
    }
    if (this.$route.query.kind_type) {
      let kind_type = this.$route.query.kind_type
      if (typeof kind_type === 'string') kind_type = [kind_type]
      this.filter.kind_type = kind_type;
    }
    if (this.$route.query.type_tone) {
      let type_tone = this.$route.query.type_tone
      if (typeof type_tone === 'string') type_tone = [type_tone]
      this.filter.type_tone = type_tone;
    }
    this.getData();
    let $this = this;
    ProjectApi.get_simple(this.$route.params.id).then(resp => {
      this.project = resp;
      if ($this.query.country_code) $this.active_country = $this.project.countries.filter(x => x.code === $this.query.country_code)[0];
      else $this.active_country = $this.project.active_country_code;
    })
  },
  methods: {
    getClass: function (tone) {
      let COLORS = this.$COLORS;
      return `color: ${COLORS[tone] !== undefined ? COLORS[tone] : '#000000'}`;
    },

    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.getData();
        this.getKeywords();
      }
    },

    getData: function () {
      let $this = this, data = this.$route.query;
      if (this.active_country.code) data.country_code = this.active_country.code;
      data = {...data, ...this.filter}
      let query = Object.entries(data).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      query.push(`page=${$this.page}`)
      query.push(`size=${$this.size}`)
      query.push(`q=${$this.q}`)
      PictureApi.links_info($this.$route.params.id, query.join('&')).then(response => {
        $this.links = response.results;
        $this.all_count = response.count;
      })

    },
    compareObjects: function (object1, object2, key) {
      const obj1 = object1[key] != null ? typeof object1[key] == 'string' ? object1[key].toUpperCase() : object1[key] : '';
      const obj2 = object2[key] != null ? typeof object2[key] == 'string' ? object2[key].toUpperCase() : object2[key] : '';
      if (obj1 < obj2) {
        return -1
      }
      if (obj1 > obj2) {
        return 1
      }
      return 0
    },
    changeOrder: function (name) {
      if (this.order_by === name) {
        if (name.indexOf('-') + 1) this.order_by = name.slice(1);
        else this.order_by = '-' + name
      } else this.order_by = name

    },
    getOrderClass: function (name) {
      let o_class = 'bi bi-caret';
      if (this.order_by.indexOf(name) + 1) {
        if (this.order_by.indexOf('-') + 1) o_class += '-up';
        else o_class += '-down';
        o_class += '-fill'
      } else o_class += '-down';
      return o_class;
    },
    changeTone: function (result) {
      let $this = this;
      if (!this.query.uniq) {
        $this.links.forEach(function (e) {
          if (e.img_obj_id && e.img_obj_id === result.img_obj_id) {
            e.tone = result.tone;
            e.get_tone_display = result.get_tone_display;
          }
        })
        PictureApi.update(result.img_obj_id, result).then(response => {
          $this.$notify({
            type: 'success ',
            text: 'Данные обновлены'
          });
        })
      } else {
        PictureApi.update(result.id, result).then(response => {
          $this.$notify({
            type: 'success ',
            text: 'Данные обновлены'
          });
        })
      }
    },

  }
}

</script>


