import { HTTP } from "./common";

export const TonApi = {
  list() {
    return HTTP.get(`tr_online/full_list/`).then((response) => {
      return response.data;
    });
  },
  project(project_id) {
    return HTTP.get(`tr_online/?project=${project_id}&page=1&size=100`).then((response) => {
      return response.data;
    });
  },
  post(data) {
    return HTTP.post('tr_online/', data).then((response) => {
      return response.data;
    });
  },
  delete(id) {
    return HTTP.delete(`tr_online/${id}/`);
  }
};
