import { ProjectApi } from "@/api/project";

const Project = {
  state: {
    projects: [],
    search: "",
    load: false,
    project_filters: []
  },
  mutations: {
    setFilters(state, filters) {
      state.project_filters = filters;
    },
    addObject(state, object) {
      state.projects = [...state.projects, object];
    },
    updateObject(state, object) {
      let index = state.projects.indexOf(
        state.projects.find((x) => x.id === object.id)
      );
      if (index + 1)
        state.projects.splice(
          state.projects.indexOf(
            state.projects.find((x) => x.id === object.id)
          ),
          1,
          object
        );
      else state.projects.push(object);
    },
    updateListSort(state, objects) {
      ProjectApi.change_sort(objects);
      state.projects = objects;
    },
    setSearchQ(state, q) {
      state.search = q;
    },
    setObjects(state, objects) {
      state.projects = objects.list.results;
    },
    loader(state, status) {
      state.load = status;
    },
  },
  actions: {
    updateFilters({ commit }, filters) {
      commit("setFilters", filters);
    },
    updateObject({ commit }, objectData) {
      if (!objectData.id) commit("addObject", objectData);
      else commit("updateObject", objectData);
    },
    projects({ commit }, filter) {
      if (this.getters.isLoggedIn)
        ProjectApi.list(filter).then((projects) => {
          commit("setObjects", { list: projects });
        });
    },
    loader({ commit }, status) {
      commit("loader", status);
    },
  },
  getters: {
    projects: (state) =>
      state.projects.filter((project) => {
        return (
          project.name.toLowerCase().indexOf(state.search.toLowerCase()) + 1
        );
      }),
    load: (state) => state.load,
    search: (state) => state.search,
    project_filters: (state) => state.project_filters
  },
};

export default Project;
