import { HTTPText } from "./common";

export const TextProjectApi = {
  list() {
    return HTTPText("get", `projects/`).then((response) => {
      return response.data;
    });
  },

  update(object) {
    if (object.id)
      return HTTPText("put", `projects/${object.id}/`, object).then((response) => {
        return response.data;
      });
    else
      return HTTPText("post", "projects/", object).then((response) => {
        return response.data;
      });
  },

  get(id) {
    return HTTPText("get", `projects/${id}/`).then((response) => {
      return response.data;
    });
  },
  recalc_unique(id) {
    return HTTPText("post", `projects/${id}/recalc_unique/`).then((response) => {
      return response.data;
    });
  },

  load_by_xlsx(id, data){
    return HTTPText("post", `projects/${id}/load_by_xlsx/`, data, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
      return response.data;
    });
  },

};
