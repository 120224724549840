<template>

  <li :class="'list-group-item list-group-item-action d-flex gap-3'+(alert.delete?' bounce-leave-active':'')+(!alert.read?' no-read':'')">
    <i :class="`fs-1 bi bi-exclamation-octagon-fill text-${alert.color}`"></i>
    <div class="d-flex gap-2 w-100 justify-content-between">
      <div>
        <h6 class="d-flex flex-column">{{ alert.text }}<a v-if="alert.link" target="_blank" @click="setRead" :href="alert.link"
                                                                        class="btn btn-sm btn-outline-dark">Показать</a>
        </h6>
      </div>
      <div class="d-flex">
        <small class="opacity-50 text-nowrap">{{
            $moment(alert.date_create).format("HH:mm")
          }}<br>{{ $moment(alert.date_create).format("DD.MM") }}</small>
        <button v-if="!alert.read" title="Отметить прочитанным" @click="alert.delete=true;setRead(alert)"
                class="btn btn-sm btn-outline-dark"><i
            class="bi bi-bookmark-check"></i></button>
      </div>

    </div>

  </li>

</template>

<script>

import {AlertApi} from "@/api/alerts";

export default {
  name: 'AlertItem',
  props: {
    alert: {
      type: Object, default() {
        return {}
      }
    },
  },
  methods: {
    setRead() {
      let data = JSON.parse(JSON.stringify(this.alert))
      data.read = true
      AlertApi.post(data).then(response => this.$store.dispatch("updateAlert", response))
    }
  }
}
</script>
<style scoped>

.list-group-item {
  padding: 10px;
}
.no-read{
  background: #e7e7e7;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
