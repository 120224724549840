<template>
  <main class="dashboard links">
    <div class="card mt-3 " :style="{'overflow-x': 'hidden'}">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item col-3">
            <h3>{{ project.name }}: {{ names[$route.params.type] }}</h3>
          </li>

        </ul>

      </div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-5">
            <MultiSelect
                v-model="selected_links"
                :options="links_filter_options.map(x=>{return {id: x, name:x}})"
                :multi="true"
                :only_value="true"
                name="начните вводить ссылку">
            </MultiSelect>
          </div>
          <div class="col-5 ">
            <MultiSelect
                v-model="selected_backlinks"
                :options="backlinks_filter_options.map(x=>{return {id: x, name:x}})"
                :multi="true"
                :only_value="true"
                name="начните вводить backlink">
            </MultiSelect>
          </div>

        </div>


        <div class="row mb-2">
          <div class="col-5">
            <MultiSelect
                v-model="selected_rels"
                :options="rels_options"
                :multi="true"
                field_id="label"
                field_name="value"
                :only_value="true"
                name="выберите REL">
            </MultiSelect>
          </div>
          <div class="col-5">
            <MultiSelect
                v-model="selected_statuses"
                :options="statuses_options.map(x=>{return {id: x, name:x}})"
                :multi="true"
                :only_value="true"
                name="выберите статус">
            </MultiSelect>
          </div>
        </div>


        <div class="row mb-2">
          <div class="col-5">
            <MultiSelect
                track-by="id"
                label="email"
                v-model="selected_creators"
                :options="creator_options.map(x=>{return {id: x, name:x}})"
                :multi="true"
                :only_value="true"
                name="выберите автора">
            </MultiSelect>
          </div>

        </div>

        Период:
        <div class="d-flex align-items-center justify-content-start col-5">
          <div class="me-1 ">c</div>
          <div class="me-1">
            <date-picker
                v-model="start_date"
                locale="ru"
                :clearable="false"
                format="dd.MM.yyyy"
                model-type="yyyy-MM-dd"
                auto-apply
                :enable-time-picker="false"
            ></date-picker>
          </div>
          <div class="me-1">по</div>
          <div class="me-1">
            <date-picker
                v-model="end_date"
                locale="ru"
                :clearable="false"
                format="dd.MM.yyyy"
                model-type="yyyy-MM-dd"
                auto-apply
                :enable-time-picker="false"
            ></date-picker>
          </div>

        </div>


        <div :style="{'display': 'flex', 'align-items': 'baseline', 'justify-content': 'space-between'}">
          <div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="unverified_backlinks_flag" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">Показывать непроверенные backlinks</label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="show_backlink_creator"
                     id="ShowBacklinkCreatorCheckBox">
              <label class="form-check-label" for="ShowBacklinkCreatorCheckBox">Показывать автора baklink'а</label>
            </div>

          </div>


          <button type="button" class="btn btn-primary" @click="click_apply_filters()">Применить</button>
        </div>


        <div class="mb-1" v-if="check_role!= 'analyst'">
          <button
              type="button"
              class="btn btn btn-success btn-sm"
              @click="result.unshift(
                    {
                        id:'',
                        link:'',
                        link_create_date:format_date_for_new_backlink(new Date(Date.now())),
                        backlinks:[
                            {
                                backlink:'',
                                create_date: format_date_for_new_backlink(new Date(Date.now())),
                                history: [],
                            }
                        ],
                    }
                )">Добавить ссылку
          </button>

          <button
              class="btn btn-primary btn-sm"
              type="button"
              title="выгрузить в Excel"
              @click="click_get_excel_report()"
          ><i class="bi bi-file-earmark-arrow-down"></i>
          </button>

          <button
              class="btn btn-dark btn-sm"
              type="button"
              title="проверть статусы"
              @click="click_start_scrapping_backlink()"
          ><i class="bi bi-arrow-clockwise"></i>
          </button>

          <button class="btn btn btn-warning btn-sm" type="button" @click="$router.push({name: 'backlink_stat'})">
            Посмотреть статстику
          </button>


        </div>

        <nav v-if="pages_list ">
          <ul class="pagination justify-content-center fz-9" v-if="pages_list">
            <li :class="`page-item${p==current_page?' active':''}`" v-for="p in pages_list">
              <a class="page-link" @click.prevent="current_page=p, send_request() " href="">{{ p }}</a>
            </li>
          </ul>
        </nav>


        <div class="table-responsive">
          <table class="table">

            <tr class="sticky-top">
                <th><b>Дата
                  <popper hover>
                     <span slot="reference">
                      <i class="bi bi-question-circle"></i>
                    </span>
                    <template #content>
                    <span class="popper text-start">
                      Дата появления ссылки в базе
                    </span>
                    </template>
                  </popper>
                </b></th>
                <th><b>Ссылка</b></th>
                <th><b>Дата</b></th>
                <th><b>{{ names[$route.params.type] }}</b></th>
                <th></th>
                <th v-if="show_backlink_creator">автор</th>
                <th class="col-date-haed" v-for="date in backlinks_history_dates">{{ date }}
                  <div class="d-flex justify-content-between pb-0">
                    <div class="col-rel">REL</div>
                    <div class="col-status">Статус</div>
                  </div>
                </th>
            </tr>

            <template class="gray" v-for="(res, link_index) in result">

              <tr class="gray" v-for="(back, backlink_index) in  res.backlinks">

                <td v-if="res.backlinks.indexOf(back)==0">{{ format_date(res.link_create_date) }}</td>
                <td v-else></td>

                <td v-if="res.backlinks.indexOf(back)==0" style="white-space: normal;">
                  <div v-if="res.id" class="text-truncate">
                    <a :href="res.link" :title="res.link" target="_blank">{{ res.link }}</a></div>
                  <div v-else>
                    <input class="form-control" v-model="res.link" :ref="'input_link'+link_index">
                  </div>
                </td>
                <td v-else></td>

                <td>
                  <div v-if="back.id">{{ format_date(back.create_date) }}</div>
                  <div v-else>
                    <input v-model="back.create_date" type="date" :max="(new Date)|moment('YYYY-MM-DD')"
                           style="width:100%;">
                  </div>
                </td>

                <td style="white-space: normal;">
                  <div>
                    <!-- Если зашел аналитик, то выводим тольуо ссылку бэклинка -->
                    <div v-if="(back.history.length>0) || (check_role == 'analyst')" class=" text-truncate">
                      <a :href="'://'+back.backlink" :title="back.backlink" target="_blank">{{ back.backlink }}</a>
                    </div>

                    <input v-else class="form-control" v-model="back.backlink"
                           @change="back.id?click_save_backlink(link_index,backlink_index):''"
                           :ref="'input_backlink'+link_index+'-'+backlink_index">

                  </div>
                </td>

                <td style="height: 1px;" v-if="check_role != 'analyst'">
                  <div style=" height: 100%;">
                    <div class="col-3" style="display: flex;">
                      <!-- <button class="btn btn-danger" type="button" title="удалить" @click="click_delete_backlink(link_index,backlink_index)"><i class="bi bi-x-lg"></i></button> -->
                      <button v-if="res.backlinks.indexOf(back)==0"
                              class="btn btn-success backlink-action-button"
                              title="добавить backlink"
                              @click="res.backlinks.unshift(
                                            {
                                                backlink:'',
                                                create_date: format_date_for_new_backlink(new Date(Date.now())),
                                                history: [],
                                            }
                                        )"><i class="bi bi-plus-lg"></i>
                      </button>
                      <button class="btn btn-danger backlink-action-button" type="button" title="удалить backlink"
                              @click="click_show_modal_for_delete_backlink(link_index,backlink_index)"><i
                          class="bi bi-x-lg"></i></button>
                      <button class="btn btn-warning backlink-action-button" type="button" v-if="!back.id"
                              title="сохранить backlink" @click="click_save_backlink(link_index,backlink_index)"><i
                          class="bi bi-save"></i></button>
                    </div>
                  </div>
                </td>
                <td v-else></td>

                <td v-if="show_backlink_creator">{{ back.creator.email ? back.creator.email : '-' }}</td>


                <td v-for="his_date in backlinks_history_dates" class="border">
                  <div v-if="back.history.find(x => x.date === his_date)" class="d-flex justify-content-between pb-0">
                    <div class="col-rel">{{ back.history.find(x => x.date === his_date).rel }}</div>
                    <div
                        :class="`${back.history.find(x => x.date === his_date).status==='200'?'bg-success text-white ':''}col-status`">
                      {{ back.history.find(x => x.date === his_date).status }}
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </table>

        </div>
      </div>

      <modal modal_id="delete_backlink">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Удалить backlink ?</h5>
            <button type="button" class="btn-close" @click="$modal('hide', 'delete_backlink');" data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body d-flex">

            <h1 class="text-danger"><i class="bi bi-exclamation-lg"></i></h1>
            <p> Сбор данных по backlink'у: {{ deleted_backlink.backlink.backlink_url }} будет прекращен, вся стория
              проверки бэклинка будет безвозвратно утеряна</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$modal('hide', 'delete_backlink');"
                    data-bs-dismiss="modal">Отмена
            </button>
            <button type="button" class="btn btn-danger"
                    v-on:click="click_delete_backlink(deleted_backlink.link.link_index,deleted_backlink.backlink.backlink_index );">
              Подтвердить
            </button>
          </div>
        </div>
      </modal>
    </div>

  </main>

</template>

<script>
import {ProjectApi} from "@/api/project";
import moment from 'moment'
import MultiSelect from "@/plugins/Select.vue";


export default {
  name: 'ProjectBacklinks',
  components: {
    MultiSelect
  },
  data() {

    return {
      pages_list: [],
      value: '',
      unverified_backlinks_flag: true,
      result: [],
      project: '',
      backlinks_history_dates: [],
      rels_options: [],
      selected_rels: [],
      names: {inner: 'Внутренние ссылки', back: 'Backlinks'},
      table_filter: '',

      creator_options: [],
      selected_creators: [],

      statuses_options: [],
      selected_statuses: [],

      backlinks_filter_options: [],
      selected_backlinks: [],
      first_backlinks_filter: [],

      links_filter_options: [],
      selected_links: [],

      deleted_backlink: {
        'link': {
          "link_index": '',
          "link_id": '',
          "link_url": '',
        },
        "backlink": {
          "backlink_index": '',
          "backlink_id": '',
          "backlink_url": '',
        }
      },

      start_date: moment().subtract(7, 'd').format('DD.MM.YYYY'),
      end_date: moment().format('DD.MM.YYYY'),

      pages: 0,
      current_page: 1,

      show_backlink_creator: false,

    }
  },

  mounted() {
    if (this.$route.query.link) this.selected_links.push(this.$route.query.link)
    this.get_project()
    this.send_request()
  },

  watch: {
    $route(to, from) {
      this.send_request()
    },
    selected_links(value) {
      if (value.length == 0) {
        this.backlinks_filter_options = this.first_backlinks_filter
      } else {
        if (this.result.length) {
          let backlinks = []
          let filtered_backlinks = ''
          for (let i = 0; i < value.length; i++) {
            filtered_backlinks = this.result.filter(link => link.link == value[i])[0].backlinks
            backlinks.push(...filtered_backlinks.map(item => item.backlink))
          }
          this.backlinks_filter_options = backlinks
        }
      }
    },
  },
  computed: {
    check_role() {
      return this.$store.getters.user.post_type
    },
  },

  methods: {
    get_project() {
      ProjectApi.get_simple(this.$route.params.id).then(response => {
        this.project = response;
      })

    },

    send_request() {
      ProjectApi.get_backlinks_first_info(
          this.$route.params.id,
          {
            start_date: this.start_date,
            end_date: this.end_date,
            link_type: this.$route.params.type,
            rels: this.selected_rels,
            statuses: this.selected_statuses,
            unverified_backlinks_flag: this.unverified_backlinks_flag,
            selected_backlinks: this.selected_backlinks,
            selected_links: this.selected_links,
            page: this.current_page,
            selected_creators: this.selected_creators

          },
      ).then((response) => {
            this.info_serializer(response)
          }
      );
    },

    info_serializer(response) {
      this.result = response.links;
      this.backlinks_history_dates = response.backlinks_history_dates;
      this.rels_options = response.rel
      this.statuses_options = response.statuses
      this.backlinks_filter_options = response.backlinks_filter
      this.first_backlinks_filter = response.backlinks_filter
      this.links_filter_options = response.links_filter
      this.pages = response.pages.pages
      this.current_page = response.pages.current_page
      this.creator_options = response.creator_filter


      let first_visual_page = this.current_page - 4 > 0 ? this.current_page - 4 : 1
      let last_visual_page = this.current_page + 4 <= this.pages ? this.current_page + 4 : this.pages
      let result = []
      for (let i = first_visual_page; i <= last_visual_page; i++) {
        result.push(i)
      }
      this.pages_list = result

    },

    click_start_scrapping_backlink() {
      ProjectApi.start_scrapping_backlink(
          this.$route.params.id,
      ).then((response) => {
            if (response == 'ok') {
              this.$notify({
                type: 'success',
                text: "Процесс проверки запущен"
              })
            }
          }
      )

    },
    click_get_excel_report() {
      ProjectApi.get_backlink_excel_report(
          this.$route.params.id,
          {
            data: this.result,
            backlinks_history_dates: this.backlinks_history_dates
          },
      ).then((response) => {

            var filename = "";
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(decodeURIComponent(escape(response.headers['content-disposition'])));
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            } else {
              filename = 'Backlinks.xlsx'
            }

            const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
          }
      );
    },

    click_show_modal_for_delete_backlink(link_index, backlink_index) {

      let link = this.result[link_index]
      let backlink = link.backlinks[backlink_index]

      this.deleted_backlink['link'] = {
        "link_index": link_index,
        "link_id": link.id,
        "link_url": link.link
      }

      this.deleted_backlink['backlink'] = {
        "backlink_index": backlink_index,
        "backlink_id": backlink.id,
        "backlink_url": backlink.backlink
      }

      if (backlink.id != undefined) {

        this.$modal('show', 'delete_backlink')
      } else {
        this.result[link_index].backlinks.splice(backlink_index, 1);
      }
    },

    is_valid_URL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.\\W,~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
    },
    click_save_backlink(link_index, backlink_index) {
      let link = this.result[link_index]
      let backlink = this.result[link_index].backlinks[backlink_index]
      if (!link.link || !backlink.backlink) {

        if (!link.link) {
          this.$notify({
            type: 'error',
            text: 'Заполните поле Ссылка'
          })
          this.$refs[`input_link${link_index}`][0].style.border = "5px solid #ff0000"

        }

        if (!backlink.backlink) {
          this.$notify({
            type: 'error',
            text: 'Заполните поле Backlink'
          })
          this.$refs[`input_backlink${link_index}-${backlink_index}`][0].style.border = "5px solid #ff0000"
        }

      } else if (!this.is_valid_URL(backlink.backlink) || (!this.is_valid_URL(link.link))) {

        if (!this.is_valid_URL(link.link)) {
          this.$notify({
            type: 'warn',
            text: 'Введите в поле Ссылка корректную ссылку'
          })
          this.$refs[`input_link${link_index}`][0].style.border = "5px solid #ffc107"

        }

        if (!this.is_valid_URL(backlink.backlink)) {
          this.$notify({
            group: 'alert',
            type: 'warn',
            text: 'Введите в поле Backlink корректную ссылку'
          })
          this.$refs[`input_backlink${link_index}-${backlink_index}`][0].style.border = "5px solid #ffc107"

        }


      } else {

        ProjectApi.update_backlink(this.$route.params.id, {
          link_id: link.id,
          link_url: link.link,
          link_type: this.$route.params.type,
          link_create_date: link.link_create_date,
          backlink_url: backlink.backlink,
          backlink_id: backlink.id,
          backlink_create_date: backlink.create_date
        },).then((response) => {

          if (response.error) {
            this.$notify({
              group: 'alert',
              type: 'warn',
              text: response.error
            })

          } else {
            let new_link_index = this.result.findIndex(link => link.id == response.link_id) - 1

            if (new_link_index > -1) {
              this.result.splice(link_index, 1)

              this.result[new_link_index].backlinks.unshift(response['backlink']);
            } else {
              this.result[link_index].backlinks.splice(backlink_index, 1, response['backlink']);
            }

            this.$refs[`input_link${link_index}`][0].style.border = ""
            this.$refs[`input_backlink${link_index}-${backlink_index}`][0].style.border = ""
          }


        });
      }

    },

    click_delete_backlink(link_index, backlink_index) {

      if (this.deleted_backlink.backlink.backlink_id != undefined) {

        ProjectApi.delete_backlink(this.$route.params.id, this.deleted_backlink.backlink.backlink_id).then(
            response => {
              this.$modal.hide('delete_backlink')
              if (response.status == 200) {
                this.result[link_index].backlinks.splice(backlink_index, 1);
              } else {
                alert("Не получается удалить")
              }
            }
        )
      }

    },

    click_apply_filters() {
      this.current_page = 1
      this.send_request()

    },


    format_date(date) {

      date = new Date(Date.parse(date))

      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      let year = date.getFullYear()

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      return [day, month, year].join('.');
    },

    format_date_for_new_backlink(date) {


      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      let year = date.getFullYear()

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      return [year, month, day].join('-');
    }
  }
}

</script>


<style>


.multiselect__tag {
  background: #198754 !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #0d6efd !important;
  color: #fff;
}

.multiselect__option--highlight {
  background: #00000011 !important;
  outline: none !important;
  color: #35495e !important;
}

.multiselect__option .multiselect__option--highlight .multiselect__option--selected {
  background: #0d6efd !important;
  color: #fff;
}

.multiselect__option--selected {
  background: #0d6efd !important;
  color: #fff !important;
}


</style>

<style scoped="">

.card {
  white-space: nowrap;
  overflow-x: scroll;
  padding-bottom: 50px;
}

.col-t.one {
  width: 80px;
  margin: 0;
}

.col-t.two {
  width: 160px;
  margin: 0;
}

.col-t {
  text-align: center;
  height: 30px;
}

.col-t, .col-t-3 {
  border: 1px solid #ccc;
  padding: 2px;
}

.no-b {
  border: none;
}

.col-t, .col-t-3 {
  display: inline-block;
}

.col-t-3 {
  width: 350px;
  white-space: normal;
}

.row-t {
  display: table;
}

.inner-cell {
  background: white;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 260px;
}

/* td{
    border: 1px solid;
} */

table td, th {
  table-layout: fixed;
  width: 250px;
}


tr:nth-of-type(1), td:nth-of-type(1), th:nth-of-type(1),
tr:nth-of-type(2), td:nth-of-type(2), th:nth-of-type(2),
tr:nth-of-type(3), td:nth-of-type(3), th:nth-of-type(3),
tr:nth-of-type(4), td:nth-of-type(4), th:nth-of-type(4),
tr:nth-of-type(5), td:nth-of-type(5), th:nth-of-type(5) {
  background-color: white;
  position: sticky;
}

th:nth-of-type(1), td:nth-of-type(1), td:nth-of-type(1) {
  left: 0em;
  min-width: 8em;
  max-width: 8em;
}

th:nth-of-type(2), td:nth-of-type(2), td:nth-of-type(2) {
  left: 6em;
  min-width: 20em;
  max-width: 20em;
}

th:nth-of-type(3), td:nth-of-type(3), td:nth-of-type(3) {
  left: 26em;
  min-width: 8.5em;
  max-width: 8.5em;
}

th:nth-of-type(4), td:nth-of-type(4), tr:nth-of-type(4) {
  left: 32em;
  min-width: 20em;
  max-width: 20em;
}

th:nth-of-type(5), td:nth-of-type(5), tr:nth-of-type(5) {
  left: 48em;
}


.inner-cell {
  background: white;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 260px;
}


.gray {
  background: #eee !important;
}

.col-rel {
  min-width: 6em;
  display: flex;
  justify-content: center;
  max-width: 8em;
}

.col-status {
  display: flex;
  justify-content: center;
  min-width: 4em;
}

.col-date-haed {
  text-align: center;
}


.fixed + div {
  /*margin-left: 350px!important;*/
}

.col-t-3.no-b.fixed {
  height: 40px;
  margin-bottom: -10px;
  border-right: 1px solid #ccc;
}

.uniq-p {
  padding-top: 40px;
}


.pagination > li > a, .pagination > li > span {
  border: 1px solid black;
  color: black;
}

.pagination > li.active > a {
  background: black;
  color: #fff;
  border-color: black;
  box-shadow: none;
}

.multiselect__option--highlight {
  background: #449b74;
}

.backlink-action-button {
  margin: 0 1px 0 1px;
}


</style>

