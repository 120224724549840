import { HTTP } from "./common";

export const AlsoAskApi = {
  list(filter) {
    return HTTP.get(`also_ask/?${filter}`).then((response) => {
      return response.data;
    });
  },
  get_filters(project_id, country_code) {
    return HTTP.get(`also_ask/${project_id}/get_filters/?country_code=${country_code}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  update_tone(related) {
    return HTTP.post(`also_ask/update_tone/`, related).then((response) => {
      return response.data;
    });
  }
};
