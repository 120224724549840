<template>
  <div>
    <div slider id="slider-distance">
      <div>
        <div inverse-left :style="`width: ${sizes.left}%;`"></div>
        <div inverse-right :style="`width: ${sizes.right}%;`"></div>
        <div range :style="`left:${sizes.left}%; right: ${sizes.right}%;`"></div>
        <span thumb :style="`left: ${sizes.left}%;`"></span>
        <span thumb :style="`left: ${sizes.range}%;`"></span>
        <div sign :style="`left: ${sizes.left}%;`">
          <span id="value">{{ format(min_value) }}</span>
        </div>
        <div sign :style="`left:${sizes.range}%;`">
          <span id="value">{{ format(max_value) }}</span>
        </div>
      </div>
      <input type="range" tabindex="0" :value="min_value" :max="max" :min="min" step="1"
             v-on:input="min_value = $event.target.value"/>
      <input type="range" tabindex="0" :value="max_value" :max="max" :min="min" step="1"
             v-on:input="max_value = $event.target.value"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "RangeSlider",
  props: ["model", "min", "max", "format"],
  data() {
    return {
      min_value: this.model[0],
      max_value: this.model[1],
    }
  },
  watch: {
    min_value(val) {
      this.$emit('update:model', [this.min_value, this.max_value]);
    },
    max_value(val) {
      this.$emit('update:model', [this.min_value, this.max_value]);
    }
  },
  computed: {
    sizes() {
      let right = this.calcValue(this.max_value), left = this.calcValue(this.min_value);
      return {
        right: 100 - right,
        range: right,
        left: left
      };
    }
  },
  methods: {
    calcValue(value) {
      return (100 / (parseInt(this.max) - parseInt(this.min))) * parseInt(value) - (100 / (parseInt(this.max) - parseInt(this.min))) * parseInt(this.min);
    }
  }
}
</script>

<style scoped>

[slider] {
  position: relative;
  height: 14px;
  border-radius: 10px;
  text-align: left;
  margin: 45px 0 10px 0;
}

[slider] > div {
  position: absolute;
  left: 13px;
  right: 15px;
  height: 14px;
}

[slider] > div > [inverse-left] {
  position: absolute;
  left: 0;
  height: 14px;
  border-radius: 10px;
  background-color: #CCC;
  margin: 0 7px;
}

[slider] > div > [inverse-right] {
  position: absolute;
  right: 0;
  height: 14px;
  border-radius: 10px;
  background-color: #CCC;
  margin: 0 7px;
}

[slider] > div > [range] {
  position: absolute;
  left: 0;
  height: 14px;
  border-radius: 14px;
  background-color: #3498db;
}

[slider] > div > [thumb] {
  position: absolute;
  top: -7px;
  z-index: 2;
  height: 28px;
  width: 28px;
  text-align: left;
  margin-left: -11px;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  background-color: #FFF;
  border-radius: 50%;
  outline: none;
}

[slider] > input[type=range] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  cursor: pointer;
  z-index: 3;
  height: 14px;
  top: -2px;
  width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

div[slider] > input[type=range]::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div[slider] > input[type=range]::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div[slider] > input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div[slider] > input[type=range]:focus {
  outline: none;
}

div[slider] > input[type=range]::-ms-thumb {
  pointer-events: all;
  width: 28px;
  height: 28px;
  border-radius: 0;
  border: 0 none;
  background: red;
}

div[slider] > input[type=range]::-moz-range-thumb {
  pointer-events: all;
  width: 28px;
  height: 28px;
  border-radius: 0;
  border: 0 none;
  background: red;
}

div[slider] > input[type=range]::-webkit-slider-thumb {
  pointer-events: all;
  width: 28px;
  height: 28px;
  border-radius: 0;
  border: 0 none;
  background: red;
  -webkit-appearance: none;
}

div[slider] > input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div[slider] > input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

div[slider] > input[type=range]::-ms-tooltip {
  display: none;
}

[slider] > div > [sign] {
  opacity: 0;
  position: absolute;
  margin-left: -11px;
  top: -39px;
  z-index: 3;
  background-color: #3498db;
  color: #fff;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  -webkit-border-radius: 28px;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

[slider] > div > [sign]:after {
  position: absolute;
  content: '';
  left: 0;
  border-radius: 16px;
  top: 19px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top-width: 16px;
  border-top-style: solid;
  border-top-color: #3498db;
}

[slider] > div > [sign] > span {
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  position: absolute;
  left: -13px;
  background: #3498db;
  padding: 0 5px;
  border-radius: 10px;
  text-align: center;
  min-width: 100px;
}

[slider] > div > [sign]:last-child > span {
  left: -45px;
}

[slider]:hover > div > [sign] {
  opacity: 1;
}
</style>
