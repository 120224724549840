<template>
  <section>
    <div class="d-flex d-flex justify-content-between align-items-end">
      <div class="col-6">
        <div class="dropdown" v-if="active_country">
          <button class="btn btn-filter "
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{ active_country.name }}
          </button>
          <ul class="dropdown-menu ">
            <template v-for="county in project.countries">
              <li @click="active_country=county">{{ county.name }}</li>
            </template>
          </ul>
        </div>
      </div>
      <div class="col-6 position-relative align-content-end">
        <date-picker
                     v-model="active_date"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="dd.MM.yyyy"
                     auto-apply
                     :allowed-dates="allowedDates"
                     :enable-time-picker="false"
        ></date-picker>
      </div>
    </div>
    <SmallProject v-bind:project="project"/>
    <template v-if="project && project.stats">
      <template v-for="(states, index) in all_states">
        <div class="d-flex stats-wrap" v-if="!hide_more || !index">
          <div class="stats d-flex flex-nowrap">
            <div v-for="tone in tones" :class="`info-block ${tone}`">
              <div v-for="state in states">
                <div class="title">Всего в Топ-{{ state }}</div>
                <div class="line row" v-for="type in types">
                  <div class="col-4">{{ type.name }}:</div>
                  <div class="col-2 value">
                    <span class="text-danger">{{ project.stats[tone][`_${state}${type.param}`] }}</span>
                  </div>
                  <div class="col value row flex-nowrap">
                    <i class="ico yandex"></i>
                    <span>{{ project.stats[tone][`_${state}${type.param}_y`] }}</span>
                  </div>
                  <div class="col value row flex-nowrap">
                    <i class="ico google"></i>
                    <span>{{ project.stats[tone][`_${state}${type.param}_g`] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <button :class="`btn btn-${hide_more?'outline-':''}dark`" @click="hide_more=!hide_more">
      <template v-if="!hide_more">Скрыть</template>
      <template v-else>Показать</template>
      больше выборок <i :class="`bi bi-chevron-${hide_more?'down':'up'}`"></i></button>

    <ul class="list-group" v-if="project?.id && project.stats">
      <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country?.code,
                                  filter_type: 'auto_new', date: active_date, uniq: true}}"
                   class="list-group-item d-flex justify-content-between align-items-center">
        <span>Новая автоматическая <br>нецелевая информация Топ-20</span>
        <span class="badge bg-archive">{{ String(project.stats.new_negative).padStart(3, '0') }}</span>
      </router-link>
      <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country?.code,
                                filter_type: 'auto_all',  engine: '', date: active_date, uniq: true}}"
                   class="list-group-item d-flex justify-content-between align-items-center">
        <span>Автоматически размечено в Топ-20</span>
        <span class="badge bg-gray">{{ String(project.stats.auto_mark).padStart(3, '0') }}</span>
      </router-link>
      <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country?.code,
                                 filter_type: 'no_tone', engine: '', date: active_date, uniq: true}}"
                   class="list-group-item d-flex justify-content-between align-items-center">
        <span>Неразмеченные ссылки в Топ-20</span>
        <span class="badge bg-archive">{{ String(project.stats.no_mark).padStart(3, '0') }}</span>
      </router-link>
      <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { country_code: active_country?.code,
                                      filter_type: 'no_content',  engine: '', date: active_date, uniq: true, state: 100}}"
                   class="list-group-item d-flex justify-content-between align-items-center">
        <span>Ссылки не получившие контент</span>
        <span class="badge bg-archive">{{ String(project.stats.no_content).padStart(3, '0') }}</span>
      </router-link>
    </ul>
    <router-link v-if="project?.id" :to="{name: 'project_result', params: {id: project.id}}"
                 class="btn btn-outline-dark mb-2">
      Топ ссылок
    </router-link>
  </section>
</template>
<script>
import {defineComponent} from "vue";
import SmallProject from "@/mobile/includes/SmallProject.vue";
import {ProjectApi} from "@/api/project";
import {MobileApi} from "@/api/mobile";

export default defineComponent({
  components: {SmallProject},
  data() {
    return {
      old_active_date: null,
      tones: ['negative', 'positive'],
      all_states: [[10, 20], [50, 100]],
      hide_more: true,
      stats: null,
      types: [{name: 'Общий', param: ''}, {name: 'Уникальный', param: '_uniq'}],
      allowedDates: []
    }
  },
  computed: {
    project: {
      get: function () {
        return this.$store.getters.favor_project(this.$route.params.id)
      },
      set: function (val) {
      }
    },
    active_country: {
      get: function () {
        return this.project ? (this.project.countries || []).find(x => x.active) : null;
      },
      set: function (val) {
        ProjectApi.set_active_country(this.project.id, val.code)
        this.project.countries.forEach(x => x.active = x.code === val.code)
        this.$store.dispatch('updateFavorite', this.project)
        this.$store.dispatch('getFavorite', this.project.id);
      }
    },
    active_date: {
      get: function () {
        return this.project ? this.project.last_parser : null;
      },
      set: function (val) {
        this.project.last_parser = val;
        this.$store.dispatch('updateFavorite', this.project);
        this.$store.dispatch('getFavorite', this.project.id);
      }
    },
  },
  watch: {
    project: {
      deep: true,
      handler() {
        if (this.project && !this.project.stats) this.$store.dispatch('getFavorite', this.project.id);
      }

    },
  },
  async mounted() {
    if (!this.project) {
      let $this = this;
      MobileApi.list(this.$route.params.id).then(response => {
        response.results.forEach(project => {
          $this.$store.dispatch("updateFavorite", project)
        })
      })
    }
    if (this.project && !this.project.stats) this.$store.dispatch('getFavorite', this.project.id);
    if (this.project) ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)
  },
  methods: {}

})
</script>