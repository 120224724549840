<template>
  <main class="dashboard">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">

      <h1>Изменить пароль</h1>
    </div>
    <div class="card">


      <div class="card-body">
        <div class="row">
          <div class="col-sm-5 m-3">


            <form @submit="changePassword" class="form-box flex-list step-32">
              <div class="form-floating mb-2">
                <input class="form-control" id="oldPassword" :type="show_pass?'text':'password'" name="" required
                       v-model="oldPassword" placeholder="Пароль">
                <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <label for="oldPassword">Текущий пароль</label>
                <div class="form-group__message">
                  <div class="error" v-for="error in baseErrors">- {{ error }}</div>
                </div>
              </div>
              <div class="form-floating mb-2">
                <input class="form-control " id="c" :type="show_pass?'text':'password'" name="" required
                       v-model="newPassword" placeholder="Пароль">
                <label for="newPassword">Новый пароль</label>
                <div class="form-group__message">
                  <div class="error" v-for="error in newErrors">- {{ error }}</div>
                </div>
              </div>
              <div class="form-floating mb-2">
                <input class="form-control " id="repeatPassword" :type="show_pass?'text':'password'" name="" required
                       v-model="repeatPassword" placeholder="Пароль">
                <label for="repeat">Повторите новый пароль</label>
                <i v-on:click="show_pass=!show_pass" style="right: 40px"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <div class="form-group__message">
                  <div class="error" v-for="error in repeatErrors">- {{ error }}</div>
                </div>
              </div>


              <div class="form-floating mb-2">
                <button class="w-100 btn btn-primary blue-bg" type="submit">Изменить пароль</button>
              </div>


            </form>
          </div>
        </div>


      </div>
    </div>

  </main>


</template>

<script>
import {UserApi} from '@/api/user'
import passwordMixin from "./password_mixins";

export default {
  name: 'Settings',
  mixins: [passwordMixin],
  computed: {
    user: {
      get() {
        return this.$store.getters.user
      },
      set(value) {
        this.$store.dispatch('update_user', value)
      }
    }
  },
  methods: {
    changePassword: function (e) {
      e.preventDefault();
      let $this = this;
      if (!this.repeatErrors.length && !this.repeatErrors.length) {
        this.baseErrors = [];
        UserApi.update_password({
          old: this.oldPassword,
          newp: this.newPassword,
          repeat: this.repeatPassword
        }).then(response => {
          if (response.data['errors']) {
            $this.baseErrors = response.data['errors'];
          } else {
            let username = $this.user.email, password = $this.newPassword;
            this.$notify({
              type: 'success ',
              text: 'Пароль обновлён'
            });
            this.$store.dispatch('login', {
              username,
              password
            }).then(response => {

            });
          }
        })
      }
    }
  }
}

</script>
