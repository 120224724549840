<template>
  <main class="dashboard">
    <div class="filters d-flex">
      <div :class="(filter.projects_error ?' form-error':'')">
        <MultiSelect
            v-model="filter.projects"
            :options="projects_names"
            :required="false"
            :multi="true"
            name="Проекты"
            style="max-width: 400px"
            :only_value="true"
        ></MultiSelect>
        <div class="error" v-if="filter.projects_error">{{ filter.projects_error }}</div>
      </div>

      <div :class="(filter.country_error ?' form-error':'')">
        <MultiSelect
            v-model="filter.country"
            :options="countries"
            :required="true"
            :multi="false"
            name="Страны"
            style="height: 35px"
            :only_value="false"
        ></MultiSelect>
        <div class="error" v-if="filter.country_error">{{ filter.country_error }}</div>
      </div>

      <div :class="(filter.start_date_error ?' form-error':'')">
        <date-picker
            v-model="filter.start_date"
            locale="ru"
            :clearable="false"
            format="dd.MM.yyyy"
            model-type="yyyy-MM-dd"
            auto-apply
            :enable-time-picker="false"
        ></date-picker>

        <div class="error" v-if="filter.start_date_error">{{ filter.start_date_error }}</div>
      </div>

      <div :class="(filter.end_date_error ?' form-error':'')">
        <date-picker v-model="filter.end_date"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :enable-time-picker="false"
        ></date-picker>

        <div class="error" v-if="filter.end_date_error">{{ filter.end_date_error }}</div>
      </div>

      <button class="btn btn-outline-dark" style="height: 35px" v-on:click="getData()">Показать</button>
    </div>
    <div class="row mt-5" v-if="!projects.length">
      <h5>Выберите параметры и нажмите кнопку "Показать"</h5>
    </div>

    <div class="table-responsive mt-5">
      <table class="table table-hover table-bordered table-bordered">

        <template v-for="(project, index) in info">
          <thead v-if="!index">
          <tr>
            <template v-for="(date, d_index) in dates">
              <td class="border-end sticky" v-if="!index&&!d_index">
                <div class="total">
                  <b>Сумма</b>
                </div>
              </td>
              <th colspan="2" class="text-center p-1 border-end">
                <small>{{ $moment(date).format("DD.MM.YYYY") }}</small>
              </th>
            </template>
          </tr>
          <tr>
            <template v-for="(date, d_index) in dates">
              <td v-if="!index&&!d_index" class="border-end sticky">
                <div class="total">
                  <i class="yandex"></i>
                  <i class="google"></i>
                </div>
              </td>

              <template v-for="(engine, en_index) in $ENGINES">
                <th :class="'text-center p-1'+ (en_index?' border-end':'')">
                  <i :class="engine"></i>
                </th>
              </template>
            </template>
          </tr>
          </thead>
          <tbody>
          <tr class="gray">
            <td class="gray d-flex border-0 justify-content-between border-end sticky">
              <div>
                <div class="btn btn-sm btn-outline-dark p-0" @click="project.open =! project.open">
                  <i v-if="!project.open" class="bi bi-plus"></i>
                  <i v-else class="bi bi-dash-lg"></i>
                </div>
                {{ project.name }}
              </div>
              <div class="total">
                <b>{{Object.values(project.keys).map(x => Object.values(x)).flat(1).map(x=>x.yandex).reduce((s, a) => s + a, 0)}}</b>
                <b>{{Object.values(project.keys).map(x => Object.values(x)).flat(1).map(x=>x.google).reduce((s, a) => s + a, 0)}}</b>
              </div>
            </td>
            <!--          .reduce((partialSum, a) => partialSum + a, 0);-->
            <template v-for="date in dates">
              <td class="text-center p-1">

              <span class="text-dark"> {{
                  Object.values(project.keys).map(x => x[date].yandex).reduce((s, a) => s + a, 0)
                }}</span>
              </td>
              <td class="text-center p-1 border-end">
              <span class="text-dark">{{
                  Object.values(project.keys).map(x => x[date].google).reduce((s, a) => s + a, 0)
                }} </span>
              </td>
            </template>

          </tr>

          <template v-if="project.open" v-for="(value,  key) in project.keys">
            <tr>
              <td class="border-end sticky">{{ key }}
                <div class="total">
                  <div class="total">
                <b>{{Object.values(value).map(x=>x.yandex).reduce((s, a) => s + a, 0)}}</b>
                <b>{{Object.values(value).map(x=>x.google).reduce((s, a) => s + a, 0)}}</b>
              </div>
                </div>
              </td>
              <template v-for="date in dates">
                <td class="text-center p-1">

                  <span class="text-dark"> {{ value[date].yandex }}</span>
                </td>
                <td class="text-center p-1 border-end">
                  <span class="text-dark"> {{ value[date].google }}</span>
                </td>
              </template>

            </tr>

          </template>
          </tbody>

        </template>

      </table>
    </div>

  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import moment from 'moment';
import validateMixin from "@/mixins/validate"

export default {
  name: 'FullInfo',
  mixins: [validateMixin],
  data() {
    return {
      projects: [],
      dates: [],
      projects_names: [],
      filter: {
        projects: [],
        country: null,
        start_date: moment().add(-6, "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD")
      },
      countries: [],
      active_country: null,
      validate_name: "filter",
      validate: {
        country: [],
        end_date: [{
          func: (o) => {
            return (moment(o.end_date) - moment(o.start_date)) < 0;
          }, error: 'Дата окончания не может быть меньше даты старта'
        }, {
          func: (o) => {
            return !o.end_date;
          }, error: 'Обязательное поле'
        }],
        start_date: []

      }

    }
  },
  watch: {},
  computed: {
    info() {
      this.dates = [...new Set(this.projects.map(x => x.date_create))];
      this.projects_names.forEach(project => {
        let keys = this.projects.filter(x => parseInt(x.project_id) === parseInt(project.id))

        project.keys = {}
        let key_name = keys.map(x => x.keyword_name);
        key_name.forEach(key => {
          let data = {}
          this.dates.forEach(date => {
            data[date] = {}
            this.$ENGINES.forEach(engine => {
              data[date][engine] = keys.find(x => x.keyword_name === key && x.date_create === date && x.engine === engine)?.search_quake || 0
            })
          })
          project.keys[key] = data
        })
      })
      return this.projects_names.filter(x => Object.keys(x.keys).length > 0);
    }

  },
  mounted: function () {
    let $this = this;
    ProjectApi.countries().then(response => {
      $this.countries = response.results;
      $this.active_country = $this.countries[0];
    });
    ProjectApi.get_select_list().then(response => this.projects_names = response);
  },
  methods: {


    getData: function () {
      if (!this.validateData()) {
        let filter = structuredClone(this.filter);
        filter.country = filter.country.code;
        if (!filter.projects.length) delete filter.projects;
        let f_string = new URLSearchParams(filter).toString();
        ProjectApi.get_search_results(f_string).then(response => this.projects = response)
      }
    }
  }
}

</script>

<style scoped lang="scss">

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  width: 500px;
  justify-content: space-between;
  display: flex;
  min-height: 27px;
}

.total {
  margin-left: auto;
  display: flex;
  width: 80px;
  align-items: center;
  align-self: center;
  justify-content: space-evenly;
  flex: 0 0 auto;
}

.gray {
  background: #eee !important;
}

.table-responsive {
  overflow-x: auto;
}

tbody {
  tr:hover {
    background: #A6A4A4FF !important;

    td {
      background: #A6A4A4FF !important;
    }
  }
}

.filters {
  gap: 10px;
}

</style>

