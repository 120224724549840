<template>
    <main class="dashboard">
        <div
                class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2>{{ link.link }} История изменений</h2>
            <BackButton></BackButton>
        </div>

        <div class="card">

            <div class="card-body">

                <div class="table-responsive">
                    <table class="table mb-5">
                        <thead>
                        <tr>
                            <th scope="col">Тон</th>
                            <th scope="col">Вид разметки</th>
                            <th scope="col">Тип разметки</th>
                            <th scope="col">Суд</th>
                            <th scope="col">Дата суда</th>
                            <th scope="col">Тип удаления</th>
                            <th scope="col">Стоимость удаления</th>
                            <th scope="col">Статус</th>
                            <th scope="col">Примечание</th>
                            <th scope="col">Пользователь</th>
                            <th scope="col">Дата</th>

                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="link in links">
                            <tr>
                                <td :style="`${link.changed.indexOf('tone')+1?'background: #eee':''}`">
                                    <div class="dropdown no-wrap">
                                        <span>{{ link.get_tone_display }}</span>
                                        <a class="dropdown-toggle p-0" href="#" id="dropdownMenuLink"
                                           data-bs-toggle="dropdown"
                                           aria-expanded="false">

                                        </a>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li v-for=" (tone, key) in $TONES"
                                                v-on:click="link.get_tone_display=tone.name;
                                                            link.kind_type='manual';
                                                            link.get_kind_type_display='Ручной';
                                                            link.type_tone='new';
                                                            link.get_type_tone_display='Новый';
                                                            link.tone=key;
                                                            updateLink(link);"
                                                class="dropdown-item link">{{ tone.name }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td :style="`${link.changed.indexOf('kind_type')+1?'background: #eee':''}`">
                                    {{ link.get_kind_type_display }}
                                </td>
                                <td :style="`${link.changed.indexOf('type_tone')+1?'background: #eee':''}`">
                                    <div class="dropdown no-wrap">
                                        <span>{{ link.get_type_tone_display }}</span>
                                        <a class="dropdown-toggle p-0" href="#" :id="`dropdownMTypeTone${link.id}`"
                                           data-bs-toggle="dropdown"
                                           aria-expanded="false">

                                        </a>
                                        <ul class="dropdown-menu" :aria-labelledby="`dropdownMTypeTone${link.id}`">
                                            <li v-for="(tone, key) in  type_tones(link)"
                                                v-on:click="link.get_type_tone_display=tone;
                                                        link.type_tone=key;
                                                        updateLink(link)"
                                                :class="`dropdown-item link pb-0 pt-0 ${link.type_tone === key?'active':''}`">
                                                {{ tone }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td :style="`${link.changed.indexOf('court')+1?'background: #eee':''}`">
                                    {{ link.court ? 'Да' : 'Нет' }}
                                </td>
                                <td :style="`${link.changed.indexOf('court_date')+1?'background: #eee':''}`">
                                    {{ link.court_date }}
                                </td>
                                <td :style="`${link.changed.indexOf('delete_type')+1?'background: #eee':''}`">
                                    {{ link.get_delete_type_display }}
                                </td>
                                <td :style="`${link.changed.indexOf('delete_price')+1?'background: #eee':''}`">
                                    {{ link.delete_price }}
                                </td>
                                <td :style="`${link.changed.indexOf('status')+1?'background: #eee':''}`">
                                    {{ link.get_status_display }}
                                </td>
                                <td :style="`${link.changed.indexOf('comment')+1?'background: #eee':''}`">
                                    {{ link.comment }}
                                </td>
                                <td>{{ link.user }}</td>
                                <td>{{ link.date_create }}

                                </td>


                            </tr>
                        </template>
                        </tbody>
                    </table>


                </div>
            </div>


        </div>

    </main>
</template>

<script>
import {LinkApi} from '@/api/project'

export default {
    name: 'LinksHistory',
    components: {},
    computed: {
        type_tones: function () {
            let vm = this;
            return function (link) {
                return vm.$getTypes(link.tone);
            };
        },
    },
    data() {
        return {
            current_date: new Date(),
            links: [],
            link: {},
        }
    },
    mounted: function () {
        let $this = this;
        LinkApi.get($this.$route.params.link_id).then(response => $this.link = response);
        this.getData();
    },
    methods: {
        getData: function () {
            let $this = this;
            LinkApi.get_history($this.$route.params.link_id).then(response => $this.links = response);
        },
        updateLink: function (link) {
            LinkApi.update_history_tone(link).then(response => {
                this.$notify({
                    type: 'success ',
                    text: 'Данные обновлены'
                });
            })
        },

        getClass: function (link) {
            let COLORS = {
                'negative_auto_new': '#ec6b0a',
                'negative_manual_offer': '#d50004',
                'negative_manual_new': '#ff003a',
                'not_negative_auto_new': '#000000',
                'not_negative_manual_new': '#000000',
                'positive_manual_start': '#00a2ea',
                'positive_manual_new': '#00a2ea',
                'positive_manual_our': '#00b050',
                'irrelevant_manual_start': '#808080',
                'irrelevant_manual_new': '#808080',
            };
            let val = `${link.tone}_${link.kind_type}_${link.type_tone}`;
            return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;

        },
    }
}

</script>


