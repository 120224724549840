<template>

  <div class="card mb-2">
    <div class="card-header d-flex justify-content-between">

      <CountryChoice v-if="country_code"
                     v-bind:active_country.sync="country_code"
                     v-bind:project_id="project.id"
                     v-bind:countries="project.countries"
                     v-bind:changeCountry="changeCountry"></CountryChoice>
      <div class="d-flex" style="align-items: center;">
        <b class="me-2">Дата: </b>
        <date-picker v-model="date"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :allowed-dates="allowedDates"
                     :enable-time-picker="false"
        ></date-picker>
      </div>
    </div>

    <div class="screen-list mt-4">
      <div class="header">
        <div class="keyword">Запрос</div>
        <div class="column" v-for="date in week">
          {{ $moment(date, "DD.MM.YYYY").format('DD/MM/YYYY') }}<span class="text-secondary ms-2"
                                                        style="text-transform: uppercase;">{{
            $moment(date, "DD.MM.YYYY").format('dd')
          }}</span>
        </div>
      </div>
      <template v-for="(key, key_index) in keyword_names">
        <div class="screen-item">
          <div class="keyword key-name">{{ key.keyword_name }}</div>
          <div class="column" v-for="(date, index) in week">
            <div class="row" v-if="!key_index">
              <div class="col engine" v-for="engine in $ENGINES"><i :class="'ico '+ engine"></i></div>
            </div>
            <div class="row">
              <div class="col engine" v-for="engine in $ENGINES">
                <a :href="key[date][engine]" target="_blank"> <template v-if="key[date][engine]">png</template><template v-else>-</template></a>
              </div>
            </div>

          </div>

        </div>
      </template>
    </div>

  </div>

</template>

<script>
import {HintApi} from '@/api/hints'
import moment from "moment";
import {ProjectApi} from "@/api/project";

export default {
  name: 'HintScreen',
  components: {},
  data() {
    return {
      project: {},
      screens: [],
      date: null,
      country_code: null,
      allowedDates: []
    }
  },
  watch: {
    date(newVal, oldVal) {
      if (oldVal) this.getScreen()
    },
    country_code() {
      this.getScreen()
    },
  },
  computed: {
    keyword_names() {

      let screens = this.screens.filter((obj1, i, arr) =>
          arr.findIndex(obj2 => (obj2.keyword_id === obj1.keyword_id)) === i
      )
      screens.forEach(screen => {
        this.week.forEach((date, index) => {
          this.$ENGINES.forEach(engine => {
            let scr = this.screens.find(x => x.keyword_id === screen.keyword_id && x.engine_code === engine && moment(x.date_create).format("DD.MM.YYYY") === date);
            if (!screen[date]) screen[date] = {}
            screen[date][engine] = scr?.screenshot
          })

        })
      })


      return screens
    },
    week() {
      let week = [];

      let current = moment(this.date)
      current = current.add(-(current.isoWeekday() - 1), 'd');

      for (let i = 0; i < 7; i++) {
        console.log(current.format("DD.MM.YYYY"))
        week.push(
            current.format("DD.MM.YYYY")
        );
        current = current.add(1, 'd');

      }
      return week;
    }
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_simple($this.$route.params.id).then(response => {
      $this.project = response;
      HintApi.get_avail_date_calendar($this.$route.params.id).then(response => {
        $this.allowedDates = response;
        this.date = $this.allowedDates[$this.allowedDates.length - 1]
        $this.country_code = $this.project.active_country_code;
      });

    });

  },
  methods: {
    getScreen() {
      let $this = this;
      HintApi.get_screen($this.$route.params.id, $this.country_code.code, $this.date).then(response => {
        $this.screens = response
      });
    },
    changeCountry: function (country, project_id) {
      if (this.country_code.code !== country.code) {
        this.country_code = country;
      }
    }
  }
}

</script>


<style scoped lang="scss">

.screen-list {
  overflow-x: scroll;
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .column {
    width: 200px !important;
    flex: 0 0 auto;
    text-align: center;

    .engine {
      text-align: center;
    }

    .screens {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .screen {
        display: flex;
        gap: 10px;
      }
    }

  }

  .header {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
  }

  .screen-item {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    border-bottom: 1px solid rgba(139, 139, 139, 1);
    width: max-content;
    padding-bottom: 10px;
  }

  .keyword {
    width: 250px;
    flex: 0 0 auto;
    position: sticky;
    left: 0;
    background: #fff;
    display: flex;
    align-items: center;
  }
}

</style>
