import {createApp} from 'vue'
import App from './App'
import MobileApp from './MobileApp'
import mobile_router from './router/mobile'

import router from './router'
import store from './store/index'

import VueCookies from 'vue3-cookies'
import Notifications from '@kyvg/vue3-notification'

import CustomVar from '@/plugins/custom'
import ModalCustom from '@/components/ModalCustom.vue'
import CountryChoice from '@/components/CountryChoice.vue'
import BackButton from '@/components/BackButton.vue'
import MultiSelect from '@/plugins/Select.vue'
import AutoComplete from '@/plugins/AutoComplete.vue'

import 'vue-final-modal/style.css'
import './registerServiceWorker'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Popper from "vue3-popper";

import moment from 'moment';
moment.locale('ru');

// import './registerServiceWorker'
let app;
const mobile = (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
if (mobile) {
    app = createApp(MobileApp);
    app.use(mobile_router)
}
else {
    app = createApp(App);
    app.use(router)
}
app.use(Notifications).use(VueCookies).use(store)
app.component("BackButton", BackButton)
app.component("modal", ModalCustom)
app.component("CountryChoice", CountryChoice)
app.component("MultiSelect", MultiSelect)
app.component("AutoComplete", AutoComplete)
app.component('date-picker', VueDatePicker);
app.component("popper", Popper);
app.use(CustomVar)
app.config.globalProperties.$moment=moment;

app.mount('#app')


