<template>
  <main class="dashboard links">
    <div class="mb-4">
      <h2>Отчеты: {{ project.name }}</h2>
    </div>
    <div class="card-body">
      <SmallReport v-bind:date_start="report.date_start"
                   v-bind:date="report.date"
                   v-bind:simpleProject="project"
                   v-bind:state="report.state"
                   v-bind:full_width="true"
                   v-bind:allDates="[]"
                   v-bind:successFunc="createReport"
      ></SmallReport>

      <div class="table-responsive mt-4" v-if="reports.length">
        <p><i>* Отчёты хранятся в течение 7 дней</i></p>
        <table class="table dict">
          <thead>
          <tr class="sticky-top">
            <th scope="col"> №</th>
            <th scope="col">Тип</th>
            <th scope="col">Страна</th>
            <th scope="col">ТОП</th>
            <th scope="col">Шаблон</th>
            <th scope="col">Период</th>

            <th scope="col">Размер</th>
            <th scope="col">Ссылка/статус</th>
            <th scope="col">Дата</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr class="empty"></tr>
          <template v-for="(object, index) in reports">
            <tr :class="object.edit?'edit':''">
              <td>{{ index + 1 }}</td>
              <td>{{ object.get_report_type_display }}</td>
              <td>
                <div class="countries">
                <span v-for="cc in object.additional.countries?.map((x)=> project_countries?.find(c=>c.id === x))" class="flag">
              <i :class="`${cc?.code.toLowerCase()}`" :title="cc?.name"></i>
              </span>
                  </div>
              </td>
              <td>ТОП {{ object.state }}</td>
              <td>{{ settings[object.report_type].settings.template.find(x => x.value === object.template)?.name }}</td>
              <td>{{ $moment(object.date_start).format("DD.MM.YY") }} - {{
                  $moment(object.date).format("DD.MM.YY")
                }}
              </td>
              <td>{{ sizeInMb(object.size) }}</td>
              <td>
                <template v-if="object.report_file">
                  <a :href="object.report_file"><i class="bi bi-file-arrow-down"></i> Скачать отчёт</a><br>
                  <template v-if="object.sending">
                    <div class="d-flex">
                      <input v-model="object.email" type="email" name="email" placeholder="Email" class="form-control">
                      <button class="btn btn-dark" v-on:click="sendToEmail(object)"><i class="bi bi-send"></i>
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="object.email">
                      <small>Отправлен на {{ object.email }}</small>
                    </template>
                    <a v-else href="" v-on:click.prevent="object.sending = true;"><i
                        class="bi bi-send"></i> Отправить на email</a>
                  </template>
                </template>
                <template v-if="!!object.error">
<div class="d-flex">
                    <button @click="restartReport(object.id)" class="btn btn-warning btn-sm" title="Повторить генерацию">
                    <i class="bi bi-arrow-clockwise"></i></button>
                   <popper hover placement="top">
                  <span style="cursor: pointer;" class="text-danger d-flex"><i class="bi bi-exclamation-lg"></i> Произошла ошибка</span>
                    <template #content>
                      <p v-for="val in object.error.split('\n')">
                        {{val}}
                      </p>
                    </template>
                  </popper>

</div>
                </template>
                <template v-if="!!!object.error && !object.report_file">Отчёт генерируется</template>

              </td>
              <td>{{ object.date_create }}</td>
              <td>{{ object.user_name }}</td>
            </tr>
          </template>

          </tbody>
        </table>

      </div>
      <template v-if="pager.length>1">
        <nav>
          <ul class="pagination justify-content-center">
            <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
              <a class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
            </li>
          </ul>
        </nav>
      </template>

    </div>
  </main>
</template>

<script>
import {ReportApi} from '@/api/reports'
import SmallReport from "@/components/SmallReport.vue";

import moment from 'moment'
import {ProjectApi} from "@/api/project";
import {state} from "@/socket";


export default {
  name: 'ProjectReports',
  components: {
    SmallReport,
  },
  data() {
    let date_start = new Date();
    date_start = new Date(date_start.setDate(date_start.getDate() - 7));
    return {
      reports: [],
      size: 20,
      all_count: 0,
      project: {},
      page: 1,
      settings: null,

      report: {
        state: 20,
        // date: moment().format("YYYY-MM-DD"),
        // date_start: moment().format("YYYY-MM-DD"),
      },
      dates:{

      }
    }
  },
  computed: {
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      return Array.from({length: page_count}, (_, i) => i + 1);
    },
    project_countries() {
      return this.project ? this.project.countries : []
    },
    ws_state() {
      return state.report;
    },
  },
  watch: {
    page: {
      deep: true,
      handler(val) {
        this.getReportData();
      }
    },
    ws_state: {
      deep: true,
      handler(val) {
        if (val.project === parseInt(this.$route.params.id)) {
          this.addReport(val)
        }
      }
    }
  },
  mounted: function () {
    this.getProject();
    this.getReportData();
    ReportApi.get_report_setting().then(response => this.settings = response)
  },

  methods: {

    restartReport(_id) {
      let $this = this;
      ReportApi.restart(_id).then(response => {
        $this.$notify({
          type: 'success ',
          text: 'Отчёт отправлен на формирование'
        });
      });
    },

    getProject: function () {
      ProjectApi.get_simple(this.$route.params.id).then(response => this.project = response);
    },
    sizeInMb(value) {
      let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
      if (!value) return '0 B';
      let i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));
      if (i) return Math.round(value / Math.pow(1024, i), 2) + ' ' + sizes[i];
      return "-"
    },
    createReport(response) {
      this.reports.unshift(response)
    },
    getReportData() {
      let $this = this;
      ReportApi.list(this.$route.params.id, $this.page, $this.size).then(response => {
        $this.reports = response.results;
        $this.all_count = response.count;
      });
    },
    sendToEmail(report) {
      let $this = this;
      if (report.email) {
        ReportApi.send_report_to_email(report.id, report.email).then(() => {
          $this.$notify({
            type: 'success ',
            text: `Отчёт отправлен на почту ${report.email}`
          });
          report.sending = false;
        });
      } else {
        $this.$notify({
          type: 'error ',
          text: 'Введите email'
        });
      }
    },
    addReport(report) {
      let index_report = this.reports.findIndex(e => e.id);

      if (index_report > -1) {
        this.reports.splice(index_report, 1, report)
      }
    },
  }
}

</script>
