import { HTTP } from "./common";

export const AuditApi = {

  get(id) {
    return HTTP.get(`audit/${id}/`).then((response) => {
      return response.data;
    });
  },
  add_link(id, link_ids) {
    return HTTP.post(`audit/${id}/add_link/`, {link_ids: link_ids}).then((response) => {
      return response.data;
    });
  },


};
