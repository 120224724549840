import { HTTP } from './common'

export const PictureApi = {
    keywords (project_id, country_code) {
    return HTTP.get(`pictures/keywords/?project_id=${project_id}&country_code=${country_code}`).then(response => {
      return response.data
    })
    },
  detail_project(project_id, date, country_code) {
    return HTTP.get(`pictures/detail_project/?project_id=${project_id}&date=${date}&country_code=${country_code}`).then(response => {
      return response.data
    })
  },
  update(id, object) {
    return HTTP.put(`pictures_uniq/${id}/`, object).then(response => {
      return response.data
    });
  },
  chart_data(id) {
    return HTTP.get(`pictures_uniq/${id}/chart_data/`).then(response => {
      return response.data
    });
  },
  list (key, date, country_code) {
    return HTTP.get(`pictures/?project_id=${key}&date=${date}&country_code=${country_code}`).then(response => {
      return response.data
    })
  },
  list_uniq (id, tone, query, page, size) {
    return HTTP.get(`pictures_uniq/?project_id=${id}&tone=${tone}&q=${query}&page=${page}&size=${size}`).then(response => {
      return response.data
    })
  },
  get_stat (id, date, country_code) {
    return HTTP.get(`pictures/get_stat/?project_id=${id}&date=${date}&country_code=${country_code?country_code:''}`).then(response => {
      return response.data
    })
  },

  links_info(id, query) {
    return HTTP.get(`pictures/links_info/?${query}&project_id=${id}`).then((response) => {
      return response.data;
    });
  },
   get_full(id, date, country_code) {
    return HTTP.get(`pictures/get_full/?date=${date}&country_code=${country_code}&project_id=${id}`).then(
      (response) => {
        return response.data;
      }
    );
  },
   download_images(id) {
    return HTTP.post(`pictures/download_images/?project_id=${id}`).then(
      (response) => {
        return response.data;
      }
    );
  },

};
