<template>
  <div class="btn-toolbar btn-back ">
    <div class="btn-group">
      <button @click="$router.go(-1)" class="btn btn-sm btn-outline-dark">
        <i class="bi bi-arrow-left-short me-1"></i>
        Назад
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: ['link']
}
</script>

<style scoped>
  .btn-back {
    top: 110px;
    position: fixed;
    right: 20px;
    z-index: 10000;
  }
</style>
