<script setup>
import {computed, ref, onMounted, onUnmounted} from 'vue'
import {useStore} from 'vuex'
import {notify} from "@kyvg/vue3-notification";
import {useRoute} from 'vue-router';
import {UserApi} from "@/api/user";

const route = useRoute()
const store = useStore()


onMounted(() => {
  document.body.classList.add('full-width')
})
onUnmounted(() => {
  document.body.classList.remove('full-width')
})


const remember = computed(() => store.getters.remember)

const user = {
  username: remember.value,
  password: "",
  remember: !!remember.value
}
const show_pass = ref(false);
let state = ref("login");

function login(e) {
  e.preventDefault();
  store.dispatch('login', user).then(() => {
    location.search = "";
    location.pathname = route.query.next || '/';
  }).catch(err => {
    let err_text = 'Данные неверны';
    if (err.response.status === 429) err_text = 'Превышено количество попыток ввода пароля';
    notify({
      type: 'error ',
      title: "Ошибка!",
      text: err_text,
    });

  })
}

function restore() {
  UserApi.restore_password(user.username).then(response => {
    if (response.data['errors']) {
      for (let i in response.data['errors']) {
      notify({
          type: 'error',
          text: response.data['errors'][i]
        })
      }
    } else state.value = 'message'

  });
}


</script>
<template>

  <main class="form-signin">
    <form @submit.prevent="restore" v-if="state === 'forget'">
      <h3 class="h3 mb-3 text-white text-center">Восстановление пароля</h3>

      <div class="mb-3">
        <input type="email" name="email" class="form-control grey" required v-model="user.username" id="login"
               placeholder="Логин">
      </div>

      <button class="w-100 btn btn-lg btn-secondary text-white" type="submit">Восстановить</button>
      <div class="forget">
        <a href="" @click.prevent="state = 'login'" class="text-center text-white p-2">Войти</a>
      </div>
    </form>

    <form v-else-if="state === 'message'">
      <h3 class="h3 mb-3 text-white text-center"> Инструкции по восстановлению пароля отправлены на указанный
        email.</h3>


      <button class="w-100 btn btn-lg btn-secondary text-white" type="button" @click="state='login'">Вход</button>
    </form>

    <form @submit="login" v-else>
      <h3 class="h3 mb-3 text-white text-center">Авторизация</h3>

      <div class="mb-3">
        <input type="email" name="email" class="form-control grey" required v-model="user.username" id="login"
               placeholder="Логин">
      </div>
      <div class="mb-3 password">
        <input class="form-control grey" id="password" :type="show_pass?'text':'password'" name="password" required
               v-model="user.password" placeholder="Пароль">
        <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
      </div>

      <div class="checkbox mb-3">
        <input id="id_remember" class="black" type="checkbox" v-model="user.remember" value="remember-me">
        <label class="text-white" for="id_remember">
          Запомнить
        </label>
      </div>
      <button class="w-100 btn btn-lg btn-secondary text-white" type="submit">Вход</button>
      <div class="forget">
        <a href="" @click.prevent="state = 'forget'" class="text-center text-white p-2">Забыли пароль?</a>
      </div>
    </form>

  </main>

</template>
<style scoped lang="scss">
.forget {
  display: flex;
  justify-content: space-around;
}
</style>