<template>
  <router-link :to="{name: 'project_detail', params: {id: project.id}}" class="card">
    <ul>
      <li class="name">{{ project.name }} <i class="star"></i></li>
      <li><span>Статус </span> <b :class="`ms-1 badge bg-${project.project_type}`">{{ project.get_project_type_display }}</b></li>
      <li><span>В работе с: </span> <span class="value">{{ $moment(project.start_date).format('DD MMMM YYYY') }}</span></li>
      <li><span>ДДЛ: </span><span class="value">{{ $moment(project.end_date).format('DD MMMM YYYY') }}</span></li>
      <li><span>Неделя: </span>
        <span class="value"><span :class="`value text-${delta_week.current > delta_week.all?'danger':'success'}`">{{ delta_week.current }}</span> из {{delta_week.all}}</span>
        <span>  Месяцев: </span> <span class="value">{{ project.month_count }}</span>
      </li>
    </ul>
  </router-link>
</template>
<script>

import moment from "moment"

export default {
  name: "SmallProject",
  props: {
    project: {type: Object, default: function () {return {}}},
  },

  computed: {
    delta_week() {
      const
          start_date = moment(this.project.start_date),
          end_date = moment(this.project.end_date),
          all_week = Math.round((end_date - start_date) / 1000 / 60 / 60 / 24 / 7)
      return {all: all_week, current: Math.round((this.$moment() - start_date) / 1000 / 60 / 60 / 24 / 7)}
    },
  },

  mounted() {
  },
  methods: {}
};
</script>
