import { HTTP } from "./common";

export const OrganizationApi = {

  list() {
    return HTTP.get(
      `organizations/`
    ).then((response) => {
      return response.data;
    });
  },
  get(id) {
    return HTTP.get(
      `organizations/edit/?id=${id?id:""}`
    ).then((response) => {
      return response.data;
    });
  },
  organization_settings() {
    return HTTP.get(
      `organizations/organization_settings/`
    ).then((response) => {
      return response.data;
    });
  },
  create_account(object_id, account) {
    return HTTP.post(
      `organizations/${object_id}/create_account/`, account
    ).then((response) => {
      return response.data;
    });
  },
  update_account(object_id, account) {
    return HTTP.put(
      `organizations/${object_id}/update_account/`, account
    ).then((response) => {
      return response.data;
    });
  },
  preview_mail(object_id) {
    return HTTP.get(
      `organizations/${object_id}/preview_mail/`
    ).then((response) => {
      return response.data;
    });
  },
  send_letter(object_id) {
    return HTTP.get(
      `organizations/${object_id}/send_letter/`
    ).then((response) => {
      return response.data;
    });
  },
  update(object) {
    const data = {data: JSON.stringify(object)};
    if (object.id)
      return HTTP.patch(`organizations/${object.id}/`, data).then((response) => {
        return response.data;
      });
    else
      return HTTP.post("organizations/", data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
        return response.data;
      });
  },

};
