<template>
  <main class="dashboard">
    <div class="card mt-3">
      <div class="card-header">

        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item col">
            <h3>{{ project.name }}
            </h3>
          </li>
        </ul>
        <div class="mt-1 row">
          <div class="col">
            <b class="text-primary">Связь стран с проектами в ПланФикс</b>
            <ul class="list-unstyled">
              <li v-for="country in project.countries">
                {{ country.name }} -
                <span v-if="country.pf_code && country.pf_code.id">
                  <a v-if="country.pf_code.task_id" target="_blank"
                     :href="`https://serm-dr.planfix.ru/task/${country.pf_code.task_id}`">{{ country.pf_code.name }}</a>
                  <template v-else>{{ country.pf_code.name }}</template>
                </span>
                <span v-else class="text-danger">НЕ СВЯЗАН</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-1 text-center col-md-2 offset-md-5">
          <select class="form-select form-select-sm m-3" v-model="daily_date" aria-label=".form-select-sm example">
            <option v-for="date in daily_dates" :value="date">{{
                $moment(date.date_create).format('DD.MM HH:mm')
              }}
            </option>
          </select>
        </div>
        <div class="mt-1 text-center">
          <CountryChoice v-bind:active_country.sync="active_country"
                         v-bind:project_id="$route.params.id"
                         v-bind:countries="project.countries"
                         v-bind:changeCountry="changeCountry"></CountryChoice>


        </div>
        <div class="mt-1 text-center">
          <ul class="nav nav-pills">
            <li class="nav-item engine" v-for="engine in engines">
              <a :class="`nav-link${active_engine===engine?' active':''}`"
                 v-on:click.prevent="active_engine=engine"
                 href="#">{{ engine.toUpperCase() }}</a>
            </li>

          </ul>
        </div>

      </div>

    </div>


    <div class="card m-1 border-0 mb-3">
      <div class="border-0 d-flex pb-2">
        <button v-for="top in tops" type="button" @click="state=top"
                :class="`btn btn-outline-primary me-2${state===top?' active':''}`">
          ТОП-{{ top }}
        </button>
        <div class="form-check form-switch ms-5 pt-2">
          <input class="form-check-input" v-model="full_url" type="checkbox" role="switch" id="full_url" checked>
          <label class="form-check-label" for="full_url">Полные ссылки</label>
        </div>
        <div style="margin-left: auto;" v-if="daily_date">
          <button class="btn btn-sm btn-primary" @click="downloadResult" title=" Выгрузить промежуточную выдачу"><i
              class="bi bi-file-earmark-arrow-down"></i> Выгрузить выдачу
          </button>
        </div>

      </div>
      <div class="card-body border border-primary">

        <LinkDetail v-if="link" v-bind:link_id="link" v-bind:date="active_date" v-bind:audit="true"
                    v-bind:country_code="active_country.code"
                    v-bind:project_id="$route.params.id" v-bind:keywords="keywords"></LinkDetail>
        <div class="table-responsive d-flex search-results" style="overflow: scroll; align-items: start" v-else>
          <table class="table" v-for="(key, index) in keywords">
            <thead>
            <tr>
              <th scope="col" class="text-center no-wrap" :colspan="!index?3:2">{{ key.name }}</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center">
              <td v-if="!index" style="width: 50px;">
              </td>
              <td style="background-color: #eee">
                <i :class="active_engine"></i>
                <!--                <b>{{ active_date }}</b>-->
                <popper hover>
                  <span slot="reference">
                     <sup> <i class="bi bi-question-circle"></i></sup>
                    </span>
                  <template #content>
                    <span class="popper text-start">
                      Каноническая выдача
                    </span>
                  </template>
                </popper>
              </td>
              <th class="no-wrap"><i :class="active_engine"></i>
                <b>{{ $moment(daily_date.date_create).format('DD.MM HH.mm') }}</b>
              </th>

            </tr>
            <tr>

              <td v-if="!index">
                <table class="table abs">

                  <tbody>
                  <tr v-for="count in getCounter(key)" v-if="count<=state">
                    <td :class="`no-wrap${full_url?' full-url':''}`">
                      <div class="counter" v-if="!index">{{ count }}</div>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </td>
              <td>
                <table class="table">

                  <tbody>
                  <template v-for="link in key.searches[active_engine]">
                    <tr v-if="link.place<=state" style="background-color: #eee">
                      <td :class="`no-wrap link_info${full_url?' full-url text-truncate':''}`">

                        <span :class="`link`">
                          <popper hover>
                            <span slot="reference" :class="`domain-max`">
                            <a :href="link.link" @click.prevent="linkInfo(link.link_obj_id, true, link)"
                               :style="`${show_tone?getClass(link):''}`">
                              {{ full_url ? link.link : link.domain }}
                            </a>
                              <button class="btn btn-light copy" title="Копировать ссылку"
                                      @click.prevent="copyText(link.link)"><i
                                  class="bi bi-clipboard"></i></button>
                            </span>
                            <template #content>
                            <div class="popper text-start">
                              <a :href="link.link">{{ link.link }}</a>
                              <br>
                              Тон: {{ link.tone_name }}
                              <br>
                              Вид: {{ link.kind_type_name }}
                              <br>
                              Тип: {{ link.type_tone_name }}
                              <br>
                              <template v-if="link.error">
                              <b>Ошибка получения контента</b>
                              <br>
                                </template>
                              <template v-if="link.cross">
                             <b>Пересечение с выдачей изображений</b>
                              <br>
                                </template>
                              Метод воздействия: {{ link.delete_type }}
                              <br>
                              Комментарий: {{ link.comment }}
                            </div>
                            </template>


                          </popper>

                      </span>
                      </td>

                    </tr>
                  </template>
                  </tbody>
                </table>
              </td>
              <td>
                <table class="table">
                  <tbody>
                  <template v-for="(link, l_index) in daily_search.filter(k=>k.id===key.id)[0].searches[active_engine]">
                  <tr v-if="link.place<=state">
                    <td :class="`no-wrap link_info${full_url?' full-url text-truncate':''}`">
                      <span :class="`link`" :style="`${show_tone?getClass(link):''}`">
                        <popper hover>
                          <span slot="reference" :class="`domain-max`">
                            <a :href="link.link" @click.prevent="linkInfo(link.link_obj_id, true, link)"
                               :style="`${show_tone?getClass(link):''}`">
                              {{ full_url ? link.link : link.domain }}
                            </a>

                              <button title="Копировать ссылку" @click.prevent="copyText(link.link)"
                                      class="btn btn-light copy"><i
                                  class="bi bi-clipboard"></i></button>
                            </span>
                          <template #content>
                            <div class="popper text-start">
                              <a :href="link.link" target="_blank">{{ link.link }}</a>
                              <br>
                              Тон: {{ link.tone_name }}
                              <br>
                              Вид: {{ link.kind_type_name }}
                              <br>
                              Тип: {{ link.type_tone_name }}
                              <br>
                              <template v-if="link.error">
                              <b>Ошибка получения контента</b>
                              <br>
                                </template>
                              Метод воздействия: {{ link.delete_type }}
                              <br>
                              <template v-if="link.cross">
                             <b>Пересечение с выдачей изображений</b>
                              <br>
                                </template>
                              Комментарий: {{ link.comment }}
                            </div>
                          </template>


                          </popper>

                      </span>
                    </td>

                  </tr>
</template>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>


        </div>
      </div>
    </div>

  </main>
</template>

<script>
import LinkDetail from "./detail/includes/LinkDetail.vue";
import moment from "moment";
import {ProjectApi} from "@/api/project";
import {DailyApi} from "@/api/daily";

export default {
  name: "DailySearch",
  components: {
    LinkDetail,
  },

  data() {
    return {
      active_date: null,
      daily_dates: [],
      daily_date: null,
      daily_search: [],
      tops: [10, 20, 50, 100],
      active_engine: 'yandex',
      old_state: null,
      state: null,
      full_url: false,
      project: {},
      keywords: [],
      link: null,
      show_tone: true,
      active_country: {code: null, yandex: true, google: true}
    }
  },
  computed: {
    date_query() {
      let date = moment(this.active_date, 'DD/MM/YYYY');
      return `${date.year()}-${date.month() + 1}-${date.date()}`;
    },
    engines() {
      let engines = []
      if (this.active_country.yandex) engines.push('yandex')
      if (this.active_country.google) engines.push('google')
      if (engines.length) this.active_engine = engines[0];
      return engines
    }
  },
  watch: {
    daily_date: function (val) {
      this.getDailyObject();
    },
  },
  mounted: function () {

    if (this.$route.query.link) this.linkInfo(this.$route.query.link, false);
    this.getProject()
  },
  methods: {
    getDailyDates: function () {
      const $this = this;
      DailyApi.list($this.$route.params.id, $this.active_country.code).then(response => {
        $this.daily_dates = response
        if (response.length) $this.daily_date = response[0]
      })
    },
    getDailyObject: function () {
      const $this = this;
      DailyApi.get($this.daily_date.id).then(response => {
        $this.daily_search = response
      })
    },
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.full_info = false;
        this.full_info_show = false;
        this.getKeywords(this.project.id);
        this.getDailyDates()
      }
    },
    getProject: function () {
      let $this = this;
      ProjectApi.get_simple($this.$route.params.id).then(response => {
        $this.project = response;
        $this.active_country = $this.project.active_country_code;
        if (!$this.state) $this.state = $this.project.active_state;
        if (!$this.active_date) {
          $this.active_date = $this.project.last_parser;
        }
        $this.getKeywords($this.project.id);
        $this.getDailyDates()
      })
    },
    closeLink: function () {
      history.pushState({}, null, `${this.$route.path}`);
      this.link = null;
    },
    getKeywords: function (id) {
      let $this = this;
      ProjectApi.keywords(id, 100, $this.date_query, $this.active_country.code || 'RU', false, 20).then(response => {
        $this.keywords = response.results;

      })
    },
    linkInfo: function (id, state = true, link = null) {
      this.link = id;
      if (link && !link.check) {
        link.check = true;
        this.project.no_check -= 1;
      }
      if (state) history.pushState({}, null, `${this.$route.path}?link=${id}`);
    },
    getClass: function (link) {
      if (link.error && !link.tone) return `color: #ff8c39`;
      else {
        let COLORS = this.$COLORS;
        let val = `${link.tone}_${link.kind_type}_${link.type_tone}`;
        return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;
      }
    },
    getCounter(key) {
      let count = this.active_country.yandex ? key.searches.yandex.length : key.searches.google.length;
      return Array.from({length: count}, (_, i) => i + 1)
    },
    copyText(text) {
      const $this = this;
      navigator.clipboard.writeText(text).then(function () {
        $this.$notify({
          type: 'success ',
          text: 'Ссылка скопирована'
        });
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    downloadResult() {
      const $this = this;
      DailyApi.download_report(this.daily_date.id).then(response => {
        let name = `Промежуточная выдача_search_result-${moment($this.daily_date.date_create).format('YYYY')}.xlsx`
        let blob = new Blob([response], {type: 'application/vnd.ms-excel'});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      })
    }
  },
}
</script>

<style scoped>
.engine_info {
  height: 50px !important;
}

.domain-max {
  max-width: 374px;
  overflow: hidden;
  display: block;
}

.text-right {
  text-align: right;
}

.link_info button.copy {
  display: none;
}

.link_info:hover button.copy {
  display: inline-block;
}

.link_info.full-url button.copy {
  display: inline-block;
}

.link_info button.copy {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.link_info {
  position: relative;
}
</style>

