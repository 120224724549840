<template>

  <main class="dashboard links">
    <div class="mb-4 row">
      <h2 class="col">Блок знаний: {{ project.name }}</h2>

    </div>
    <div class="mb-5">
      <div class="d-flex filters">

        <CountryChoice v-if="project.countries" v-bind:active_country.sync="active_country"
                       v-bind:project_id="$route.params.id"
                       v-bind:countries="project.countries"
                       v-bind:changeCountry="changeCountry"></CountryChoice>
        <button type="button" v-for="eng in $ENGINES"
                @click="active_engine = eng"
                :class="'btn btn-sm no-wrap ' + (active_engine===eng?'btn-dark':'btn-outline-dark')">
          <i :class="eng"></i> {{ eng }}
        </button>

        <date-picker
            v-model="active_date"
            locale="ru"
            :clearable="false"
            format="dd.MM.yyyy"
            model-type="yyyy-MM-dd"
            auto-apply
            :allowedDates="allowedDates"
            :enable-time-picker="false"></date-picker>

        <SmallReport
            class="col-3"
            v-if="project.id"
            v-bind:simpleProject="project"
            v-bind:date_start="active_date"
            v-bind:date="active_date"
            v-bind:state="20"
            v-bind:country_id="active_country.id"></SmallReport>

      </div>


    </div>

    <div class="knowledge">
      <template v-for="know in knowledges">
        <KnowledgeSmall :knowledge="know" :changeFunction="changeAll"></KnowledgeSmall>
      </template>
    </div>
  </main>

</template>

<script>
import moment from "moment";
import {ProjectApi} from "@/api/project";
import {KnowledgeApi} from "@/api/knowledge";
import SmallReport from "@/components/SmallReport.vue";
import KnowledgeSmall from "@/views/projects/knowledge/KnowledgeSmall.vue";

export default {
  name: "KnowledgeView",
  components: {
    SmallReport,
    KnowledgeSmall
  },

  data() {
    return {
      active_date: null,
      old_active_date: null,
      knowledge: [],
      active_engine: 'yandex',
      project: {},
      allowedDates: [],
      active_country: {code: null, yandex: true, google: true},
      knowledge_types: {
        right_only: "Только справа",
        with_top: "Информация над выдачей",
        "with_extended_top": "Расширенная информация над выдачей"
      }
    }
  },
  computed: {
    engines() {
      let engines = []
      if (this.active_country.yandex) engines.push('yandex')
      if (this.active_country.google) engines.push('google')
      if (engines.length) this.active_engine = engines[0];
      return engines
    },
    knowledges() {
      return this.knowledge.filter(x => x.engine === this.active_engine);
    }
  },
  watch: {
    active_date: function (val) {
      if (this.old_active_date && this.old_active_date !== val) this.getKnowledge();
      this.old_active_date = val
    },
  },
  mounted: function () {
    this.getProject()
    ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)
  },
  methods: {
    getKnowledge: function () {
      const $this = this;
      KnowledgeApi.list($this.$route.params.id, $this.active_country.code, $this.active_date).then(response => {
        $this.knowledge = response
      })
    },
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.getKnowledge(this.project.id);
      }
    },
    getProject: function () {
      let $this = this;
      ProjectApi.get_simple($this.$route.params.id).then(response => {
        $this.project = response;
        $this.active_country = $this.project.active_country_code;
        if (!$this.active_date) {
          $this.active_date = moment($this.project.last_parser, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        $this.getKnowledge()
      })
    },
    changeAll() {
      this.knowledges.forEach(x=>{
        x.popular=false
      });

    }
  },
}
</script>
<style lang="scss" scoped>
.knowledge {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
</style>