<template>
  <main class="dashboard">
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item col-sm">
            <h3>{{ project.name }}</h3>
          </li>
        </ul>
      </div>

        <div class="d-flex mb-3 mt-3 filters" style="gap: 10px; align-items: baseline;">
              <date-picker v-if="active_date"
                           style="width: 150px;"
                           locale="ru"
                           :clearable="false"
                           v-model="active_date"
                           format="dd.MM.yyyy"
                           model-type="yyyy-MM-dd"
                           auto-apply
                           :allowed-dates="allowedDates"
                           :enable-time-picker="false">
              </date-picker>

              <div class="form-check form-switch">
                <input class="form-check-input" v-model="full_url" type="checkbox" role="switch" id="full_url" checked>
                <label class="form-check-label" for="full_url">Полные ссылки</label>
              </div>

              <CountryChoice
                  v-bind:active_country="active_country"
                  v-bind:project_id="$route.params.id"
                  v-bind:countries="project.countries"
                  v-bind:changeCountry="changeCountry">
              </CountryChoice>
               <MultiSelect
                           v-model="active_keyword"
                           :options="keywords"
                           :multi="false"
                           name="Запроc">
              </MultiSelect>
              <button
                  v-bind:class="`no-wrap btn btn-sm btn-success ${button_data[selected_verified].button_color}`"
                  @click="change_snapshot_verified">{{ button_data[selected_verified].button_text }}
                <popper hover class="align-self-start">
                   <span slot="reference">
                                    <i class="bi bi-question-circle"></i>
                                </span>
                  <template #content>
                                <span class="popper text-start">
                                    {{ button_data[selected_verified].hint }}
                                </span>
                  </template>

                </popper>
              </button>

        </div>
      <div class="card-body">
        <template v-if="snapshot.snapshot && keywords && active_keyword">
          <div class="card-body border border-primary">
            <div class="table-responsive d-flex position-relative search-results">
              <template v-for="engine in $ENGINES">
                <table class="table" v-if="active_country[engine]">
                  <thead>
                  <tr>
                    <th scope="col" class="text-center no-wrap" :colspan="3"><i :class="engine"></i></th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr class="text-center">
                    <td style="width: 50px;"></td>
                    <td>Каноническая</td>
                    <td>Клиентская</td>
                  </tr>

                  <tr>
                    <td style="max-width: 20px;">
                      <table class="table result">
                        <tbody>
                        <tr class="num" v-if="active_keyword"
                            v-for='index in 100'>
                          <td class="no-wrap link_info engine_info">
                            <div class="counter">{{ index }}</div>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </td>

                    <td>
                      <table class="table result" style="background-color: #00000010;">
                        <tbody>
                        <template v-for="link in active_keyword.searches[engine]">
                          <tr>
                            <td :class="`no-wrap link_info engine_info${full_url?' full-url text-truncate':''}`">
                            <span :class="`link`">
                                <popper hover>
                                 <span class="domain-max">
                                        <a
                                            :href="link.link" target="_blank"
                                            :class=" bold_link===link.link_obj_id?' fw-bold link_display':''"
                                            @click.shift.exact="click_on_link_to_display(link.link_obj_id)"
                                            :style="getClass(link)"
                                            :ref="`link-${link.link_obj_id}`"
                                        >{{
                                            full_url ? link.link : link.domain
                                          }}
                                        </a>
                                    </span>
                                  <template #content>
                                    <div class="popper text-start">
                                        Тон: {{ link.tone_name }}<br/>
                                        Тип: {{ link.type_tone_name }}<br/>
                                        Вид: {{ link.kind_type_name }}
                                    </div>
                                  </template>

                                </popper>
                            </span>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table class="table result" v-if="active_snapshot">


                        <draggable
                            v-model="active_snapshot[engine]"
                            @change="afterAdd($event, active_keyword.id, engine )"
                            item-key="id"
                            ghost-class="ghost"
                        >

                          <template #item="{element}">
                            <tr>
                              <td :class="`no-wrap link_info engine_info${full_url?' full-url text-truncate':''}`">
                                      <span :class="`link`">
                                          <a
                                              :href="element.link" target="_blank"
                                              :class=" bold_link===element.link_obj_id?' fw-bold link_display':''"
                                              @click.shift.exact="click_on_link_to_display(element.link_obj_id)"
                                              :style="getClass(element)"
                                              :ref="`link-${element.link_obj_id}`"
                                          >{{ full_url ? element.link : element.domain }}
                                          </a>
                                      </span>
                              </td>
                            </tr>
                          </template>
                        </draggable>
                      </table>


                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </template>
        <div v-if="Object.keys(changes).length">
          <h5 class="text-danger">Новые:</h5>
          <div
              v-for="(event, index) in Object.keys(changes).map(x=>Object.keys(changes[x]).map(key=>changes[x][key])).flat(Infinity)">
            <b>{{ index + 1 }})</b> {{ event }}
          </div>
        </div>

        <div v-if="show_update_button || Object.keys(changes).length>0">
          <button class="btn btn-sm btn-success"
                  @click="updateSnapshot"
          >Сохранть изменения
          </button>
        </div>
        <div v-if="Object.keys(old_changes).length">
          <h5 class="text-success">Ранние:</h5>
          <div
              v-for="(event, index) in Object.keys(old_changes).map(x=>Object.keys(old_changes[x]).map(key=>old_changes[x][key])).flat(Infinity)">
            <b>{{ index + 1 }})</b> {{ event }}
          </div>
        </div>

      </div>
    </div>

  </main>
</template>


<script>
import {ProjectApi} from '@/api/project'
import moment from 'moment';
import draggable from 'vuedraggable'


export default {
  name: 'CurrentLinks',
  components: {
    draggable,
  },

  data() {
    return {
      allowedDates: [],
      drag: false,
      state: 100,
      active_date: null,
      old_active_date: null,
      keywords: [],
      active_keyword: null,
      bold_link: '',
      active_country: {},
      project: {},
      full_url: false,
      changes: {},
      old_changes: {},
      snapshot: {},
      show_update_button: false,
      selected_verified: false,
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        sort: true,
        chosenClass: 'chosen',
      },

      button_data: {
        true: {
          hint: 'Сейчас выдача отображается у пользователей в TR.com',
          button_text: 'Изъять',
          button_color: 'btn-success'
        },
        false: {
          hint: 'Сейчас выдача не отображается у пользователей в TR.com',
          button_text: 'Отправить',
          button_color: 'btn-danger'
        }

      }
    }
  },
  computed: {
    date_query() {
      return moment(this.active_date).format('YYYY-MM-DD');
    },
    active_snapshot() {
      return this.snapshot.snapshot.country_data.find(x => x.code === this.active_country.code).data.top_links.find(x => x.id === this.active_keyword.id)?.searches
    },
  },

  watch: {
    active_date: function (val) {
      if (this.old_active_date !== null) this.getSnapshot();
      this.old_active_date = val;
    },
    active_country() {
      this.getKeywords()
    },
    selected_verified(new_value) {
      this.show_update_button = (new_value !== this.snapshot.verified) ? true : false
    }
  },

  mounted: function () {
    // ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)
    this.getProject();
  },
  methods: {
    getProject: function () {
      let $this = this;
      ProjectApi.get_simple($this.$route.params.id).then(response => {
        $this.project = response;
        if (!$this.state) $this.state = $this.project.active_state;
        $this.active_country = $this.project.active_country_code;
        if (!$this.active_date) {
          $this.active_date = moment($this.project.last_parser, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }
        this.getSnapshot()
      })
    },
    getSnapshot() {
      let $this = this;
      ProjectApi.get_snapshot(this.$route.params.id, this.date_query).then(response => {
        $this.snapshot = response
        $this.selected_verified = $this.snapshot.verified
        $this.allowedDates = $this.snapshot.all_snapshot_dates.map(x => moment(x, 'DD/MM/YYYY').format('YYYY-MM-DD'))
        $this.old_changes = response.events || {}
      })
    },

    afterAdd(evt, key_id, engine) {
      const element = evt.moved.element
      const oldIndex = evt.moved.oldIndex
      const newIndex = evt.moved.newIndex

      if (!this.changes[this.active_country.code]) {
        this.changes[this.active_country.code] = {};
      }

      let changes_country = this.changes[this.active_country.code]

      if (!changes_country[key_id]) {
        changes_country[key_id] = [];
      }

      changes_country[key_id].push(`${this.$store.getters.user.fullname} ${moment().format("DD.MM.YYYY")} в запросе ${this.active_keyword.name} ${engine} переместили ${element.link} c ${oldIndex + 1} позиции на ${newIndex + 1} позицию`)
      this.changes[this.active_country.code] = changes_country
    },

    change_snapshot_verified() {
      let data = {
        'snapshot_id': this.snapshot.pk,
        'verified': !this.selected_verified,
      }

      ProjectApi.change_snapshot_verified(data).then(response => {
        if (response.status === 200) {

          this.$notify({
            type: 'success',
            text: `Клиентская выдача успешно ${response.data.new_verified ? 'отправлена' : 'изъята'}`
          });

          this.selected_verified = response.data.new_verified

        } else {
          this.$notify({
            type: 'error',
            text: `Возникла ошибка: ${response.data.error}`
          });
        }
      })

    },

    updateSnapshot() {

      let save_data = Object.assign({}, this.old_changes);

      for (let country in this.changes) {
        for (let keyword in this.changes[country]) {

          if (!save_data[country]) {
            save_data[country] = {}
          }

          let changes_country = save_data[country]

          if (!changes_country[keyword]) {
            // changes_country[key] = []
            changes_country[keyword] = []
          }

          save_data[country][keyword].push.apply(save_data[country][keyword], this.changes[country][keyword])
        }
      }


      let data = {
        'snapshot_data': this.snapshot,
        'events': save_data
      }
      ProjectApi.update_snapshot(this.$route.params.id, data).then(response => {
        if (response === 'ok') {
          this.$notify({
            type: 'success',
            text: "Выдача успешно сохранена"
          });

          this.old_changes = save_data
          this.changes = []

        } else {
          this.$notify({
            type: 'error',
            text: "Возникла ошибка при сохранении выдачи"
          });
        }
      })
    },
    click_on_link_to_display(link_obj_id) {
      this.bold_link = this.bold_link === link_obj_id ? link_obj_id : ''
    },

    getClass(link) {
      if (link.error && !link.tone) return `color: #ff8c39`;
      else {
        let COLORS = this.$COLORS;
        let val = `${link.tone}_${link.kind_type}_${link.type_tone}`;
        return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;
      }
    },

    changeCountry(country) {
      this.active_country = country
    },

    getKeywords: function () {
      let $this = this;
      ProjectApi.keywords(this.$route.params.id, 100, this.date_query, this.active_country.code || 'RU', false, 20).then(response => {
        $this.keywords = response.results;
        $this.active_keyword = $this.keywords[0];
      })
    },
  }
}

</script>

<!--<style scoped>-->
<!--.engine_info {-->
<!--  height: 50px !important;-->
<!--}-->

<!--.domain-max {-->
<!--  max-width: 374px;-->
<!--  overflow: hidden;-->
<!--  display: block;-->
<!--}-->

<!--.text-right {-->
<!--  text-align: right;-->
<!--}-->

<!--.link_info button.copy {-->
<!--  display: none;-->
<!--}-->

<!--.link_info:hover button.copy {-->
<!--  display: inline-block;-->
<!--}-->

<!--.link_info.full-url button.copy {-->
<!--  display: inline-block;-->
<!--}-->

<!--.link_info button.copy {-->
<!--  position: absolute;-->
<!--  top: 0;-->
<!--  right: 0;-->
<!--  z-index: 10;-->
<!--}-->

<!--.link_info {-->
<!--  position: relative;-->
<!--}-->

<!--.link_display {-->
<!--  background: rgba(13, 110, 253, .25);-->
<!--}-->

<!--.ghost {-->
<!--  opacity: 0.5;-->
<!--  background: #dadada;-->
<!--}-->

<!--.chosen {-->
<!--  opacity: 0.5;-->
<!--  background: #dadada;-->
<!--}-->

<!--.link {-->
<!--  cursor: pointer;-->
<!--}-->

<!--td {-->
<!--  max-width: 400px;-->
<!--  height: 20px;-->
<!--}-->

<!--.table.sticky {-->
<!--  position: sticky;-->
<!--  left: 0;-->
<!--  display: flex;-->
<!--  flex-direction: column;-->
<!--  flex: 1;-->
<!--}-->

<!--.table.sticky td {-->
<!--  background: #fff;-->
<!--  font-weight: bold;-->
<!--}-->

<!--.head {-->
<!--  width: 105px;-->
<!--  flex: 0 0 105px;-->
<!--}-->

<!--.place {-->
<!--  width: 40px;-->
<!--  text-align: left;-->
<!--  margin-left: 0;-->
<!--  flex: 0 0 40px;-->
<!--}-->

<!--.card-body {-->
<!--  padding: 15px;-->
<!--}-->

<!--.scroll-y {-->
<!--  height: 250px;-->
<!--  overflow-y: scroll;-->
<!--}-->

<!--.no-wrap.full-url {-->
<!--  white-space: normal;-->
<!--  height: 130px;-->
<!--}-->

<!--.card-header-tabs li a {-->
<!--  white-space: nowrap;-->
<!--  height: 30px;-->
<!--}-->

<!--.popper.text-start {-->
<!--  max-width: 300px;-->
<!--  white-space: normal;-->
<!--}-->

<!--.engine_info {-->
<!--  height: 20px;-->
<!--  padding: 7px;-->
<!--}-->

<!--.domain-max {-->
<!--  max-width: 374px;-->
<!--  overflow: hidden;-->
<!--  display: block;-->
<!--}-->

<!--.text-right {-->
<!--  text-align: right;-->
<!--}-->

<!--.link_info button.copy {-->
<!--  display: none;-->
<!--}-->

<!--.link_info:hover button.copy {-->
<!--  display: inline-block;-->
<!--}-->

<!--.link_info.full-url button.copy {-->
<!--  display: inline-block;-->
<!--}-->

<!--.link_info button.copy {-->
<!--  position: absolute;-->
<!--  top: 0;-->
<!--  right: 0;-->
<!--  z-index: 10;-->
<!--}-->

<!--.link_info {-->
<!--  position: relative;-->
<!--}-->

<!--.link_display {-->
<!--  background: rgba(13, 110, 253, .25);-->
<!--}-->

<!--.popper .col-4 {-->
<!--  max-width: 60px !important;-->
<!--  width: 60px !important;-->
<!--}-->

<!--.table-sub-head {-->
<!--  top: 40px;-->
<!--  background: #fff;-->
<!--  z-index: 10 !important;-->
<!--}-->

<!--.table-head {-->
<!--  background: #fff;-->
<!--  z-index: 10 !important;-->
<!--}-->

<!--table {-->
<!--  border-collapse: separate-->
<!--}-->

<!--.table.result {-->
<!--  margin-top: 20px;-->
<!--}-->
<!--</style>-->

