import { HTTP } from "./common";

export const KnowledgeApi = {
  list(project_id, country_code, date) {
    return HTTP.get(`knowledge/?project_id=${project_id}&country_code=${country_code}&date=${date}`).then((response) => {
      return response.data;
    });
  },
  get(id) {
    return HTTP.get(`knowledge/${id}/`).then((response) => {
      return response.data;
    });
  },
  update(id, data) {
    return HTTP.patch(`knowledge/${id}/`, data).then((response) => {
      return response.data;
    });
  },
};
