<template>
  <div class="col-sm-6">
    <div class="row">
      <div :class="'col-8'+ (project.name_error ?' form-error':'')">
        <input type="text" placeholder="Название проекта" class="form-control" id="name" :disabled="!(can_edit && checkFieldPerm('name'))"
               v-model="project.name" required="">
        <div class="error" v-if="project.name_error">{{project.name_error}}</div>
      </div>
      <div :class="'col-4'+(can_edit && checkFieldPerm('project_type')?'':'area-disabled')+ (project.project_type_error ?' form-error':'')">
          <MultiSelect
              v-model="project.project_type"
              :options="project_types"
              :multi="false"
              :name="'Статус'"
              :required="true"
              :only_value="true"
              :max="5"
          ></MultiSelect>
        <div class="error" v-if="project.project_type_error">{{project.project_type_error}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-8"></div>
      <div class="col-4"></div>
    </div>


    <div :class="`mt-2 ${can_edit && checkFieldPerm('countries')?'':'area-disabled'}`">
      <label for="name" class="form-label mt-3">Страны</label>
      <span class="form-group__message d-inline" v-if="project.country_error">{{ project.country_error }}</span>

      <MultiSelect
          v-model="project.countries"
          :model_error="project.country_error"
          :options="countries"
          :multi="true"
          :max="5"
      ></MultiSelect>
    </div>
    <div :class="`mt-2 ${can_edit && checkFieldPerm('countries')?'':'area-disabled'}`" v-if="project.countries.length">

      <div v-for="country in project_countries" class="card country">
        <div class="card-body">
          <strong class="card-title no-wrap">{{ country.name }} <sup>{{ country.code }}</sup></strong>
          <div class="form-check">
            <input class="form-check-input" :disabled="!country.can_tone" type="checkbox" v-model="country.tone">
            <label class="form-check-label">
              Тональность
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" :disabled="!country.can_theme" type="checkbox" v-model="country.theme">
            <label class="form-check-label">
              Темы
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" :id="`hint_${country.code}`" type="checkbox" v-model="country.hint">
            <label class="form-check-label" :for="`hint_${country.code}`">
              Подсказки
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" :id="`hint_screen_${country.code}`" type="checkbox"
                   @change="country.hint=country.hint_screen?country.hint_screen:country.hint"
                   v-model="country.hint_screen">
            <label class="form-check-label" :for="`hint_screen_${country.code}`">
              Подсказки скриншотами
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="country.image">
            <label class="form-check-label">
              Изображения
            </label>
          </div>
          <div class="d-flex">
            <div class="form-check me-2">
              <input class="form-check-input" type="checkbox" v-model="country.yandex">
              <label class="form-check-label">
                Y
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="country.google">
              <label class="form-check-label">
                G
              </label>
            </div>
          </div>
          <div class="row pf-code">
            <MultiSelect
                v-model="country.pf_code"
                :options="pf_codes"
                :multi="false"
                :only_value="false"
                name="PF задача"
            ></MultiSelect>
          </div>

        </div>
      </div>

    </div>
    <div class="row mt-2 mb-3" v-for="(keyword, index) in project.keywords_data">
      <div class="col-10">
        <input class="form-control"
               :disabled="!(can_edit && checkFieldPerm('keywords') && (project.data_collect || !project.id))"
               v-model="project.keywords_data[index].name" type="text" :ref="`keyword-input-${index}`">


      </div>
      <template v-if="can_edit && checkFieldPerm('keywords') && (project.data_collect || !project.id)">
        <button type="button" @click="delete_key=keyword; $modal('show', 'delete_object')" v-if="keyword.id"
                class="btn btn-danger col-1 btn-sm">
          <i class="bi bi-x-circle-fill"></i>
        </button>
        <button type="button" v-else class="btn btn-danger col-1 btn-sm"
                @click="project.keywords_data.splice(index, 1)">
          <i class="bi bi-dash"></i>
        </button>
        <button type="button" v-if="index === project.keywords_data.length - 1" class="btn btn-success col-1 btn-sm"
                @click="project.keywords_data.push({name:'', id: '', countries: [], s_result: true})">
          <i class="bi bi-plus-lg"></i>
        </button>
      </template>
      <div
          :class="`row m-2 ${can_edit && checkFieldPerm('keywords') && (project.data_collect || !project.id)?'':'area-disabled'}`">
        <!--            <div class="check_key">-->
        <div class="form-check col-2 pt-2">
          <input class="form-check-input" type="checkbox" v-model="project.keywords_data[index].s_result"
                 :id="'search'+index">
          <label class="form-check-label" :for="'search'+index">
            Выдача
          </label>
        </div>
        <div class="col-6">
          <MultiSelect
              v-model="keyword.countries"
              name="Страны"
              :options="project_countries"
              :multi="true"
              :max="5"
          ></MultiSelect>
        </div>
        <!--            </div>-->
      </div>

    </div>
    <template v-if="can_edit && load_files.length">
      <label class="form-label mt-3">Массовая загрузка данных:</label>
      <div class="form-group">
        <span class="mb-1">Тип загрузки</span>
        <select class="form-select" v-model="active_load_data">
          <option v-for="load in load_files" :value="load.name">{{ load.title }}</option>
        </select>
      </div>

      <div class="mb-3 mt-2" v-for="load in load_files">
        <template v-if="active_load_data === load.name">
          <span class="form-label mt-3">Файл</span>
          <input class="form-control" accept=".xlsx" @change="saveFileDict($refs[load.name], load.name)"
                 :ref="load.name" type="file"
                 :id="load.name">
        </template>
      </div>
      <label class="form-label mt-3">Образцы файлов:</label>
      <div class="" v-for="load in load_files">
        <a class="fst-italic"
           :href="`https://804303.selcdn.ru/ra_media/media/static/temp/${load.temp}.xlsx`">{{ load.title }}</a>
      </div>
    </template>
    <modal :modal_id="'delete_object'">
      <template v-slot:header>
        Удалить "{{ delete_key.name }}"?
      </template>
      <template v-slot:body>
        <div class="d-flex">
          <h1 class="text-danger"><i class="bi bi-exclamation-lg"></i></h1>
          <p> Вместе с ключевым словом удалятся результаты выдачи</p>
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'delete_object');"
                data-bs-dismiss="modal">Отмена
        </button>
        <button type="button" class="btn btn-danger" v-on:click="DeleteObject(delete_key);">Удалить</button>
      </template>
    </modal>
  </div>
</template>

<script>
import {PfApi} from "@/api/planfix";
import {ProjectApi} from "@/api/project";
import MultiSelect from "@/plugins/Select.vue";

export default {
  name: "BaseInfo",
  props: ['project', 'types', 'load_data', 'saveFileDict', 'can_edit', 'checkFieldPerm'],
  data() {
    return {
      countries: [],
      pf_codes: [],
      active_load_data: '',
      delete_key: {name: ''},
      component: {},
    }
  },
  components: {},

  watch: {
    active_load_data(val) {
      let $this = this;
      $this.load_data.forEach(function (e) {
        if ($this.$refs[e.name]) {
          $this.$refs[e.name].value = null
        }
      });
    }
  },
  computed: {
    project_types() {
      return Object.keys(this.types).map(x => {
        return {id: x, name: this.types[x]}
      })
    },
    load_files() {
      return this.load_data.filter(x => (this.$store.getters.user.permission.load_files.indexOf(x.name) + 1))
    },
    project_countries() {
      let qs = this.project.countries, $this = this;
      qs.forEach(function (country) {
        let index = $this.project.countries.indexOf(country);
        if (!country.s_id) {
          if (country.code === 'RU') {
            country.tone = true;
            country.yandex = true;
            country.google = true;
            country.hint = true;
          } else country.google = true;
        }
        $this.project.countries[index] = country
      })
      return qs
    }
  },
  mounted: function () {
    let $this = this;
    PfApi.get($this.$route.params.id).then(response => {
      $this.pf_codes = response;
    });
    ProjectApi.countries().then(response => {
      $this.countries = response.results;
      if (!$this.project.countries.length) {
        $this.project.countries = $this.project.countries.concat($this.countries.filter(x => x.code === 'RU'))
      }
    });
  },
  methods: {
    DeleteObject(keyword) {
      let $this = this;
      ProjectApi.delete_keyword($this.project.id, keyword.id).then(response => {
        $this.$modal('hide', 'delete_object');
        $this.project.keywords_data.splice($this.project.keywords_data.indexOf(keyword), 1);
        if (!$this.project.keywords_data.length) $this.project.keywords_data.push({name: '', id: ''});
        $this.$notify({
          type: 'success ',
          text: response
        });
      }).catch(err => {
        $this.$notify({
          type: 'error ',
          text: err.response.data
        });
      });
    },
  }
}
</script>

<style lang="scss">

.card.country {
  width: 12rem;
  display: inline-block;
}

.pf-code {
  .select {
    font-size: 12px;
    padding: 0;
    width: 100%;

    .dropdown-toggle {
      padding: 5px 0;
      min-width: 100% !important;

      .value {
        display: block;
        white-space: normal;
        text-align: left;
      }
    }
  }


}


</style>

