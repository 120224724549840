<template>

  <tr :class="wiki.collect?'':'text-secondary'">

    <td>
      {{ index + 1 }}
    </td>
    <td>
      <template v-if="wiki.edit">
        <div class="d-flex" style="align-items: center">
        <input class="form-control" v-model="wiki.link">
        <a href="" class="ms-sm-2 no-wrap" v-on:click.prevent="wiki.id?wiki.edit=false:addWiki(wiki)"><i
            class="bi bi-check-circle"></i>
        </a>
          </div>
      </template>
      <template v-else>
        <router-link :class="wiki.collect?'':'text-secondary'"
                     :to="{name: 'project_wikipedia_detail', params: {id: $route.params.id, wiki_id: wiki.id}}">
          {{ decodeURI(wiki.link) }}
        </router-link>
        <a href="" class="no-wrap ms-2" v-on:click.prevent="wiki.edit=true"><i class="bi bi-pencil"></i></a>
      </template>

    </td>
    <td>
      <div v-if="wiki.id" class="form-check form-switch">
        <input class="form-check-input"
               v-model="wiki.collect"
               @change="startParser"
               type="checkbox"
               role="switch"
               :id="`wikiCollect`+wiki.id">
        <label
            class="form-check-label"
            :for="`wikiCollect`+wiki.id"
        ></label>
      </div>
    </td>
    <td>
      <MultiSelect
          v-model="wiki.country"
          name="Страны"
          :options="countries"
          :multi="true"
      ></MultiSelect>
    </td>

    <td><span v-if="wiki.date">{{ $moment(wiki.date).format("DD.MM.YYYY") }}</span></td>
    <td>
      <div class="form-check form-switch" v-if="wiki.id">
        <input class="form-check-input" v-model="wiki.subscribe" @change="subscribe" type="checkbox"
               role="switch"
               id="flexSwitchCheckChecked">
      </div>
    </td>
  </tr>

</template>

<script>
import {WikiApi} from "@/api/wiki";

export default {
  name: "WikipediaItem",
  props: {
    wiki: Object,
    index: Number,
    countries: Array,
    pushWiki: Function
  },
  watch: {
    wiki: {
      handler: function () {
        let data = JSON.parse(JSON.stringify(this.wiki));
        data.country_ids = data.country.map(x => x.s_id);
        if (data.id) WikiApi.update(data)
      },
      deep: true
    }
  },


  methods: {

    subscribe() {
      WikiApi.subscribe(this.wiki.id, {subs: this.wiki.subscribe, project_id: this.$route.params.id})
      this.$notify({
        type: 'success ',
        text: `Вы ${this.wiki.subscribe ? 'подписаны на уведомления' : 'отписались от уведомлений'}`
      });
    },
    addWiki(wiki){
      let data = JSON.parse(JSON.stringify(wiki));
      data.country_ids = data.country.map(x => x.s_id);
      data.project = this.$route.params.id;
      WikiApi.update(data).then(resp=>{
        this.pushWiki(wiki, resp)
      })
    },
    startParser(){
      if (this.wiki.id) WikiApi.start_parser(this.wiki.id)
    }

  },
}
</script>

<style scoped>
.high {
  cursor: pointer;
  background: #ffc107;
}

.d-flex p {
  flex: 50%
}

.d-flex p:first-child {
  border-right: 1px solid #ccc;
}

.d-flex .diff {
  padding-left: 5px;
}
</style>
<style>

.bg-green {
  background-color: #72ff88;
  opacity: 0.8;
}

.bg-red {
  background-color: #ff9285;
  opacity: 0.8;
}
</style>
