<script setup>
import {computed, ref, onMounted, onUnmounted} from 'vue'
import {useStore} from 'vuex'
import {notify} from "@kyvg/vue3-notification";
import {useRoute} from 'vue-router';

const route = useRoute()
const store = useStore()


onMounted(() => {
  document.body.classList.add('full-width')
})
onUnmounted(() => {
  document.body.classList.remove('full-width')
})


const remember = computed(() => store.getters.remember)

const user = {
  username: remember.value,
  password: "",
  remember: !!remember.value
}
const show_pass = ref(false);

function login(e) {
  e.preventDefault();
  store.dispatch('login', user).then(() => {
      location.search = "";
      location.pathname = route.query.next || '/';
  }).catch(err => {
    let err_text = 'Данные неверны';
    if (err.response.status === 429) err_text = 'Превышено количество попыток ввода пароля';
    notify({
      type: 'error ',
      title: "Ошибка!",
      text: err_text,
    });

  })
}


</script>
<template>
  <main class="login">
    <form @submit="login">
      <h1>Авторизация</h1>

      <div class="position-relative">
        <input type="text" class="form-control" required v-model="user.username" id="login" placeholder="Логин">
      </div>
      <div class="position-relative">
        <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
        <input id="password" class="form-control" :type="show_pass?'text':'password'" name="" required
               v-model="user.password" placeholder="Пароль">
      </div>

      <div class="checkbox mb-3">

        <input type="checkbox" v-model="user.remember" id="remember_me" value="remember-me" checked>
        <label for="remember_me">Запомнить</label>
      </div>
      <button class="w-100 btn btn-lg btn-secondary" type="submit">Вход</button>
    </form>
  </main>
</template>

<style scoped>

main {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100%;
}

.login form {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}


.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


</style>