<template>
  <div>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'UniqueText',
}

</script>
<style scoped>
.dashboard {
  margin-left: 26%;
  width: 75%;
  margin-top: 100px;
}

</style>
