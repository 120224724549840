<template>
  <main class="dashboard">
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">
        Настройки проекта {{ tr_settings.name }} для TR.com

      </h1>
      <BackButton></BackButton>
    </div>

    <div>
      <SettingMenu :id="$route.params.id" v-bind:route="$route.name" v-bind:project="project">
      </SettingMenu>
    </div>

    <form class="needs-validation" novalidate="">

      <div class="row g-3">

        <div :class="`col-sm-6 ${can_edit?'':'area-disabled'}`">
            <div :class="`mt-2 ${can_edit && checkFieldPerm('organization')?'':'area-disabled'}`">
              <label class="form-label mt-3">Организация</label>
              <MultiSelect
                v-model="tr_settings.organization"
                :options="organizations"
                :multi="false"
                :only_value="true"
                :max="5">
            </MultiSelect>

            </div>
            <div class="form-check mt-3">
              <input class="form-check-input" :disabled="!(can_edit && checkFieldPerm('show_on_ton'))" type="checkbox"
                     v-model="tr_settings.show_on_ton" id="trcom">
              <label class="form-check-label" for="trcom">
                Отобразить на ton-reputation
              </label>
            </div>



          <div :class="`mt-2 ${can_edit && checkFieldPerm('tr_countries')?'':'area-disabled'}`">
            <label for="name" class="form-label mt-3">Отображаемые страны</label>
            <MultiSelect
                v-model="tr_settings.tr_countries"
                :options="tr_settings.countries"
                :multi="true"
            >
            </MultiSelect>
          </div>

          <div :class="`mt-2 ${can_edit && checkFieldPerm('tr_update_snapshot_schedule')?'':'area-disabled'}`">
            <label for="name" class="form-label mt-3">График обновления выдачи</label>
            <MultiSelect
                v-model="tr_settings.tr_update_snapshot_schedule"
                :options="weekdays"
                :multi="true"
            >
            </MultiSelect>
          </div>

          <div :class="`mt-2 ${can_edit && checkFieldPerm('tr_active_state')?'':'area-disabled'}`">

            <label class="form-label mt-3">Отображаемый ТОП</label>
             <MultiSelect
                v-model="tr_settings.tr_active_state"
                :options="$STATES"
                :multi="false"
                :only_value="true"
             >
            </MultiSelect>

          </div>

          <div class="form-check mt-3">
            <input class="form-check-input"
                   :disabled="!(can_edit && checkFieldPerm('update_snapshot_with_confirmation'))" type="checkbox"
                   v-model="tr_settings.update_snapshot_with_confirmation"
                   id="update_checkbox">
            <label class="form-check-label" for="update_checkbox">Обновлять с подтверждением</label>
          </div>


        </div>
      </div>

      <ModalSave v-bind:project="project" v-bind:save_errors="save_errors"></ModalSave>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn btn-secondary btn-sm m-2" @click="reset_changes" type="button">
          Сбросить изменения
        </button>

        <button class="btn btn-warning btn-sm m-2" @click="save_current_project_to_snapshot" type="button">
          Обновить в ЛК tr.com
        </button>

        <button class="btn btn-primary btn-sm m-2" type="submit">
          Сохранить
        </button>
      </div>
    </form>


  </main>
</template>

<script>
import {ProjectApi} from "@/api/project";
import {OrganizationApi} from "@/api/organization";
import ModalSave from "@/views/projects/edit/includes/ModalSave.vue";
import moment from 'moment'
import SettingMenu from '@/components/SettingMenu';


export default {
  name: 'TrSetting',
  components: {
    ModalSave, SettingMenu
  },
  data() {
    return {
      tr_settings: {},
      stock_tr_settings: {},
      save_errors: [],
      save_process: false,
      project: {},
      organizations: [],
    }
  },
  computed: {
    can_edit() {
      return this.project.project_type !== 'archive';
    },
    weekdays() {
      return Array.apply(null, Array(7)).map(function (_, i) {
        return {'name': moment(i, 'e').startOf('week').isoWeekday(i + 1).format('dddd'), 'id': i};
      })
    }
  },
  beforeMount() {
    if (!(this.$store.getters.user.permission.actions.indexOf('project_tr_setting') + 1)) this.$router.push({name: '404'});
  },
  mounted: function () {
    this.get_tr_settings()
    OrganizationApi.list().then(response => this.organizations = response.results);
    let $this = this;
    let forms = document.querySelectorAll('.needs-validation');

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
          form.addEventListener('submit', function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            } else {
              event.preventDefault();
              event.stopPropagation();
              $this.saveForm()
            }
            form.classList.add('was-validated')
          }, false)
        })
  },

  methods: {
    checkFieldPerm(name) {
      return this.$store.getters.user.permission.project_fields.indexOf(name) + 1;
    },
    save_current_project_to_snapshot() {
      ProjectApi.save_current_project_to_snapshot(this.$route.params.id).then(response => {
        this.$notify({
          type: 'success',
          text: response.data
        });
      }).catch(error => {
        this.$notify({
          type: 'error',
          text: error.response.data
        });
      })
    },
    get_tr_settings() {
      ProjectApi.get_tr_settings(this.$route.params.id).then(response => {
        this.tr_settings = response
        this.deserialize_tr_settings()
      })
    },
    deserialize_tr_settings() {
      let deserializerd_tr_countries = []
      for (let i of this.tr_settings.tr_countries) {
        deserializerd_tr_countries.push.apply(deserializerd_tr_countries, this.tr_settings.countries.filter(x => x.code === i))
      }
      this.tr_settings.tr_countries = deserializerd_tr_countries

      let deserializerd_weekdays = []
      for (let i of this.tr_settings.tr_update_snapshot_schedule) {
        deserializerd_weekdays.push(this.weekdays[i])
      }
      this.tr_settings.tr_update_snapshot_schedule = deserializerd_weekdays

      this.stock_tr_settings = structuredClone(this.tr_settings);
    },

    saveForm() {

      let update_data = {}

      for (let i in this.stock_tr_settings) {
        this.stock_tr_settings[i]
        if (JSON.stringify(this.stock_tr_settings[i]) !== JSON.stringify(this.tr_settings[i])) {
          update_data[i] = this.tr_settings[i]
        }
      }

      ProjectApi.update_tr_settings(this.$route.params.id, update_data).then(response => {
        this.tr_settings = response
        this.deserialize_tr_settings()
      })

    },
    reset_changes() {
      this.tr_settings = structuredClone(this.stock_tr_settings);
    },
  }

}

</script>
<style scoped>

label {
  font-weight: 700;
}

.check_key .form-check {
  margin: -5px 0 0;
}
</style>
  