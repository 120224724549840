<template>
  <main class="dashboard stats">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <h1>Статистика</h1>
      <BackButton></BackButton>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="row mb-2">

          <div class="col">
            <MultiSelect
                v-model="project"
                :options="projects"
                :multi="false"
                name="Проект"
                :only_value="false"
            ></MultiSelect>
          </div>
          <div class="col no-wrap">
            <div class="btn-group" role="group">
              <template v-for="g in graphs">
                <button type="button" :class="`btn btn-outline-primary${g.value === graph?' active':''}`"
                        @click="graph=g.value!==graph?g.value:graph"
                >{{ g.name }}
                </button>
              </template>
            </div>
          </div>

          <div class="col">
            <ul class="nav nav-pills">
              <li class="nav-item engine" v-for="eng in engines">
                <a :class="`nav-link${engine===eng?' active':''}`"
                   v-on:click.prevent="engine=eng"
                   href="#">{{ eng }}</a>
              </li>

            </ul>
          </div>


        </div>
        <div class="row justify-content-center mb-3">
          <div class="col">
            <div v-if="project && graph === 'keyword'">
               <CountryChoice v-if="project?.countries"
              v-bind:active_country="project_country"
              v-bind:countries="project.countries"
              v-bind:changeCountry="(country) => project_country = country">
          </CountryChoice>

            </div>
          </div>
          <div class="col-4">
            <div class="me-2 d-flex">
              <date-picker
                  v-model="filter.date_start"
                  locale="ru"
                  :clearable="false"
                  format="dd.MM.yyyy"
                  model-type="dd/MM/yyyy"
                  auto-apply
                  :enable-time-picker="false"
              ></date-picker>

              <date-picker
                  v-model="filter.date_end"
                  locale="ru"
                  :clearable="false"
                  format="dd.MM.yyyy"
                  model-type="dd/MM/yyyy"
                  auto-apply
                  :enable-time-picker="false"
              ></date-picker>
              <button v-if="graph !== 'keyword'" class="btn btn-outline-secondary" v-on:click.prevent="getAllLink()"
                      type="button"
                      id="button-query"><i class="bi bi-search"></i></button>
            </div>
          </div>
          <div class="col" v-if="graph==='links'">
            <MultiSelect
                v-model="day_of_week"
                :options="days_of_week"
                :multi="true"
                name="День недели"
                v-bind:only_value="true"
            ></MultiSelect>
          </div>
          <div class="col-3">
            <button @click="$modal('show', 'settings');" class="btn btn-sm btn-primary">
              <i class="bi bi-gear"></i>
            </button>
            <button class="btn btn-sm btn-primary" v-if="graph!=='keyword'" @click="getReport()">
              <i class="bi bi-file-arrow-down"></i>
            </button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="d-grid gap-2 d-md-block mb-1" v-if="graph!=='keyword'">
            <template v-for="u_link in links">
              <button v-on:click="addLink(u_link.id)" type="button"
                      :style="choice_links.filter(x => x.id === u_link.id).length?getClass(u_link, 'background-color'):''"
                      :class="`btn btn-sm linked ms-1 mb-2 text-truncate btn-${choice_links.filter(x => x.id === u_link.id).length?'success':'secondary'}`">
                <popper hover>
                  {{ u_link.link }}
                  <template #content>
                    <div class="popper text-start">
                      <span>Проект: {{ u_link.project_name }}</span><br>
                      <span>Тон: {{ u_link.get_tone_display }}</span><br>
                      <span>{{ u_link.title }}</span><br>
                      <a :href="u_link.link" target="_blank">{{ u_link.link }}</a>
                    </div>
                  </template>
                </popper>
              </button>
              <button v-if="choice_links.filter(x => x.id === u_link.id).length" @click="removeTrack(u_link)"
                      class="btn btn-sm btn-danger text-white mb-2"
                      title="Удалить из отслеживания"><i class="bi bi-x"></i></button>
            </template>

          </div>
          <div class="d-grid gap-2 d-md-block"
               v-for="link in choice_links.filter(x=>!links.filter(y=>y.id===x.id).length)">
            <popper hover>
              <span class="btn linked btn-sm btn-outline-primary text-truncate">{{ link.link }}</span>
              <template #content>
                <div class="popper text-start">
                  <span>Проект: {{ link.project_name }}</span><br>
                  <span>Тон: {{ link.get_tone_display }}</span><br>
                  <span>{{ link.title }}</span><br>
                  <a :href="link.link" target="_blank">{{ link.link }}</a>
                </div>
              </template>

            </popper>

            <button @click="addTrack(link)" type="button" class="btn btn-sm btn-primary text-truncate"
                    title="Добавить на отслеживание"><i class="bi bi-star"></i></button>
          </div>
        </div>
      </div>
      <template v-if="graph==='gist'" v-for="link in choice_links">
        <a v-if="choice_links.length > 1" :href="link.link" target="_blank" class="h5 p-2">{{ link.link }}</a>
        <gist_stat v-bind:link="link"
                   v-bind:engine="engine"
                   v-bind:day_of_week="day_of_week"
                   v-bind:countryFunc="getLink"></gist_stat>
      </template>
      <template v-if="graph==='links'" v-for="link in choice_links">
        <a v-if="choice_links.length > 1" :href="link.link" target="_blank" class="h5 p-2">{{ link.link }}</a>
        <link_stat
            v-bind:link="link"
            v-bind:engine="engine"
            v-bind:day_of_week="day_of_week"
            v-bind:countryFunc="getLink">
        </link_stat>
      </template>
      <link_keyword v-if="graph==='keyword' && project"
                    v-bind:project="project"
                    v-bind:country="project_country"
                    v-bind:filter="filter"
                    v-bind:engine="engine">
      </link_keyword>
    </div>
    <modal modal_id="settings">
      <template v-slot:header>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <h4>Статистика: Настройки</h4>
    </div>
      </template>

      <template v-slot:body>
        <LinkStatSettings v-bind:projects="projects" v-bind:user_links.sync="user_links"></LinkStatSettings>
      </template>

      <template v-slot:footer>

      </template>


    </modal>

  </main>


</template>

<script>
import {LinkApi, ProjectApi} from "@/api/project";
import GistStat from "@/components/linkstat/Gist.vue"
import GraphLink from "@/components/linkstat/GraphLink.vue"
import GraphKeyword from "@/components/linkstat/GraphKeyword.vue"
import LinkStatSettings from "@/components/linkstat/LinkStatSettings.vue"
import moment from "moment"

export default {
  name: 'LinkStat',
  components: {
    'gist_stat': GistStat,
    'link_stat': GraphLink,
    'link_keyword': GraphKeyword, LinkStatSettings
  },
  data() {
    return {
      filter: {
        date_end: moment().format('DD/MM/YYYY'),
        date_start: moment().add(-14, "days").format('DD/MM/YYYY')
      },
      user_links: [],
      day_of_week: [],
      days_of_week: [
        {id: 2, name: 'Понедельник'},
        {id: 3, name: 'Вторник'},
        {id: 4, name: 'Среда'},
        {id: 5, name: 'Четверг'},
        {id: 6, name: 'Пятница'},
        {id: 7, name: 'Суббота'},
        {id: 1, name: 'Воскресенье'}
      ],
      projects: [],
      project: null,
      old_project: null,
      graph: 'gist',
      // graph: 'keyword',
      graphs: [
        {name: 'Гистограмма', value: 'gist'},
        {name: 'График запрос', value: 'links'},
        {name: 'График ссылка', value: 'keyword'}
      ],
      engine: 'yandex',
      project_country: {},
      active_country: {},
      choice_links: [],
    }
  },
  watch: {
    active_country: function (val) {
      this.getLink(this.link.id, val)
    },
    project: function (val) {
      if (this.old_project && this.old_project !== val) this.choice_links = [];
      this.old_project = val;
      this.project_country = val.countries[0];
    },
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_select_list().then(resp => {
      $this.projects = resp;
      LinkApi.get_tracked_links().then(resp => $this.user_links = resp)
      if ($this.$route.params.link_id) $this.addLink($this.$route.params.link_id);
    });

  },
  computed: {
    links() {
      if (this.project) return this.user_links.filter(link => link.project_id === this.project.id);
      return [];
    },
    engines() {

      if (this.project && this.project.countries && this.project_country) {
        let country = false;
        if (this.graph === 'keyword') {
          country = this.project.countries.filter(x => x.code === this.project_country.code)[0];
        } else {
          country = this.project.countries.filter(x => x.code === this.active_country.code)[0];
        }
        if (country) {
          let engines = [];
          if (country.yandex) engines.push('yandex')
          if (country.google) engines.push('google')
          if (!(engines.indexOf(this.engine) + 1)) this.engine = engines[0]
          return engines
        }
      }
      // }
      return ['yandex', 'google']
    }

  },
  methods: {
    changeCountry: function (id, code) {
      this.active_country = code;
      this.getLink(id, code);
    },
    getClass: function (link, style = "color") {
      let COLORS = this.$COLORS;
      let val = `${link.tone}_${link.kind_type}_${link.type_tone}`;
      return `${style}: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;

    },
    addTrack: function (link) {
      let $this = this;
      LinkApi.add_to_tracked(link.id).then(function (response) {
        $this.user_links.push(response);
      })
    },
    removeTrack: function (link) {
      let $this = this;
      LinkApi.remove_from_tracked(link.id).then(function (response) {
        $this.user_links.splice($this.user_links.findIndex((obj => obj.id === parseInt(link.id))), 1);
      })
    },
    async addLink(link_id) {
      if (!this.choice_links.filter(x => x.id === link_id).length) {
        let link = await this.getLinkData(link_id)
        this.choice_links.push(link)
        let has_projects = this.projects.filter(x => x.id === link.project_id)
        if (has_projects.length) this.project = has_projects[0]
        this.getLink(link_id)
      } else this.choice_links.splice(this.choice_links.findIndex((obj => obj.id === parseInt(link_id))), 1);
    },
    async getLinkData(link_id) {
      return await LinkApi.get_tracked_info(link_id)
    },
    getAllLink() {
      if (!this.choice_links.length) {
        this.$notify({
          type: 'error ',
          text: 'Не выбрана ссылка'
        });
      } else {
        this.choice_links.forEach(link => this.getLink(link.id))
      }
    },
    async getLink(link_id = null, code = null) {
      if (link_id && !this.$route.params.link_id) history.pushState({}, null, `/links_statistics/${link_id}/`);
      if (!link_id) {
        this.$notify({
          type: 'error ',
          text: 'Не выбрана ссылка'
        });
      } else {


        let link_index = this.choice_links.findIndex((obj => obj.id === parseInt(link_id))), $this = this;


        let filter = structuredClone(this.filter);
        if (typeof filter.date_start === 'object') filter.date_start = `${filter.date_start.getDate()}/${filter.date_start.getMonth() + 1}/${filter.date_start.getFullYear()}`;
        if (typeof filter.date_end === 'object') filter.date_end = `${filter.date_end.getDate()}/${filter.date_end.getMonth() + 1}/${filter.date_end.getFullYear()}`;
        if (code) filter.country_code = code;
        let f_string = new URLSearchParams(filter).toString();

        LinkApi.get_stat_data(link_id, f_string).then(response => {
          let link = JSON.parse(JSON.stringify($this.choice_links[link_index]))
          link.keywords = response.keyword;
          link.dates = response.dates;
          $this.choice_links.splice(link_index, 1, link);

        })
      }
    },

    getReport: function () {
      let $this = this;
      if (!this.choice_links.length) $this.$notify({
        type: 'error ',
        text: 'Не выбрана ссылка'
      });
      else {
        let filter = structuredClone(this.filter);
        if (typeof filter.date_start === 'object') filter.date_start = `${filter.date_start.getDate()}/${filter.date_start.getMonth() + 1}/${filter.date_start.getFullYear()}`;
        if (typeof filter.date_end === 'object') filter.date_end = `${filter.date_end.getDate()}/${filter.date_end.getMonth() + 1}/${filter.date_end.getFullYear()}`;
        if (this.active_country) filter.country_code = this.active_country.code;
        if (this.project_country) filter.country_code = this.project_country.code;
        filter.graph = this.graph;
        let f_string = new URLSearchParams(filter).toString();
        this.choice_links.forEach(link => {
          LinkApi.get_report_data(link.id, f_string).then(response => {
            $this.$notify({
              type: 'success ',
              text: 'Процесс создания отчёта запущен. Отчёт будет доступен в разде "Отчёты"'
            });
          })
        })
      }

    },

  }
}

</script>
<style scoped>

</style>
