<template>


  <div class="card-body">
    <template v-if="!object">
      <div class="row h5" v-if="link">
        <div class="col">
          <a :href="link.link" target="_blank"> {{ link.link }}</a>
        </div>
        <div class="col-2">
        <span :class="`dropdown-item text-center active bg-${link.tone}`">
        {{ link.get_tone_display }}
        </span>
        </div>
        <div class="col-2">
          {{ link.get_type_tone_display }}
        </div>
        <div class="col">
          <button class="btn btn-success" @click="addNote"><i class="bi bi-plus-circle"></i>Добавить задачу</button>
        </div>
      </div>
      <div class="alert alert-secondary" role="alert" v-if="!tasks.length">
        Активных задач для ссылки нет
      </div>
      <div v-else>
        <TableList v-bind:objects="tasks"></TableList>
      </div>
    </template>
    <template v-else>
      <form @submit.prevent="saveNote" class="form p-3 border rounded  border-2 border-primary-subtle">
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label>Площадка</label>
              <select required class="form-select" v-model="object.placement">
                <option v-for="placement in placements" :value="placement" :key="placement.id">{{
                    placement.url
                  }}
                </option>
              </select>
            </div>
            <div class="col-6">
              <div v-if="object.placement.miralinks && object.placement.miralinks.info">
                <p><b>Трафик:</b> <span>{{ object.placement.miralinks.info.traffic }}</span><br>
                  <b>Стоимость, Р</b> <span>{{ object.placement.miralinks.info.price_rur }}</span><br>
                  <b>Стоимость, USD</b> <span>{{ object.placement.miralinks.info.price_usd }}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Язык</label>
          <select required class="form-select" v-model="object.lang"
                  @change="changeValue(object)">
            <option v-for="lang in LANG" :value="lang" :key="lang">{{ lang }}</option>
          </select>
        </div>
        <div :class="object.n_questions_error?'form-group--error':''">
          <label for="">Желаемое количество текстов <i>(от 1 до 100)</i></label>
          <input required class="form-control" type="number" v-model="object.n_questions" max="100" min="1">
        </div>
        <div class="error" v-if="object.n_questions_error">{{ object.n_questions_error }}</div>
        <div :class="object.max_length_error?'form-group--error':''">
          <label for="">Максимальное количество символов в одном тексте <i>(от 100 до 7000)</i></label>
          <input required class="form-control" @change="changeValue(object)" type="number" v-model="object.max_length"
                 max="7000" min="100">
        </div>
        <div class="error" v-if="object.max_length_error">{{ object.max_length_error }}</div>
        <hr>
        <div :class="object.article_prompt_prefix_error?'form-group--error':''">
          <label>Префикс промпта</label>
          <textarea class="form-control" name="" cols="5" rows="1" v-model="object.article_prompt_prefix">
                      </textarea>

        </div>
        <div :class="object.text_error?'form-group--error':''">

          <label>Тема материалов</label>
          <textarea required class="form-control" name="" cols="10" rows="3"
                    v-on:keyup="$func.setValue(object, 'text_error', false)"
                    v-model="object.text"></textarea>

        </div>
        <div class="error" v-if="object.text_error">{{ object.text_error }}</div>
        <div :class="object.article_prompt_postfix_error?'form-group--error':''">

          <label>Постфикс промпта</label>
          <textarea class="form-control" name="" cols="5" rows="1"
                    v-model="object.article_prompt_postfix"></textarea>

        </div>
        <hr>
        <div :class="object.headline_prompt_prefix_error?'form-group--error':''">

          <label>Префикс промпта заголовка</label>
          <textarea class="form-control" name="" cols="5" rows="1"
                    v-model="object.headline_prompt_prefix"></textarea>
        </div>
        <hr>
        <div :class="object.headline_prompt_prefix_error?'form-group--error':''">

          <label>Ключ <sup>*</sup> </label>
          <input required type="text" class="form-control" v-model="object.keyword">
        </div>
        <sup>* Должен входить во все отсылки. Нажатие сочетания Alt + X добавляет выделенный текст в это поле</sup>
        <hr>
        <div class="row">
          <div class="input-group mt-1" v-for="(person_reference, index) in object.person_references">

            <label for="" class="input-group-text">Абзац номер</label>
            <input class="form-control col-1" style="max-width: 75px;" v-model="person_reference.ref_position"
                   type="number">
            <label for="" class="input-group-text">Отсылка {{ index + 1 }}</label>
            <input required class="form-control" v-model="person_reference.ref_name" type="text">
            <input type="text" class="form-control" readonly disabled value="Сгенерировать уникальные">
            <div class="input-group-text">
              <input class="form-check-input mt-0" v-model="person_reference.generate" type="checkbox">
            </div>


            <div class="buttons" style="width: 70px">
              <button class="btn btn-success"
                      @click="object.person_references.push({ref_position: index+2, ref_name: '', generate: false})">+
              </button>
              <button class="btn btn-danger" v-if="index"
                      @click="object.person_references.splice(index, 1)">-
              </button>
            </div>
          </div>

        </div>
        <hr>
        <div class="mt-2">
          <label for="">Отправить на Email</label>
          <input class="form-control" type="email" v-model="object.email">
        </div>
        <div class="d-flex mt-3">
          <button class="btn btn-success" type="submit"><i
              class="bi bi-check-circle me-2"></i>
            <small>Сохранить</small>
          </button>
          <br>
          <button class="btn btn-danger ms-2" v-on:click.prevent="object=null"><i
              class="bi bi-x-circle me-2"></i>
            <small>Отмена</small>
          </button>

        </div>
      </form>
    </template>


  </div>
</template>
<script>

import {DonorApi} from "@/api/donors";
import {LinkApi} from "@/api/project";
import TableList from "./TableList.vue";

export default {
  name: 'DonorService',
  props: {
    simpleProject: {type: Object, default: {}},
  },
  components: {TableList},
  data() {
    return {
      link: null,
      tasks: [],
      object: null,
      placements: [],
      empty: {
        text: '',
        placement: {},
        lang: 'ru',
        n_questions: 1,
        max_length: 2000,
        article_prompt_prefix: '',
        article_prompt_postfix: '',
        headline_prompt_prefix: '',
        email: '',
        keyword: '',
        person_references: [{ref_position: 1, ref_name: '', generate: false}],
      },
      LANG: ['ru', 'en'],
      ARTICLE_GENERATION_PROMPT_PREFIX_DICT: {
        "ru": "Напиши статью размером не менее {0} символов на эту тему:\n",
        "en": "Write an article with at least {0} symbols based on this topic:\n",
      },
      HEADLINE_GENERATION_PROMPT_DICT: {
        "ru": "Напиши заголовок для этого текста:\n",
        "en": "Write a headline for this text:\n",
      },
    }
  },
  computed: {},
  watch: {},
  mounted: function () {
    LinkApi.get(this.$route.params.link_id).then(resp => this.link = resp)
    this.getDonors()
    DonorApi.placement_list().then(resp => this.placements = resp)

    if (!String.format) {
      String.format = function (format) {
        let args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/{(\d+)}/g, function (match, number) {
          return typeof args[number] != 'undefined'
              ? args[number]
              : match
              ;
        });
      };
    }
    this.addNote()
    let codes = ["AltLeft", "KeyZ"]

    let pressed = new Set(), $this = this;

    document.addEventListener('keydown', function (event) {
      pressed.add(event.code);
      for (let code of codes) {
        if (!pressed.has(code)) {
          return;
        }
      }
      pressed.clear();
      if (window.getSelection()) {
        $this.object.keyword = String(window.getSelection());
      }
    });

    document.addEventListener('keyup', function (event) {
      pressed.delete(event.code);
    });


  },
  methods: {
    getDonors() {
      DonorApi.list(new URLSearchParams({
        page: 1,
        size: 20,
        link_obj_id: this.$route.params.link_id
      }).toString()).then(resp => this.tasks = resp.results)
    },
    changeValue(object) {
      object.article_prompt_prefix = String.format(this.ARTICLE_GENERATION_PROMPT_PREFIX_DICT[object.lang], object.max_length);
      object.headline_prompt_prefix = this.HEADLINE_GENERATION_PROMPT_DICT[object.lang];
    },
    addNote: function () {
      let new_empty = JSON.parse(JSON.stringify(this.empty));
      new_empty.article_prompt_prefix = String.format(this.ARTICLE_GENERATION_PROMPT_PREFIX_DICT[new_empty.lang], new_empty.max_length);
      new_empty.headline_prompt_prefix = this.HEADLINE_GENERATION_PROMPT_DICT[new_empty.lang];

      this.object = new_empty;
    },
    saveNote() {
      let $this = this, error = false;
      $this.object.person_references.forEach(ref => {
        if (!ref.ref_name.toLowerCase().includes($this.object.keyword.toLowerCase())) {
          $this.$notify({
            group: 'alert',
            type: 'error',
            text: `Отсылка "${ref.ref_name}" не включает в себя ключ ${$this.object.keyword}`
          });
          error = true;
        }
      });
      if (!error) {
        $this.object.placement_name = $this.object.placement.url;
        $this.object.placement_id = $this.object.placement.external_id;
        $this.object.link_obj = $this.$route.params.link_id;
        $this.object.link = $this.link.link;
        DonorApi.save($this.object.id, $this.object).then(() => {
          $this.getDonors()
          $this.object = null;
        })
      }
    },
    sendToGenerate(id) {
      let $this = this;
      // DictionaryApi.generate_text(id).then(response => {
      //   $this.objects.splice($this.objects.findIndex(x => x.id === id), 1, response);
      //   $this.checkStatusText(id)
      // })
    },

    checkStatusText(id) {
      let $this = this;
      DonorApi.get(id).then(response => {
        $this.tasks.splice($this.objects.findIndex(x => x.id === id), 1, response);
        if (!response.generated_content && response.status !== 'error') setTimeout(function () {
          $this.checkStatusText(id)
        }, 5000)
      })
    }
  }

}
</script>

<style scoped>

</style>
<style scoped>

</style>