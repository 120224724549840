<template>
  <main class="dashboard">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">План/Факт</h1>
      <BackButton></BackButton>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <CountryChoice v-bind:active_country.sync="active_country"
                           v-bind:project_id="$route.params.id"
                           v-bind:countries="project.countries"
                           v-bind:changeCountry="changeCountry"></CountryChoice>

          </div>

        </div>

      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-3">Дата</div>
          <div class="col-3">План</div>
          <div class="col-3">План/Факт</div>
          <div class="col-3"></div>
        </div>
        <form class="row mt-2 mb-3" v-for="(forecast, index) in forecastsCalc"
              @submit.prevent="updateForecast(forecast)">
          <div class="col-3">
            <date-picker @dp-change="forecast.id?updateForecast(forecast):''" required v-model="forecast.date"
                         ref="start_date" :config="options"></date-picker>
          </div>
          <div class="col-3">
            <input class="form-control" required @change="forecast.id?updateForecast(forecast):''"
                   v-model="forecast.inner" type="number">
          </div>
          <div class="col-3">
            <input class="form-control" required @change="forecast.id?updateForecast(forecast):''"
                   v-model="forecast.client" type="number">
          </div>
          <div class="col-3">

            <button class="btn btn-danger" type="button" title="удалить" @click="deleteForecast(forecast);"><i class="bi bi-x-lg"></i>
            </button>
            <button class="btn btn-warning" type="submit" v-if="!forecast.id" title="сохранить"><i
              class="bi bi-save"></i></button>
            <button class="btn btn-success" title="добавить строку" v-if="index === forecastsCalc.length - 1"
                    @click="forecasts.push({inner:'', client: '', country_code: active_country.code, date: '', project: parseInt($route.params.id)})">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
        </form>
        <button class="btn btn-success" title="добавить строку" v-if="!forecastsCalc.length"
                @click="forecasts.push({inner:'', client: '', country_code: active_country.code, date: '', project: parseInt($route.params.id)})">
          <i class="bi bi-plus-lg"></i>
        </button>
        <button v-if="forecastsCalc.length" @click="clearForecast()" class="btn btn-danger">Удалить всё
          {{ active_country.code }}
        </button>
      </div>
    </div>


  </main>
</template>

<script>
import {ProjectApi} from "@/api/project";
import {ForecastApi} from "@/api/forecast";
import CountryChoice from '@/components/CountryChoice';

export default {
  name: "EditForecast",
  components: {
    CountryChoice
  },
  data() {
    return {
      project: {},
      delete_forecast: {},
      active_country: {code: 'RU'},
      forecasts: [],
      options: {
        format: "YYYY-MM-DD",
        locale: "ru",
        useCurrent: false,
      },
    };
  },
  computed: {
    forecastsCalc() {
      return (this.forecasts && this.forecasts.length) ?
        this.forecasts.filter(fore => fore.country_code === this.active_country.code) :
        this.forecasts;
    },
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_simple($this.$route.params.id, this.role).then(response => {
      $this.project = response;
      $this.active_country = $this.project.active_country_code;
    })
    ForecastApi.list($this.$route.params.id).then(response => {
      $this.forecasts = response;
      // $this.forecasts.push({project_id:$this.$route.params.id, date: '', inner: '', client:'', country_code: $this.active_country.code})
    })
  },
  methods: {
    deleteForecast(forecast) {
      if (forecast.id) ForecastApi.delete(forecast)
      this.forecasts.splice(this.forecasts.indexOf(forecast), 1);
    },
    updateForecast(forecast) {
      let $this = this;
      ForecastApi.update(forecast).then(response => {
        $this.forecasts.splice($this.forecasts.indexOf(forecast), 1, response);
      })
    },
    clearForecast(forecast) {
      let $this = this;
      this.forecastsCalc.forEach(fore => {
        $this.deleteForecast(fore)
      })

    },
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
      }
    },
  }
}
</script>

<style scoped>

</style>
