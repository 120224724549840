import { HTTP } from "./common";

export const ForecastApi = {
  delete(object) {
    return HTTP.delete(`forecasts/${object.id}/`);
  },
  list(project_id) {
    return HTTP.get(`forecasts/?project_id=${project_id?project_id:''}`).then((response) => {
      return response.data;
    });
  },
  update(object) {
    if (object.id)
      return HTTP.put(`forecasts/${object.id}/`, object).then((response) => {
        return response.data;
      });
    else
      return HTTP.post("forecasts/", object).then((response) => {
        return response.data;
      });
  },
  get(id) {
    return HTTP.get(`forecasts/${id}/`).then((response) => {
      return response.data;
    });
  },
  get_chart_data(id, data) {
    return HTTP.post(`forecasts/${id}/chart_data/`, data).then((response) => {
      return response.data;
    });
  },
};
