<template>
  <section>
    <div :class="`filter${width_point>30?' horizontal':''}`">
      <div class="row d-flex justify-content-between align-items-end">
        <div class="col-6">
          <div class="dropdown" v-if="active_country">
            <button class="btn btn-filter " type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
              {{ active_country.name }}
            </button>
            <ul class="dropdown-menu ">
              <li v-for="county in project.countries" @click="active_country=county">{{ county.name }}</li>
            </ul>
          </div>
        </div>
        <div class="col-6">
          <div class="dropdown" v-if="state">
            <button class="btn btn-filter " type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
              Топ-{{ state }}
            </button>
            <ul class="dropdown-menu ">
              <li v-for="state_val in all_states" @click="state=state_val">Топ-{{ state_val }}</li>
            </ul>
          </div>
        </div>

      </div>
      <div class="row d-flex justify-content-between align-items-end">
        <div class="col">
          <div class="dropdown" v-if="active_country">
            <button class="btn btn-filter " type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
              {{ active_keyword?active_keyword.name:'-' }}
            </button>
            <ul class="dropdown-menu ">
              <li @click="active_keyword=null">-</li>
              <li v-for="keyword in active_country.keywords" @click="active_keyword=keyword">{{ keyword.name }}</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <div v-if="chart_data && chart_data.labels" class="mobile-chart">
      <div class="chart-by-line"
           :style="`width: ${(chart_data.labels.length-1)*width_point_mult}px; height: 50wh`">

                   <Line
          id="my-chart-id"
          ref="bar"
          v-if="chart_data"
          :options="chartOptions"
          :data="chart_data"
      />


      </div>
      <div
          id="legend"
          :class="`line-legend sticky`"
      >
      </div>

    </div>

    <div class="m-2 pt-2">
      <div slider id="slider-distance">
        <input type="range" max="10" min="1" v-model="range_value" class="formrange">
      </div>
    </div>

    <canvas id="canvas" height="0"></canvas>
  </section>
</template>
<script>
import {defineComponent} from "vue";
import SmallProject from "@/mobile/includes/SmallProject.vue";
import {ProjectApi} from "@/api/project";
import RangeSlider from "@/plugins/RangeSlider"
import moment from "moment/moment";

import {Line} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

ChartJS.defaults.plugins.legend.display = false;


export default defineComponent({
  components: {Line, SmallProject, RangeSlider},
  data() {
    return {
      all_states: [10, 20, 50, 100],
      state: null,
      range_value: 0,
      active_keyword: null,
      forecasts: [],
      datacollection: null,
      width_point: 30,
      scroll: false,
      stock_datacollection: {},
      data_set_options: {
        'inner': {
          'type': 'line',
          'label': 'План',
          'borderColor': 'rgb(13, 206, 112)', 'lineTension': 0
        },

        'real': {
          'type': 'line',
          'label': 'Сумма нецелевых ресурсов',
          'borderColor': 'rgb(254, 66, 45)',
          'lineTension': 0
        },

      },
      options: {
        format: "DD/MM/YYYY",
        locale: "ru",
        useCurrent: true,
      },
    }
  },
  computed: {
    width_point_mult(){
      return this.width_point + parseInt(this.range_value)*10
    },

    chartOptions() {
      let $this = this, d = moment().day() - (moment().day() ? 1 : -6)
      let week_start = moment().subtract(d + (d <= this.project.report_weekday ? 7 : 0) - this.project.report_weekday, "days");

      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
          padding: 10
        },
        plugins: {
          tooltip: {
            usePointStyle: true,
          },
          filler: {
            propagate: true
          },
          datalabels: {
            align: function (e) {
              return e.datasetIndex % 2 ? 'top' : 'bottom';
            },
            font: {
              size: '12px',
              weight: 'normal',
              family: 'Gilroy'
            }
          }
        },
        scales: {
          x:
              {
                grid: {
                  display: false
                },
                ticks: {
                  backdropColor: function (x) {
                    return x.tick.label === $this.$moment(new Date).format('DD.MM.YYYY') ? '#ddd' : '#fff'
                  },
                  showLabelBackdrop: true,
                  font: function (x) {
                    let label_date = moment(x.tick.label, 'DD.MM.YYYY')
                    return (moment(label_date).isSameOrAfter(week_start, 'day') && label_date <= moment()) ? {
                      size: 10,
                      weight: 'bold'
                    } : {size: 8}
                  },
                  autoSkip: false,
                  maxRotation: this.width_point > 30 ? 0 : 90,
                  minRotation: this.width_point > 30 ? 0 : 90,
                },
              },
          y:
              {
                grid: {
                  display: false
                },
                ticks: {
                  display: false,
                },
                maxTicksLimit: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
                suggestedMax: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
                max: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
              },

        },
      }
    },

    chart_data() {

      let ctx = document.getElementById("canvas"), $this = this;
      let gradient = '';
      this.datacollection = structuredClone(this.stock_datacollection);
      if (this.datacollection && this.datacollection.labels) {
        let new_data = [];
        for (let i in this.datacollection.datasets) {
          let d = this.datacollection.datasets[i];
          if (ctx) {
            let context = ctx.getContext("2d");
            gradient = context.createLinearGradient(0, 25, 0, 300);
            let clear_color = d.borderColor.split('(')[1].split(')')[0]
            gradient.addColorStop(0, `rgba(${clear_color}, 0.3)`);
            gradient.addColorStop(0.35, `rgba(${clear_color}, 0.01)`);
            gradient.addColorStop(1, `rgba(${clear_color}, 0)`);
          }
          let n_d = {
            borderColor: d.borderColor,
            data: d.data,
            fill: true,
            borderWidth: 1,
            backgroundColor: gradient,
            label: d.label,
            lineTension: d.lineTension,
            pointBackgroundColor: d.borderColor,
            pointStyle: 'circle',
            pointRadius: 5,
            type: d.type,

          };
          new_data.push(n_d);

        }


        return {
          labels: this.datacollection.labels,
          datasets: new_data,
        };
      } else return this.datacollection;

    },

    project: {
      get: function () {
        return this.$store.getters.favor_project(this.$route.params.id);
      },
      set: function (val) {
      }
    },
    active_country: {
      get: function () {
        let country = this.project ? (this.project.countries || []).find(x => x.active) : null;
        if (country && !this.state) {
          this.state = this.project.active_state
        }
        return this.project ? (this.project.countries || []).find(x => x.active) : null;
      },
      set: function (val) {
        ProjectApi.set_active_country(this.project.id, val.code)
        this.project.countries.forEach(x => x.active = x.code === val.code)
        // this.active_keyword = val.keywords[0]
        this.active_keyword = null;
        this.$store.dispatch('updateFavorite', this.project)
      }
    }
  },
  watch: {
    active_keyword(val) {
      this.getResult();
    },
    state(val) {
      this.getResult();
    }

  },
  async mounted() {
    this.rotateWindow();
  },
  methods: {
    getResult() {
      this.fillData()
    },
    fillData() {
      let $this = this;
      ProjectApi.get_chart_data($this.project.id, {
        state: $this.state,
        keyword: $this.active_keyword?$this.active_keyword.id:null,
        by_date: false,
        country: $this.active_country.code,
      }).then((response) => {
        this.project_report_weekday = response.report_weekday
        let new_data = [];
        let data_set_options_keys = Object.keys(this.data_set_options);

        for (let i = 0; i < data_set_options_keys.length; ++i) {
          let data_set_key = data_set_options_keys[i]


          let getted_data = []
          for (let j in response.data) {
            getted_data.push(response.data[j][data_set_key])

          }

          let n_d = {
            borderColor: this.data_set_options[data_set_key].borderColor,
            data: getted_data,
            fill: this.data_set_options[data_set_key].fill,
            label: this.data_set_options[data_set_key].label,
            lineTension: this.data_set_options[data_set_key].lineTension,
            type: this.data_set_options[data_set_key].type,
          };
          new_data.push(n_d);
        }

        $this.datacollection = {
          labels: Object.keys(response.data),
          datasets: new_data,
        };

        this.stock_datacollection = structuredClone(this.datacollection);
      });

    },
    rotateWindow() {
      this.width_point = window.screen.orientation.angle > 0 ? 51 : 30;
    }

  },
  created() {
    window.addEventListener("resize", this.rotateWindow);
  },
  destroyed() {
    window.removeEventListener("resize", this.rotateWindow);
  },
  updated() {
    if (!this.scroll) {
      let chart_area = document.getElementsByClassName("mobile-chart")
      if (chart_area.length) {
        let labels = this.chart_data.labels, currentDateIndex = 0, $this = this;
        labels.forEach((date, index) => {
          if (date === $this.$moment(new Date).format('YYYY-MM-DD')) currentDateIndex = index
        })
        chart_area[0].scrollLeft += (this.width_point_mult * currentDateIndex) - (window.innerWidth / this.width_point_mult / 2) * this.width_point_mult;
        this.scroll = true;
      }
    }
  }


})
</script>

<style scoped>

</style>