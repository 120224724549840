import moment from 'moment';

export default {
    install: (app, options) => {
        app.config.globalProperties.$modal = (key, val) => {
            switch (key) {
                case "show":
                    document.getElementById(val).classList.add("show");
                    break;
                case "hide":
                    document.getElementById(val).classList.remove("show");
                    break;
            }
        }
        app.config.globalProperties.$ENGINES = ['yandex', 'google'];
        app.config.globalProperties.$TEMPLATE = {
            orion: 'Orion',
            dr: 'DR',
            dr_bg: 'DR (тон ссылок - фон)',
            facct: 'FACCT',
            gib: 'GIB',
            sber: 'Сбер',
        };
        app.config.globalProperties.$PICTURE_TYPE = [
            {
                id: 'search',
                name: 'Блок картинки'
            },
            {
                id: 'knowledge',
                name: 'Блок знаний'
            },
            {
                id: 'preview',
                name: 'Превью'
            },
            {
                id: 'inside_carousel',
                name: 'Карусель'
            },
            {
                id: 'video_preview',
                name: 'Видео-превью'
            }
        ];
        app.config.globalProperties.$COLORS = {
            'negative_auto_new': '#ec6b0a',
            'negative': '#d50004',
            'negative_manual_offer': '#d50004',
            'negative_manual_new': '#ff003a',
            'not_negative_auto_new': '#808080',
            'not_negative_manual_start': '#808080',
            'not_negative_manual_new': '#808080',
            'positive_manual_finish': '#dbd116',
            'positive_manual_replace': '#8729a6',
            'positive_manual_start': '#1974d2',
            'positive_manual_new': '#1974d2',
            'positive': '#228b22',
            'positive_manual_our': '#228b22',
            'positive_manual_our_derivative': '#228b22',
            'irrelevant': '#808080',
            'irrelevant_manual_start': '#808080',
            'irrelevant_manual_new': '#808080',
        };
        app.config.globalProperties.$TONES = {
            'negative': {
                name: 'Нецелевой',
                full_name: 'Нецелевая',
                type_tones: {
                    'new': 'Новый', 'offer': 'Договор',
                },
                synonym: ['negative'],
                no_filter: false
            },
            'not_negative': {
                name: 'Нейтральный',
                full_name: 'Нейтральная',
                type_tones: {
                    'new': 'Новый'
                },
                synonym: ['not_negative', 'irrelevant'],
                no_filter: true
            },
            'irrelevant': {
                name: 'Нейтральный',
                full_name: 'Нейтральная',
                type_tones: {
                    'new': 'Новый', 'start': 'На старте'
                },
                synonym: ['not_negative', 'irrelevant'],
                no_filter: false
            },
            'positive': {
                name: 'Целевой',
                full_name: 'Целевая',
                type_tones: {
                    'new': 'Новый',
                    'our': 'Наш',
                    'our_derivative': 'Производная',
                    'start': 'На старте',
                    'finish': 'Добивочные',
                    'replace': 'Корректировка',
                },
                synonym: ['positive'],
                no_filter: false
            }
        };

        app.config.globalProperties.$STATES = [
            {
                id: 10,
                name: 'Топ-10'
            },
            {
                id: 20,
                name: 'Топ-20'
            },
            {
                id: 50,
                name: 'Топ-50'
            },
            {
                id: 100,
                name: 'Топ-100'
            }
        ];
        app.config.globalProperties.$TRUST = [
            {
                id: -1,
                name: 'Компроматные'
            },
            {
                id: "0",
                name: 'Непроверенные'
            },
            {
                id: 1,
                name: 'Авторитетные'
            },
            {
                id: 2,
                name: 'СМИ'
            },
            {
                id: 3,
                name: 'Гос. сайты'
            },
            {
                id: 4,
                name: 'Корпоративные сайты'
            },
            {
                id: 5,
                name: 'Иноязычные'
            },
            {
                id: 6,
                name: 'Базы данных'
            },
            {
                id: 9,
                name: 'Другое'
            }
        ];
        app.config.globalProperties.$SITE_TYPE = [
            {
                id: "biography",
                name: 'Биография'
            },
            {
                id: "news",
                name: 'Новость'
            },
            {
                id: "tag",
                name: 'Теговая страница'
            },
            {
                id: "card",
                name: 'Карточка'
            }
        ]
        app.config.globalProperties.$COURT_TYPE = [
            {
                id: 'yes',
                name: 'Да'
            },
            {
                id: 'no',
                name: 'Нет'
            },
            {
                id: 'extrajudicial',
                name: 'Внесудебная работа'
            }
        ]
        app.config.globalProperties.$DELETE_TYPES = [
            {
                id: 'not_required',
                name: 'Не требуется'
            },
            {
                id: 'delete',
                name: 'Удаление'
            },
            {
                id: 'replace',
                name: 'Замена'
            },
            {
                id: 'displacement',
                name: 'Вытеснение'
            },
            {
                id: 'additional',
                name: 'Доп. Исследование'
            }
        ]
        app.config.globalProperties.$STATUSES = [
            {
                id: 'court',
                name: 'Удалена(скрыта по суду)'
            },
            {
                id: 'delete',
                name: 'Удалена'
            },
            {
                id: 'displacement',
                name: 'Вытеснена из ТОП 20'
            },
            {
                id: 'replacement',
                name: 'Заменена на позитив'
            }
        ]
        app.config.globalProperties.$KIND_TYPES = [
            {
                id: 'auto',
                name: 'Авто'
            },
            {
                id: 'manual',
                name: 'Ручной'
            }
        ]
        app.config.globalProperties.$date_moment = (date, format) => {
            return moment(date).format(format);
        }
        app.config.globalProperties.$getTones = (type) => app.config.globalProperties.$TONES;
        app.config.globalProperties.$getTypes = (tone) => tone ? app.config.globalProperties.$TONES[tone].type_tones : {};

        app.config.globalProperties.$func = {

            momentDate: (date, format = "YYYY-MM-DD", default_type = false) => {
                try {
                    if (default_type) return moment(date, default_type).format(format)
                    return moment(date).format(format)
                } catch (e) {
                    return date
                }
            },
            createSearchParams: (params) => {
                const searchParams = new URLSearchParams();
                Object.entries(params).forEach(([key, values]) => {
                    if (Array.isArray(values)) {
                        values.forEach((value) => {
                            searchParams.append(key, value);
                        });
                    } else {
                        searchParams.append(key, values);
                    }
                });
                return searchParams;
            },
            dateString: (date) => {
                try {
                    return ('0' + date.getDate()).slice(-2) + '/'
                        + ('0' + (date.getMonth() + 1)).slice(-2) + '/'
                        + date.getFullYear();
                } catch (e) {
                    return date
                }
            },
            getClass: function (link, made = true) {
                let COLORS = app.config.globalProperties.$COLORS;
                let val = made ? `${link.tone}_${link.kind_type}_${link.type_tone}` : link;
                return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;
            },
            getTrustClass: function (resource) {
                if (resource && resource.trust === -1) {
                    return "negative";
                }
            },
            isMobile() {
                if (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            isURL(url) {
                try {
                    return new URL(url)
                } catch (err) {
                    return false
                }
            },
            getDomain(url) {
                try {
                    let uri = new URL(url)
                    return uri.origin
                } catch (err) {
                    return url
                }
            }
        };

    },

}