import { HTTP } from "./common";

export const DailyApi = {
  list(project_id, country_code) {
    return HTTP.get(`daily/?project_id=${project_id}&country_code=${country_code}`).then((response) => {
      return response.data;
    });
  },
  get(id) {
    return HTTP.get(`daily/${id}/`).then((response) => {
      return response.data;
    });
  },
  download_report(id) {
    return HTTP.get(`daily/${id}/download_report/`, {responseType: "blob"}).then((response) => {
      return response.data;
    });
  },
};
