<template>
  <main class="dashboard">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{ active_project.name }}: Анализ проекта</h1>
     <BackButton></BackButton>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-5">
            <label for="country" class="form-label">Ключевые слова</label>
            <select class="form-select" v-model="keyword" id="country" required="">
              <option value="">Выбрать...</option>
              <option v-for="key in keywords" :value="key.id">{{ key.name }}</option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="form-label">Дата</label>

            <date-picker style="width: 150px" v-model="active_date" ref="start_date"></date-picker>
          </div>
          <div class="col-md-2">
            <label for="country" class="form-label">Страны</label>
             <CountryChoice v-bind:active_country.sync="active_country"
                         v-bind:project_id="$route.params.id"
                         v-bind:countries="active_project.countries"
                         v-bind:changeCountry="changeCountry"></CountryChoice>
          </div>
        </div>
      </div>
      <div class="card-body">

        <div class="table-responsive">
          <table class="table table-bordered table-sm" >
            <tbody>
            <tr class="table-active">
              <th>ТОП100</th>
              <th></th>
            </tr>
            <tr>
              <td>Общее количество нецелевой информации</td>
              <td>{{ stat.negative_100 }}</td>
            </tr>
            <tr>
              <td>Общий процент нецелевой информации</td>
              <td>{{ stat.negative_100_percent }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация в Яндекс</td>
              <td>{{ stat.negative_100_y }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация в Google</td>
              <td>{{ stat.negative_100_g }}</td>
            </tr>
            <tr>
              <td>Уникальная нецелевая информация</td>
              <td>{{ stat.negative_100_uniq }}</td>
            </tr>
            <tr>
              <td>Уникальный в Яндекс</td>
              <td>{{ stat.negative_100_uniq_y }}</td>
            </tr>
            <tr>
              <td>Уникальный в Google</td>
              <td>{{ stat.negative_100_uniq_g }}</td>
            </tr>
            <tr>
              <td>Процент в Яндекс</td>
              <td>{{ stat.negative_100_percent_y }}</td>
            </tr>
            <tr>
              <td>Процент в Google</td>
              <td>{{ stat.negative_100_percent_g }}</td>
            </tr>
            <tr>
              <td>Количество нерелеванта</td>
              <td>{{ stat.negative_100_no }}</td>
            </tr>
            <tr>
              <td>Количество тем нецелевой информации</td>
              <td>{{ stat.negative_100_theme }}</td>
            </tr>

            <tr>
              <td>Нецелевая информация изображения</td>
              <td>{{ stat.negative_100_image }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация изображения в Яндекс</td>
              <td>{{ stat.negative_100_image_y }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация изображения в Google</td>
              <td>{{ stat.negative_100_image_g }}</td>
            </tr>
            <tr>
              <td>Уникальная нецелевая информация изображений</td>
              <td>{{ stat.negative_100_image_uniq }}</td>
            </tr>
            <tr>
              <td>Уникальные изображения в Яндекс</td>
              <td>{{ stat.negative_100_image_uniq_y }}</td>
            </tr>
            <tr>
              <td>Уникальные изображения в Google</td>
              <td>{{ stat.negative_100_image_uniq_g }}</td>
            </tr>

            <tr class="table-active">
              <th>ТОП20</th>
              <th></th>
            </tr>
            <tr>
              <td>Общее количество нецелевой информации</td>
              <td>{{ stat.negative_20 }}</td>
            </tr>
            <tr>
              <td>Общий процент нецелевой информации</td>
              <td>{{ stat.negative_20_percent }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация в Яндекс</td>
              <td>{{ stat.negative_20_y }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация в Google</td>
              <td>{{ stat.negative_20_g }}</td>
            </tr>
            <tr>
              <td>Уникальная нецелевая информация</td>
              <td>{{ stat.negative_20_uniq }}</td>
            </tr>
            <tr>
              <td>Уникальный в Яндекс</td>
              <td>{{ stat.negative_20_uniq_y }}</td>
            </tr>
            <tr>
              <td>Уникальный в Google</td>
              <td>{{ stat.negative_20_uniq_g }}</td>
            </tr>
            <tr>
              <td>Процент в Яндекс</td>
              <td>{{ stat.negative_20_percent_y }}</td>
            </tr>
            <tr>
              <td>Процент в Google</td>
              <td>{{ stat.negative_20_percent_g }}</td>
            </tr>
            <tr>
              <td>Количество нерелеванта</td>
              <td>{{ stat.negative_20_no }}</td>
            </tr>
            <tr>
              <td>Количество тем нецелевой информации</td>
              <td>{{ stat.negative_20_theme }}</td>
            </tr>

            <tr>
              <td>Нецелевая информация изображения</td>
              <td>{{ stat.negative_20_image }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация изображения в Яндекс</td>
              <td>{{ stat.negative_20_image_y }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация изображения в Google</td>
              <td>{{ stat.negative_20_image_g }}</td>
            </tr>
            <tr>
              <td>Уникальная нецелевая информация изображений</td>
              <td>{{ stat.negative_20_image_uniq }}</td>
            </tr>
            <tr>
              <td>Уникальные изображения в Яндекс</td>
              <td>{{ stat.negative_20_image_uniq_y }}</td>
            </tr>
            <tr>
              <td>Уникальные изображения в Google</td>
              <td>{{ stat.negative_20_image_uniq_g }}</td>
            </tr>

            <tr class="table-active">
              <th>ТОП10</th>
              <th></th>
            </tr>
            <tr>
              <td>Общее количество нецелевой информации</td>
              <td>{{ stat.negative_10 }}</td>
            </tr>
            <tr>
              <td>Общий процент нецелевой информации</td>
              <td>{{ stat.negative_10_percent }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация в Яндекс</td>
              <td>{{ stat.negative_10_y }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация в Google</td>
              <td>{{ stat.negative_10_g }}</td>
            </tr>
            <tr>
              <td>Уникальная нецелевая информация</td>
              <td>{{ stat.negative_10_uniq }}</td>
            </tr>
            <tr>
              <td>Уникальный в Яндекс</td>
              <td>{{ stat.negative_10_uniq_y }}</td>
            </tr>
            <tr>
              <td>Уникальный в Google</td>
              <td>{{ stat.negative_10_uniq_g }}</td>
            </tr>
            <tr>
              <td>Процент в Яндекс</td>
              <td>{{ stat.negative_10_percent_y }}</td>
            </tr>
            <tr>
              <td>Процент в Google</td>
              <td>{{ stat.negative_10_percent_g }}</td>
            </tr>
            <tr>
              <td>Количество нерелеванта</td>
              <td>{{ stat.negative_10_no }}</td>
            </tr>
            <tr>
              <td>Количество тем нецелевой информации</td>
              <td>{{ stat.negative_10_theme }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация изображения</td>
              <td>{{ stat.negative_10_image }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация изображения в Яндекс</td>
              <td>{{ stat.negative_10_image_y }}</td>
            </tr>
            <tr>
              <td>Нецелевая информация изображения в Google</td>
              <td>{{ stat.negative_10_image_g }}</td>
            </tr>
            <tr>
              <td>Уникальная нецелевая информация изображений</td>
              <td>{{ stat.negative_10_image_uniq }}</td>
            </tr>
            <tr>
              <td>Уникальные изображения в Яндекс</td>
              <td>{{ stat.negative_10_image_uniq_y }}</td>
            </tr>
            <tr>
              <td>Уникальные изображения в Google</td>
              <td>{{ stat.negative_10_image_uniq_g }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


    </div>

  </main>
</template>

<script>
import {ProjectApi, LinkApi} from '@/api/project'
import moment from 'moment';
import CountryChoice from "@/components/CountryChoice.vue";

export default {
  name: 'ProjectStat',
  components: {
    CountryChoice
  },

  data() {
    let date = new Date();
    return {
      active_date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
      state: 10,
      active_project: {},
      keywords: [],
      active_country: {code: null, yandex: true, google: true},
      stat: {},
      keyword: null,
      options: {
        format: 'DD/MM/YYYY',
        locale: 'ru',
        useCurrent: true,
      },
    }
  },
  computed: {
    date_query() {
      let date = moment(this.active_date, 'DD/MM/YYYY');
      return `${date.year()}-${date.month() + 1}-${date.date()}`;
    }
  },
  watch: {
    keyword: function (val) {
      this.fillData(this.active_project.id)
    },
    active_date: function (val) {
      this.fillData(this.active_project.id)
    }
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_simple($this.$route.params.id).then(response => {
      $this.active_project = response;
      $this.active_country = $this.active_project.active_country_code;
      $this.getKeywords();
      $this.fillData();
    })

  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.getKeywords(this.active_project.id);
        this.fillData(this.active_project.id);
      }
    },
    getKeywords: function () {
      let $this = this;
      ProjectApi.keywords($this.$route.params.id, $this.state, $this.date_query, $this.active_country.code || 'RU', false, 20).then(response => {
        $this.keywords = response.results;

      })
    },
    fillData: function () {
      let $this = this;
      ProjectApi.full_stat($this.$route.params.id, {keyword: $this.keyword, date: $this.date_query,
        country_code: $this.active_country.code || 'RU'}).then(response => {
        $this.stat = response;

      })
    },


  }
}

</script>

<style scoped>
.table-sm{
  width: 50%;
}
</style>
