import { AlertApi } from "@/api/alerts";

const Alert = {
  state: {
    alerts: [],
  },
  mutations: {
    updateAlert(state, object) {
      let index = state.alerts.findIndex(x => x.id === object.id);
      if (index >= 0)
        state.alerts.splice(
          index,
          1,
          object
        );
      else state.alerts.unshift(object);
    },
    setAlerts(state, objects) {
      state.alerts = objects;
    },
  },
  actions: {
    getAlerts({ commit }, filter) {
      if (this.getters.isLoggedIn)
        AlertApi.list().then((alerts) => {
          commit("setAlerts", alerts);
        });
    },
    updateAlert({ commit }, object) {
        commit("updateAlert", object);
    }
  },
  getters: {
    alerts: (state) => state.alerts,
  },
};

export default Alert;
