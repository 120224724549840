import { HTTP } from "./common";

export const HintApi = {

  list(filter) {
    return HTTP.get(`hints/?${filter}`).then((response) => {
      return response.data;
    });
  },
  get_filter_hints(project_id, country_code) {
    return HTTP.get(`hints/get_filter_hints/?project_id=${project_id}&country_code=${country_code}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  get_avail_date_calendar(project_id) {
    return HTTP.get(`hints/get_avail_date_calendar/?project_id=${project_id}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  update_tone(hint) {
    return HTTP.post(`hints/update_tone/`, hint).then((response) => {
      return response.data;
    });
  },

  manual_request_result(project_id) {
    return HTTP.get(
      `hints/manual_request_result/?project_id=${project_id}`
    ).then((response) => {
      return response.data;
    });
  },

  get_screen(project_id, country_code, date) {
    return HTTP.get(`hints/get_screen/?project_id=${project_id}&country_code=${country_code}${date?`&date=${date}`:""}`).then((response) => {
      return response.data;
    });
  },
};
