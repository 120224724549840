
import { HTTP } from "./common";

export const WikiApi = {
  list(project_id) {
    return HTTP.get(`wikipedia/?project_id=${project_id}`).then((response) => {
      return response.data;
    });
  },
  update(data) {
    if (data.id) return HTTP.put(`wikipedia/${data.id}/`, data).then((response) => {
      return response.data;
    });
    return HTTP.post(`wikipedia/`, data).then((response) => {
      return response.data;
    });
  },
  subscribe(id, data) {
    return HTTP.post(`wikipedia/${id}/subscribe/`, data).then((response) => {
      return response.data;
    });
  },
  start_parser(id) {
    return HTTP.post(`wikipedia/${id}/start_parser/`).then((response) => {
      return response.data;
    });
  },
  detail(id, date, date_compare) {
    return HTTP.get(`wikipedia/${id}/diff/?${date?`&date=${date}`:''}${date_compare?`&date_compare=${date_compare}`:''}`).then((response) => {
      return response.data;
    });
  },
  updateText(id, data) {
    return HTTP.post(`wikipedia/${id}/update_wiki/`, data).then((response) => {
      return response.data;
    });
  },
  get(id) {
    return HTTP.get(`wikipedia/${id}/`).then((response) => {
      return response.data;
    });
  },
  active_dates(id) {
    return HTTP.get(`wikipedia/${id}/active_dates/`).then((response) => {
      return response.data;
    });
  },
};
