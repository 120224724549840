<template>
  <main class="m-3">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <h1>Статистика: {{$route.query.link}}</h1>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a :class="`nav-link${engine==='yandex'?' active':''}`"
                   v-on:click.prevent="engine='yandex'"
                   href="#">Yandex</a>
              </li>
              <li class="nav-item">
                <a :class="`nav-link${engine==='google'?' active':''}`"
                   v-on:click.prevent="engine='google'"
                   href="#">Google</a>
              </li>

            </ul>
          </div>
          <div class="col">
            <div class="me-2 d-flex">
              <date-picker readonly style="width: 150px" v-model="filter.date_start" ref="start_date"
                           :config="options"></date-picker>

              <date-picker readonly style="width: 150px" v-model="filter.date_end" ref="start_date"
                           :config="options"></date-picker>
              <button class="btn btn-outline-secondary" v-on:click.prevent="getLink()" type="button"
                      id="button-query"><i class="bi bi-search"></i></button>
            </div>
          </div>
          <div class="col">
            <span>Проект: </span> <b>{{ link.project_name }}</b><br>
            <span>Тон: </span><b :style="getClass(link)">{{ link.get_tone_display }}</b>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="link.id">
<!--        <BarChart class="mt-4" v-if="datacollection" v-bind:data="datacollection"/>-->
    <Line
          id="my-chart-id"
          v-if="datacollection"
          :data="datacollection"
      />

        <div class="d-flex mb-3" style="overflow-x: scroll">
          <ul class="list-group list-group-horizontal me-1">
            <li class="list-group-item">
              <p>
                <span class="b-info bg-success rounded-circle text-white"><i class="bi bi-arrow-up"></i></span>
                <span class="text-secondary">({{ diff_data.up.p || 0 }}%)
              </span>
              </p>
              <div class="h2">{{ diff_data.up.c || 0 }}</div>
            </li>
            <li class="list-group-item">
              <p>
                <span class="b-info bg-secondary rounded-circle text-white">=</span>
                <span class="text-secondary">({{ diff_data.eq.p || 0 }}%)
              </span>
              </p>
              <div class="h2">{{ diff_data.eq.c || 0 }}</div>
            </li>
            <li class="list-group-item">
              <p>
                <span class="b-info bg-danger rounded-circle text-white"><i class="bi bi-arrow-down"></i></span>
                <span class="text-secondary">({{ diff_data.down.p || 0 }}%)
              </span>
              </p>
              <div class="h2">{{ diff_data.down.c || 0 }}</div>

            </li>
          </ul>
          <ul class="list-group list-group-horizontal me-1">
            <li class="list-group-item">
              <p>Средняя</p>
              <div class="h2">
                {{ diff_data.middle || 0 }}
              </div>
            </li>
            <li class="list-group-item">
              <p class="no-wrap">Видимость <span class="text-secondary">({{ diff_data.visible.p || 0 }}%)</span></p>
              <div class="h2">{{ diff_data.visible.c }}</div>
            </li>
          </ul>
          <ul class="list-group list-group-horizontal">

            <li class="list-group-item" v-for="period in diff_data.period">
              <p>{{ period[0] }}-{{ period[1] }} <span class="text-secondary">({{ period[3] || 0 }}%)</span></p>
              <div class="h2">{{ period[2] || 0 }}</div>
            </li>
          </ul>
        </div>

        <div class="table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col" class="sticky">Запросы ({{ keywords_data.length }})</th>
              <th scope="col" v-for="date in dates" class="no-wrap">{{ date }}</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="keyword in keywords_data">
              <th scope="row" class="no-wrap sticky">{{ keyword.name }}</th>
              <td v-for="position in keyword.data"
                  :class="`${position.diff&&position.pos?`bg-${position.diff > 0?'green':'pink'}`:''}`">
                {{ position.pos ? position.pos : '-' }}
                <sup v-if="position.diff&&position.pos"
                     :class="`text-${position.diff > 0?'success':'danger'}`">{{ position.diff }}</sup>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </main>


</template>

<script>
import axios from "axios";
import {Line} from "vue-chartjs";
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

ChartJS.defaults.plugins.legend.display = false;

export default {
  name: 'PfLinkStat',
  components: {
    Line
  },
  data() {
    return {
      filter: {
        date_start: new Date(new Date().setDate(new Date().getDate() - 14)),
        date_end: new Date(),
      },
      engine: 'yandex',
      options: {
        format: 'DD/MM/YYYY',
        locale: 'ru',
        useCurrent: true,
        enabledDates: [],
        ignoreReadonly: true
      },
      keywords: [],
      dates: [],
      link: {},
      period_params: [[1, 3, '#2e96dd'], [4, 10, '#21936c'], [11, 30, '#1abc9c'], [31, 50, '#a2df9f'], [51, 100, '#b0c7c7'],]
    }
  },
  mounted: function () {
    if (!this.$route.query.token && !this.$route.query.link) this.$router.push('404');
    if (this.$route.query.date_start) this.filter.date_start = this.$route.query.date_start.replaceAll(".", "/");
    else this.filter.date_start = new Date(new Date().setDate(new Date().getDate() - 14));
    if (this.$route.query.date_end)  this.filter.date_end = this.$route.query.date_end.replaceAll(".", "/");
    else this.filter.date_end = new Date();
    this.getLink();
  },
  computed: {
    datacollection() {
      let $this = this,
        dataset = [], all_count = $this.keywords_data.length,
        all_dates = $this.keywords_data.map(x => x.data)
      this.period_params.forEach(function (param) {
        let param_data = {
          label: `${param[0]}-${param[1]}`,
          backgroundColor: `${param[2]}`,
          data: []
        }
        $this.dates.forEach(function (el, index) {
          let all_l = all_dates.map(x => x[index].pos).filter(x => {
            return param[0] <= x && x <= param[1]
          }).length
          param_data.data.push(Math.round(all_l / (all_count / 100)))
        })
        dataset.push(param_data)
      })
      return {labels: $this.dates, datasets: dataset}
    },

    diff_data() {
      let diff = {up: 0, eq: 0, down: 0, middle: 0, visible: 0, period: []};
      let data = []
      this.keywords_data.forEach(function (key) {
        data.push(key.data[0])
      })
      let up = data.filter(x => {
        return x.diff > 0
      }).length
      diff.up = {c: up, p: Math.round(up / (data.length / 100))}
      let down = data.filter(x => {
        return x.diff < 0
      }).length
      diff.down = {c: down, p: Math.round(down / (data.length / 100))}
      let eq = data.filter(x => {
        return !x.diff
      }).length
      diff.eq = {c: eq, p: Math.round(eq / (data.length / 100))}
      diff.middle = Math.round(data.reduce((s, x) => s + x.pos, 0) / data.length)
      let vis = data.filter((x) => {
        return x.pos < 11
      }).length
      diff.visible = {c: vis, p: Math.round(vis / (data.length / 100))}
      let all_count = this.keywords_data.length / 100;
      this.period_params.forEach(function (param) {
        let all_l = data.map(x => x.pos).filter(x => {
          return param[0] <= x && x <= param[1]
        }).length
        diff.period.push([param[0], param[1], all_l, Math.round(all_l / all_count)])
      })
      return diff;
    },
    keywords_data() {
      let keys = [], $this = this;
      this.keywords.forEach(function (keyword) {
        let dates = keyword[$this.engine], sum = dates.reduce((s, d) => s + d, 0);
        if (sum > 0) {
          let calc_dates = []
          dates.forEach(function (el, index) {
            let diff = 0
            if (dates[index + 1]) {
              diff = dates[index + 1] - el
            }
            calc_dates.push({pos: el, diff: diff})
          })
          keys.push({name: keyword.name, data: calc_dates})
        }
      })
      return keys;
    },

  },
  methods: {
    getClass: function (link) {
      let COLORS = this.$COLORS;
      let val = `${link.tone}_${link.kind_type}_${link.type_tone}`;
      return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;
    },
    getLink: function () {
        let $this = this;
        let filter = structuredClone(this.filter);
        if (typeof filter.date_start === 'object') filter.date_start = `${filter.date_start.getDate()}/${filter.date_start.getMonth() + 1}/${filter.date_start.getFullYear()}`;
        if (typeof filter.date_end === 'object') filter.date_end = `${filter.date_end.getDate()}/${filter.date_end.getMonth() + 1}/${filter.date_end.getFullYear()}`;
        filter.link = this.$route.query.link;
        filter.token = this.$route.query.token;
        let f_string = new URLSearchParams(filter).toString();
      axios.get(`/planfix/get_link_stat/${f_string?'?'+f_string:''}`).then((response) => {
        $this.keywords = response.data.keyword;
        $this.dates = response.data.dates;
        $this.link = response.data.link;
      });
    },
  }
}

</script>
<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  left: -1px;
  z-index: 20;
  background: #eee;
  text-wrap: none;
  font-size: 12px;
}

.bg-pink {
  background-color: #fbe1df;
}

.bg-green {
  background-color: #def6e9;
}

.b-info {
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  padding: 3px 0;
  font-size: 10px;
}

.list-group-item {
  min-width: 150px;
}

.btn.linked {
  max-width: 150px;
  display: inline-block;
}
</style>
