import { HTTP } from "./common";

export const ResourceApi = {

  patch(_id, data) {
    return HTTP.patch(`resources/${_id}/`, data).then((response) => {
      return response.data;
    });
  }

};
