<template>
  <div class="card-body links graph">
    <div class="row justify-content-center mb-3">
      <div class="col-8">
        <div class="d-flex">
          <MultiSelect style="width: 100%"
                       v-model="top"
                       :options="tops"
                       :multi="false"
                       :required="true"
                       name=""
                       :only_value="false"
          ></MultiSelect>

          <MultiSelect style="width: 100%"
                       v-model="keywords"
                       :options="project_keywords"
                       :required="true"
                       :multi="true"
                       name="Запросы"
          ></MultiSelect>

          <button class="btn btn-outline-secondary" v-on:click.prevent="getData"
                  type="button"
                  id="button-query"><i class="bi bi-search"></i></button>
          <button class="btn btn-sm btn-primary" @click="getReport()">
            <i class="bi bi-file-arrow-down"></i>
          </button>
        </div>
      </div>
    </div>
    <template v-if="data">
      <button class="btn btn-sm btn-outline-primary" @click="checked=!checked"
              style="position: absolute; right: 20px;z-index: 100;">
        <template v-if="checked">Отключить</template>
        <template v-else>Включить</template>
        все
      </button>
      <div class="form-check form-switch ms-5 pt-2">
        <input class="form-check-input" v-model="full_url" type="checkbox" role="switch" id="full_url" checked>
        <label class="form-check-label" for="full_url">Полные ссылки</label>
      </div>
      <div id="legend" class="legend"></div>
      <div style="height: 500px">
        <Line
            id="my-chart-id"
            v-if="datacollection"
            :options="chartOptions"
            :data="datacollection"
            :plugins="[htmlLegendPlugin]"
        />
      </div>
    </template>
    <div class="table-responsive" v-if="data">
      <table class="table table-hover table-bordered">
        <thead>
        <tr>
          <th scope="col" rowspan="2" class="sticky">
            Ссылки ({{ links.length }})
          </th>
          <th scope="col" v-for="keyword in keywords" class="no-wrap"
              :colspan="keyword.show_all?Object.keys(Object.values(data[keyword.id])[0]).length:2"
              style="padding: 0">
            <button @click="graph_keyword=keyword.id"
                    :class="`btn btn-key ${graph_keyword===keyword.id?'btn-success':''}`">
              {{ keyword.name }}
              <button v-if="graph_keyword===keyword.id"
                      @click="keyword.show_all = !keyword.show_all"
                      class="btn show-all btn-outline-light"
                      title="Показать все результаты"><i
                  :class="`bi bi-arrows-${keyword.show_all?'collapse':'expand'}`"></i></button>
            </button>
          </th>
        </tr>
        <tr>
          <template v-for="keyword in keywords" class="no-wrap">
            <template v-if="!keyword.show_all">
              <th scope="col" class="no-wrap">{{ filter.date_end }}</th>
              <th scope="col" class="no-wrap">{{ filter.date_start }}</th>
            </template>
            <template v-else>
              <th scope="col" class="no-wrap"
                  v-for="date in Object.keys(Object.values(data[keyword.id])[0])">
                {{ date }}
              </th>
            </template>
          </template>
        </tr>
        </thead>
        <tbody class="table-group-divider">
        <tr v-for="(link, index) in links">
          <th scope="row" class="sticky">
            <span :style="getClass(link)">{{ getDomain(link) }}</span>
          </th>
          <template v-for="keyword in keywords">
            <template v-if="!keyword.show_all">
              <td>
                <template v-if="data[keyword.id][link]">
                  {{ Object.values(data[keyword.id][link])[0] }}
                </template>
              </td>
              <td>
                <template v-if="data[keyword.id][link]">
                  {{
                    Object.values(data[keyword.id][link])[Object.values(data[keyword.id][link]).length - 1]
                  }}
                </template>
              </td>
            </template>
            <template v-else>
              <td v-for="date in Object.keys(Object.values(data[keyword.id])[0])">
                <span v-if="data[keyword.id][link]">{{ data[keyword.id][link][date] }}</span>
              </td>
            </template>
          </template>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
import {ProjectApi} from "@/api/project";
import {PictureApi} from "@/api/pictures";
import {ReportApi} from "@/api/reports";
import {Line} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement} from 'chart.js'
import htmlLegendPlugin from "@/components/legendPlugin";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

ChartJS.defaults.plugins.legend.display = false;

export default {
  name: "GraphKeyword",
  props: {
    project: {type: Object, default: {}},
    filter: {type: Object, default: {}},
    country: {type: Object, default: {code: "RU"}},
    engine: {type: String, default: 'yandex'},
  },
  components: {
    Line,
  },
  data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      project_keywords: [],
      keywords: [],
      full_url: false,
      top: {name: 'TOP10', id: 10},
      graph_keyword: null,
      value: [],
      value_max: 100,
      checked: true,
      tops: [
        {name: 'TOP10', id: 10},
        {name: 'TOP20', id: 20},
        {name: 'TOP30', id: 30},
        {name: 'TOP50', id: 50}
      ],

      chartLegend: null,
      data: null,

    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x:
              {
                grid: {
                  display: false,
                },
                ticks: {
                  // autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: 12,
                  }
                },
              },
          y:
              {
                display: false,
                reverse: true,
                maxTicksLimit: this.top.id + 5,
                suggestedMax: this.top.id + 5,
                max: this.top.id + 5,
              }
        },
      }
    },
    datacollection() {
      let $this = this, dataset = [], labels = [];
      let dynamicColors = function () {
        let r = Math.floor(Math.random() * 255), g = Math.floor(Math.random() * 255),
            b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      };
      if ($this.graph_keyword && $this.data) {
        let keyword_data = $this.data[$this.graph_keyword]
        let links = Object.keys(keyword_data)
        links.forEach(function (link) {
          let col = dynamicColors();
          let param_data = {
            label: $this.getDomain(link),
            borderColor: col,
            backgroundColor: col,
            lineTension: 0,
            borderWidth: 2,
            pointRadius: 5,
            fill: false,
            data: Object.values(keyword_data[link]).slice($this.value[0], $this.value[1])
          }
          dataset.push(param_data)
          if (!labels.length) labels = Object.keys(keyword_data[link])
        })

      }
      return {labels: labels.slice($this.value[0], $this.value[1]), datasets: dataset}
    },

    links() {
      if (this.data) return [...new Set(Object.values(this.data).map(x => Object.keys(x)).flat(1))];
      return []
    },

  },
  mounted() {
    this.getKeywords()
  },
  watch: {
    checked() {
      this.offAll();
    },
    project() {
      this.keywords = [];
      this.getKeywords();
    },
    country() {
      this.keywords = [];
      this.getKeywords();
    },
    engine() {
      this.getData();
    }
  },
  methods: {
    getKeywords() {
      let $this = this;
      PictureApi.keywords(this.project.id, this.country.code).then(r => {
        $this.project_keywords = r;
      });
    },
    getData() {
      let $this = this;
      if (!this.keywords.length) {
        $this.$notify({
          type: 'error ',
          text: 'Не выбраны ключевые слова'
        });
      } else {
        let filter = structuredClone(this.filter);
        if (typeof filter.date_start === 'object') filter.date_start = `${filter.date_start.getDate()}/${filter.date_start.getMonth() + 1}/${filter.date_start.getFullYear()}`;
        if (typeof filter.date_end === 'object') filter.date_end = `${filter.date_end.getDate()}/${filter.date_end.getMonth() + 1}/${filter.date_end.getFullYear()}`;
        filter.country_code = this.country.code;
        filter.engine = this.engine;
        filter.state = this.top.id||20;
        filter.keywords = JSON.stringify(this.keywords.map(key => key.id))
        let f_string = new URLSearchParams(filter).toString();
        ProjectApi.get_stat_by_keywords(this.project.id, f_string).then((resp) => {
          $this.data = resp;
          if ($this.data) {
            $this.graph_keyword = Object.keys($this.data)[0]
            let labels = Object.keys(Object.values($this.data[$this.graph_keyword])[0])
            $this.value = [0, labels.length - 1];
            $this.value_max = labels.length - 1;
          }
        });
      }
    },
    getReport() {
      let $this = this;
      let report = {
        report_type: 'link_stat_keywords', project: $this.project.id,
        additional: {
          countries: this.project.countries.filter(x => x.code === this.country).map(x => x.id),
          keyword: $this.graph_keyword
        }, state: $this.top.id
      };
      if (typeof this.filter.date_start === 'object') report.date_start = `${this.filter.date_start.getFullYear()}-${this.filter.date_start.getMonth() + 1}-${this.filter.date_start.getDate()}`;
      else {
        let date_start = this.filter.date_start.split('/')
        report.date_start = `${date_start[2]}-${date_start[1]}-${date_start[0]}`
      }
      if (typeof this.filter.date_end === 'object') report.date = `${this.filter.date_end.getFullYear()}-${this.filter.date_end.getMonth() + 1}-${this.filter.date_end.getDate()}`;
      else {
        let date_end = this.filter.date_end.split('/')
        report.date = `${date_end[2]}-${date_end[1]}-${date_end[0]}`
      }
      ReportApi.create_report(report).then(response => {
        $this.$notify({
          type: 'success ',
          text: 'Процесс создания отчёта запущен. Отчёт будет доступен в разде "Отчёты"'
        });
      });

    },
    offAll() {
      let legend = document.getElementById('legend');
      let li = legend.getElementsByTagName('li');
      let $this = this;
      for (let i = 0; i < li.length; i++) {
        let key = li[i]
        if ($this.checked && key.className === 'hidden' || !$this.checked && key.className !== 'hidden') key.click()
      }

    },
    getClass(link) {
      let tones = link.split('\'').filter(x => x.length > 2);
      let COLORS = this.$COLORS;
      let val = `${tones[1]}_${tones[3]}_${tones[2]}`;
      return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;
    },
    getDomain(link) {
      link = link.split('\'').filter(x => x.length > 2)[0]
      const url = new URL(link)
      if (this.full_url) return link;
      else return url.origin;
    }
  }
}
</script>

<style scoped>

.show-all {
  transform: rotate(90deg);
  color: #fff;
  font-size: 17px;
  padding: 0;
  margin-left: 5px;
}

.btn-key {
  width: 100%;
  height: 55px;
  border-radius: 0;
}
</style>
