<template>
  <section>
    <div class="row d-flex justify-content-between align-items-end">
      <div class="col-6">
        <div class="dropdown" v-if="active_country">
          <button class="btn btn-filter " type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{ active_country.name }}
          </button>
          <ul class="dropdown-menu ">
            <template v-for="county in project.countries">
            <li @click="active_country=county">{{ county.name }}</li>
              </template>
          </ul>
        </div>
      </div>
      <div class="col-6">
        <div class="dropdown" v-if="state">
          <button class="btn btn-filter " type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            Топ-{{ state }}
          </button>
          <ul class="dropdown-menu ">
            <template v-for="state_val in all_states">
            <li @click="state=state_val">Топ-{{ state_val }}</li>
              </template>
          </ul>
        </div>
      </div>

    </div>
    <div class="row d-flex justify-content-between">
        <button class="btn btn-default col-1 control" @click="changeKeyword(false)" :disabled="keywords.findIndex(x=>x.id===active_keyword.id)<=0"><i class="bi bi-chevron-left"></i></button>
      <div class="col-10">
        <div class="dropdown" v-if="active_country && active_keyword && active_keyword.id">
          <button class="btn btn-filter fz-14" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{ active_keyword.name }}
          </button>
          <ul class="dropdown-menu fz-14">
            <template v-for="keyword in keywords">
            <li @click="active_keyword=keyword">{{ keyword.name }}</li>
            </template>
          </ul>
        </div>
      </div>
        <button class="btn btn-default col-1 control" @click="changeKeyword(true)" :disabled="(keywords.findIndex(x=>x.id===active_keyword.id) + 1) >= keywords.length"><i class="bi bi-chevron-right"></i></button>

    </div>
    <div class="paginate-point">
      <i v-for="(keyword, index) in keywords" :class="`bi bi-circle${keywords.findIndex(x=>x.id===active_keyword.id)===index?'-fill':''}`">{{index}}</i>
    </div>
    <div class="" v-if="links.length">
      <SmallLink :links="links.slice(0, state)"
                 :link="link_detail"
                 :engine_code="engine"
                 :backFunc="backFunc"></SmallLink>
    </div>
    <div class="" style="height: 80vh; overflow-x: hidden" v-else>
      <table class="table" v-if="active_keyword" @touchstart="touchStartMethod">
        <tr class="tr">
          <th style="width: 16px;"></th>
          <th v-if="active_country.yandex">
            <div class="yandex-full"></div>
          </th>
          <th v-if="active_country.google">
            <div class="google-full"></div>
          </th>
        </tr>
        <tr>
          <td>
            <table class="table" style="width: 16px">
              <tr v-for='index in state'>
                <td v-if="index<=state">
                  <span>{{ index }}</span>
                </td>
              </tr>
            </table>
          </td>
          <template v-for="key in ['yandex', 'google']">
          <td v-if="active_country[key]">
            <table class="table">
              <template v-for="(link, index) in active_keyword[key]">
              <tr v-if="index<state">
                <td>
                  <a :href="link.link"
                     class="link_display"
                     @click.prevent.exact="engine = key; link_detail=link;links=active_keyword[key];"
                     :style="`${$func.getClass(link.tone, false)}`" :ref="`link-${link.link_obj_id}`">
                    {{ link.domain }}
                  </a>
                </td>
              </tr>
                </template>
            </table>
          </td>
            </template>
        </tr>
      </table>

    <div class="btn-wrap control">
      <button class="btn" v-on:click="$router.go(-1)"><i class="bi bi-arrow-left"></i> Назад</button>
    </div>
    </div>
  </section>
</template>
<script>
import {defineComponent} from "vue";
import SmallProject from "@/mobile/includes/SmallProject.vue";
import SmallLink from "@/mobile/includes/SmallLink.vue";
import {ProjectApi} from "@/api/project";
import {MobileApi} from "@/api/mobile";
import router from "@/router";

export default defineComponent({
  components: {SmallProject, SmallLink},
  data() {
    return {
      all_states: [10, 20, 50, 100],
      state: 20,
      active_keyword: null,
      keywords: [],
      links: [],
      link_detail: null,
      engine: ''
    }
  },
  computed: {
    project: {
      get: function () {
        return this.$store.getters.favor_project(this.$route.params.id);
      },
      set: function (val) {
      }
    },
    active_country: {
      get: function () {
        let country = this.project ? (this.project.countries || []).find(x => x.active) : null;
        if (country && !this.active_keyword) {
          this.active_keyword = {}
        }
        return this.project ? (this.project.countries || []).find(x => x.active) : null;
      },
      set: function (val) {
        ProjectApi.set_active_country(this.project.id, val.code)
        this.project.countries.forEach(x => x.active = x.code === val.code)
        this.active_keyword = {}
        this.$store.dispatch('updateFavorite', this.project)
      }
    }
  },
  watch: {
    active_keyword() {
      if (!this.active_keyword.id) this.getResult();
      else {
        if (this.links.length) {

          this.engine = this.active_keyword['yandex'].length ? 'yandex' : 'google';
          this.links = this.active_keyword[this.engine];
          this.link_detail = this.links[0]
        }
      }
    }

  },
  async mounted() {
    // this.getResult();
  },
  methods: {
    router() {
      return router
    },
    getResult() {
      MobileApi.keywords(this.$route.params.id, this.project.last_parser, this.active_country.code).then(response => {
        this.keywords = response;
        this.active_keyword = this.keywords[0];
      })
    },
    backFunc() {
      this.links = [];
      this.link = null;
      // this.getResult();
    },
    touchStartMethod(touchEvent) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
    },
    changeKeyword(next=true){
      let index = this.keywords.findIndex(x=>x.id===this.active_keyword.id)
      if (next) {
        if ((index + 1) < this.keywords.length) {
            index += 1;
            this.active_keyword = this.keywords[index]
          }
      }
      else {
        if (index > 0) {
            index -= 1;
            this.active_keyword = this.keywords[index]
          }
      }
    },
    touchEnd(touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if (Math.abs(posXStart - posXEnd) >= window.screen.width / 2) {
        let index = this.keywords.findIndex(x=>x.id===this.active_keyword.id)
        if (posXStart < posXEnd) {
          this.changeKeyword(false)
        } else if (posXStart > posXEnd) {
          this.changeKeyword(true)
        }
      }
    }

  }

})
</script>

<style scoped>

</style>