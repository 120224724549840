<script setup>
import {state} from "@/socket";
import AlertItem from "@/components/AlertItem.vue";
import useClickOutside from '@/composables/clickOutside'
import {onMounted, computed, watch, ref} from "vue";
import {useStore} from "vuex";

const store = useStore()
const alerts = computed(() => store.getters.alerts || []);
const ws_state = computed(() => state.alert);

watch(ws_state, value => {
  const already = alerts.value.find(x => x.id === value.id);
  store.dispatch("updateAlert", value)
  if (!already) {
    alertCount.value.classList.add("animate__tada")
    playSound();
  }
})

const componentRef = ref()
const excludeRef = ref()
const dropdown = ref()
const alertCount = ref()
const isOpen = ref(false)
useClickOutside(
    componentRef,
    () => {
      isOpen.value = false
    },
    excludeRef
)

onMounted(() => {
  store.dispatch("getAlerts")
})

function playSound() {
  const audio = new Audio(process.env.BASE_URL + "/bell.wav");
  const promise = audio.play();
  if (promise) {
    promise.catch(function (error) {
      console.error(error);
    });
  }
}

function showAll() {
  dropdown.value.classList.remove('show');
}

function removeTada() {
  alertCount.value.classList.remove('animate__tada')
}


</script>
<template>

  <div class="dropdown">
    <button type="button" ref="alertCount" class="btn text-white dropdown-toggle"
            @click="removeTada"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside">
      <i class="bi bi-bell"></i> <span class="badge text-bg-danger"> {{ alerts.filter(x => !x.read).length }}</span>
    </button>
    <ul class="dropdown-menu dropdown-menu-end" ref="dropdown">
      <template v-for="alert in alerts.filter(x=>!x.read).slice(0,6)">
        <AlertItem :alert="alert"></AlertItem>
      </template>

      <li class="list-group-item list-group-item-action d-flex gap-3 p-3" aria-current="true" v-if="!alerts.length">
        <p class="mb-0 opacity-75">Нет новых событий</p>
      </li>
      <button ref="excludeRef"
              class="btn btn-sm btn-outline-dark show-all"
              v-if="alerts.length > 6" @click="isOpen = true; showAll();">
        Показать все
      </button>
    </ul>
    <div v-if="isOpen" class="all-alerts" ref="componentRef">

      <ul class="dropdown-menu  dropdown-menu-end show">
        <template v-for="alert in alerts">
          <AlertItem :alert="alert"></AlertItem>
        </template>
      </ul>
    </div>

  </div>

</template>

<style scoped>
.dropdown-menu {
  width: 400px;
  z-index: 10000;
  padding-top: 30px;
  max-height: 70vh;
  overflow-y: scroll;

  .list-group-item {
    padding: 10px;
  }

  .show-all {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.all-alerts {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;

  .dropdown-menu {
    padding-top: 0;
    max-height: 100vh;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    overflow-y: auto;
  }
}

.animate__tada {
  animation: wiggle 2s linear infinite;
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}


</style>
