<template>

  <div :class="`card border border-${status}`" v-if="data">
    <div class="card-body">
      <h4 :class="`card-title text-${status}`">
        {{ status == "danger" ? "Нецелевая информация" : "Целевая информация" }}</h4>
      <template v-for="state in $STATES">
        <template v-if="data['_'+state.id]||data['_'+state.id]==0">
          <div class="row" v-if="(state.id<50 || (state.id>=50&&show_more))">
            <div class="col p-1">
              <div class="">
                <div class="card-body ">
                  <h6 class="card-title"><b>Всего в {{ state.name }}</b></h6>
                  <hr>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Общий:</p>
                    <p class="place">
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: '', tone: getLinkTone(2), type_tone: getTypeTone(2), date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id] }}</b>
                      </router-link>
                    </p>

                    <p class="place">
                      <i class="yandex"></i>
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: 'yandex', tone: getLinkTone(2), type_tone: getTypeTone(2), date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_y'] }}</b>
                      </router-link>
                    </p>
                    <p class="place">
                      <i class="google"></i>
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: 'google', tone: getLinkTone(2), type_tone: getTypeTone(2), date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_g'] }}</b>
                      </router-link>
                    </p>
                    <p class="place">
                      <b>%</b>
                      <b :class="`text-${status}`">{{ data['_' + state.id + '_percent'] }}</b>
                    </p>


                  </div>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Уникальный:</p>
                    <p class="place">
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: '', tone: getLinkTone(2), type_tone: getTypeTone(2), date: date,
                                  country_code: country_code}}">
                        <b :class="`text-${status} me-2`">{{ data['_' + state.id + '_uniq'] }}</b>
                      </router-link>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: 'yandex', tone: getLinkTone(2), type_tone: getTypeTone(2), date: date,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_uniq_y'] }}</b>
                      </router-link>
                    </p>
                    <p class="place"><i class="google"></i>
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: 'google', tone: getLinkTone(2), type_tone: getTypeTone(2), date: date,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_uniq_g'] }}</b>
                      </router-link>
                    </p>

                    <p class="place">
                      <b>%</b>
                      <b :class="`text-${status}`">{{ data['_' + state.id + '_uniq_percent'] }}</b>
                    </p>

                  </div>
                  <div class="d-flex no-wrap" v-if="data['_'+state.id+'_image'] !== undefined">
                    <p class="text-muted head">Общий<br> картинки:</p>
                    <p class="place">
                      <router-link :to="{ name: 'images_info', params: { id: project_id }, query: {image_info: 1, state: state.id,
                                 engine: '', tone: getLinkTone(2), type_tone: getTypeTone(2), image: true, date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status} me-2`">{{ data['_' + state.id + '_image'] }}</b>
                      </router-link>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                      <router-link :to="{ name: 'images_info', params: { id: project_id }, query: {image_info: 1, state: state.id,
                                 engine: 'yandex', tone: getLinkTone(2), type_tone: getTypeTone(2), image: true, date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_image_y'] }}</b>
                      </router-link>
                    </p>
                    <p class="place"><i class="google"></i>
                      <router-link :to="{ name: 'images_info', params: { id: project_id }, query: {image_info: 1, state: state.id,
                                 engine: 'google', tone: getLinkTone(2), type_tone: getTypeTone(2), image: true, date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_image_g'] }}</b>
                      </router-link>
                    </p>

                  </div>
                  <div class="d-flex no-wrap" v-if="data['_'+state.id+'_image_uniq'] !== undefined">
                    <p class="text-muted head">Уникальный<br> картинки:</p>
                    <p class="place">
                      <router-link :to="{ name: 'images_info', params: { id: project_id }, query: {image_info: 1, state: state.id,
                                 engine: '', tone: getLinkTone(2), type_tone: getTypeTone(2), image: true, date: date,
                                  country_code: country_code}}">
                        <b :class="`text-${status} me-2`">{{ data['_' + state.id + '_image_uniq'] }}</b>
                      </router-link>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                      <router-link :to="{ name: 'images_info', params: { id: project_id }, query: {image_info: 1, state: state.id,
                                 engine: 'yandex', tone: getLinkTone(2), type_tone: getTypeTone(2), image: true, date: date,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_image_uniq_y'] }}</b>
                      </router-link>
                    </p>
                    <p class="place"><i class="google"></i>
                      <router-link :to="{ name: 'images_info', params: { id: project_id }, query: {image_info: 1, state: state.id,
                                 engine: 'google', tone: getLinkTone(2), type_tone: getTypeTone(2), image: true, date: date,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_image_uniq_g'] }}</b>
                      </router-link>
                    </p>

                  </div>
                </div>
              </div>
            </div>
            <div class="col p-1">
              <div class="">
                <div class="card-body ">
                  <h6 class="card-title"><b>
                    <template v-if="status=='danger'">По договору</template>
                    <template v-else>Размещенные публикации</template>
                    в {{ state.name }}</b></h6>
                  <hr>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Общий:</p>
                    <p class="place">
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: '', tone: getLinkTone(1), type_tone: getTypeTone(1), date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status} me-2`">{{ data['_' + state.id + '_offer'] }}</b>
                      </router-link>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: 'yandex', tone: getLinkTone(1), type_tone: getTypeTone(1), date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_offer_y'] }}</b>
                      </router-link>
                    </p>
                    <p class="place"><i class="google"></i>
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: 'google', tone: getLinkTone(1), type_tone: getTypeTone(1), date: date, uniq: false,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_offer_g'] }}</b>
                      </router-link>
                    </p>


                  </div>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Уникальный:</p>
                    <p class="place">
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: '', tone: getLinkTone(1), type_tone: getTypeTone(1), date: date,
                                  country_code: country_code}}">
                        <b
                            :class="`text-${status} me-2`">{{ data['_' + state.id + '_offer_uniq'] }}</b>
                      </router-link>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: 'yandex', tone: getLinkTone(1), type_tone: getTypeTone(1), date: date,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_offer_uniq_y'] }}</b>
                      </router-link>
                    </p>
                    <p class="place"><i class="google"></i>
                      <router-link :to="{ name: 'links_info', params: { id: project_id }, query: {state: state.id,
                                 engine: 'google', tone: getLinkTone(1), type_tone: getTypeTone(1), date: date,
                                  country_code: country_code}}">
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_offer_uniq_g'] }}</b>
                      </router-link>
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

    </div>
  </div>

</template>

<script>

export default {
  name: 'InfoBlock',
  components: {},

  props: ["data", "status", "project_id", 'date', 'images', 'show_more', 'country_code'],
  data() {
    return {}
  },

  mounted: function () {

  },
  methods: {
    getLinkTone: function (col) {
//        if (col === 1)
      if (this.status === 'danger') return ['negative'];
      else return ['positive'];
//        else {
//          if (this.status === 'danger') return ['negative', '_offer'];
//          else return ['positive', 'positive_offer']
//        }
    },
    getTypeTone: function (col) {
      if (col === 1)
        if (this.status === 'danger') return ['offer'];
        else return ['our', 'our_derivative'];
      else {
        if (this.status === 'danger') return ['offer', 'new'];
        else return ['start', 'our', 'new', 'finish', 'replace', 'our_derivative']
      }
    }

  }
}

</script>
<style scoped>
.head {
  width: 105px;
  flex: 0 0 105px;
}

.place {
  width: 40px;
  text-align: left;
  margin-left: 0;
  flex: 0 0 40px;
}

</style>