
let passwordMixin = {
  data() {
    return {
      show_pass: false,
      newPassword: '',
      oldPassword: '',
      repeatPassword: '',
      newErrors: [],
      repeatErrors: [],
      baseErrors: [],
      truePassword: (value) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W\w])[A-Za-z\d\W\w]{8,}$/.test(value),
      minPassword: 8
    }
  },
  watch: {
    newPassword(val) {
      this.newErrors = [];
      if (!this.truePassword(val)) {
        this.newErrors.push('Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы');
      }
      if (val.length < this.minPassword) {
        this.newErrors.push(`Пароль должен состоять минимум из ${this.minPassword} символов`);
      }
    },
    repeatPassword(val) {
      this.repeatErrors = [];
      if (this.newPassword !== this.repeatPassword) {
        this.repeatErrors.push('Введённые пароли не совпадают');
      }
    }
  },
};
export default passwordMixin;
