<template>
  <main class="dashboard links">
    <div class="mb-3">
      <h2>Состояние сервера</h2>
    </div>
          <div class="table-responsive">
            <button class="btn btn-sm btn-outline-dark" @click="getServerData"><i class="bi bi-arrow-clockwise"></i></button>
            <table class="table" v-if="server_info">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Всего</th>
                <th scope="col">Использовано</th>
                <th scope="col">Свободно</th>
                <th scope="col">%</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">CPU</th>
                <td colspan="4">
                  <span v-for="cpu in server_info.cpu" class="me-3">
                  {{cpu}}%
                    </span>
                </td>
              </tr>
              <tr>
                <th scope="row">Memory</th>
                <td>{{server_info.virtual.total}}Гб</td>
                <td>{{server_info.virtual.used}}Гб</td>
                <td>{{server_info.virtual.free}}Гб</td>
                <td>{{server_info.virtual.percent}}%</td>
              </tr>
              <tr>
                <th scope="row">Swap</th>
                <td>{{server_info.swap.total}}Гб</td>
                <td>{{server_info.swap.used}}Гб</td>
                <td>{{server_info.swap.free}}Гб</td>
                <td>{{server_info.swap.percent}}%</td>
              </tr>
              <tr>
                <th scope="row">Disc usage</th>
                <td>{{server_info.disk.total}}Гб</td>
                <td>{{server_info.disk.used}}Гб</td>
                <td>{{server_info.disk.free}}Гб</td>
                <td>{{server_info.disk.percent}}%</td>
              </tr>
              </tbody>
            </table>
          </div>
  </main>


</template>

<script>
  import {UserApi} from "@/api/user";

  export default {
    name: 'ServerInfo',
    data() {
      return {
        server_info: null
      }
    },
    mounted: function () {
      this.getServerData()
    },
    methods: {
      getServerData: function () {
        UserApi.server_info().then(response => this.server_info = response)
      },

    }
  }

</script>
