<template>
  <main class="dashboard">
    <div class="card mt-3">
      <div class="card-header">

        <h3>Целевая ссылка: <a :href="link.url" target="_blank">{{ link.url }}</a>
        </h3>
        <div class="row">
          <div class="col-7">
            <p>
              <b>Текст:</b> <a :href="link.od_link" target="_blank">Ссылка на OD файл</a>
            </p>
            <p v-if="link.pf_id">
              <b>PlanFix:</b> <a :href="`https://serm-dr.planfix.ru/task/${link.pf_id}/`" target="_blank">https://serm-dr.planfix.ru/task/{{
                link.pf_id
              }}</a>
            </p>
            <p>
              <b>Индексация:</b>
              <span>Yandex: <i class="bi bi-check-lg text-success" v-if="link.yandex_index"></i><i class="bi bi-x-lg text-danger" v-else></i></span>
              <span>Google: <i class="bi bi-check-lg text-success" v-if="link.google_index"></i><i class="bi bi-x-lg text-danger" v-else></i></span>
              <table class="table">
                <tr v-for="search in link.searches">
                  <td>{{ search.result__keyword__name }}</td>
                  <td>{{ search.place }}</td>
                  <td><i :class="search.result__engine__code"></i></td>
                </tr>

              </table>
            </p>
            <h5 v-if="!link.rule">Файл ТЗ не найден</h5>
            <table v-else class="table">
              <tbody>
              <tr>
                <td><p><b>Символов на текст:</b> <span
                    :class="link.result.length < link.rule.length ?'text-danger':''">{{ link.result.length }}</span> |
                  {{ link.rule.length }}</p>
                  <p><b>Плотность ключей:</b> <span
                      :class="link.result.density < link.rule.density ?'text-danger':''">{{
                      link.result.density
                    }}</span>
                    | {{ link.rule.density }}</p>
                  <p><b>Должно быть ключей:</b> <span
                      :class="link.result.key_count < link.rule.key_count ?'text-danger':''">{{
                      link.result.key_count
                    }}</span> | {{ link.rule.key_count }}</p>
                  <p><b>Изображений:</b> <span
                      :class="link.result.image_count < link.rule.image_count ?'text-danger':''">{{
                      link.result.image_count
                    }}</span> | {{ link.rule.image_count }}</p> <br>
                  <p><b>Таблица 1 (кол-во и типы ключевых запросов)</b></p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>Запрос</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>H1</th>
                      <th v-for="h2 in link.rule.headers[0].h2">H2</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(header, index) in link.rule.headers">
                      <td>{{ header.key }}</td>
                      <td><b>{{ link.result.headers[index].title }}</b> | {{ header.title }}</td>
                      <td><b>{{ link.result.headers[index].description }}</b> | {{ header.description }}</td>
                      <td><b>{{ link.result.headers[index].h1 }}</b> | {{ header.h1 }}</td>
                      <td v-for="(h2, h2_index) in header.h2"><b>{{ link.result.headers[index].h2[h2_index] }}</b> | {{
                          h2
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <br>
                  <p><b>Таблица 2 (кол-во и типы ключевых запросов в тексте)</b></p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>Запрос</th>
                      <th>Всего</th>
                      <th>Прямое</th>
                      <th>Свободное</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(keyword, index) in link.rule.keywords">
                      <td><span>{{ keyword.key }}</span></td>
                      <td><b>{{ link.result.keywords[index].total }}</b> | {{ keyword.total }}</td>
                      <td><b>{{ link.result.keywords[index].direct }}</b> | {{ keyword.direct }}</td>
                      <td><b>{{ link.result.keywords[index].free }}</b> | {{ keyword.free }}</td>
                    </tr>

                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between">
              <div class="div">
<!--                <button class="btn btn-warning">Получить новые данные</button>-->
                <p>
                  <small class="text-secondary">Данные получены: {{ link.date_create }}</small>
                </p>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" v-model="hide_image" role="switch"
                       id="flexSwitchCheckDefault">
                <label class="form-check-label" for="flexSwitchCheckDefault">Скрыть изображения</label>
              </div>
            </div>
          </div>
          <div class="col-3">
            <SimLinks :link_id="$route.params.link_id" :target="true"></SimLinks>

          </div>
        </div>

      </div>

    </div>
    <div class="card d-flex flex-row">
      <div class="card-body" v-if="link.structure">
        <div class="h3 badge bg-secondary">Размещение</div>
        <div class="h5" v-if="!link.structure.length">Данные не собраны или у прокта не указана ссылка</div>

        <template v-else>
          <p><b>Title:</b><span v-if="link.title" v-html="findDiff({text: link.title}, [{text: link.od_title}])"></span>
          </p>
          <p><b>Description:</b><span v-if="link.description" v-html="findDiff({text: link.description}, [{text: link.od_description}])"></span></p>
        </template>
        <div contenteditable v-on="listeners" ref="editable">
          <template v-for="text in link.structure">
            <h2 v-if="text.tag==='head'" v-html="findDiff(text, link.od_structure)">
            </h2>
            <figure v-else-if="text.tag==='graphic' && text.media && !hide_image">
              <p>
                <img :src="text.media.url" alt=""></p>
              <figcaption>{{ text.media.alt }}</figcaption>
            </figure>
            <p v-else v-html="findDiff(text, link.od_structure)">
            </p>


          </template>
        </div>

      </div>
      <div class="card-body" v-if="link.od_structure">
        <div class="h3 badge bg-secondary">Текстовый файл</div>
        <div class="h5" v-if="!link.od_structure.length">Данные не собраны или нет связи с итоговым файлом</div>
        <template v-else>
          <p><b>Title:</b>{{ link.od_title }}</p>
          <p><b>Description:</b>{{ link.od_description }}</p>
        </template>
        <div v-for="text in link.od_structure">
          <h2 v-if="text.tag==='head'" v-html="findDiff(text, link.structure)">
          </h2>
          <p v-else-if="text.tag==='p'" v-html="findDiff(text, link.structure)">
          </p>

        </div>
      </div>
    </div>

  </main>


</template>

<script>
import {HTTP} from "@/api/common";
import SimLinks from "@/components/SimLinks.vue"

export default {
  name: 'TargetLink',
  components: {SimLinks},
  data() {
    return {
      link: {},
      changed: false,
      hide_image: false
    }
  },
  mounted: function () {
    let $this = this;
    HTTP.get(`planfix/target/${this.$route.params.link_id}/`).then((response) => {
      $this.link = response.data
    });
  },
  computed: {
    structure() {
      return this.link.structure.filter(x => x.tag !== 'graphic')
    },
    listeners() {
      return {...this.$listeners, input: this.onInput, blur: this.recolorText};
    },
  },
  methods: {
    onInput() {
      this.changed = true;
    },
    recolorText() {
      if (this.changed) {
        let children = Array.from(this.$refs.editable.children), $this = this;
        children.forEach(element => {
          if (['p', 'h2'].indexOf(element.tagName.toLowerCase()) + 1) element.innerHTML = $this.findDiff({text: element.innerText}, $this.link.od_structure)
        })
        this.changed = false;
      }
    },

    editDistance(s1, s2) {
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();

      let costs = [];
      for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
          if (i === 0)
            costs[j] = j;
          else {
            if (j > 0) {
              let newValue = costs[j - 1];
              if (s1.charAt(i - 1) !== s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue),
                    costs[j]) + 1;
              costs[j - 1] = lastValue;
              lastValue = newValue;
            }
          }
        }
        if (i > 0)
          costs[s2.length] = lastValue;
      }
      return costs[s2.length];
    },
    similarity(s1, s2) {
      let longer = s1;
      let shorter = s2;
      if (s1 && s2) {
        if (s1.length < s2.length) {
          longer = s2;
          shorter = s1;
        }
        let longerLength = longer.length;
        if (longerLength === 0) {
          return 1.0;
        }
        return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
      }
      return 0
    },
    findSim(text, search, percent = 0.8) {
      let comp = null;
      search.every((e) => {
        let sim = this.similarity(e, text)
        if (sim > percent) comp = e;
        return sim < percent;
      })
      return comp;
    },
    findDiff(compared, structure) {
      let diff = "", $this = this;
      let text = compared.text.replace("&nbsp;", " "), compare = this.findSim(compared.text, structure.map(x => x.text))
      if (compare) {
        compare = compare.split(/\s|&nbsp;/g)
        text.split(/\s|&nbsp;/g).forEach(function (val) {
          let sim = $this.findSim(val, compare, 0.99)
          if (val.toLowerCase() !== (sim || '').toLowerCase()) {
            diff += `<span class="bg-red">${val} </span>`;
          } else diff += val + ' '
        });
      } else diff = `<span class="bg-red">${text}</span>`;
      return diff;
    },
  }
}

</script>
<style scoped>
.card-body {
  max-width: 50%;
}

img {
  max-width: 100%;
}

figcaption {
  font-style: italic;
  color: #777;
  border-left: 1px solid #777;
  padding: 10px;
}

</style>