<template>
  <div v-if="object?.id" class="text-area col">

    <div class="d-flex" style="gap: 15px;">
      <date-picker
          v-model="local_date"
          locale="ru"
          :clearable="false"
          format="dd.MM.yyyy"
          model-type="yyyy-MM-dd"
          auto-apply
          :allowed-dates="allowedDates"
          :enable-time-picker="false"
      ></date-picker>
      <div style="width: 100%" v-if="object.audit_index?.length">
        Вместимость слайда:
        <div class="progress">
          <div :class="'progress-bar' + (object.slide_fullness<=100?' bg-success':' bg-danger')"
               :style="{width: object.slide_fullness+'%'}">{{ object.slide_fullness }}%
          </div>
        </div>
      </div>
    </div>


    <div class="card mt-2">
      <div class="card-body">
        <template v-for="text in object.text_object">
          <div v-if="text.sentences.length"
               :class="'paragraph'+(text.audit?' active':'')">
            <template v-for="sent in text.sentences">
              <p v-if="sent.text.length" @click="sent.clicked = !sent.clicked" :class="sent.clicked?'clicked':''">
                <span v-html="highlightText(sent, status)"></span>
                <button @click="sent.negative = !sent.negative; updateWikiText();">
                  <template v-if="sent.negative">-</template>
                  <template v-else>+</template>
                </button>
              </p>
            </template>
            <button class="paragraph-btn" @click="text.audit = !text.audit; updateWikiText();">
              <template v-if="text.audit">-</template>
              <template v-else>+</template>
            </button>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import {WikiApi} from "@/api/wiki";

export default {
  name: "WikipediaText",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    text: Object,
    allowedDates: Object,
    status: String,
    modelValue: String,
  },

  data() {
    return {
      local_date: null,
      old_local_date: null,
      object: null
    }
  },
  watch: {
    local_date: function (val) {
      if (this.old_local_date != null) {
        this.$emit('update:modelValue', val);
      }
      this.old_local_date = val
    },
    modelValue: function (val) {
      this.local_date = this.modelValue;
    },
    text: function (val) {
      this.object = this.text;
    },
  },
  mounted: function () {


  },
  methods: {
    mergeIndex(intervals) {
      if (!intervals.length) return [];

      intervals.sort((a, b) => a[0] - b[0]);

      const merged = [];
      let [currentStart, currentEnd] = intervals[0];

      for (const [start, end] of intervals.slice(1)) {
        if (start <= currentEnd) {
          currentEnd = Math.max(currentEnd, end);
        } else {
          merged.push([currentStart, currentEnd]);
          currentStart = start;
          currentEnd = end;
        }
      }
      merged.push([currentStart, currentEnd]);
      return merged;

    },
    removeInterval(intervals, remove) {
      const [removeStart, removeEnd] = remove;
      const result = [];

      for (const [start, end] of intervals) {
        if (end < removeStart || start > removeEnd) {
          result.push([start, end]);
        } else {
          if (start < removeStart) {
            result.push([start, removeStart - 1]);
          }
          if (end > removeEnd) {
            result.push([removeEnd + 1, end]);
          }
        }
      }
      return result;
    },
    highlightText(sentence, type) {
      let text = "";
      let words = sentence.text.split(" ");
      if (sentence.negative) text += `<span class="bg-negative">${sentence.text} </span>`;
      else {
        words.forEach((word) => {
          if (this.inIntervals(sentence.start + words.length, sentence.diff)) text += `<span class="bg-${type}">${word} </span>`;
          else text += word + " ";
        })
      }
      if (text.includes("===")) text = `<h4>${text.replaceAll("===", "")}</h4>`
      if (text.includes("==")) text = `<h2>${text.replaceAll("==", "")}</h2>`
      return text;
    },
    updateWikiText() {
      let data = {
        id: this.object.id,
        negative_index: this.object.text_object.map(x => x.sentences).flat(2).filter(x=>x.negative).map(x=>[x.start, x.end]),
        audit_index: this.object.text_object.filter(x => x.audit).map(x => [x.start, x.end]),
      }
      WikiApi.updateText(this.$route.params.wiki_id, data).then(response => this.object = {...this.object, ...response})
    },
    inIntervals(index, intervals) {
      if (typeof intervals[Symbol.iterator] === 'function') {
        for (const [start, end] of intervals) {
          if (index >= start && index <= end) {
            return true;
          }
        }
      }
      return false;
    },
  },
}
</script>

<style scoped>
.text-area .card-body {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .paragraph {
    position: relative;
    cursor: pointer;

    &.active {
      background: rgb(235 230 230 / 65%);

      .paragraph-btn {
        display: flex;
      }
    }

    &:hover {
      background: rgb(235 230 230 / 65%);

      .paragraph-btn {
        display: flex;
      }
    }

    button {
      border-color: #999;
      box-shadow: none;
      border-radius: 5px;
      margin-right: 2px;
      display: none;

      &.paragraph-btn {
        border: 1px solid #ccc;
        position: absolute;
        height: 100%;
        right: -13px;
        top: 0;
        width: 12px;
        padding: 0;
        align-items: center;
        border-radius: 0;
        background: none;
      }
    }

    p {
      display: inline;

      &.clicked {
        font-weight: bold;

        button {
          display: inline;
        }
      }
    }

  }
}
</style>
<style>

</style>
