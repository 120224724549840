<template>
  <div v-if="show" class="ppa_install">
    Добавить приложение на рабочий стол?

    <button class="btn-sm btn btn-success" @click="installPWA">
      Добавить!
    </button>

    <button class="btn-sm btn btn-danger ms-3" @click="dismissPrompt">
      Нет, спасибо
    </button>
  </div>
</template>

<script>
export default {
  name: "PWAPromt",
  computed: {
    show_pwa: {
      get: function () {
        return this.$store.getters.show_pwa;
      },
      set: function (val) {
        localStorage.setItem("pwa", val);
      }

    }
  },
  data: () => ({
    show: false,
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.show = !this.show_pwa
    })
  },

  methods: {
    dismissPrompt() {
      this.show_pwa = true;
      this.show = false;
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt()
        // if (choice.outcome === 'accepted') {
        // } else {
        // }
      })
    },
  }
}
</script>
<style>
.ppa_install {
  position: fixed;
  bottom: 0;
  background: #000;
  color: #fff;
  margin: 10px;
  padding: 10px;
}
</style>