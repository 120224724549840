<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ organization.name }}</td>
    <td :class="($moment().diff($moment(organization.date_end), 'days')>0)?'bg-danger':($moment().diff($moment(organization.date_end), 'days'))>-7?'bg-warning':''">
      {{ organization.date_end }}
    </td>
    <td>
      {{ organization.users_count }}
    </td>
    <td>{{ organization.site }}</td>
    <td class="d-flex">
      <MultiSelect
          v-model="organization.managers"
          :options="managers"
          :required="false"
          name="Менеджеры"
          :only_value="true"
          :multi="true"
      >
      </MultiSelect>
      <a v-if="organization.users_count" class="btn btn-sm btn-outline-dark" :href="loginUrl()" target="_blank">Вход</a>
      <router-link :to="{name: 'clients_edit', params: {client_id: organization.id}}"
                   class="btn btn-sm btn-outline-dark">Просмотр
      </router-link>
    </td>
  </tr>

</template>

<script>
import {OrganizationApi} from "@/api/organization";

export default {
  name: "ClientSmall",
  props: {
    organization: Object,
    managers: Object,
    index: Number
  },
  data() {
    return {}
  },
  watch: {
    "organization.managers": {
      deep: true,
      handler(val) {
        OrganizationApi.update({"id": this.organization.id, "managers": val})
      }
    }
  },
  mounted: function () {

  },
  methods: {
    loginUrl(){
      const hash = btoa(JSON.stringify({jwt: this.$store.getters.jwt, org: this.organization.id}))
      return `${location.protocol}//${this.organization.site}?hash=${hash}`
    }
  },
}
</script>
<style scoped lang="scss">
td.d-flex {
  gap: 10px;
  flex-direction: row;

  .select {
    width: 250px;
  }

  button, a {
    max-height: 35px;
  }
}
</style>
