let validateMixin = {
    data() {
        return {
            validate_name: "project",
            validate: {}
        }
    },
    methods: {
        clearError() {
            let $this = this;
            Object.keys(this[this.validate_name]).forEach(field => {
                $this.$watch(`${$this.validate_name}.${field}`, function (newValue, oldValue) {
                    $this.validateField((field))
                }, {deep: true});
            })
        },
        validateField(field) {
            let object = this[this.validate_name]
            let error = false;
            if (!object?.project_type || this.fieldShow(field)) {
                object[`${field}_error`] = false;
                delete object[`${field}_error`]
                let rule = this.validate[field];
                if (rule) {
                    if (!rule.length) {
                        if (!object[field]) {
                            error = true;
                            object[`${field}_error`] = rule.error ? rule.error : 'Обязательное поле'
                        }
                    } else {
                        rule.forEach(func => {
                            if (func.func(object)) {
                                error = true;
                                object[`${field}_error`] = func.error ? func.error : 'Обязательное поле'
                            }
                        })
                    }
                }
            }
            return error;
        },
        validateData() {
            let error = false;
            for (const field in this.validate) {
                let field_error = this.validateField(field);
                error = field_error ? field_error : error;
            }
            return error;
        },

    }
};
export default validateMixin;
