<template>
  <main class="dashboard">
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">
        <template>Настройки связи проекта с ton-online</template>

      </h1>
      <BackButton></BackButton>
    </div>

    <div>
      <SettingMenu :id="$route.params.id" v-bind:route="$route.name" v-bind:project="{}">
      </SettingMenu>
    </div>

    <form class="needs-validation" novalidate="">

      <div class="row g-3">

        <div class="col-sm-10">

          <div :class="`mt-2 ${checkFieldPerm('research')?'':'area-disabled'}`">
            <label for="name" class="form-label mt-3 "><strong>Проекты ton-online</strong></label>
            <span class="form-group__message d-inline" v-if="research_error">{{ research_error }}</span>
            <MultiSelect
                v-model="research"
                :model_error="research_error"
                :options="ton_projects"
                :multi="true"
                :addValue="addValue"
                :removeValue="removeValue"
                field_name="external_name"
                field_id="external_id"
            >
            </MultiSelect>
          </div>

        </div>
      </div>
    </form>


  </main>
</template>

<script>
import SettingMenu from '@/components/SettingMenu';
import {TonApi} from "@/api/tonOnline";


export default {
  name: 'TrSetting',
  components: {
    SettingMenu
  },
  data() {
    return {
      ton_projects: [],
      research: [],
      research_error: false,
    }
  },
  beforeMount() {
    if (!(this.$store.getters.user.permission.actions.indexOf('project_ton_online_setting') + 1)) this.$router.push({name: '404'});
  },
  mounted: function () {
    TonApi.list().then(response => this.ton_projects = response.map(x => {
      return {external_id: x.external_id, external_name: x.name}
    })).catch(e =>{
      console.log(e)
    })
    TonApi.project(this.$route.params.id).then(response => this.research = response.results)
  },

  methods: {
    checkFieldPerm(name) {
      return this.$store.getters.user.permission.project_fields.indexOf(name) + 1;
    },
    addValue(val) {
      TonApi.post({project: this.$route.params.id, external_id: val.external_id}).then(response =>
        this.research.splice(this.research.findIndex(x => x.external_id === val.external_id), 1, response)
      )
    },
    removeValue(val) {
      TonApi.delete(val.id)
    }
  }

}

</script>
