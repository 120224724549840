import {reactive} from "vue";

import store from "@/store"

export const state = reactive({
    doc: null
});
let user_id = reactive(null);

setTimeout(function (){
    user_id = store.getters.user.id;
    if (user_id) connectWs();

}, 1500)
const URL = `${location.protocol.includes("https")?"wss":"ws"}://${process.env.VUE_APP_TEXT_API}/ws/`
function connectWs() {
    const chatSocket = new WebSocket(
        `${URL}response_channel/${user_id}/`
    );

    chatSocket.onmessage = function (e) {
        let data = JSON.parse(JSON.parse(e.data))
        state.doc = data.object;
    };

    chatSocket.onclose = function (e) {
        if (user_id.value) {
            setTimeout(function () {
                connectWs();
            }, 1000);
        }
    };
    chatSocket.onerror = function (err) {
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        chatSocket.close();
    };
}
