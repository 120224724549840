import {HTTP} from "@/api/common";

const API_URL = `${location.protocol}//${process.env.VUE_APP_TEXT_API}/api/v1/`
export const HTTPText = (method, path, data, config) => {
    return new Promise((resolve, reject) => {
        HTTP({
            url: API_URL + path,
            method: method,
            data: data,
            params: config,
        }).then((response) => {
            resolve(response);
        }).catch((error)=>{
        return reject(error);
    })
    })
}

export default HTTPText;
