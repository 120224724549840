<template>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6">
        <info_block v-bind:data="project.negative" v-bind:images="images" v-bind:status="`danger`"
                    v-bind:show_more="full_info_show" v-bind:country_code="active_country.code"
                    v-bind:project_id="project.id" v-bind:date="date"></info_block>
      </div>
      <div class="col-sm-6">
        <info_block v-bind:data="project.positive" v-bind:images="images" v-bind:status="`success`"
                    v-bind:show_more="full_info_show" v-bind:country_code="active_country.code"
                    v-bind:project_id="project.id" v-bind:date="date"></info_block>
      </div>
    </div>
    <div class="mt-4" v-if="funcAll">
      <button @click="funcAll()" class="btn btn-outline-dark">
        <template v-if="full_info_show">Скрыть</template>
        <template v-else>Показать</template>
        больше выборок
      </button>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="d-flex">
          <div class="card-body ">

            <p class="text-muted">
              <router-link class="text-muted" :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country.code,
                                 engine: '', tone: ['negative'], type_tone: ['new'], kind_type: ['auto'], filter_type: 'new_negative', date: date}}">
                Новая автоматическая нецелевая информация ТОП-20: <b class="text-danger">{{ project.new_negative }} </b>
              </router-link>
              <popper hover>
                <i class="bi bi-question-circle ms-2"></i>
                <template #content>
                     <span class="popper text-start">
                      Новые уникальные ссылки впервые появившиеся в выдаче в ТОП 20 размеченные автоматически
                    </span>
                </template>
              </popper>
            </p>

            <p class="text-muted">
              <router-link class="text-muted" :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country.code,
                                 engine: '', tone: [], kind_type: ['auto'], date: date}}">
                Автоматически размечено в ТОП-20: <b class="text-primary">{{ project.auto_mark }} </b>
              </router-link>
              <popper hover>

                <i class="bi bi-question-circle ms-2"></i>
                <template #content>
                      <span class="popper text-start">
                      Уникальные ссылки в ТОП 20 размеченные автоматически
                    </span>
                </template>
              </popper>
            </p>
            <p class="text-muted">
              <router-link class="text-muted" :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country.code,
                                 engine: '', tone: ['null'], date: date}}">
                Неразмеченных ссылок в ТОП-20: <b class="text-danger">{{ project.no_mark }} </b>
              </router-link>
              <popper hover>
                <i class="bi bi-question-circle ms-2"></i>
                <template #content>
                      <span class="popper text-start">
                      Уникальные ссылки в ТОП 20 не размеченные автоматически
                    </span>
                </template>
              </popper>
            </p>
            <p v-if="project.no_content !== undefined" class="text-muted">
              <router-link class="text-muted" :to="{ name: 'links_info', params: { id: project.id }, query: { country_code: active_country.code,
                                 engine: '', tone: ['error'], date: date}}">
                Ссылок не получивших контент: <b class="text-danger">{{ project.no_content }} </b>
              </router-link>
              <popper hover>
                <i class="bi bi-question-circle ms-2"></i>
                <template #content>
                      <span class="popper text-start">
                      Ссылки выдавшие ошибки при получении контента
                    </span>
                </template>
              </popper>
            </p>
            <p v-if="project.no_check !== undefined">
              <router-link class="text-muted" :to="{ name: 'links_info', params: { id: project.id }, query: { country_code: active_country.code,
                                 no_check: true, date: date}}">
                Непроверенных ссылок: <b class="text-danger">{{ project.no_check }} </b>
              </router-link>
              <popper hover>
                <i class="bi bi-question-circle ms-2"></i>
                <template #content>
                      <span class="popper text-start">
                      Непроверенные ссылки
                    </span>
                </template>

              </popper>
            </p>
            <p v-if="project.no_mark_image !== undefined" class="text-muted">Неразмеченных изображений в ТОП-20:
              <router-link :to="{ name: 'images_info', params: { id: project.id }, query: {image_info: 1, state: 20, image: true,
                                 engine: '', tone: ['null'], date: date}}">
                <b class="text-danger">{{ project.no_mark_image }} </b>
              </router-link>
            </p>
            <p v-if="project.no_mark_pict !== undefined" class="text-muted">Неразмеченных изображений в ТОП-20:
              <router-link :to="{ name: 'images_info', params: { id: project.id },query: {state:  20, country_code: active_country.code,
                                 engine: '', tone: ['null'], date: date}}">
                <b class="text-danger">{{ project.no_mark_pict }} </b>
              </router-link>
            </p>

          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card-body" v-if="project.id">
          <SmallReport v-bind:date_start="date"
                       v-bind:date="date"
                       v-bind:simpleProject="project"
                       v-bind:state="state"
                       v-bind:date_config="date_config"
                       v-bind:allDates="allowedDates?allowedDates:[]"
                       v-bind:country_id="active_country.id"></SmallReport>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallReport from '@/components/SmallReport.vue';
import InfoBlock from '@/components/InfoBlock.vue';

export default {
  name: "sermDetail",
  props: ['project', 'date', 'state', 'images', 'date_config', 'active_country', 'funcAll', 'full_info_show', 'allowedDates'],
  components: {
    'info_block': InfoBlock, SmallReport
  },
}
</script>

<style scoped>
.date {
  height: 100px;
  margin: 20px 0;
}

.card-body {
  padding: 15px;
}

</style>
