<template>
  <main class="dashboard links">
    <div class="mb-4">
      <h2>Уникальные изображения: {{ project.name }}</h2>
      <BackButton></BackButton>
    </div>
    <div class="d-flex filters mb-5">
      <MultiSelect v-model="filter.tone"
                   :options="Object.keys($TONES).filter(x => !$TONES[x].no_filter).map(x => {
        return {id: x, name: $TONES[x].name}
      })"
                   :multi="false"
                   :only_value="true"
                   name="Тональность"></MultiSelect>

      <MultiSelect
              v-model="filter.state"
              :options="$STATES"
              :multi="false"
              :only_value="true"
              name="ТОП"></MultiSelect>
       <CountryChoice v-if="active_country.code"
                             v-bind:active_country="active_country"
                             v-bind:project_id="$route.params.id"
                             v-bind:countries="project.countries"
                             v-bind:changeCountry="changeCountry"></CountryChoice>
      <button class="btn btn-sm btn-warning" title="Выберите изображения для объединения" @click="mergeImage"
                      :disabled="!select_images.length">Объединить</button>
    </div>


        <div class="table-responsive" style="height: calc(100vh - 250px)">
          <table class="table">
            <thead>
            <tr class="sticky-top">
              <th scope="col">№ п/п</th>
              <th scope="col">Уникальное изображение</th>
              <th scope="col">Тон картинки</th>
              <th scope="col" style="min-width: 300px">Запрос/Позиции/Ссылки</th>


            </tr>
            </thead>
            <tbody>
            <template v-for="(link, index) in links">
              <tr>
                <td>
                  <input type="checkbox"
                         v-model="select_images" :value="link.id"
                  >
                  {{ index + 1 + (page - 1) * size }}
                </td>
                <td>
                  <a :href="link.image" target="_blank"
                     :style="`background: url(${link.image}) center center / contain no-repeat; width: 150px; height: 150px; display: block;`">
                  </a>
                </td>
                <td>
                  <ul class="dropdown list">
                    <template v-for="(tone, key) in $TONES">
                      <li v-if="!tone.no_filter"
                          v-on:click="link.get_tone_display=tone.name; link.tone=key; updateImage(link);"
                          :class="`dropdown-item ${(tone.synonym.indexOf(link.tone)+1)?`active bg-${key}`:''}`">
                        {{ tone.name }}
                      </li>
                    </template>
                  </ul>
                </td>

                <td>
                  <!--                  <div class="keys row pe-2">-->
                  <!--                    <div class="key col-10"></div>-->
                  <!--                    <div class="col-1 text-center"><i class="yandex"></i></div>-->
                  <!--                    <div class="col-1 text-center"><i class="google"></i></div>-->
                  <!--                  </div>-->
                  <div class="pe-2" v-for="keyword in link.similar">
                    <div class="">{{ keyword.name }}:</div>
                    <div class="" v-for="(place, index) in keyword.place">
                      <i :class="place.engine"></i> {{ place.place }}
                      <a :href="place.link" target="_blank">{{ place.link }}</a>
                    </div>
                  </div>

                </td>
              </tr>
            </template>
            </tbody>
          </table>
          <nav>
            <ul class="pagination justify-content-center fz-9" v-if="pager.length">
              <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
                <a v-if="typeof p === 'number'" class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
                <span class="page-link" v-else>{{ p }}</span>
              </li>
            </ul>
          </nav>


        </div>


  </main>
</template>

<script>
import {ProjectApi, LinkApi} from '@/api/project'

export default {
  name: 'ProjectImages',
  components: {},

  data() {
    return {
      project: {},
      links: [],
      page: 1,
      select_images: [],
      size: 50,
      all_count: 0,
      active_country: {code: null, yandex: true, google: true},
      filter: {
        state: 100,
        tone: ''
      },
    }
  },
  watch: {
    filter: {
      deep: true,
      handler(val) {
        this.page = 1;
        this.getLinks();
      }
    },
    page: {
      deep: true,
      handler(val) {
        this.getLinks();
      }

    },
  },
  computed: {
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      let pages = Array.from({length: page_count}, (_, i) => i + 1);
      let array = [];
      if (this.page > 5) {
        array = array.concat(pages.slice(0, this.page - 3 > 3 ? 3 : this.page - 3));
        array.push('...')
      }
      array = array.concat(pages.slice(this.page - 4 > 0 ? this.page - 4 : 0, this.page + 3));
      if (this.page < (pages.length - 5)) {
        array.push('...');
        array = array.concat(pages.slice(pages.length - 3, pages.length));
      }
      return array;
    },
  },
  mounted: function () {
    let $this = this, data = this.$route.query;
    this.active_country.code = data.country_code || "RU";
    ProjectApi.get_simple($this.$route.params.id).then(response => {
      $this.project = response;
      $this.getLinks();
    })

  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
      }
      this.getLinks();
    },
    mergeImage() {
      let first_image, first_index, $this = this;
      this.select_images.forEach(image_id => {
        let image = this.links.find(x => x.id === image_id), image_index = this.links.findIndex(x => x.id === image.id);
        if (!first_image) {
          first_image = image
        } else {
          this.links.splice(image_index, 1)
        }
      });
      ProjectApi.merge_image($this.$route.params.id, {ids: this.select_images}).then(response => {
        $this.select_images = []
        $this.links.splice($this.links.findIndex(x => x.id === first_image.id), 1, response.image)
      })
    },
    getLinks() {
      let $this = this, data = this.$route.query;
      let query = {...data, ...this.filter}
      query.page = $this.page;
      query.size = $this.size;
      query.country_code = $this.active_country.code;
      query.q = $this.q;
      ProjectApi.uniq_images($this.$route.params.id, query).then(response => {
        $this.links = response.results;
        $this.all_count = response.count;
      })
    },
    changeTone: function (tone, link) {
      let $this = this;
      LinkApi.update_uniq_image(link).then(response => {
        $this.links.splice($this.links.indexOf(link), 1, response);
        $this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    },
    updateImage: function (link) {
      let $this = this;
      link.type = 'obj'
      LinkApi.update_image(link).then(_ => {
        $this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    },


  }
}

</script>
