<template>
  <main class="dashboard">
    <div class="mb-4 row">
      <h2 class="col">Статистика страницы Wikipedia <br>
        {{ project.name }} <a v-if="wiki.link" :href="wiki.link" target="_blank">{{ decodeURI(wiki.link) }}</a></h2>
      <small class="col-4 text-end">
        Создание {{ $moment(wiki.date_create).format("DD/MM/YYYY") }} <br>
        Последнее редактирование {{ $moment(wiki.date_create).format("DD/MM/YYYY") }}
        <button class="btn btn-sm btn-outline-dark" @click="startParser">Обновить данные</button>
      </small>
    </div>
    <div class="mb-4 row">
      <h2 class="col">Сравнение версий страниц</h2>
      <small class="col">
        <span class="legend">
        <span class="negative">Нецелевая информация</span>
        <span class="new">Новая добавленная информация</span>
        <span class="delete">Удалена в более новой версии</span>
          </span>
      </small>
    </div>
    <div class="row text-area">
      <WikipediaText
          :text="object"
          :allowedDates="allowedDates"
          status="delete"
          v-model="date"
      ></WikipediaText>
      <WikipediaText
          :text="compare"
          :allowedDates="allowedDates"
          status="new"
          v-model="date_compare"
      ></WikipediaText>

    </div>


  </main>
</template>

<script>
import moment from "moment";
import {ProjectApi} from "@/api/project";
import {WikiApi} from "@/api/wiki";
import WikipediaText from "@/views/projects/wikipedia/WikipediaText.vue";

export default {
  name: "WikipediaView",
  components: {
    WikipediaText
  },

  data() {
    return {
      old_date: null,
      date: null,
      date_compare: null,
      wiki: {subscribe: false},
      object: {},
      compare: {},
      project: {},
      allowedDates: []
    }
  },
  watch: {
    date: function (val) {
      if (this.old_date != null) this.getWiki();
      this.old_date = val
    },
    date_compare: function (val) {
      if (this.old_date != null) this.getWiki();
      this.old_date = val
    },
  },
  mounted: function () {
    this.getProject();
  },
  methods: {

    inIntervals(index, intervals) {
      if (typeof intervals[Symbol.iterator] === 'function') {
        for (const [start, end] of intervals) {
          if (index >= start && index <= end) {
            return true;
          }
        }
      }
      return false;
    },
    findIntervals(intervals, target) {
      const [targetStart, targetEnd] = target;
      const result = [];

      for (const [start, end] of intervals) {
        if (start <= targetStart && end >= targetEnd) {
          result.push([start, end]);
        }
      }

      return result;
    },
    mergeIndex(intervals) {
      if (!intervals.length) return [];

      intervals.sort((a, b) => a[0] - b[0]);

      const merged = [];
      let [currentStart, currentEnd] = intervals[0];

      for (const [start, end] of intervals.slice(1)) {
        if (start <= currentEnd) {
          currentEnd = Math.max(currentEnd, end);
        } else {
          merged.push([currentStart, currentEnd]);
          currentStart = start;
          currentEnd = end;
        }
      }
      merged.push([currentStart, currentEnd]);
      return merged;

    },
    textToObject(text) {
      let data = [];
      let paragraphs = text.text.split("\n");
      let start = 0;
      paragraphs.forEach((paragraph) => {
        let paragraph_data = {
          start: start,
          end: start + paragraph.length,
          sentences: [],
          audit: false
        }
        const segmenter = new Intl.Segmenter([], { granularity: "sentence" });
        let sentences = segmenter.segment(paragraph)[Symbol.iterator]();
        sentences = [...sentences];
        if (this.inIntervals(start, text.audit_index)) paragraph_data.audit = true;

        let sentence_start = start;
        if (sentences.length) {

          sentences.forEach((sentence) => {
            paragraph_data.sentences.push(
                {
                  text: sentence.segment,
                  start: sentence_start,
                  end: sentence_start + sentence.segment.length,
                  negative: this.inIntervals(sentence_start, text.negative_index)
                }
            )
            sentence_start += sentence.segment.length + 1;
          })
        }
        start += paragraph.length + 1
        data.push(paragraph_data)
      })
      return data;
    },
    markHighlight(text1, text2) {
      text1.text_object.forEach((paragraph, index) => {
        paragraph.sentences.forEach((sentence, s_index) => {
          sentence.diff = [];
          let compare = text2.text_object[index] && text2.text_object[index].sentences[s_index] ? text2.text_object[index].sentences[s_index] : null;
          if (compare) {
            let comp_words = compare.text.split(' '), words = sentence.text.split(' '), current_index = sentence.start;
            words.forEach((word, w_index) => {
              if (word !== comp_words[w_index]) {
                sentence.diff.push([current_index, current_index + word.length])
              }
              current_index += word.length + 1
            })


          } else sentence.diff.push([sentence.start, sentence.end])
        })
      })
    },
    f_date(date) {
      return date ? moment(date).format('YYYY-MM-DD') : undefined;
    },
    getWiki: function () {
      const $this = this;
      WikiApi.detail($this.$route.params.wiki_id, this.f_date(this.date), this.f_date(this.date_compare)).then(response => {
        if (response.object?.date_create) {
          $this.object = response.object;
          $this.object.text_object = $this.textToObject($this.object);
          $this.date = $this.$moment($this.object.date_create).format('YYYY-MM-DD')
        }
        if (response.compare?.date_create) {
          $this.compare = response.compare;
          $this.compare.text_object = $this.textToObject($this.compare);
          $this.date_compare = $this.$moment($this.compare.date_create).format('YYYY-MM-DD')
        }
        if ($this.object.id) $this.markHighlight($this.object, $this.compare);
        if ($this.compare.id) $this.markHighlight($this.compare, $this.object);
        WikiApi.active_dates(this.$route.params.wiki_id).then(response => this.allowedDates = response.map(d => moment(d, 'DD/MM/YYYY').format('YYYY-MM-DD')))
      })
    },
    getProject: function () {
      let $this = this;
      ProjectApi.get_simple($this.$route.params.id).then(response => {
        $this.project = response;
        WikiApi.get($this.$route.params.wiki_id).then(response => {
          $this.wiki = {...$this.wiki, ...response}
          $this.getWiki()
        })

      })
    },
    startParser() {
      if (this.wiki.id) WikiApi.start_parser(this.wiki.id).then(() => this.getWiki())
    }


  }
  ,
}
</script>

<style scoped>
.legend {
  display: flex;
  gap: 11px;

  span {
    position: relative;
    white-space: nowrap;
    font-weight: 400;
    line-height: 23px;
    margin-left: 30px;

    &:before {
      content: "";
      display: block;
      height: 20px;
      width: 20px;
      position: absolute;
      left: -31px;
      border-radius: 4px;
      background: rgba(234, 234, 234, 1);
    }

    &.negative:before {
      background: rgba(254, 66, 45, 0.25);
    }

    &.new:before {
      background: rgba(252, 189, 6, 0.25);
    }
  }
}


</style>
<style>
.bg-new {
  background: rgba(252, 189, 6, 0.25);
}

.bg-delete {
  background: rgba(234, 234, 234, 1);
  text-decoration: line-through;
}
</style>