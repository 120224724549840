import { HTTP } from "./common";

export const PhraseApi = {

  list(id) {
    return HTTP.get(
      `phrases/${id}/get_phrases/`
    ).then((response) => {
      return response.data;
    });
  },
  add_phases(id, data) {
    return HTTP.post(
      `phrases/${id}/add_phases/`, data
    ).then((response) => {
      return response.data;
    });
  },
  remove_phases(id, data) {
    return HTTP.post(
      `phrases/${id}/remove_phases/`, data
    ).then((response) => {
      return response.data;
    });
  },
  start_search(id, data) {
    return HTTP.post(
      `phrases/${id}/start_search/`, data
    ).then((response) => {
      return response.data;
    });
  },


};
