<template>
  <main class="dashboard links">
    <div class="mb-4 row">
      <h2 class="col">Подсказки: {{ project.name }}</h2>
      <small class="col-4 text-end" v-if="allowedDates">
        Последний сбор подсказок {{ allowedDates[allowedDates.length - 1] }}<br>
        Следующий сбор подсказок {{ next_monday() }}
      </small>
    </div>
    <div class="mb-5">
      <div class="d-flex filters">
        <CountryChoice v-if="project.countries" v-bind:active_country.sync="active_country"
                       v-bind:project_id="$route.params.id"
                       v-bind:countries="project.countries.filter(x=>x.hint)"
                       v-bind:changeCountry="changeCountry"></CountryChoice>
        <date-picker
            v-model="filter.date_start"
            locale="ru"
            :clearable="false"
            format="dd.MM.yyyy"
            model-type="yyyy-MM-dd"
            auto-apply
            :allowed-dates="allowedDates"
            :enable-time-picker="false"></date-picker>

        <date-picker
            v-model="filter.date_end"
            locale="ru"
            :clearable="false"
            format="dd.MM.yyyy"
            model-type="yyyy-MM-dd"
            auto-apply
            :allowed-dates="allowedDates"
            :enable-time-picker="false"></date-picker>
      </div>
      <div class="d-flex filters">
        <button type="button" v-for="eng in $ENGINES"
                @click="engine = eng"
                :class="'btn btn-sm no-wrap ' + (engine===eng?'btn-dark':'btn-outline-dark')">
          <i :class="eng"></i> {{ eng }}
        </button>

        <MultiSelect
            v-if="!!filter_keyword"
            v-model="filter.keyword"
            :options="filter_keyword"
            :multi="true"
            name="Запроc">
        </MultiSelect>


        <MultiSelect
            v-model="filter.hint"
            :options="uniq_hints"
            :multi="true"
            field_name="hint"
            name="Уникальная подсказка"></MultiSelect>

      </div>
      <div class="row mt-3" style="align-items: end">
        <div class="col d-flex filters">
          <div class="d-flex filters">
            <span>Пересобрать</span>
            <button @click="startHintParser()" v-if="$store.getters.user.permission.actions.indexOf('restart_hint') + 1"
                    class="btn btn-outline-dark float-right"><i class="bi bi-arrow-clockwise"></i>
            </button>

          </div>

          <router-link :to="{name: 'hint_screen', params: { id: $route.params.id }}"
                       class="btn btn-outline-primary float-right ms-4">Скриншоты выдачи
          </router-link>

        </div>
        <SmallReport
            class="col"
            v-if="project.id && filter.date_start && filter.date_end"
            v-bind:simpleProject="project"
            v-bind:date_start="filter.date_start"
            v-bind:date="filter.date_end"
            v-bind:state="20"
            type="line"
            :allDates="allowedDates"
            v-bind:country_id="active_country.id"></SmallReport>
      </div>

    </div>

    <div class="card-body">


      <div class="table-responsive d-flex">
        <table class="table">
          <thead>
          <tr>
            <th scope="col" class="no-wrap">
              <MultiSelect
                  v-model="table_filter.keyword"
                  v-bind:options="table_keywords"
                  v-bind:multi="true"
                  name="Запрос"></MultiSelect>
            </th>
            <th scope="col" class="no-wrap">
              <MultiSelect
                  v-model="table_filter.hint"
                  :options="table_hints"
                  :multi="true"
                  name="Подсказка"></MultiSelect>
            </th>
            <th scope="col" class="no-wrap">
              <MultiSelect
                  v-model="table_filter.tone"
                  :options="table_tones"
                  :multi="true"
                  name="Тональность"></MultiSelect>
            </th>
            <th scope="col" :colspan="dates.length">
              {{ table_data.length }} из {{ count }}
            </th>
          </tr>
          <tr class="sticky-top">
            <th scope="col" class="no-wrap">Запрос
              <i v-on:click="changeOrder('keyword_id');" :class="`link ${getOrderClass('keyword_id')}`"></i></th>
            <th scope="col">Подсказка
              <i v-on:click="changeOrder('hint');" :class="`link ${getOrderClass('hint')}`"></i></th>
            <th scope="col" class="no-wrap">Тональность
              <i v-on:click="changeOrder('tone');" :class="`link ${getOrderClass('tone')}`"></i>
            </th>
            <th scope="col" class="text-center no-wrap" v-for="date in dates">{{ date }}
              <i v-on:click="changeOrder(date);"
                 :class="`link ${getOrderClass(date)}`"></i>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="hint in table_data">
            <td class="link">
              {{ hint.keyword.replace(new RegExp(" $"), "_") }}
            </td>
            <td>
              <span :style="$func.getClass(hint.tone, false)">{{ hint.hint }}</span>
            </td>
            <td>
              <ul class="dropdown list">
                <template v-if="$store.getters.user.permission.actions.indexOf('edit_hint_tone') + 1">
                  <template v-for="(tone, key) in $TONES">
                    <li v-if="!tone.no_filter"
                        v-on:click="hint.tone = key; changeTone(hint);"
                        :class="`dropdown-item ${(hint.tone === key)?`active bg-${key}`:''}`">
                      {{ tone.name }}
                    </li>
                  </template>
                </template>
                <template v-else>
                  <li :class="`dropdown-item active bg-${hint.tone}`">
                    {{ hint.get_tone_display }}
                  </li>
                </template>
              </ul>
            </td>
            <td v-for="date in dates">
                  <span class="me-2" :style="$func.getClass(hint.tone, false)" v-if="hint[date].place">
                    {{ hint[date].place }}
                    <sub v-if="hint[date].diff"
                         :style="$func.getClass(hint[date].diff>0?'positive':'negative', false)">
                      <template v-if="hint[date].diff>0">+</template><template
                        v-else></template> {{ hint[date].diff }}
                    </sub>
                  </span>
              <span v-else>-</span>
            </td>

          </tr>

          </tbody>
        </table>


      </div>
    </div>
  </main>
</template>

<script>
import SmallReport from '@/components/SmallReport';
import {ProjectApi} from "@/api/project";
import {HintApi} from "@/api/hints";
import moment from "moment"


export default {
  name: 'ProjectHints',
  components: {
    SmallReport
  },
  data() {
    return {
      project: {id: ''},
      hints: [],
      order_by: 'keyword_id',
      count: 0,
      table_filter: {
        keyword: [], hint: [], tone: []
      },
      filter: {
        date_start: null,
        date_end: null, keyword: [], hint: []
      },
      engine: 'yandex',
      keywords: [],
      dates: [],
      active_country: {code: null, yandex: true, google: true},
      allowedDates: []
    }
  },
  computed: {
    filter_keyword() {
      return this.keywords.filter(x => !x.countries.length || (x.countries.map(c => c.code).indexOf(this.active_country.code) + 1))
    },
    uniq_hints() {
      let hints = [];
      if (this.filter.keyword.length) hints = this.filter.keyword.map(x => x.hints).flat(1);
      else hints = this.filter_keyword.map(x => x.hints).flat(1);
      return hints.filter((obj1, i, arr) =>
          arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
      )
    },
    table_tones: function () {
      let data = Object.keys(this.$TONES).filter(x => !this.$TONES[x].no_filter).map(x => {
        return {id: x, name: this.$TONES[x].name}
      })
      data.push({id: null, name: 'Неразмечена'})
      return data
    },
    table_keywords() {
      let keywords = [];
      this.table_data.forEach(e => keywords.push(JSON.stringify({id: e.keyword_id, name: e.keyword})))
      return Array.from(new Set(keywords), JSON.parse);
    },
    table_hints() {
      let hints = [];
      this.table_data.forEach(function (h) {
        hints.push(JSON.stringify({id: h.hint_obj_id, name: h.hint}))
      })
      return Array.from(new Set(hints), JSON.parse)
    },
    table_data() {
      let $this = this, order = this.order_by, data = [];
      let hints = this.hints.filter(x => x.engine === this.engine)
      if (this.filter.keyword.length) {
        let keyword_ids = this.filter.keyword.map(x => x.id)
        hints = hints.filter(x => keyword_ids.indexOf(x.keyword_id) + 1)
      }
      if (this.filter.hint.length) {
        let hints_ids = this.filter.hint.map(x => x.id)
        hints = hints.filter(x => hints_ids.indexOf(x.hint_obj_id) + 1)
      }
      this.dates = [...new Set(hints.map(x => moment(x.date_create).format("DD.MM.YYYY")))];
      hints = hints.filter((obj1, i, arr) =>
          arr.findIndex(obj2 => (obj2.hint_obj_id === obj1.hint_obj_id && obj2.keyword_id === obj1.keyword_id)) === i
      )
      hints.forEach(hint => {
        this.dates.forEach((date, index) => {
          let place = this.hints.find(x => x.hint_obj_id === hint.hint_obj_id && x.keyword_id === hint.keyword_id && moment(x.date_create).format("DD.MM.YYYY") === date);
          place = place?.place || 0
          hint[date] = {place: place}
          if (index && place) hint[this.dates[index - 1]].diff = place - hint[this.dates[index - 1]].place
        })
      })
      this.count = hints.length;
      if (order) {
        if (order.indexOf('-') + 1) {
          order = order.slice(1);
          hints.sort(function (a, b) {
            return $this.compareObjects(b, a, order);
          });
        } else {
          hints.sort(function (a, b) {
            return $this.compareObjects(a, b, order);
          });
        }
      }
      if ($this.table_filter.keyword.length) {
        let ids = $this.table_filter.keyword.map(k => k.id)
        hints = hints.filter(key => ids.includes(key.keyword_id));
      }
      if ($this.table_filter.hint.length) {
        let ids = $this.table_filter.hint.map(k => k.id)
        hints = hints.filter(key => ids.includes(key.hint_obj_id));
      }
      if ($this.table_filter.tone.length) {
        let ids = $this.table_filter.tone.map(k => k.id?.toLowerCase())
        hints = hints.filter(key => ids.includes(key.tone));
      }
      return hints
    }
  },
  watch: {
    "filter.date_end"(newVal, oldVal) {
      if (!!oldVal) this.getHints()
    },
    "filter.date_start"(newVal, oldVal) {
      if (!!oldVal) this.getHints()
    }
  },

  mounted: function () {
    this.getProject()

  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.getHints()
      }
    },
    compareObjects: function (object1, object2, key) {
      const obj1 = object1[key] != null ? typeof object1[key] == 'string' ? object1[key].toUpperCase() : JSON.stringify(object1[key]) : '';
      const obj2 = object2[key] != null ? typeof object2[key] == 'string' ? object2[key].toUpperCase() : JSON.stringify(object2[key]) : '';
      if (obj1 < obj2) {
        return -1
      }
      if (obj1 > obj2) {
        return 1
      }
      return 0
    },

    changeOrder: function (name) {
      if (this.order_by === name) {
        if (name.indexOf('-') + 1) this.order_by = name.slice(1);
        else this.order_by = '-' + name
      } else this.order_by = name
    },
    getOrderClass: function (name) {
      let o_class = 'bi bi-caret';
      if (this.order_by.indexOf(name) + 1) {
        if (this.order_by.indexOf('-') + 1) o_class += '-up';
        else o_class += '-down';
        o_class += '-fill'
      } else o_class += '-down';
      return o_class;
    },
    getProject: function () {
      let $this = this;
      ProjectApi.get_simple($this.$route.params.id).then(response => {
        $this.project = response;
        $this.active_country = $this.project.active_country_code;
        HintApi.get_avail_date_calendar(this.$route.params.id).then(r => {
          $this.allowedDates = r;
          if (!$this.filter.date_end) {
            let date = moment($this.allowedDates[$this.allowedDates.length - 1])
            $this.filter.date_end = date.format("YYYY-MM-DD");
            $this.filter.date_start = date.add(-7, "days").format("YYYY-MM-DD");
            $this.getHints()
          }
        })
        HintApi.get_filter_hints($this.$route.params.id, $this.active_country.code).then(response => {
          $this.keywords = response;
        });
      });
    },
    startHintParser: function () {
      let $this = this;
      HintApi.manual_request_result($this.$route.params.id).then(response => {
        if (response.type.trim() === 'success') {
          $this.getHints();
        }
        $this.$notify(response);

      });
    },
    getHints: function () {
      let $this = this, filter = structuredClone(this.filter);
      filter.project = $this.$route.params.id;
      filter.country_code = $this.active_country.code;
      let f_string = new URLSearchParams(filter).toString();
      HintApi.list(f_string).then(response => {
        $this.hints = response;
      })

    },
    changeTone: function (hint) {
      let $this = this;
      HintApi.update_tone({id: hint.hint_obj_id, tone: hint.tone}).then(response => {
        $this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Данные обновлены'
        });
        $this.hints.forEach(function (h) {
          if (h.hint_obj_id === hint.hint_obj_id) {
            h.tone = hint.tone;
          }
        })
      })
    },
    next_monday() {
      return moment().add(1, "days").format('YYYY-MM-DD');
    }

  }


}

</script>
<style scoped>

</style>
