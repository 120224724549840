import {HTTP} from "./common";

const BASE_URL = 'mobile/'
export const MobileApi = {
    list(project_id) {
        return HTTP.get(`${BASE_URL}projects/${project_id?`?project_id=${project_id}`:''}`).then((response) => {
            return response.data;
        });
    },
    get(id, country_code, date) {
        return HTTP.get(`${BASE_URL}projects/${id}/?country_code=${country_code}${date ? `&date=${date}` : ''}`).then((response) => {
            return response.data;
        });
    },
    keywords(id, date, country_code) {
        return HTTP.get(
            `${BASE_URL}projects/${id}/keywords/?date=${date}&country_code=${country_code}`
        ).then((response) => {
            return response.data;
        });
    },
};
