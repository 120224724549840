<template>
  <main class="dashboard links">
    <div class="mb-4">
      <h2>{{ object.name }}: Уникальные изображения</h2>
      <BackButton></BackButton>
    </div>
    <div class="d-flex filters mb-5">
      <div class="form-group">
        <label>Тональность</label>
        <select class="form-select" v-model="tone">
          <option value=""></option>
          <option v-for="(tone, key) in $TONES" v-if="!tone.no_filter" :value="key">{{ tone.name }}</option>
        </select>
      </div>
      <div class="form-group ps-sm-2">

        <label>Поиск по ссылке</label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" v-model="query" aria-describedby="button-query">
          <button class="btn btn-outline-secondary" v-on:click.prevent="getLinks()" type="button"
                  id="button-query"><i class="bi bi-search"></i></button>

        </div>
      </div>

    </div>


    <div class="table-responsive" style="height: calc(100vh - 250px)">
      <table class="table">
        <thead>
        <tr class="sticky-top">
          <th scope="col">№ п/п</th>
          <th scope="col">Уникальное изображение</th>
          <th scope="col">Тон картинки</th>
          <th scope="col" style="min-width: 300px">Запрос/Позиции/Ссылки</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(link, index) in pictures">
          <td>
            {{ index + 1 + (page - 1) * size }}
          </td>
          <td>
            <a :href="link.image" target="_blank"
               :style="`background: url(${link.image}) center center / contain no-repeat; width: 150px; height: 150px; display: block;`">
            </a>
          </td>
          <td>
            <ul class="dropdown list">
              <template v-for="(tone, key) in $TONES">
                <li v-if="!tone.no_filter"
                    v-on:click="link.tone=key; link.get_tone_display=tone.name; changeTone(link)"
                    :class="`dropdown-item ${(tone.synonym.indexOf(link.tone)+1)?`active bg-${key}`:''}`">
                  {{ tone.name }}
                </li>
              </template>
            </ul>
          </td>
          <td>
            <div class="pe-2" v-for="keyword in link.similar">
              <div class="">{{ keyword.name }}:</div>
              <div class="" v-for="(place, index) in keyword.place">
                <i :class="place.engine"></i> {{ place.place }}
                <a :href="place.link" target="_blank">{{ place.link }}</a>
              </div>
            </div>
          </td>

        </tr>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination justify-content-center fz-9" v-if="pager.length">
        <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
          <a class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
        </li>
      </ul>
    </nav>

  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import {PictureApi} from '@/api/pictures'

export default {
  name: 'UniqPicture',
  components: {},

  data() {
    return {
      object: {},
      pictures: [],
      next: true,
      z_index: 0,
      page: 1,
      off_count: false,
      tone: '',
      size: 20,
      all_count: 0,
      query: '',
      tops: {
        10: 'ТОП 10', 20: 'ТОП 20',
        50: 'ТОП 50', 100: 'ТОП 100'
      },
    }
  },
  computed: {
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      let pages = Array.from({length: page_count}, (_, i) => i + 1);
      let array = [];
      if (this.page > 5) {
        array = array.concat(pages.slice(0, this.page - 3 > 3 ? 3 : this.page - 3));
        array.push('...')
      }
      array = array.concat(pages.slice(this.page - 4 > 0 ? this.page - 4 : 0, this.page + 3));
      if (this.page < (pages.length - 5)) {
        array.push('...');
        array = array.concat(pages.slice(pages.length - 3, pages.length));
      }

      return array;
    },

  },
  watch: {
    tone: {
      deep: true,
      handler(val) {
        this.page = 1;
        this.next = true;
        this.getLinks();
      }

    },
    page: {
      deep: true,
      handler(val) {
        this.getLinks();
      }

    }
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_simple($this.$route.params.id).then(response => {
      $this.object = response;
      $this.getLinks();
    })

  },
  methods: {
    getLinks() {
      let $this = this;
      PictureApi.list_uniq($this.$route.params.id, $this.tone, $this.query, $this.page, $this.size).then(response => {
        $this.pictures = response.results;
        $this.all_count = response.count;
      })
    },
    getClass: function (tone) {
      let COLORS = this.$COLORS;
      return `color: ${COLORS[tone] !== undefined ? COLORS[tone] : '#000000'}`;
    },
    setEditComment: function (link, name, val) {
      this.$func.setValue(link, name, val);
    },
    changeTone: function (result) {
      let $this = this;
      PictureApi.update(result.id, result).then(response => {
        $this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    },

  }
}

</script>


