<template>
  <main class="dashboard links">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h2>Результаты сбора</h2>

    </div>
    <div class="filters d-flex">
      <date-picker
          v-model="active_date"
          locale="ru"
          :clearable="false"
          format="dd.MM.yyyy"
          model-type="dd/MM/yyyy"
          auto-apply
          :enable-time-picker="false"
      ></date-picker>
      <button class="col-1 ms-1 btn btn-outline-dark" @click="getData(active_date)">Показать</button>
    </div>
     <CountryChoice
        v-bind:active_country.sync="active_country"
        v-bind:countries="countries"
        v-bind:changeCountry="(country)=>{active_country = country}"></CountryChoice>


        <div class="table-responsive">
          <table class="table">
            <thead class="sticky-top">
            <tr>
              <th>Проект</th>
              <th>Запрос</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="project in projects">
              <td>{{ project.name }} ({{project.user__organization}})
                <button @click="bindLink(project.id)" class="btn btn-warning btn-sm text-sm text-white" title="связать ссылки" v-if="$store.getters.user.is_superuser">
                  <i class="bi bi-link-45deg"></i></button>
              </td>
              <td>
                <table class="table">
                  <tr v-for="key in project.keys">
                    <td>{{ key.name }}</td>
                    <td :class="!key.y?'bg-danger':key.y < 50?'bg-warning':''"><i class="yandex"></i>{{ key.y }}</td>
                    <td :class="!key.g?'bg-danger':key.g < 50?'bg-warning':''"><i class="google"></i>{{ key.g }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>


  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import moment from "moment";

export default {
  name: 'ServicePage',

  data() {
    return {
      active_date: moment().format('DD/MM/YYYY'),
      countries: [],
      active_country: null,
      projects: []
    }
  },

  mounted: function () {
    let $this = this;
    ProjectApi.countries().then(response => {
      $this.countries = response.results;
      $this.active_country = $this.countries[0];
      this.getData();
    });
  },
  watch: {
    active_country: function () {
      this.getData()
    },
  },
  methods: {
    getData: function (date=null) {
      let $this = this;
      ProjectApi.parser_state_tr($this.active_country, date).then(response => {
        $this.projects = response;

      })
    },
    bindLink(project_id){
       ProjectApi.bind_link(project_id, this.active_date)
    }

  }
}

</script>

