<template>
  <section>
    <div class="row d-flex justify-content-between align-items-end">
      <div class="col-6">
        <div class="dropdown" v-if="active_country">
          <button class="btn btn-filter " type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{ active_country.name }}
          </button>
          <ul class="dropdown-menu ">
            <template v-for="county in project.countries">
              <li @click="active_country=county; getData();">{{ county.name }}</li>
            </template>
          </ul>
        </div>
      </div>
      <div class="col-6">
        <div class="dropdown" v-if="state">
          <button class="btn btn-filter " type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            Топ-{{ state }}
          </button>
          <ul class="dropdown-menu ">
            <template v-for="state_val in all_states">
              <li @click="state=state_val">Топ-{{ state_val }}</li>
            </template>
          </ul>
        </div>
      </div>

    </div>
    <div class="row d-flex justify-content-between align-items-end">
      <div class="col">
        <div class="dropdown" v-if="active_filter">
          <button class="btn btn-filter " type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{ active_filter.name }}
          </button>
          <ul class="dropdown-menu ">
            <template v-for="(filter, key) in filters">
              <li @click="filter_key=key">{{ filter.name }}</li>
            </template>
          </ul>
        </div>
      </div>

    </div>
    <div class="h3" v-if="error_msg">{{ error_msg }}</div>
    <SmallLink v-if="link_detail" :links="links"
               :link="link_detail"
               :backFunc="backFunc"></SmallLink>


  </section>
</template>
<script>
import {defineComponent} from "vue";
import SmallLink from "@/mobile/includes/SmallLink.vue";
import {ProjectApi} from "@/api/project";

export default defineComponent({
  components: {SmallLink},
  data() {
    return {
      filters: {
        auto_new: {
          name: 'Новая авто. нецелевая информация',
          query: {tone: ['negative'], type_tone: ['new'], kind_type: ['auto']}
        },
        auto_all: {name: 'Автоматически размечено', query: {kind_type: ['auto']}},
        no_tone: {name: 'Неразмеченные ссылки', query: {tone: ['null']}},
        no_content: {name: 'Ссылки не получившие контент', query: {tone: ['error']}},
      },
      all_states: [10, 20, 50, 100],
      links: [],
      start_request: false,
      link_detail: null,
      error_msg: null,
      state: this.$route.query.state || 20,
      filter_key: this.$route.query.filter_type
    }
  },
  computed: {
    active_filter: {
      get: function () {
        return this.filters[this.filter_key];
      },
      set: function () {
      }
    },
    project: {
      get: function () {
        return this.$store.getters.favor_project(this.$route.params.id);
      },
      set: function () {
      }
    },
    query: function () {
      return this.$route.query
    },
    active_country: {
      get: function () {
        let country = this.project ? (this.project.countries || []).find(x => x.active) : null;
        if (country && !this.links.length && !this.start_request) {
          this.links.push({})
          this.getData()
        }
        return country
      },
      set: function (val) {
        ProjectApi.set_active_country(this.project.id, val.code)
        this.project.countries.forEach(x => x.active = x.code === val.code)
        this.$store.dispatch('updateFavorite', this.project)
      }
    }
  },
  watch: {
    active_filter() {
      this.getData()
    },
    state() {
      this.getData()
    }
  },
  mounted() {

    // this.getData()
  },
  methods: {
    getData: function () {
      this.error_msg = null;
      this.start_request = true;
      let $this = this, data = this.$route.query;
      // if (this.active_country.code) data.country_code = this.active_country.code;
      data.state = this.state;
      let query = {...data, ...this.active_filter.query}
      ProjectApi.links_info($this.$route.params.id, query).then(response => {
        $this.links = response.results;
        $this.link_detail = $this.links[0];
        if (!$this.links.length) this.error_msg = 'Ссылок не найдено';
      })
    },
    backFunc() {
      this.$router.push({name: 'project_detail', params: {id: this.$route.params.id}})
    }
  }

})
</script>
