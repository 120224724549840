import { HTTP } from "./common";

export const AlertApi = {

  list() {
    return HTTP.get(`alerts/`).then((response) => {
      return response.data;
    });
  },
  post(obj) {
    return HTTP.put(`alerts/${obj.id}/`, obj).then((response) => {
      return response.data;
    });
  },
  set_all_read() {
    return HTTP.put(`alerts/set_all_read/`).then((response) => {
      return response.data;
    });
  },


};


