<template>
  <main class="dashboard">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Контент</h1>
      <BackButton></BackButton>
    </div>

    <div class="card">

      <div class="card-body" v-if="content">
        <article class="blog-post">
          <h2 class="blog-post-title">{{content.title}}</h2>
          <p class="blog-post-meta">{{content.date_create}}</p>
          <p class="blog-post-meta">{{content.ri}}</p>
          <p>
            {{content.description}}
          </p>
          <hr>

          <p>
            {{content.content}}


          </p>
        </article>


      </div>


    </div>

  </main>
</template>

<script>
  import {LinkApi} from '@/api/project'

  export default {
    name: 'LinkContent',
    components: {},

    data() {
      return {
        current_date: new Date(),
        content: null,
        show_all: false

      }
    },
    mounted: function () {
      let $this = this;
      LinkApi.get_content($this.$route.params.link_id).then(response => {
        $this.content = response;
      })

    },
    methods: {}
  }

</script>


