<template>

  <div class="d-flex" style="gap: 30px; min-height: 600px">
    <div class="projects-list">
    <div class="project" v-for="project in link_projects">
      <h4>{{ project.name }}</h4>
      <ul class="list-group" v-for="u_link in user_links.filter(link => link.project_id === project.id)">
        <li class="list-group-item d-flex justify-content-between">
          <a type="button" :class="`ms-1 s_link`">
            <popper hover>
              {{ u_link.link }}
              <template #content>
                <div class="popper text-start">
                  <span>Проект: {{ u_link.project_name }}</span><br>
                  <span>Тон: {{ u_link.get_tone_display }}</span><br>
                  <span>{{ u_link.title }}</span><br>
                  <a :href="u_link.link" target="_blank">{{ u_link.link }}</a>
                </div>
              </template>
            </popper>
          </a>
          <button v-if="u_link.id === u_link.id" @click="removeTrack(u_link)"
                  class="btn btn-sm btn-danger text-white"
                  title="Удалить из отслеживания"><i class="bi bi-x"></i></button>
        </li>
      </ul>
      <hr>
    </div>
    </div>

    <div>
      <div class="h4">Добавить ссылки</div>
      <MultiSelect
          v-model="project"
          :options="projects"
          :multi="false"
          name="Выберите проект"
      ></MultiSelect>

      <ul class="list-group" v-show="!!project">
        <li class="list-group-item">
          <label for="" class="form-label mt-3">Начните вводить ссылку</label>
          <input class="form-control" type="text" v-model="q_link"></li>
        <li v-for="link in link_search" class="list-group-item d-flex justify-content-between">
          <a type="button"
             :class="`s_link ms-1`">
            <popper hover>
              {{ link.link }}
              <template #content>
                <div class="popper text-start">
                  <span>Проект: {{ link.project_name }}</span><br>
                  <span>Тон: {{ link.get_tone_display }}</span><br>
                  <span>{{ link.title }}</span><br>
                  <a :href="link.link" target="_blank">{{ link.link }}</a>
                </div>
              </template>
            </popper>
          </a>


          <button class="btn btn-success btn-sm" @click="addTrack(link)"><i class="bi bi-plus"></i></button>
        </li>

      </ul>
    </div>
  </div>

</template>

<script>
import {LinkApi} from "@/api/project";
import {HTTP} from '@/api/common'
import axios from 'axios'
import MultiSelect from "@/plugins/Select.vue";

export default {
  name: 'LinkStatSettings',
  components: {
    MultiSelect
  },
  props: ['projects', 'user_links'],
  data() {
    return {
      project: '',
      links: [],
      q_link: '',
      controller: new AbortController(),
      request: false,
    }
  },
  mounted: function () {


  },
  computed: {
    link_projects() {
      if (this.user_links) {
        let ids = this.user_links.map(x => x.project_id)
        return this.projects.filter(pr => ids.indexOf(pr.id) + 1);
      }
      return [];
    },
    link_search() {
      return this.links.filter(x => !(this.user_links.map(x => x.id).indexOf(x.id) + 1));
    }
  },
  watch: {

    q_link: function (val) {
      if (val.length > 3 && val !== 'http' && val !== 'https') {
        this.searchLinks()
      } else {
        this.links = []
      }

    },
  },

  methods: {
    addTrack: function (link) {
      let $this = this;
      LinkApi.add_to_tracked(link.id).then(function (response) {
        $this.user_links.push(link)
        $this.$notify({
          type: 'success ',
          text: 'Ссылка добавлена'
        });
      })
    },
    removeTrack: function (link) {
      let $this = this;
      LinkApi.remove_from_tracked(link.id).then(function (response) {
        $this.user_links.splice($this.user_links.findIndex((obj => obj.id === parseInt(link.id))), 1);
        $this.$notify({
          type: 'success ',
          text: 'Ссылка удалена'
        });
      })
    },
    cancelSearch() {
      this.request.cancel();
      this.request = null;
    },
    searchLinks: function () {
      let $this = this;
      if (this.request) this.cancelSearch();
      const axiosSource = axios.CancelToken.source();
      this.request = {cancel: axiosSource.cancel};
      HTTP.get(`links/search_by_q/?q=${this.q_link}&project=${this.project.id}`, {cancelToken: axiosSource.token}).then(resp => {
        $this.request = null;
        $this.links = resp.data
      })
      // LinkApi.search_by_q(this.q_link).then(resp => this.links = resp)
    },

  }
}

</script>
<style scoped>
.btn-danger, .btn-success {
  height: 32px;
}

.s_link {
  max-width: 360px;
  white-space: normal;
    word-break: break-all;
}
</style>
