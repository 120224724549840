<template>
  <section>
    <template v-for="project in projects">
    <SmallProject v-bind:project="project"/>
      </template>
  </section>
</template>
<script>

import SmallProject from "@/mobile/includes/SmallProject.vue";

export default {
  name: "Front",
  components: {SmallProject},

  data() {
    return {
    }
  },
  computed: {
    projects: {
      get: function () {
        return this.$store.getters.favorites;
      },
      set: function () {
      }
    },
  },
  watch: {},
  mounted: function () {
  },
  methods: {

  },
}
</script>