<template>
  <main class="dashboard">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">План/Факт</h1>
      <BackButton></BackButton>
    </div>

    <Forecast v-bind:id="$route.params.id" v-bind:stat="true"/>

  </main>
</template>

<script>
import Forecast from "@/components/Prognoz.vue";

export default {
  name: "ProjectForecast",
  components: {
    Forecast
  },

  data() {
    return {

    }
  },
  mounted: function () {
  },
  methods: {

  }
}

</script>

