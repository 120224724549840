<template>
  <main>
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs border-white">
        <li class="col">
          <h4>{{ project.name }}</h4>

        </li>

        <li class="d-flex flex-row justify-content-end mb-3 col no-wrap">
          <div class="form-check form-switch me-3">
            <input class="form-check-input" v-model="project.textru_check" v-on:change="updateProject" type="checkbox"
                   role="switch"
                   id="textru_check" checked>
            <label class="form-check-label" for="textru_check">Проверка в Text.ru</label>
          </div>
          <div class="form-check form-switch d-flex">
            <input class="form-check-input" v-model="not_active" type="checkbox" role="switch" id="showDeleted"
                   checked>
            <label class="form-check-label" for="showDeleted">Показать удалённые</label>
            <div class="help" @click="toggle.show=!toggle.show;">
              <i class="bi bi-question-circle"></i>
              <div :class="`popup${toggle.show?' show':''}`">{{ toggle.help }}</div>
            </div>
          </div>
          <select class="form-select form-select-sm pager ms-2" v-model="size">
            <option v-for="page in pagers" :value="page" :key="page">{{ page }}</option>
          </select>
        </li>
      </ul>
      <div class="row mb-3">
        <div class="col">
          <button class="btn btn-sm btn-outline-success" @click="$modal('show', 'add_document');">Добавить текст для
            проверки
          </button>
        </div>
        <div class="col">
          <div class="search" role="search">
            <i class="bi bi-search"></i>
            <input type="search" class="form-control" v-model="q" placeholder="Поиск..." aria-label="Поиск">
          </div>
        </div>
        <div class="col text-end">
          <button class="btn btn-sm btn-outline-warning text-black" @click="recalcUnique()">Проверить уникальность
          </button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr class="table-primary">
            <th scope="col" class="col-1">№</th>
            <th scope="col" class="col-2">Ссылка на текст</th>
            <th scope="col" class="col-2">Название файла</th>
            <th scope="col" class="col-2">Текст-эталон</th>
            <th scope="col" class="col-1">Внутренняя уникальность</th>
            <th scope="col" v-if="project.textru_check" class="col-1">Внешняя уникальность</th>
            <th scope="col" class="col-1">Общая уникальность</th>
            <th scope="col" class="col-1">Дата проверки</th>
            <th scope="col" class="col-1"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(doc, index) in q_documents">
            <th scope="row">{{ (index + 1) + ((page - 1) * size) }}</th>
            <td><a v-if="doc.ya_disk_url" :href="doc.ya_disk_url"
                   target="_blank" class=" text-truncate"
                   style="max-width: 200px; display: block;">{{ doc.ya_disk_url || '-' }}</a></td>
            <td><a href="" v-on:click.prevent="showDoc(doc)">({{ doc.id }}) {{ doc.file_name }}</a></td>
            <td>
              <input type="checkbox" v-model="doc.reference" v-on:change="updateDoc(doc)">
            </td>

            <td>
              <template v-if="doc.check_process">
                <span class="text-secondary">проверка...</span>
              </template>
              <template v-else>
                {{ doc.internal_uniqueness !== null ? Math.round(doc.internal_uniqueness) + '%' || '-' : '-' }}
              </template>
            </td>
            <td v-if="project.textru_check">
              <template v-if="doc.check_process">
                <span class="text-secondary">проверка...</span>
              </template>
              <template v-else>
                {{ doc.external_uniqueness !== null ? Math.round(doc.external_uniqueness) + '%' || '-' : '-' }}
              </template>
            </td>
            <td>
              <template v-if="doc.check_process">
                <span class="text-secondary">проверка...</span>
              </template>
              <template v-else>
                {{ doc.total_uniqueness !== null ? Math.round(doc.total_uniqueness) + '%' || '-' : '-' }}
              </template>
            </td>
            <td>
              <template v-if="doc.last_check">{{ $moment(doc.last_check).format('DD.MM.YYYY') }}</template>
            </td>
            <td>
              <button v-if="doc.is_active" class="btn btn-sm"
                      @click="delete_object=doc; $modal('show', 'delete_object')">
                <i
                    class="bi bi-x-lg"></i></button>
              <span v-else>
                <sup>удалён</sup>

                  <button class="btn btn-sm btn-warning" @click="delete_object=doc; DeleteObject(true);"><small>восстановить</small></button>

              </span>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
      <nav>
        <ul class="pagination justify-content-center fz-9" v-if="pager.length > 1">
          <li v-for="p in pager" :class="`page-item${p===page?' active':''}`">
            <a class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
          </li>
        </ul>
      </nav>
    </div>
    <modal modal_id="delete_object" class="text-black">
      <template v-slot:header>
        <h2>Удалить "{{ delete_object.file_name }}"?</h2>
      </template>
      <template v-slot:body>
        <div class="modal-body d-flex">
          <h1 class="text-danger"><i class="bi bi-exclamation-lg"></i></h1>
          <p>Текст будет скрыт и исключен из расчётов уникальности</p>
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'delete_object');">Отмена
        </button>
        <button type="button" class="btn btn-danger" v-on:click="DeleteObject(false)">Удалить</button>
      </template>

    </modal>
    <modal modal_id="add_document" class="text-black">
      <template v-slot:header>
        <h2>Загрузить текст на проверку</h2>
      </template>
      <template v-slot:body>
        <div class="form-group">
          <select class="form-select form-select-sm ms-2" v-model="empty_doc.type">
            <option selected value="">Способ загрузки</option>
            <template v-for="type in types" :key="type.val">
              <option :value="type.val">{{ type.name }}</option>
            </template>
          </select>
        </div>
        <hr>
        <div class="form-check" v-if="empty_doc.type !== 'xlsx'">
          <input class="form-check-input" type="checkbox" v-model="empty_doc.reference" id="flexCheckChecked"
                 :checked="empty_doc.type !== 'xlsx'">
          <label class="form-check-label" for="flexCheckChecked">
            Текст-эталон
          </label>
        </div>
        <template v-if="empty_doc.type === 'link' || empty_doc.type === 'od_link'">
          <div class="form-group">
            <div class="input-group mb-3">
              <input type="text" v-model="empty_doc.link" class="form-control" placeholder="Введите ссылку"
                     aria-label="Введите ссылку" aria-describedby="document_link">
              <button class="btn btn-outline-secondary" type="button" @click="loadLink" id="document_link">OK
              </button>
            </div>

          </div>
        </template>
        <template v-else-if="empty_doc.type === 'xlsx'">
          <div class="input-group mb-3">
            <input type="file" class="form-control" id="document_file" ref="documentXlsxFile">
            <button @click="loadXlsx($refs.documentXlsxFile)" class="btn btn-success btn-sm">Загрузить</button>

          </div>
          <a :href="`/media/static/temp/links.xlsx`" target="_blank"><i class="bi bi-question-circle"></i> Образец
            файла</a>

        </template>
        <template v-else-if="empty_doc.type === 'file'">
          <div class="input-group mb-3">
            <input type="file" class="form-control" id="document_file" ref="documentFile"
                   @change="saveFileDict($refs.documentFile)">

          </div>

        </template>
        <template v-else-if="empty_doc.type === 'text'">
        </template>

        <div class="form-group" v-if="empty_doc.type !== 'xlsx'">
          <input :readonly="empty_doc.type !== 'text'" accept="application/vnd.sealed.xls"
                 placeholder="Название загруженного текста" class="file_name"
                 name="file_name"
                 v-model="empty_doc.file_name">
          <textarea name="" v-model="empty_doc.content" id="" :disabled="empty_doc.type!=='text'" cols="30"
                    rows="10"></textarea>
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" :disabled="!empty_doc.content" class="btn btn-success" @click="saveDoc">
          Загрузить
        </button>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'add_document');">
          Отмена
        </button>
      </template>

    </modal>
    <DocDetail v-if="detail_document" :key="detail_document.key" v-bind:doc_id="detail_document.id"></DocDetail>

  </main>
</template>

<script>
import {TextProjectApi} from "./api/project";
import {DocumentApi} from "./api/document";
import DocDetail from "./DocDetail.vue";
import {state} from "./socket";

export default {
  name: "TextProjectDetail",
  data() {
    return {
      project: {},
      delete_object: {},
      detail_document: null,
      documents: [],
      toggle: {
        help: 'Включить убранные из анализа уникальности тексты. Включенными они не будут влиять на' +
            ' новые проверки уникальности. Можно просто посмотреть последние данные по ним, без возможности' +
            ' корректировки.', show: false
      },
      not_active: false,
      pagers: [10, 15, 25],
      size: 10,
      page: 1,
      q: '',
      types: [
        {name: 'Ссылка OD', val: 'od_link'},
        {name: 'Внешняя ссылка', val: 'link'},
        {name: 'XLSX со списком сайтов', val: 'xlsx'},
        {name: 'Файл', val: 'file'},
        {name: 'Текст', val: 'text'}
      ],
      load_xlsx: "",
      empty_doc: {
        project: this.$route.params.id,
        type: '',
        link: '',
        file: '',
        reference: false,
        file_name: '',
        content: '',
        is_active: true
      }
    }
  },
  components: {DocDetail},
  watch: {
    $route(to, from) {
      this.getProject();
    },
    ws_state: {
      deep: true,
      handler(val) {
        if (val.project === parseInt(this.$route.params.id)) {
          let index_report = this.documents.findIndex(e => e.id === val.id);
          if (index_report > -1) {
            this.documents.splice(index_report, 1, val)
          }
          else this.documents.unshift(val)
        }
      }
    }
  },
  computed: {
    ws_state() {
      return state.doc;
    },
    filter_doc() {
      return this.documents.filter(x => x.project === parseInt(this.$route.params.id)).filter(doc => (doc.file_name ? doc.file_name.toLowerCase().indexOf(this.q.toLowerCase()) + 1 : 1));
    },
    q_documents() {
      let docs = JSON.parse(JSON.stringify(this.filter_doc));
      if (!this.not_active) docs = docs.filter(x => x.is_active === !this.not_active)
      return docs.slice(this.size * (this.page - 1), this.size * this.page)
    },
    pager() {
      let all_count = this.filter_doc.filter(x => x.is_active === !this.not_active).length
      let page_count = Math.ceil(all_count / this.size);
      let pages = Array.from({length: page_count}, (_, i) => i + 1);
      let array = [];
      if (this.page > 5) {
        array = array.concat(pages.slice(0, this.page - 3 > 3 ? 3 : this.page - 3));
        array.push('...')
      }
      array = array.concat(pages.slice(this.page - 4 > 0 ? this.page - 4 : 0, this.page + 3));
      if (this.page < (pages.length - 5)) {
        array.push('...');
        array = array.concat(pages.slice(pages.length - 3, pages.length));
      }
      return array;
    },

  },
  mounted: function () {
    this.getProject();
  },
  methods: {
    getProject: function () {
      TextProjectApi.get(this.$route.params.id).then(response => this.project = response);
      let $this = this;
      this.empty_doc.project = this.$route.params.id;
      DocumentApi.list(`project_id=${this.$route.params.id}`).then(response => {
        $this.documents = response
      });
    },
    saveFileDict(ref, name) {
      this.empty_doc.file = ref.files[0];
      this.empty_doc.file_name = this.empty_doc.file.name
      let formData = new FormData();
      formData.set('file', this.empty_doc.file)
      DocumentApi.read_from_file(formData).then(response => this.empty_doc.content = response.text)
    },
    loadXlsx(ref) {
      let formData = new FormData();
      let $this = this;
      formData.set('file', ref.files[0])
      TextProjectApi.load_by_xlsx(this.$route.params.id, formData).then(response => {
        if (response.message) {
          $this.$notify({
            group: 'alert',
            type: 'success',
            text: response.message
          })
          $this.emptyDoc();
          $this.$modal('hide', 'add_document');
        } else if (response.error) {
          $this.$notify({
            group: 'alert',
            type: 'error',
            text: response.error
          })
        }
      })
    },
    loadLink() {
      let $this = this;
      if (!this.empty_doc.link) {
        this.$notify({
          type: 'error',
          text: 'Введите ссылку'
        })
      } else {
        DocumentApi.get_content_by_link(this.empty_doc).then(response => {
          if (response.content) {
            $this.empty_doc = {...$this.empty_doc, ...response}
          } else if (response.error) {
            $this.$notify({
              type: 'error',
              text: response.error
            })
          }
        })
      }
    },
    updateDoc(doc) {
      doc.save_anyway = true
      DocumentApi.update(doc).then(() => this.$notify({
            type: 'success',
            text: 'Документ сохранён'
          })
      )
    },
    emptyDoc() {
      this.empty_doc = {
        project: this.$route.params.id,
        type: '',
        link: '',
        file: '',
        file_name: '',
        reference: false,
        content: '',
        is_active: true
      };
    },
    saveDoc() {
      if (!this.empty_doc.file_name) {
        this.$notify({
          type: 'error',
          text: 'Введите название'
        })
      } else {
        let $this = this;
        let formData = new FormData();
        let data = $this.empty_doc;
        for (let i in data) {
          formData.set(i, data[i])
        }
        formData["save_anyway"] = true
        $this.emptyDoc();
        DocumentApi.update(formData).then(response => {
          // $this.documents.unshift(response)
          $this.$notify({
            type: 'success',
            text: 'Документ добавлен'
          });
          $this.$modal('hide', 'add_document');
        })
      }
    },
    recalcUnique() {
      this.$notify({
        type: 'success',
        text: 'Запущен процесс пересчёта уникальности'
      });
      TextProjectApi.recalc_unique(this.$route.params.id)
    },
    showDoc(doc) {

      // if (this.detail_document === doc) {
      //   this.$modal('show', 'show_document');
      // } else

        this.detail_document = doc
        this.detail_document.key = Math.random().toString(36).slice(2);
    },
    DeleteObject(is_active = false) {
      let $this = this;
      DocumentApi.delete($this.delete_object.id, is_active).then(response => {
        $this.$modal('hide', 'delete_object');
        if ($this.not_active) {
          let doc = response;
          doc.is_active = is_active;
          $this.documents.splice($this.documents.indexOf($this.delete_object), 1, doc);
        } else {
          $this.documents.splice($this.documents.indexOf($this.delete_object), 1);
        }
        $this.delete_object = {};
        $this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Текст ' + (!is_active ? 'удалён' : 'восстановлен')
        });
      });

    },
    updateProject() {
      TextProjectApi.update(this.project).then(() => this.$notify({
            type: 'success',
            text: 'Проект обновлён'
          })
      )
    },

  },

}
</script>

<style scoped>
.popup {
  position: absolute;
  width: 250px;
  font-size: 12px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: none;
  right: 0;
  z-index: 10;
}

.popup.show {
  display: block;
}

.help {
  position: relative;
  cursor: pointer;
  font-size: 10px;
  margin-left: 5px;
}

.pager {
  width: 100px;
}

textarea {
  width: 100%;
  background: #eee;
}

.file_name {
  width: 100%;
  border: 0;
  padding: 10px;
  background: #1CA5B8;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-weight: bold;
}

.file_name::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.file_name:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

.file_name::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}


.modal.doc .modal-content {
  overflow-y: scroll;
  height: 100vh;
}
</style>
