<template>
  <div class="small-link" @touchstart="touchStartMethod" @touchmove="touchMove">
    <div class="info d-flex" v-if="active_link.keyword">
      <div>
        <span class="me-2">Yandex: <b>{{ best.yandex || '-' }}</b></span>
        <span>Google: <b>{{ best.google || '-' }}</b></span>
      </div>
      <span class="position-absolute">Показано: <b>{{ links.findIndex(x => x.id === active_link.id) + 1 }} из {{
          links.length
        }}</b></span>
    </div>

    <div class="info" v-else>
      <div :class="`${engine_code}-full`"></div>
      <span class="position-absolute">Позиция: <b>{{ active_link.place }}</b></span>
    </div>
    <div class="card" id="link_detail">
      <a class="link" :href="active_link.link" target="_blank">{{ active_link.link }}</a>
      <span>{{ active_link.title }}</span>
      <span>{{ active_link.description }}</span>
      <template v-for="(tone, key) in $TONES">
        <button v-if="!tone.no_filter"
                @click="checkTypeTone(active_link, key); active_link.tone=key; updateLink(active_link)"
                :class="`btn btn-${key}${active_link.tone === key?' actual':''}`">
          {{ tone.full_name }}
        </button>
      </template>

      <div class="btn-wrap">
        <template v-for="(name, key) in delete_types">
          <button
              @click="active_link.delete_type=key; updateLink(active_link)"
              :class="`btn btn-delete${active_link.delete_type===key?' actual':''}`">
            {{ name }}
          </button>
        </template>
      </div>
      <div class="row d-flex justify-content-between align-items-end" v-if="link.tone">
        <div class="col">
          <div class="dropdown">
            <button class="btn btn-filter border-dark-gray" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
              Тип ссылки: {{ active_link.get_type_tone_display || 'Не выбран' }}
            </button>
            <ul class="dropdown-menu no-filter">
              <template v-for="(name, key) in $getTypes(active_link.tone)">
                <li
                    @click="active_link.type_tone = key; active_link.get_type_tone_display=name; updateLink(active_link)">
                  {{ name }}
                </li>
              </template>
            </ul>
          </div>
        </div>

      </div>

    </div>
    <div class="btn-wrap control">
      <button class="btn" @click="backFunc">Назад</button>
      <button class="btn" v-if="links.length > index+1" @click="nextLink">Дальше <i class="bi bi-chevron-right"></i>
      </button>
    </div>

  </div>
</template>
<script>

import {LinkApi} from "@/api/project";

export default {
  name: "SmallLink",
  props: {
    links: {
      type: Array, default: function () {
        return []
      }
    },
    link: {
      type: Object, default: function () {
        return {}
      }
    },
    backFunc: {type: Function},
    engine_code: {type: String, default: ''},
  },
  data() {
    return {
      active_link: {},
      index: 0,
      posXStart: null,
      delete_types: {
        'delete': 'Удаление',
        'replace': 'Замена',
        'displacement': 'Вытеснение',
        'not_required': 'Не требуется',
        'additional': 'Доп. Исследование'
      },
    }
  },

  computed: {
    best() {
      let engines = ['yandex', 'google'], data = {};
      engines.forEach(engine => {
        let best = 0
        if (this.active_link.keyword) {
          let places = Object.keys(this.active_link.keyword).map(x => this.active_link.keyword[x].place.filter(k => k.engine === engine)).flat(1).map(x => x.place);
          if (!places.length) places.push(0);
          best = Math.min(...places);
        }
        data[engine] = best;
      })
      return data
    },
  },
  watch: {
    link() {
      this.setLink()
    }
  },
  mounted() {
    this.setLink()
  },
  methods: {
    setLink() {
      this.active_link = this.link
      this.index = this.links.findIndex(x => x.id === this.link.id)
    },
    checkTypeTone(link, tone) {
      if (link.tone !== tone) {
        link.type_tone = 'new';
        link.get_type_tone_display = 'Новый';
      }
    },
    nextLink() {
      if ((this.index + 1) < this.links.length) {
        this.index += 1;
        this.active_link = this.links[this.index]
      }
    },
    prevLink() {
      if (this.index > 0) {
        this.index -= 1;
        this.active_link = this.links[this.index]
      }
    },
    updateLink(link) {

      let $this = this, data = JSON.parse(JSON.stringify(link));
      LinkApi.get(link.id).then(response => {
        let fields = Object.keys(link);
        fields = fields.filter((el) => !['tone', 'type_tone', 'delete_type'].includes(el));

        fields.forEach(field => {
          if (response[field]) data[field] = response[field]
        })
        LinkApi.update(data).then(response => {
          $this.$notify({
            type: 'success ',
            text: 'Данные обновлены'
          });
        })
      });
    },
    touchStartMethod(touchEvent) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      this.posXStart = touchEvent.changedTouches[0].clientX;
      addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, this.posXStart), {once: true});
    },
    touchMove(touchEvent) {
      const posXNow = touchEvent.changedTouches[0].clientX;
      if (this.posXStart && Math.abs(this.posXStart - posXNow) > 20) {
        const card = document.getElementById('link_detail')
        card.style = `transform: translateX(${posXNow - this.posXStart}px)`;

      }
    },
    touchEnd(touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      let card = document.getElementById('link_detail')
      card.style = `transform: translateX(0px)`;
      if (Math.abs(posXStart - posXEnd) >= window.screen.width / 2) {
        let func;
        if (posXStart < posXEnd) {
          func = this.prevLink; // swipe right
        } else if (posXStart > posXEnd) {
          func = this.nextLink; // swipe left
        }
        func();

      }
    }
  },
};
</script>
