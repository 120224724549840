<template>
  <div>
    <button v-if="!target" class="btn btn-outline-secondary similar-link btn-sm" @click="getLinks(true);"><i
        class="bi bi-link"></i></button>
    <template v-else>
      <button v-if="sim_links.length" type="button" class="btn btn-outline-danger"
              @click="$modal('show', `settings${link_id}`);">
        <i class="bi bi-exclamation-triangle text-dark"></i>
        Найдены похожие ссылки
      </button>
      <button v-else type="button" class="btn btn-outline-info" @click="$modal('show', `settings${link_id}`);">
        Связать ссылки
      </button>
    </template>
    <modal :modal_id="`settings${link_id}`">
      <template v-slot:header>
        <h5 class="modal-title" v-if="target">Найденные совпадения</h5>
        <h5 class="modal-title" v-else>Выберите каноническую ссылку</h5>


        <form @submit.prevent="getLinks" class="search inner-addon left-addon ms-3 d-flex">
          <div role="search">
            <i class="bi bi-search"></i>
            <input type="search" class="form-control" placeholder="Поиск" v-model="q" aria-label="Поиск">
          </div>
          <button class="btn btn-sm btn-primary" style="height: 35px;" type="submit">Найти</button>
        </form>
      </template>
      <template v-slot:body>
        <ul class="list-group">
          <li class="list-group-item" v-for="link in sim_links">
            <div class="row">
              <input width="10px" class=" col-1 form-check-input me-1" type="checkbox"
                     @change="link.checked = !link.checked"
                     name="listGroupRadio" value="">
              <label class="form-check-label text-truncate col-8">
                <a :href="link.link" :title="link.link" :style="$func.getClass(link)" target="_blank">
                  {{ link.link }}
                </a>
              </label>
              <span :style="$func.getClass(link)" class="col-1 no-wrap">{{ link.get_tone_display }}</span>
              <span class="col-1 no-wrap">{{ link.get_type_tone_display }}</span>
              <div class="col text-end">
                <button style="max-width: 50px; width:50px; height: 30px;" v-show="link.checked && !link.similar"
                        @click="active_link=link; $modal('show',`do_canonical${link_id}`);"
                        class="btn btn-sm btn-warning"
                        title="Назначить канонической">
                  <i class="bi bi-flag text-white"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary no-wrap" data-bs-dismiss="modal">Закрыть</button>
        <template v-if="sim_links.filter(x=>x.checked).length">
          <button type="button" class="btn btn-primary no-wrap" @click="bindLink">Связать с текущей ссылкой*</button>
          <small style="width: 100%; text-align: right">* Это действие назначит текущую ссылку канонической</small>
        </template>
      </template>

    </modal>
    <modal :modal_id="`do_canonical${link_id}`">
      <template v-slot:header>
        <h5 class="modal-title">Сделать ссылку канонической? Это действие необратимо</h5>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary"
                @click="active_link=null; $modal('hide', `do_canonical${link_id}`);"
                data-bs-dismiss="modal">Закрыть
        </button>
        <button type="button" class="btn btn-success" @click="setCanon">Подтвердить</button>
      </template>

    </modal>

  </div>
</template>
<script>
import {HTTP} from "@/api/common";

export default {
  name: 'SimLinks',
  components: {},
  props: ['link_id', "link_obj", 'target', "open", "updFunc"],
  data() {
    return {
      sim_links: [],
      q: '',
      active_link: null
    }
  },
  mounted: function () {
    if (this.target) this.getLinks()
  },
  computed: {},
  watch: {
    open(val) {
      if (val) this.getLinks(true)
    }
  },

  methods: {
    getLinks(open_popup = false) {
      let $this = this;
      HTTP.get(`planfix/target/${this.link_id}/get_similar_links/${this.q ? `?q=${this.q}` : ''}`).then(
          response => {
            $this.sim_links = response.data
            if (open_popup) $this.$modal('show', `settings${$this.link_id}`);
          });
    },
    setCanon() {
      let $this = this;
      $this.$modal('hide', `do_canonical${$this.link_id}`)
      HTTP.post(`planfix/target/set_canon_link/`, {
        "link_id": this.link_id,
        "canon": this.active_link.id
      }).then((response) => {
        $this.$notify({
          type: 'success ',
          text: "Ссылки связаны"
        });
        if ($this.target) location.pathname = response.data.new_link
        else {
          $this.updFunc($this.link_obj, [], true)
          $this.updFunc($this.active_link, [], true)
          $this.$modal('hide', `settings${$this.link_id}`);
        }
      });
    },
    bindLink() {
      let $this = this;
      HTTP.post(`planfix/target/bind_canon_link/`,
          {
            "link_id": this.link_id,
            "binds": this.sim_links.filter(x => x.checked).map(x => x.id)
          }).then(
          response => {
            $this.$notify({
              type: 'success ',
              text: "Ссылки связаны"
            });
            if (!$this.target) {
              $this.updFunc($this.link_obj, [], true)
              $this.updFunc($this.active_link, [], true)
            }
            $this.getLinks()
          });
    }
  }
}

</script>

<style scoped lang="scss">
.modal-body {
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.similar-link {
  padding: 0 5px!important;

  i {
    font-size: 10px!important;
  }
}

</style>