<template>
  <main class="dashboard links">
    <div class="filters d-flex">
      <MultiSelect
          v-model="filters.tone"
          :options="Object.keys($TONES).filter(x => !$TONES[x].no_filter).map(x => {
        return {id: x, name: $TONES[x].name}
      })"
          :multi="false"
          :only_value="true"
          name="Тональность">
      </MultiSelect>
      <MultiSelect
          v-model="filters.state"
          :options="$STATES"
          :multi="false"
          :only_value="true"
          name="ТОП">
      </MultiSelect>
      <date-picker
          v-model="start_date"
          locale="ru"
          :clearable="false"
          format="dd.MM.yyyy"
          model-type="yyyy-MM-dd"
          auto-apply
          :enable-time-picker="false"
      ></date-picker>
      <date-picker v-model="end_date"
                   locale="ru"
                   :clearable="false"
                   format="dd.MM.yyyy"
                   model-type="yyyy-MM-dd"
                   auto-apply
                   :enable-time-picker="false"
      ></date-picker>
      <div class="form-check form-switch">
        <input class="form-check-input" v-model="uniq" type="checkbox" role="switch" id="uniq" checked>
        <label class="form-check-label" for="uniq">Уникальные ссылки</label>
      </div>
      <button class="btn btn-outline-dark" v-on:click="getData()">Показать</button>
    </div>
    <div class="filters d-flex">
      <CountryChoice v-bind:active_country.sync="active_country"
                     v-bind:countries="countries"
                     v-bind:changeCountry="(country) => active_country = country"></CountryChoice>
    </div>


    <table class="table table-hover table-bordered table-responsive">
      <template v-for="(project, p_key, index) in info.projects">
        <tr v-if="!index">
          <template v-for="(info, i_key, index) in info.total">
            <td v-if="!index"></td>
            <th colspan="2" class="text-center p-1">
              <small>{{ i_key }}</small>
            </th>
          </template>
        </tr>
        <tr v-if="!index">
          <template v-for="(info, i_key, index) in info.total">
            <td v-if="!index"></td>
            <th class="text-center p-1">
              <i class="yandex"></i>
            </th>
            <th class="text-center p-1">
              <i class="google"></i>
            </th>
          </template>
        </tr>

        <tr class="gray">
          <td class="gray d-flex border-0 justify-content-between" style="max-width: 500px;">
            <div>
              <div class="btn btn-sm btn-outline-dark p-0" @click="checkOpen(p_key)">
                <i v-if="!opens.indexOf(p_key)+1" class="bi bi-plus"></i>
                <i v-else class="bi bi-dash-lg"></i>
              </div>
              <router-link :to="`/projects/${p_key}`">{{ getProjectName(p_key) }}</router-link>
            </div>
            <span>
                <sup>план</sup><br>
                <sup>факт</sup>
              </span>
          </td>
          <template v-for="(result, index) in project.results">
            <td colspan="2" class="text-center">
                      <span
                          :class="`${project.forecasts[Object.keys(project.forecasts)[index]]<(result.y + result.g)?'text-danger':'text-dark'}`">
                           {{ project.forecasts[Object.keys(project.forecasts)[index]] }}

                      </span><br>
              <span class="text-dark">
                        {{ result.y + result.g }}
                      </span>
            </td>
          </template>
        </tr>

        <template v-if="opens.indexOf(p_key)+1" v-for="(keyword, k_key, k_index) in project.keywords">
          <tr>
            <td>{{ k_key }}</td>
            <template v-for="(result, k) in keyword">
              <td class="text-center">

                <span class="text-dark"> {{ result.yandex }}</span>
              </td>
              <td class="text-center">
                <span class="text-dark"> {{ result.google }}</span>
              </td>
            </template>

          </tr>

        </template>


      </template>

    </table>


  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import moment from 'moment';

export default {
  name: 'FullInfo',

  data() {
    return {
      dates: [],
      projects: [],
      projects_names: [],
      opens: [],
      countries: [],
      active_country: null,
      sum_dates: {},
      full_links: [],
      link: '',
      filters: {
        tone: 'negative',
        state: 20,
      },
      uniq: false,
      start_date: moment().add(-14, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    }
  },
  watch: {
    active_country: function (val) {
      this.getData()
    },
  },
  computed: {
    info() {
      let projects = this.projects, info_data = {};
      for (let index in projects) {
        let project = projects[index], results = Object.values(project.keywords)[0];
        let p_result = [];
        for (let res in results) {
          let y = Object.values(project.keywords).map((key) => {
            return key[res].yandex
          }).reduce((a, c) => {
            return a + c
          });
          let g = Object.values(project.keywords).map((key) => {
            return key[res].google
          }).reduce((a, c) => {
            return a + c
          });
          p_result.push({
            "y": y,
            "g": g
          });
          if (!info_data[res]) info_data[res] = {g: 0, y: 0};
          info_data[res].g += g;
          info_data[res].y += y;
        }

        projects[index]['results'] = p_result;
      }
      return {'projects': projects, 'total': info_data};
    }

  },
  mounted: function () {
    let $this = this;
    ProjectApi.countries().then(response => {
      $this.countries = response.results;
      $this.active_country = $this.countries[0];
      this.getData();
    });
    ProjectApi.get_select_list().then(response => this.projects_names = response);


  },
  methods: {

    getProjectName: function (id) {
      let project = this.projects_names.filter((pr) => {
        return pr.id == id
      });
      let name = '-';
      if (project.length) {
        project = project[0]
        name = project.name
        if (project.project_type === 'archive') name = 'Архив_' + name
        if (project.user) name = 'Свободный из TR.com_' + name
      }
      return name;
    },
    getData: function () {

      let $this = this, filter = this.filters;
      if (this.uniq) filter.uniq = true;
      else delete filter.uniq;
      filter.date_start = this.start_date;
      filter.date_end = this.end_date;
      filter.country_code = $this.active_country.code;
      let f_string = new URLSearchParams(this.filters).toString();
      ProjectApi.get_full_stat(f_string).then(response => {
        $this.projects = response;
      })
    },

    checkOpen: function (project) {
      let index = this.opens.indexOf(project);
      if (index + 1) {
        this.opens.splice(index, 1);
      } else {
        this.opens.push(project)
      }
    },
    setValue(obj, name, value) {
      this.$func.setValue(obj, name, value);
    }
  }
}

</script>

<style scoped>
.card {
  white-space: nowrap;
  overflow-x: scroll;
  padding-bottom: 50px;
}

.col-t.one {
  width: 80px;
  margin: 0;
}

.col-t.two {
  width: 160px;
  margin: 0;
}

.col-t {
  text-align: center;
  height: 30px;
}

.col-t, .col-t-3 {
  border: 1px solid #ccc;
  padding: 2px;
}

.no-b {
  border: none;
}

.col-t, .col-t-3 {
  display: inline-block;
}

.col-t-3 {
  width: 350px;
  white-space: normal;
}

.row-t {
  display: table;
}

.fixed, tr td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
}

.gray {
  background: #eee !important;
}

.fixed:not(.gray-bg) {
  background: #fff;
}

.fixed + div {
  /*margin-left: 350px!important;*/
}

.col-t-3.no-b.fixed {
  height: 40px;
  margin-bottom: -10px;
  border-right: 1px solid #ccc;
}

.uniq-p {
  padding-top: 40px;
}
</style>

