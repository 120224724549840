import {MobileApi} from "@/api/mobile";

const MobileProject = {
    state: {
        favorites: [],
    },
    mutations: {
        setFavorites(state, favorites) {
            state.favorites = favorites;
        },
        updateFavorite(state, response) {
            let project = state.favorites.find(item => item.id === response.id);
            if (!project) state.favorites.push(response)
            else {
                project = {...project, ...response}
                state.favorites.splice(
                    state.favorites.findIndex(item => item.id === project.id),
                    1,
                    project
                );
            }
        },
    },
    actions: {
        favorites({commit}) {
            if (this.getters.isLoggedIn)
                MobileApi.list().then((response) => {
                    commit("setFavorites", response.results);
                });
        },
        updateFavorite({commit}, data) {
            commit("updateFavorite", data);
        },
        getFavorite({commit, getters}, id) {
            let project = getters.favorites.find(item => item.id === parseInt(id));
            let date = project.last_parser;
            let active_country = project.countries.find(x => x.active), country_code = 'RU';
            if (active_country) country_code = active_country.code
            else country_code = project.countries[0].code
            MobileApi.get(project.id, country_code, date).then(response => {
                    if (date) response.last_parser = date;
                    commit("updateFavorite", response);
                }
            )
        }
    },
    getters: {
        favorites: (state) => state.favorites,
        favor_project: (state) => (project_id) => {
            return state.favorites.find(item => item.id === parseInt(project_id));
        }
    },
};

export default MobileProject;
