<template>
  <div>
    <form v-on:submit.prevent="$modal('show', 'create_report');"
          :class="`rounded p-3 ${full_width?'':'border border-primary'} ${type}`">
      <div class="row mb-3 align-items-center">
        <label v-if="full_width">Тип отчёта</label>
        <h5 v-else><i class="bi bi-file-earmark-arrow-down"></i>Отчёты</h5>
        <div class="col">
          <select required v-model="report.report_type" id="id_report" class="form-select">
            <option v-for="(type_name, type) in report_types" :value="type" :key="type">{{ type_name.name }}</option>
          </select>
        </div>
      </div>
      <button class="btn btn-dark" type="submit">Выбрать</button>
    </form>
    <modal :modal_id="'create_report'">
      <template v-slot:header>
        <h1 v-if="report_types[report.report_type]">Отчёт "{{ report_types[report.report_type].name }}"</h1>
      </template>
      <template v-slot:body>
        <form v-on:submit.prevent="submitReport" class="modal-form rounded p-3">
          <template v-if="report_types[report.report_type]">
            <div v-if="report_types[report.report_type].settings.country !== null && project_countries.length"
                 :class="'row mb-3 align-items-center' +
          (report.project_error?' form-group--error':'')">

              <div class="col-2">
                <label for="id_project">Страны</label>
              </div>
              <div class="col" id="countries">
                <MultiSelect
                    v-model="additional.countries"
                    :options="project_countries"
                    :multi="report_types[report.report_type].settings.country"
                    name=""></MultiSelect>

              </div>
            </div>
            <div v-if="report_types[report.report_type].settings.engine" class="row mb-3 align-items-center">
              <div class="col-2">
                <label for="id_project">Поисковая система</label>
              </div>
              <div class="col" id="countries">
                <MultiSelect
                    v-model="additional.engine"
                    :options="$ENGINES.map(x=> {return {name:x, id: x}})"
                    :multi="false"
                    :only_value="true"
                    name=""></MultiSelect>

              </div>
            </div>
            <div v-if="report_types[report.report_type].settings.selected_parts" class="row mb-3 align-items-center">
              <div class="col-2">
                <label></label>
              </div>
              <div class="col">
                <div v-for="country in additional_countries" class="card country">

                  <div class="card-body">
                    <strong class="card-title no-wrap">{{ country.name }} <sup>{{ country.code }}</sup></strong>
                    <div class="form-check">
                      <input class="form-check-input" :disabled="!country.can_tone" type="checkbox"
                             v-model="country.settings.dynamics">
                      <label class="form-check-label">
                        Динамика
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" :disabled="!country.can_tone" type="checkbox"
                             v-model="country.settings.plan">
                      <label class="form-check-label">
                        План-факт
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"
                             v-model="country.settings.search_result">
                      <label class="form-check-label">
                        Поисковые разультаты
                      </label>
                    </div>
                    <div class="d-flex">
                      <div class="form-check me-2">
                        <input class="form-check-input" :disabled="!country.yandex"
                               type="checkbox"
                               v-model="country.settings.yandex">
                        <label class="form-check-label">
                          Y
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" :disabled="!country.google"
                               type="checkbox"
                               v-model="country.settings.google">
                        <label class="form-check-label">
                          G
                        </label>
                      </div>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="country.settings.placement">
                      <label class="form-check-label">
                        Размещение публикаций
                      </label>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" :disabled="!country.hint" type="checkbox"
                             v-model="country.settings.hint">
                      <label class="form-check-label">
                        Подсказки
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" :disabled="country.code !== 'RU'" type="checkbox"
                             v-model="country.settings.lawyer">
                      <label class="form-check-label">
                        Юр.работы (только для РФ)
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" :disabled="!(simpleProject.wiki && simpleProject.wiki_link)"
                             type="checkbox"
                             v-model="country.settings.wiki">
                      <label class="form-check-label">
                        Wikipedia
                      </label>
                    </div>


                  </div>
                </div>
              </div>
            </div>


            <div
                v-if="report_types[report.report_type].settings.keyword && additional.countries && project_keywords.length"
                :class="'row mb-3 align-items-center' +
          (report.project_error?' form-group--error':'')">

              <div class="col-2">
                <label for="id_project">Запрос</label>
              </div>
              <div class="col" id="countries">
                <MultiSelect
                    v-model="additional.keyword"
                    :options="project_keywords"
                    v-bind:multi="false"
                    name=""></MultiSelect>
              </div>
            </div>

            <div v-show="!(['auditor_report'].indexOf(report.report_type)+1)"
                 :class="'row mb-3 align-items-center'+ (report.date_error?' form-group--error':'')">

              <div class="col-2">
                <label for="id_date">Период</label>
              </div>
              <div class="col">

                <div v-if="!report_types[report.report_type].settings.date"
                     class="d-flex justify-content-start align-items-center">

                  <span class="me-2">на</span>
                  <date-picker
                      v-model="report.date"
                      locale="ru"
                      :clearable="false"
                      auto-apply
                      :enable-time-picker="false"
                      format="dd.MM.yyyy"
                      :allowed-dates="dates.length?dates:allDates.length?allDates:undefined"
                      model-type="yyyy-MM-dd"
                  ></date-picker>
                </div>
                <div v-else class="d-flex justify-content-start align-items-center">
                  <span class="me-2">с</span>
                  <date-picker id="id_date"
                               class="me-1"
                               v-model="report.date_start"
                               locale="ru"
                               :clearable="false"
                               auto-apply
                               :enable-time-picker="false"
                               format="dd.MM.yyyy"
                               :allowed-dates="dates.length?dates:allDates.length?allDates:undefined"
                               model-type="yyyy-MM-dd"></date-picker>
                  <span class="me-2">по</span>
                  <date-picker v-model="report.date"
                               locale="ru"
                               :clearable="false"
                               auto-apply
                               :enable-time-picker="false"
                               format="dd.MM.yyyy"
                               :allowed-dates="dates.length?dates:allDates.length?allDates:undefined"
                               model-type="yyyy-MM-dd"></date-picker>
                </div>
                <div class="error" v-if="report.date_error">{{ report.date_error }}</div>
              </div>
            </div>

            <div v-if="report_types[report.report_type].settings.source"
                 :class="'row mb-3 align-items-center'+ (report.source_error?' form-group--error':'')">
              <div class="col-2">
                <label for="id_state">Сформировать из:</label>
              </div>
              <div class="col">
                <select v-model="report.source" id="id_template" class="form-select">
                  <option v-for="(name, val) in source_options" :value="val">{{ name }}</option>
                </select>
                <div class="error" v-if="report.source_error">{{ report.source_error }}</div>
              </div>
            </div>


            <div
                v-if="report_types[report.report_type].settings.template && report_types[report.report_type].settings.template.length"
                :class="'row mb-3 align-items-center'+ (report.template_error?' form-group--error':'')">
              <div class="col-2">
                <label for="id_state">Шаблон</label>
              </div>
              <div class="col">
                <select v-model="report.template" id="id_template" class="form-select">
                  <option v-for="name in  report_types[report.report_type].settings.template" :value="name.value">
                    {{ name.name }}
                  </option>
                </select>
                <div class="error" v-if="report.template_error">{{ report.template_error }}</div>
              </div>
            </div>


            <div>
              <div v-if="report_types[report.report_type].settings.title_photo"
                   :class="'row mb-3 align-items-center'+ (report.title_photo_error?' form-group--error':'')">
                <div class="col-2">
                  <label for="id_title_photo">Фото клиента</label>
                </div>
                <div class="col">
                  <input id="id_title_photo" class="form-control" type="text" v-model="additional.title_photo"/>
                  <div class="error" v-if="additional.title_photo_error">{{ additional.title_photo_error }}</div>
                </div>
              </div>
              <div v-if="report_types[report.report_type].settings.previous_presentation"
                   :class="'row mb-3 align-items-center'+ (report.previous_presentation_error?' form-group--error':'')">
                <div class="col-2">
                  <label for="id_previous_presentation">Ссылка на прошлый отчёт</label>
                </div>
                <div class="col">

                  <div class="input-group mb-3">

                    <input id="id_previous_presentation" class="form-control" type="text"
                           v-model="additional.previous_presentation">
                    <div class="input-group-text">
                      <label for="">сохранить результат в той же папке</label>
                      <input type="checkbox" class="form-check-input mt-0 ms-1"
                             aria-label="сохранить результат в той же папке"
                             v-model="additional.previous_presentation_near">
                    </div>
                  </div>

                  <div class="error" v-if="additional.previous_presentation_error">{{
                      additional.previous_presentation_error
                    }}
                  </div>
                </div>
              </div>
            </div>


            <div v-if="report_types[report.report_type].settings.state"
                 :class="'row mb-3 align-items-center'+ (report.state_error?' form-group--error':'')">
              <div class="col-2">
                <label for="id_state">ТОП</label>
              </div>
              <div class="col">
                <select v-model="report.state" id="id_state" class="form-select">
                  <option v-for="state in states" :value="state">{{ state }}</option>
                </select>
                <div class="error" v-if="report.state_error">{{ report.state_error }}</div>
              </div>
            </div>

            <div v-if="report_types[report.report_type].settings.link"
                 class="row mb-3 align-items-center">
              <div class="col-2">
                <label for="id_state">Ссылка</label>
              </div>
              <div class="col link-area" id="links" role="search">
                <i class="bi bi-search"></i>
                <input class="form-control" type="text" v-model="q_link" placeholder="Начните вводить ссылку">
                <ul class="search-result" v-if="link_search.length">
                  <li v-for="link in link_search" class="list-group-item d-flex justify-content-between">
                    <a type="button" @click="additional.link=link.id; q_link=link.link; link_search=[]"
                       :style="$func.getClass(link)">
                      {{ link.link }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <div class="row mb-3 align-items-center">
            <div class="col-2">
              <label for="id_email">Отправить на Email</label>
            </div>
            <div class="col">
              <input id="id_email" class="form-control" v-model="report.email" type="text"/>
            </div>
          </div>
          <button type="button" class="btn btn-secondary me-2" @click="$modal('hide', 'create_report');"
                  data-bs-dismiss="modal">Отмена
          </button>
          <button class="btn btn-success" type="submit">Сформировать отчёт</button>
        </form>
      </template>

    </modal>
    <template v-if="request.id && !request.report_file">
      Ожидание результата ...
    </template>
    <template v-if="request.report_file">
      <a :href="request.report_file"><i class="bi bi-file-arrow-down"></i> Скачать отчёт</a><br>

    </template>
    <template v-else-if="request.error">
      <span class="text-danger"><i class="bi bi-exclamation-lg"></i> Произошла ошибка</span>
    </template>
  </div>
</template>

<script>
import {ReportApi} from "@/api/reports";
import {LinkApi, ProjectApi} from "@/api/project";
import {HintApi} from "@/api/hints";
import {state} from "@/socket";
import axios from "axios";
import {HTTP} from '@/api/common'

export default {
  name: "SmallReport",
  props: {
    date_start: {},
    date: {},
    state: {type: Number},
    country_id: {type: Number},
    allDates: {
      type: Array, default() {
        return []
      }
    },
    date_config: {
      type: Object, default() {
        return {}
      }
    },
    simpleProject: {
      type: Object, default() {
        return {}
      }
    },
    full_width: {
      type: Boolean,
      default: false
    },
    successFunc: {
      type: Function,
      default: null
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      reports: {},
      request: {},
      report: {},
      q_link: "",
      link_search: [],
      get_dates: false,
      states: [10, 20, 30, 50, 100],
      old_report_type: null,
      source_options: {
        'canon_search_result': 'Канонической выдачи',
        'client_search_result': 'Клиентской выдачи',
      },
      project_keywords: [],
      links: [],
      dates: [],
      controller: new AbortController(),
      link_request: false,
      additional: {
        link: '', countries: [], keyword: '',
        previous_presentation: '',
        previous_presentation_near: false,
        title_photo: '',
        selected_parts: {}
      },
    }
  },
  components: {},

  watch: {
    q_link: function (val) {
      if (val.length > 3 && val !== 'http' && val !== 'https') {
        this.searchLinks()
      } else {
        this.link_search = []
      }

    },
    simpleProject() {
      this.setReport()
    },
    state() {
      this.setReport()
    },
    country_id() {
      this.setReport()
    },
    dates() {
      if (this.dates.length) {
        this.dates.sort()
        let date = this.dates[this.dates.length - 1];
        this.report.date = date;
        this.report.date_start = date;
      }
    },
    "report.report_type": {
      deep: true,
      handler(val) {
        if (this.reports[val]) {
          let type_date = this.reports[val].settings.date_setting
          switch (type_date) {
            case 'hint':
              HintApi.get_avail_date_calendar(this.$route.params.id).then(response => this.dates = response)
              break;

            case 'image':
              ProjectApi.active_images_dates(this.$route.params.id).then(response => this.dates = response)
              break;

            default:
              ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.dates = response)
              break;
          }

        }
      }
    },
    report: {
      handler(val) {
        if (val.report_type !== this.old_report_type) {
          this.additional.countries = []
        }
        this.old_report_type = val.report_type
        if (this.reports[val.report_type] && !this.reports[val.report_type].settings.date && val.date) {
          this.report.start_date = val.date;
        }
      },
      deep: true
    },
    ws_state: {
      deep: true,
      handler(val) {
        if (this.request.id === val.id) {
          this.request = {...this.request, ...val}
        }
      }
    }
  },
  computed: {
    ws_state() {
      return state.report;
    },
    allowedDates() {
      console.log(this.dates.length ? this.dates : this.allDates ? this.allowedDates : [])
      return this.dates.length ? this.dates : this.allDates ? this.allowedDates : [];
    },
    user() {
      return this.$store.getters.user;
    },
    project_countries() {
      return (this.report.project && this.simpleProject.countries !== undefined) ? this.simpleProject.countries : []
    },
    report_types() {
      return Object.fromEntries(Object.entries(this.reports).filter(x => this.full_width ? x : x[1].settings.section === this.$route.name))
    },
    project_links() {
      return this.links.map((x) => {
        return {id: x.id, name: x.link}
      })
    },
    additional_countries() {
      let $this = this;
      let qs = this.additional.countries ? this.additional.countries : [];
      qs.forEach(function (country) {
        country.settings = {
          dynamics: country.can_tone,
          plan: country.can_tone,
          search_result: true,
          yandex: country.yandex,
          google: country.google,
          placement: true,
          hint: country.hint,
          lawyer: country.code === 'RU',
          wiki: $this.simpleProject.wiki && !!$this.simpleProject.wiki_link
        }
      })
      return qs
    }
  },
  mounted() {
    this.get_dates = !this.allDates || !this.allDates.length;
    ReportApi.get_report_setting().then(response => this.reports = response)
    this.setReport();
    // LinkApi.get_tracked_links(this.simpleProject.id).then((links) => this.links = links)
  },
  methods: {
    cancelSearch() {
      this.link_request.cancel();
      this.link_request = null;
    },
    searchLinks: function () {
      let $this = this;
      if (this.link_request) this.cancelSearch();
      const axiosSource = axios.CancelToken.source();
      this.link_request = {cancel: axiosSource.cancel};
      HTTP.get(`links/search_by_q/?q=${this.q_link}&project=${this.simpleProject.id}`, {cancelToken: axiosSource.token}).then(resp => {
        $this.link_request = null;
        $this.link_search = resp.data
      })
    },
    setReport() {
      this.report = {
        report_type: {},
        date: (this.date && this.date.includes("/")) ? this.$moment(this.date, "YYYY-MM-DD").format("DD/MM/YYYY") : this.date,
        date_start: (this.date_start && this.date_start.includes("/")) ? this.$moment(this.date_start, "YYYY-MM-DD").format("DD/MM/YYYY") : this.date_start,
        project: this.simpleProject.id,
        state: this.simpleProject.active_state || 20,
        additional: {countries: [this.country_id], engine: null},
        source: 'canon_search_result',
        template: 'dr'
      }
    },
    submitReport() {
      let $this = this, error = false, fields = Object.keys($this.report);
      if (this.reports[this.report.report_type] && !this.reports[this.report.report_type].settings.date && this.report.date) {
        this.report.date_start = this.report.date
      }
      for (let field in fields) {
        let key = fields[field];
        if (!(key.indexOf('error') + 1) && key !== 'email' && !$this.report[key]) {
          error = true;
          $this.$func.setValue($this.report, `${key}_error`, 'Обязательное поле')
        }
      }

      if (!error) {

        if (this.additional) {
          this.report.additional = {};

          if (this.report_types[this.report.report_type].settings.selected_parts && this.additional.countries) {
            $this.report.additional["selected_parts"] = {}
            JSON.parse(JSON.stringify($this.additional.countries)).forEach(country => {
              $this.report.additional.selected_parts[country.id] = country.settings
            })
          }

          let additional = JSON.parse(JSON.stringify(this.additional));
          Object.keys(additional).forEach(x => {
            if (x !== "selected_parts") {
              if (Array.isArray(additional[x])) this.report.additional[x] = additional[x].map(c => c.id)
              else if (typeof (additional[x]) === 'object') this.report.additional[x] = [additional[x]?.id]
              else this.report.additional[x] = additional[x]

            }
          })

        }
        this.$modal('hide', 'create_report');
        ReportApi.create_report(this.report).then(response => {
          this.successCreate(response)

          this.report = {
            report_type: '', state: 20,
            date: this.report.date,
            date_start: this.report.date_start,
            project: this.simpleProject.id,
            email: ''
          };
          this.additional = {
            link: '', countries: [], keyword: '',
            previous_presentation: '',
            previous_presentation_near: false,
            title_photo: '',
            selected_parts: {}
          };


          this.$notify({
            type: 'success ',
            text: 'Отчёт отправлен на формирование'
          });
        }).catch(err => {
          this.report.project = this.simpleProject.id;
          let e = err.response.data;
          typeof (e) == 'object' && e.non_field_errors !== undefined && (e = e.non_field_errors.toString());
          this.$notify({
            type: 'error ',
            text: e
          });
        });

      }
    },
    successCreate(response) {
      this.request = {id: response.id};
      if (this.successFunc) {
        this.successFunc(response)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-form {
  min-height: 440px;
}

.link-area {
  position: relative;

  .search-result {
    position: absolute;
    max-height: 200px;
    overflow-x: auto;
    background: #fff;
    z-index: 10;
    border: 1px solid #cecece;
    border-radius: 20px;
    width: 95%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.line {
  display: flex;
  gap: 10px;
  padding: 10px !important;
  border-color: black !important;
  width: 400px;
  margin-left: auto;

  .row {
    display: flex;
    flex-direction: row;

    h5 {
      width: auto;
    }
  }
}
</style>
