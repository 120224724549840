<template>
    <main class="dashboard" >
      <div class="card mt-3" :style="{'overflow-x': 'hidden', }" >
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item col-3">
              <h3>Статистика по добавленным backlink'ам</h3>
            </li>
  
          </ul>
  
        </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-5">

              <multiselect
                  v-model="selected_contractors"
                  :options="contractors_options"
                  :multiple="true"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder="начните вводить подрядчика">
              </multiselect>
            </div>

            <div class="col-5 ">
              <multiselect
                  v-model="selected_project"
                  :options="project_options"
                  :close-on-select="false"
                  :multiple="true"
                  :show-labels="false"
                  placeholder="начните вводить проект">
              </multiselect>
            </div>
  
          </div>

          <div :style="{'display': 'flex', 'align-items': 'center', 'justify-content': 'space-between'}">
            <div class="col">
                Период:
                <div class="d-flex align-items-center justify-content-start col-5">
                    <div class="me-1 ">c</div>
                    <div class="me-1">
                    <date-picker
                        v-model="start_date"
                        :config="date_picker_options"
                    ></date-picker>
                    </div>
                    <div class="me-1">по</div>
                    <div class="me-1">
                    <date-picker
        
                        v-model="end_date"
                        :config="date_picker_options"
                    ></date-picker>
                    </div>
        
                </div>
            </div>
            <button type="button" class="btn btn-primary" @click="click_apply_filters()">Применить</button>
          </div>
  
          <div class="">
            <table class="table table-bordered ">
  
              <tr>
                <template>
                  <th><b>Проект</b></th>
                  <td v-for="contractor in actual_contractors()">{{ contractor }}</td>
                </template>
              </tr>
  
                <tr class="gray" v-for="project in actual_projects()">
  
                  <td>{{project }}</td>
                  <td v-for="contractor in actual_contractors()" >{{stat[project][contractor] }}</td>
                  
                </tr>
            </table>
  
          </div>
        </div>
      </div>
  
    </main>
  
  </template>
  
  <script>
  import {ProjectApi} from "@/api/project";
  import moment from 'moment';

  
  export default {
    name: 'ProjectBacklinks',
    components: {
      // Multiselect,
    },
    data() {
  
      return {
        selected_contractors: [],
        contractors_options: [],

        selected_project: [],
        project_options: [],

        stat: [],

        date_picker_options: {
          format: 'DD.MM.YYYY',
          locale: 'ru',
          maxDate: moment(),
          // useCurrent: true,
          ignoreReadonly: true,
        },

        start_date: moment().subtract(30, 'd').format('DD.MM.YYYY'),
        end_date: moment().format('DD.MM.YYYY'),

      }
    },
  
    mounted() {
        this.send_request()
    },
    
    methods: {

        actual_contractors(){
            if (this.selected_contractors.length>0){
                return this.selected_contractors
            }else{
                return this.contractors_options 
            }
        },

        actual_projects(){
            if (this.selected_project.length>0){
                return this.selected_project
            }else{
                return this.project_options 
            }
        },
  
        send_request() {
            let data = {
                start_date: this.start_date,
                end_date: this.end_date,
            }
            ProjectApi.get_backlink_stat(data).then(response => {
                this.contractors_options=response.contractors_options
                this.project_options=response.project_options
                this.stat=response.stat
            })
        },
    
      click_apply_filters() {
        this.send_request()
      },

    }
  }
  
  </script>
  
  
  <style>
  
  
  .multiselect__tag {
    background: #198754 !important;
  }
  
  .multiselect__option--selected.multiselect__option--highlight {
    background: #0d6efd !important;
    color: #fff;
  }
  
  .multiselect__option--highlight {
    background: #00000011 !important;
    outline: none !important;
    color: #35495e !important;
  }
  
  .multiselect__option .multiselect__option--highlight .multiselect__option--selected {
    background: #0d6efd !important;
    color: #fff;
  }
  
  .multiselect__option--selected {
    background: #0d6efd !important;
    color: #fff !important;
  }
  
  
  </style>

  <style scoped="">
  
  .card {
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: 50px;
  }
  
  .col-t.one {
    width: 80px;
    margin: 0;
  }
  
  .col-t.two {
    width: 160px;
    margin: 0;
  }
  
  .col-t {
    text-align: center;
    height: 30px;
  }
  
  .col-t, .col-t-3 {
    border: 1px solid #ccc;
    padding: 2px;
  }
  
  .no-b {
    border: none;
  }
  
  .col-t, .col-t-3 {
    display: inline-block;
  }
  
  .col-t-3 {
    width: 350px;
    white-space: normal;
  }
  
  .row-t {
    display: table;
  }
  
  .inner-cell {
    background: white;
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 260px;
  }
  
  /* td{
      border: 1px solid;
  } */
  
  table td, th {
    table-layout: fixed;
    width: 250px;
  }
  
  
  tr:nth-of-type(1), td:nth-of-type(1), th:nth-of-type(1),
  tr:nth-of-type(2), td:nth-of-type(2), th:nth-of-type(2),
  tr:nth-of-type(3), td:nth-of-type(3), th:nth-of-type(3),
  tr:nth-of-type(4), td:nth-of-type(4), th:nth-of-type(4),
  tr:nth-of-type(5), td:nth-of-type(5), th:nth-of-type(5) {
    background-color: white;
    position: sticky;
  }
  
  th:nth-of-type(1), td:nth-of-type(1), td:nth-of-type(1) {
    left: 0em;
    min-width: 6em;
    max-width: 6em;
  }
  
  th:nth-of-type(2), td:nth-of-type(2), td:nth-of-type(2) {
    left: 6em;
    min-width: 3em;
    max-width: 3em;
  }
  
  th:nth-of-type(3), td:nth-of-type(3), td:nth-of-type(3) {
    left: 9em;
    min-width: 8.5em;
    max-width: 8.5em;
  }
  
  th:nth-of-type(4), td:nth-of-type(4), tr:nth-of-type(4) {
    left: 32em;
    min-width: 20em;
    max-width: 20em;
  }
  
  th:nth-of-type(5), td:nth-of-type(5), tr:nth-of-type(5) {
    left: 48em;
  }
  
  
  .inner-cell {
    background: white;
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 260px;
  }
  
  
  .gray {
    background: #eee !important;
  }
  
  .col-rel {
    min-width: 6em;
    display: flex;
    justify-content: center;
    max-width: 8em;
  }
  
  .col-status {
    display: flex;
    justify-content: center;
    min-width: 4em;
  }
  
  .col-date-haed {
    text-align: center;
  }
  
  
  .fixed + div {
    /*margin-left: 350px!important;*/
  }
  
  .col-t-3.no-b.fixed {
    height: 40px;
    margin-bottom: -10px;
    border-right: 1px solid #ccc;
  }
  
  .uniq-p {
    padding-top: 40px;
  }
  
  
  .pagination > li > a, .pagination > li > span {
    border: 1px solid black;
    color: black;
  }
  
  .pagination > li.active > a {
    background: black;
    color: #fff;
    border-color: black;
    box-shadow: none;
  }
  
  .multiselect__option--highlight {
    background: #449b74;
  }
  
  .backlink-action-button {
    margin: 0 1px 0 1px;
  }
  
  
  </style>
  
  