import { HTTPText } from "./common";

export const DocumentApi = {
  list(filter) {
    return HTTPText("get", `documents/${filter?`?${filter}`:''}`).then((response) => {
      return response.data;
    });
  },
  get_content_by_link(doc){
    return HTTPText("post", 'documents/get_content_by_link/', doc).then((response) => {
      return response.data;
    });
  },
  download(id){
    return HTTPText("get", `documents/${id}/download/`, {responseType: "blob"}).then((response) => {
      return response.data;
    });
  },
  get_content_od(id){
    return HTTPText("get", `documents/${id}/get_content_od/`).then((response) => {
      return response.data;
    });
  },
  read_from_file(data){
    return HTTPText("post", `documents/read_from_file/`, data, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
      return response.data;
    });
  },
  update(object) {
    if (object.id)
      return HTTPText("put", `documents/${object.id}/`, object).then((response) => {
        return response.data;
      });
    else
      return HTTPText("post", "documents/", object).then((response) => {
        return response.data;
      });
  },

  get(id) {
    return HTTPText("get", `documents/${id}/`).then((response) => {
      return response.data;
    });
  },
  delete(id, is_active) {
    return HTTPText("post", `documents/${id}/delete/`, {is_active: is_active}).then((response) => {
      return response.data;
    });
  },
  recalc_unique(id) {
    return HTTPText("post", `documents/${id}/recalc_unique/`).then((response) => {
      return response.data;
    });
  },
  get_versions(id) {
    return HTTPText("get", `documents/${id}/get_versions/`).then((response) => {
      return response.data;
    });
  },
  restore_text(doc_id, version_id) {
    return HTTPText("post", `documents/${doc_id}/restore_text/`, {version: version_id}).then((response) => {
      return response.data;
    });
  },

};
