<template>
  <main class="container">
    <div class="row justify-content-center">
      <div class="col-sm-7 align-self-center">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">

          <h1>Смена пароля</h1>
        </div>
        <div class="card">


          <div class="card-body">


            <form @submit="changePassword" class="form-box flex-list step-32">
              <div class="form-floating mb-2">
                <input class="form-control" id="oldPassword" :type="show_pass?'text':'password'" name="" required
                       v-model="oldPassword" placeholder="Пароль">
                <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <label for="oldPassword">Текущий пароль</label>
                <div class="form-group__message">
                  <div class="error" v-for="error in baseErrors">- {{ error }}</div>
                </div>
              </div>
              <div class="form-floating mb-2">
                <input class="form-control " id="c" :type="show_pass?'text':'password'" name="" required
                       v-model="newPassword" placeholder="Пароль">
                <label for="newPassword">Новый пароль</label>
                <div class="form-group__message">
                  <div class="error" v-for="error in newErrors">- {{ error }}</div>
                </div>
              </div>
              <div class="form-floating mb-2">
                <input class="form-control " id="repeatPassword" :type="show_pass?'text':'password'" name="" required
                       v-model="repeatPassword" placeholder="Пароль">
                <label for="repeat">Повторите новый пароль</label>
                <i v-on:click="show_pass=!show_pass" style="right: 40px"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <div class="form-group__message">
                  <div class="error" v-for="error in repeatErrors">- {{ error }}</div>
                </div>
              </div>


              <div class="form-floating mb-2">
                <button class="w-100 btn btn-primary blue-bg" type="submit">Изменить пароль</button>
              </div>


            </form>


          </div>
        </div>
      </div>
    </div>
  </main>


</template>

<script>
import passwordMixin from "./password_mixins";
import axios from 'axios'

export default {
  name: 'ForcedPassword',
  mixins: [passwordMixin],
  data() {
    return {}
  },
  mounted() {
    let $this = this;
    if (!this.$route.query.token && !this.$route.query.uuid) this.$router.push({name: '404'});
    this.$store.dispatch('check_tokens', {
      token: $this.$route.query.token,
      uid: $this.$route.query.uuid
    }).then(() => {
    }).catch(err => {
      $this.$notify({
        type: 'error ',
        text: err.response.data
      })
      this.$router.push({name: '404'});
    })
  },
  methods: {
    changePassword: function (e) {
      e.preventDefault();
      let $this = this;
      if (!this.repeatErrors.length && !this.repeatErrors.length) {
        this.baseErrors = [];
        axios.post('/auth/staff/change_password/', {
          token: $this.$route.query.token,
          uid: $this.$route.query.uuid,
          old: $this.oldPassword,
          newp: $this.newPassword,
          repeat: $this.repeatPassword
        }).then((response) => {
           if (response.data['errors']) {
            $this.baseErrors = response.data['errors'];
          } else {
            this.$notify({
              type: 'success ',
              text: 'Пароль обновлён'
            });
            this.$router.push({name: 'login'});
          }
        }).catch(err => {
      $this.$notify({
        type: 'error ',
        text: err.response.data
      })
    })
      }
    }
  }
}

</script>
