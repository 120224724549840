<template>
  <div class="not-found">

    <h1><i class="bi bi-search"></i>404</h1>
    <h1>Страница не найдена</h1>
    <p>
      <router-link to="/"><i class="bi bi-arrow-left-short"></i> Вернуться на главную</router-link>
    </p>
  </div>

</template>
<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: center;
  justify-content: center;
}


</style>
