<template>
  <div class="card-body links graph">
    <CountryChoice
        v-bind:active_country="active_country"
        v-bind:countries="link.countries"
        v-bind:changeCountry="(country) => {active_country === country.code; countryFunc(link.id, country.code)}">
    </CountryChoice>
    <button class="btn btn-sm btn-outline-primary" @click="checked=!checked"
            style="position: absolute; right: 20px;">
      <template v-if="checked">Отключить</template>
      <template v-else>Включить</template>
      все
    </button>
    <div :id="`legend-${link.id}`" class="legend"></div>
    <div style="height: 450px">
      <Line
          v-if="datacollection"
          :options="chartOptions"
          :data="datacollection"
          :plugins="[htmlLegendPlugin]"
      />
    </div>

    <div class="table-responsive">
      <table class="table table-hover table-bordered">
        <thead>
        <tr>
          <th scope="col" class="sticky">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" :id="`offAll-${link.id}`"
                     @change="offAll"
                     checked>
              <label class="form-check-label" for="flexCheckChecked">
                Запросы ({{ keywords_data.length }})
              </label>
            </div>
          </th>
          <th scope="col" v-for="date in dates" class="no-wrap">{{ date[1] }} <span
              style="text-transform: uppercase">{{ $moment(date[1]).format('dd') }}</span>
          </th>
        </tr>
        </thead>
        <tbody class="table-group-divider">
        <tr v-for="(keyword, index) in keywords_data">
          <th scope="row" class="no-wrap sticky">
            <div class="form-check">
              <input :class="`form-check-input key-check-${link.id}`" type="checkbox"
                     @change="changeOne(index)"
                     :id="`key-${link.id}-${index}`" checked>
              <label class="form-check-label" for="flexCheckChecked">
                {{ keyword.name }}
              </label>
            </div>
          </th>
          <!--          <template>-->
          <td v-for="position in keyword.data"
              :class="`${position.diff&&position.pos?`bg-${position.diff > 0?'green':'pink'}`:''}`">
            {{ position.pos ? position.pos : '-' }}
            <sup v-if="position.diff&&position.pos"
                 :class="`text-${position.diff > 0?'success':'danger'}`">{{ position.diff }}</sup>
          </td>
          <!--            </template>-->
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
import RangeSlider from "@/plugins/RangeSlider"
import moment from 'moment'
import {Line} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement} from 'chart.js'
import htmlLegendPlugin from "@/components/legendPlugin";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

ChartJS.defaults.plugins.legend.display = false;

export default {
  name: "GraphLink",
  props: {
    link: {type: Object, default: {}},
    engine: {type: String, default: 'yandex'},
    countryFunc: {type: Function, default: null},
    day_of_week: {type: Array, default: []},
  },
  components: {
    Line, RangeSlider
  },
  data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      chartLegend: null,
      checked: true,
      active_country: {},
      default_value: null
    }
  },
  watch: {
    checked() {
      document.getElementById(`offAll-${this.link.id}`).click()
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x:
              {
                grid: {
                  display: false,
                },
                ticks: {
                  // autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: 12,
                  }
                },
              },
          y:
              {
                display: false,
                reverse: true,
                maxTicksLimit: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                suggestedMax: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                max: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,

              }
        },

        plugins: {
          htmlLegend: {
            containerID: `legend-${this.link.id}`,
          },
          legend: {
            display: false,
          }
        }
      }
    },

    value: {
      get() {
        if (!this.default_value) return this.link.dates ? [0, this.link.dates.length] : [0, 1];
        else return this.default_value;
      },
      set(val) {
        this.default_value = val;
      }
    },
    value_max: {
      get() {

        return this.link.dates ? this.link.dates.length : 0;
      },
      set(val) {
        return val;
      }
    },
    datacollection() {
      let $this = this, dataset = [], labels = [];
      let dynamicColors = function () {
        let r = Math.floor(Math.random() * 255), g = Math.floor(Math.random() * 255),
            b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      };
      this.keywords_data.forEach(function (keyword) {
        let col = dynamicColors();
        let param_data = {
          label: keyword.name,
          borderColor: col,
          lineTension: 0,
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
          backgroundColor: col,
          data: keyword.data.map(x => x.pos).slice($this.value[0], $this.value[1])
        }
        dataset.push(param_data)
        labels.push(keyword.name)
      })
      if (dataset.length) {
        let first_val = dataset[0].data
        let median = []
        first_val.forEach(function (el, index) {
          median.push(Math.round(dataset.map(x => x.data[index]).reduce((a, x) => a + x) / dataset.length))
        })
        dataset.unshift({
          label: 'Среднее значение',
          borderColor: dynamicColors(),
          backgroundColor: `rgba(255, 255, 255, 0)`,
          data: median
        })
      }
      return {labels: this.dates.map(date => date[1]).slice($this.value[0], $this.value[1]), datasets: dataset}
    },

    dates() {
      let dates = [], $this = this;
      if (this.day_of_week.length) {
        this.link.dates.forEach((date, index) => {
          let week = moment(date, 'YYYY-MM-DD').day()
          if ($this.day_of_week.indexOf(week + 1) + 1) {
            dates.push([index, date])
          }
        })
      } else {
        dates = this.link.dates || [];
        dates = dates.map((date, index) => [index, date])
      }
      return dates;
    },

    keywords_data() {
      let keys = [], $this = this, date_index = this.dates.map(el => el[0]);
      if (this.link.keywords) this.link.keywords.forEach(function (keyword) {
        let dates = keyword[$this.engine], sum = dates.reduce((s, d) => s + d, 0);
        if (sum > 0) {
          let calc_dates = []
          dates.forEach(function (el, index) {
            if (date_index.indexOf(index) + 1) {
              let diff = 0
              if (dates[index + 1]) {
                diff = dates[index + 1] - el
              }
              calc_dates.push({pos: el, diff: diff})
            }
          })
          keys.push({name: keyword.name, data: calc_dates})
        }
      })
      return keys;
    },

  },
  mounted() {
    this.default_value = null;
    this.active_country = this.link.countries[0];
  },
  methods: {
    offAll() {
      let keywords = document.getElementsByClassName(`key-check-${this.link.id}`);
      let $this = this;
      for (let i = 0; i < keywords.length; i++) {
        keywords[i].checked = document.getElementById(`offAll-${$this.link.id}`).checked;
        $this.changeOne(i);
      }
    },
    changeOne(index) {
      let legend = document.getElementById(`legend-container-${this.link.id}`);
      let key = legend.getElementsByTagName('li')[index + 1];
      let check = document.getElementById(`key-${this.link.id}-${index}`)
      if (check.checked && key.className === 'hidden' || !check.checked && key.className !== 'hidden') key.click()
    }
  }
}
</script>
<style>
.graph{
.legend ul {
  flex-direction: column;
    gap: 0;
  display: block;
    column-count: 4;
  li {
    overflow: hidden;
    p {
      padding: 0;
      border-width: 3px;
      border-style: solid;
      border-radius: 0;
      display: block;
      font-size: 12px;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
}

</style>