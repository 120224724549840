<template>
  <div class="table-responsive">
    <table class="table dict">
      <thead>
      <tr>
        <th scope="col"> №</th>
        <th scope="col" class="mw200">Площадка</th>
        <th scope="col" class="mw200">Референс</th>
        <th scope="col">Кол-во текстов</th>
        <th scope="col">Статус</th>
        <th scope="col">Дата добавления</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr class="empty"></tr>
      <template v-for="(object, index) in objects">
        <tr>
          <td>{{ index + 1 }}</td>
          <td>{{ object.placement_name }}</td>
          <td class="text-area">
            <template v-if="object.generated_content">
              <a :href="object.generated_content" target="_blank">{{ object.text }} <i>( символов
                {{ object.max_length }})</i>
                <i class="bi bi-file-arrow-down ms-1"></i>
              </a>

            </template>
            <template v-else>{{ object.text }} <i>( символов {{ object.max_length }})</i></template>
          </td>

          <td>{{ object.ready }}/{{ object.n_questions }}</td>

          <td>{{ object.get_status_display }}</td>
          <td>{{ $moment(object.date_create) }}</td>
          <td>
            <div class="d-flex" v-if="!object.status || (['new', 'error'].indexOf(object.status) + 1)">
              <button v-if="!object.edit"
                      v-on:click.prevent="$func.setValue(object, 'edit', true);"
                      class="no-wrap btn btn-primary btn-sm"><i class="bi bi-pencil"></i>

              </button>

              <button v-if="object.id" @click="sendToGenerate(object.id)"
                      class="btn btn-sm btn-success no-wrap ms-1">
                <i class="bi bi-send" title="Отправить на генерацию"></i>
              </button>
            </div>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

  </div>
</template>

<script>

export default {
  name: "TableList",
  props: ["objects"],
  data() {
    return {}
  },

  mounted: function () {

  },
  computed: {}
}
</script>