<template>
  <main>

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">

      <div class="d-flex justify-content-start grid gap-3">
        <MultiSelect v-model="filter.seo"
                      name="SEO специалист"
                      field_name="fullname"
                      :options="managers.filter(x => x.post_type==='serm')"
                      :multi="false"
                      :only_value="true">
        </MultiSelect>
        <MultiSelect v-model="filter.manager"
                      name="Менеджер проекта"
                      field_name="fullname"
                      :options="managers.filter(x => x.post_type==='manager')"
                      :multi="false"
                      :only_value="true">
        </MultiSelect>
        <MultiSelect v-model="filter.auditor"
                      name="Аудитор"
                      field_name="fullname"
                      :options="managers.filter(x => x.post_type==='auditor')"
                      :multi="false"
                      :only_value="true">
        </MultiSelect>
      </div>

      <div class="text-secondary no-wrap">
        Показано проектов {{ filtered_projects.length }} из {{ all_count }}
      </div>

    </div>
<!--    <draggable v-model="projects" item-key="id" @change="changeSort" class="projects">-->
<!--  <template #item="{element}">-->
<!--    <ProjectInfo :project="element"></ProjectInfo>-->
<!--  </template>-->
<!--</draggable>-->
    <div class="projects">
      <ProjectInfo v-for="project in filtered_projects" :project="project"></ProjectInfo>
    </div>
    <nav>
      <ul class="pagination justify-content-center fz-9" v-if="pager.length">
        <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
          <a v-if="typeof p === 'number'" class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
          <span class="page-link" v-else>{{ p }}</span>
        </li>
      </ul>
    </nav>
  </main>
</template>

<script>
import {ProjectApi} from "@/api/project";
import {UserApi} from "@/api/user";
import ProjectInfo from "@/components/ProjectInfo.vue"
import draggable from 'vuedraggable'

export default {
  name: 'FrontView',
  components: {
    ProjectInfo,
    draggable
  },
  data() {
    return {
      projects: [],
      managers: [],
      filter: {seo: '', manager: '', auditor: ''},
      page: 1,
      size: 20,
      all_count: 0
    }
  },
  computed: {
    filters() {
      return this.$store.getters.project_filters || []
    },
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      return Array.from({length: page_count}, (_, i) => i + 1);
    },
    filtered_projects() {
      let favor = this.$store.getters.user.favorites || [];
      let qs = this.projects;
      if (favor.length) {
        qs = qs.map((pr) => {
          if (favor.filter(p => p.id === pr.id).length) pr.sort = -100
          return pr
        })
      }
      return qs.sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0))
    }
  },
  watch: {
    filters() {
      this.getProjects()
    },
    filter: {
      deep: true,
      handler() {
        this.getProjects();
      }
    },
    page: {
      deep: true,
      handler() {
        this.getProjects();
      }
    }
  },
  mounted: function () {
    this.getProjects()
    UserApi.managers().then(response => this.managers = response);
  },
  methods: {
    changeSort(val, val1){
      console.log(val)
      console.log(val1)
    },
    getProjects() {
      let filter = JSON.parse(JSON.stringify(this.filter));
      filter['project_type'] = this.filters;
      let $this = this;
      filter = `page=${this.page}&size=${this.size}&query=${JSON.stringify(filter)}`
      ProjectApi.list(filter).then(response => {
        $this.projects = response.results;
        $this.all_count = response.count;
      });
    }
  }

}

</script>

