<template>
  <div>

    <div class="text-area "
         contenteditable="true"
    >
      <div style="white-space: break-spaces;" class="redactor">
        <p>
        <span v-for="word in words" v-html="word.val" :class="`${word.ex?'high':''} ${word.in?'high2':''}`">
        </span>
        </p>
      </div>
    </div>
    </div>

</template>

<script>
export default {
  name: 'Editor',

  props: ['words'],

  data() {
    return {
      innerValue: this.value
    }
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.innerHTML)
    }
  }
}
</script>


<style scoped>

.redactor {
  height: 450px;
  padding: 10px;
  font: 20px/28px 'Open Sans', sans-serif;
  letter-spacing: 1px;
  display: block;
  margin: 0;
  border: 2px solid #74637f;
  border-radius: 0;
  color: #444;
  overflow: auto;
  resize: none;
  transition: transform 1s;
}

.redactor:focus {
  outline: none;
  box-shadow: 0 0 0 2px #c6aada;
}

.redactor p {
  margin-bottom: 0;
}

.high {
  border-radius: 3px;
  background-color: #97D077 !important; /* or whatever */
}

.high2 {
  border-radius: 3px;
  background-color: #99CCFF !important; /* or whatever */
}


</style>
