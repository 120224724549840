import Vuex from "vuex";
import User from "@/store/user";
import Project from "@/store/objects";
import MobileProject from "@/store/mobile";
import Alert from "@/store/alerts";


export default new Vuex.Store({
  modules: {
    User,
    Project,
    MobileProject,
    Alert
  },
});
