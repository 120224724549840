import axios from "axios";
import store from "../store/index";
import router from "@/router";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.withCredentials = true;
export let cancelSource = axios.CancelToken.source();

export const requestInterceptor = config => {
  config.cancelToken = cancelSource.token;
  return config;
};
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.request.use(
  function (config) {

    store.dispatch("loader", true);
    return config;
  },
  function (error) {
    store.dispatch("loader", false);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {

    store.dispatch("loader", false);
    return response;
  },
  function (error) {
    if (error.response.status === 404) {
      router.push('/404')
    }
    if (error.response.status === 403) {
      alert("Действие запрещено")
    }
    if (error.response.status === 401) store.dispatch("logout");
    store.dispatch("loader", false);
    return Promise.reject(error);
  }
);
export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    // Set your Authorization to 'JWT', not Bearer!!!
    Authorization: `JWT ${store.getters.jwt}`,
    "Content-Type": "application/json",
  },
  xhrFields: {
    withCredentials: true,
  },
});

export default HTTP;
