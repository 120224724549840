import { HTTP } from "./common";

export const RelatedApi = {
  list(project_id, filter) {
    return HTTP.get(`related/${project_id}/get_data/?${filter}`).then((response) => {
      return response.data;
    });
  },
  get_filters(project_id, country_code) {
    return HTTP.get(`related/${project_id}/get_filters/?country_code=${country_code}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  update_tone(related) {
    return HTTP.post(`related/update_tone/`, related).then((response) => {
      return response.data;
    });
  }
};
