<template>
  <main class="dashboard">
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">
        <template v-if="project?.id">Редактировать</template>
        <template v-else>Добавить</template>
        проект
        <template v-if="project?.name">: {{ project?.name }}</template>
        <button v-if="project?.id" @click="addToFavor();" class="btn"><i
            :class="`h3 text-warning bi bi-star${project?.favorite?'-fill':''}`"></i></button>
      </h1>
      <BackButton></BackButton>
    </div>
    <div>
      <SettingMenu v-if="$route.params.id"
                   :id="$route.params.id" v-bind:route="$route.name" v-bind:project="project"
      >
      </SettingMenu>

    </div>

    <form class="needs-validation form-edit " novalidate="" v-if="project">
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div :class="'col-8'+ (project.name_error ?' form-error':'')">
              <label>Название проекта <sup>*</sup></label>
              <input type="text" placeholder="Название проекта" class="form-control" id="name"
                     :disabled="!(can_edit && checkFieldPerm('name'))"
                     v-model="project.name" required="">
              <div class="error" v-if="project.name_error">{{ project.name_error }}</div>
              <button class="btn btn-sm btn-outline-dark mt-3" v-if="project.person && !show_case"
                      @click="show_case=!show_case">Настройка падежей <i class="bi bi-caret-down-fill"></i></button>
              <ul class="rp" v-if="project.person && show_case">
                <li><label>Именительный</label><input type="text" placeholder="Именительный падеж" class="form-control"
                                                      :disabled="!(can_edit && checkFieldPerm('person'))"
                                                      v-model="project.person.name"></li>
                <li><label>Родительный</label><input type="text" placeholder="Родительный падеж" class="form-control"
                                                     :disabled="!(can_edit && checkFieldPerm('person'))"
                                                     v-model="project.person.gent"></li>
                <li><label>Дательный</label><input type="text" placeholder="Дательный падеж" class="form-control"
                                                   :disabled="!(can_edit && checkFieldPerm('person'))"
                                                   v-model="project.person.datv"></li>
                <li><label>Винительный</label><input type="text" placeholder="Винительный падеж" class="form-control"
                                                     :disabled="!(can_edit && checkFieldPerm('person'))"
                                                     v-model="project.person.accs"></li>
                <li><label>Творительный</label><input type="text" placeholder="Творительный падеж" class="form-control"
                                                      :disabled="!(can_edit && checkFieldPerm('person'))"
                                                      v-model="project.person.ablt"></li>
                <li><label>Предложный</label><input type="text" placeholder="Предложный падеж" class="form-control"
                                                    :disabled="!(can_edit && checkFieldPerm('person'))"
                                                    v-model="project.person.loct"></li>
              </ul>
            </div>
            <div
                :class="'col-4'+(can_edit && checkFieldPerm('project_type')?'':' area-disabled')+ (project.project_type_error ?' form-error':'')">
              <label>Статус <sup>*</sup></label>
              <MultiSelect
                  v-model="project.project_type"
                  :options="project_types"
                  :multi="false"
                  :name="'Статус'"
                  :required="true"
                  :only_value="true"
                  :max="5"
              ></MultiSelect>
              <div class="error" v-if="project.project_type_error">{{ project.project_type_error }}</div>
            </div>
          </div>
          <div class="row">
            <div
                :class="'col-8'+ (project.countries_error ?' form-error':'')+(can_edit && checkFieldPerm('countries')?'':' area-disabled')">
              <label>Страны <sup>*</sup></label>
              <MultiSelect
                  v-model="project.countries"
                  :model_error="project.country_error"
                  :options="countries"
                  :required="true"
                  :multi="true"
                  name="Страны"
                  :max="5"
              ></MultiSelect>
              <div class="error" v-if="project.countries_error">{{ project.countries_error }}</div>
            </div>
            <div
                :class="'col-4'+ (project.active_state_error ?' form-error':'')+(can_edit && checkFieldPerm('default_state')?'':' area-disabled')">
              <label>Топ</label>
              <MultiSelect
                  v-model="project.default_state"
                  :model_error="project.default_state_error"
                  :options="states.map(x => {return {id: x, name: `ТОП ${x}`}})"
                  :multi="false"
                  name="Топ"
                  :only_value="true"
              ></MultiSelect>

            </div>
          </div>
          <div :class="'country-cards' + (can_edit && checkFieldPerm('countries')?'':' area-disabled')"
               v-if="project.countries && project.countries.length">

            <div v-for="country in project_countries" class="card country">
              <div class="card-body">
                <strong class="card-title no-wrap">{{ country.name }} <sup>{{ country.code }}</sup></strong>
                <div class="checkbox">
                  <input :id="'tone'+country.id" :disabled="!country.can_tone" type="checkbox" v-model="country.tone">
                  <label :for="'tone'+country.id">
                    Тональность
                  </label>
                </div>
                <div class="checkbox">
                  <input :id="'theme'+country.id" :disabled="!country.can_theme" type="checkbox"
                         v-model="country.theme">
                  <label :for="'theme'+country.id">
                    Темы
                  </label>
                </div>
                <div class="checkbox">
                  <input :id="'hint'+country.id" type="checkbox" v-model="country.hint">
                  <label :for="'hint'+country.id">
                    Подсказки
                  </label>
                </div>
                <div class="checkbox">
                  <input :id="'hint_screen'+country.id" type="checkbox"
                         @change="country.hint=country.hint_screen?country.hint_screen:country.hint"
                         v-model="country.hint_screen">
                  <label class="form-check-label" :for="'hint_screen'+country.id">
                    Подсказки скриншотами
                  </label>
                </div>
                <div class="checkbox">
                  <input :id="'image'+country.id" type="checkbox" v-model="country.image">
                  <label :for="'image'+country.id">
                    Изображения
                  </label>
                </div>
                <div class="checkbox">
                  <input :id="'yandex'+country.id" type="checkbox" v-model="country.yandex">
                  <label :for="'yandex'+country.id">
                    Yandex
                  </label>
                </div>
                <div class="checkbox">
                  <input :id="'google'+country.id" type="checkbox" v-model="country.google">
                  <label :for="'google'+country.id">
                    Google
                  </label>
                </div>
                <div class="row pf-code">
                  <MultiSelect
                      v-model="country.pf_code"
                      :options="pf_codes"
                      :multi="false"
                      :only_value="false"
                      name="PF задача"
                  ></MultiSelect>
                </div>

              </div>
            </div>

          </div>
          <div class="keywords-row">
            <div class="keyword" v-for="(keyword, index) in project.keywords_data">
              <div class="row">
                <div :class="'col-10'+ (project.keywords_data[index].name_error ?' form-error':'')">
                  <input class="form-control" placeholder="Запрос"
                         :disabled="!(can_edit && checkFieldPerm('keywords') && (project.data_collect || !project?.id))"
                         v-model="project.keywords_data[index].name" type="text" :ref="`keyword-input-${index}`">

                  <div class="error" v-if="project.keywords_data[index].name_error">
                    {{ project.keywords_data[index].name_error }}
                  </div>
                </div>
                <div class="col">
                  <template v-if="can_edit && checkFieldPerm('keywords') && (project.data_collect || !project?.id)">
                    <button type="button" @click="delete_key=keyword; $modal('show', 'delete_object')" v-if="keyword.id"
                            class="btn btn-danger text-white btn-sm">
                      <i class="bi bi-x-lg"></i>
                    </button>
                    <button type="button" v-else class="btn btn-danger text-white btn-sm"
                            @click="project.keywords_data.splice(index, 1)">
                      <i class="bi bi-dash"></i>
                    </button>
                    <button type="button" v-if="index === project.keywords_data.length - 1"
                            class="btn btn-success text-white btn-sm"
                            @click="project.keywords_data.push({name:'', id: '', countries: [], s_result: true})">
                      <i class="bi bi-plus-lg"></i>
                    </button>
                  </template>
                </div>
              </div>
              <div v-if="project.countries"
                   :class="`row ${can_edit && checkFieldPerm('keywords') && (project.data_collect || !project.id)?'':'area-disabled'}`">

                <div class="col-8">
                  <MultiSelect
                      v-model="keyword.countries"
                      name="Страны"
                      :options="project_countries"
                      :multi="true"
                  ></MultiSelect>
                </div>
                <div class="checkbox col">
                  <input type="checkbox" v-model="project.keywords_data[index].s_result"
                         :id="'search'+index">
                  <label :for="'search'+index">
                    Выдача
                  </label>
                </div>


              </div>
            </div>
          </div>
          <modal :modal_id="'delete_object'" class="warning">
            <template v-slot:header>
              <h4> Удалить "{{ delete_key.name }}"?</h4>
            </template>
            <template v-slot:body>
              <div class="d-flex">
                <h1 class="text-danger"><i class="bi bi-exclamation-lg"></i></h1>
                <p> Вместе с ключевым словом удалятся результаты выдачи</p>
              </div>
            </template>
            <template v-slot:footer>
              <button type="button" class="btn btn-secondary" @click="$modal('hide', 'delete_object');"
                      data-bs-dismiss="modal">Отмена
              </button>
              <button type="button" class="btn btn-danger" v-on:click="DeleteObject(delete_key);">Удалить</button>
            </template>
          </modal>
        </div>
        <div :class="`col-sm-6 ${can_edit?'':'area-disabled'}`">
          <div class="row">
            <div class="col-6" v-if="fieldShow('seo_specialists')">
              <div
                  :class="can_edit && checkFieldPerm('seo_specialists')?'':'area-disabled'+ (project.seo_specialists_error ?' form-error':'')">
                <label>SEO специалист</label>
                <MultiSelect
                    v-model="project.seo_specialists"
                    :options="managers"
                    :multi="true"
                    name="SEO специалист"
                    :only_value="true"
                ></MultiSelect>
                <div class="error" v-if="project.seo_specialists_error">{{ project.seo_specialists_error }}</div>
              </div>
            </div>
            <div class="col-6" v-if="fieldShow('seo_specialist')">
              <div
                  :class="(can_edit && checkFieldPerm('seo_specialist')?'':'area-disabled')+ (project.seo_specialist_error ?' form-error':'')">
                <label>Ответственный SEO специалист</label>
                <MultiSelect
                    v-model="project.seo_specialist"
                    :options="managers"
                    :multi="false"
                    name="Ответственный SEO специалист"
                    :only_value="true"
                ></MultiSelect>
                <div class="error" v-if="project.seo_specialist_error">{{ project.seo_specialist_error }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
                :class="'col-6'+(can_edit && checkFieldPerm('project_managers')?'':' area-disabled')+(project.project_managers_error ?' form-error':'')">
              <label>Менеджер проекта <sup>*</sup></label>
              <MultiSelect
                  v-model="project.project_managers"
                  :options="managers"
                  :multi="true"
                  name="Менеджер проекта"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="project.project_managers_error">{{ project.project_managers_error }}</div>
            </div>
            <div
                :class="'col-6'+(can_edit && checkFieldPerm('auditors')?'':' area-disabled')+(project.auditors_error ?' form-error':'')">
              <label>Аудитор</label>
              <MultiSelect
                  v-model="project.auditors"
                  :options="managers"
                  :multi="true"
                  name="Аудитор"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="project.auditors_error">{{ project.auditors_error }}</div>
            </div>
          </div>
          <div class="row">
            <div
                :class="'col-6'+(can_edit && checkFieldPerm('lawyers')?'':' area-disabled')+(project.lawyers_error ?' form-error':'')">
              <label>Юрист</label>
              <MultiSelect
                  v-model="project.lawyers"
                  :options="managers"
                  :multi="true"
                  name="Юрист"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="project.lawyers_error">{{ project.lawyers_error }}</div>
            </div>
            <div
                :class="'col-6'+(can_edit && checkFieldPerm('wiki_specialists')?'':' area-disabled')+(project.wiki_specialists_error ?' form-error':'')">
              <label>Wiki специалист</label>
              <MultiSelect
                  v-model="project.wiki_specialists"
                  :options="managers"
                  :multi="true"
                  name="Wiki специалист"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="project.wiki_specialists_error">{{ project.wiki_specialists_error }}</div>
            </div>
          </div>


          <div
              :class="can_edit && checkFieldPerm('analysts')?'':'area-disabled'+(project.analysts_error ?' form-error':'')">
            <label>Аналитик</label>
            <MultiSelect
                v-model="project.analysts"
                :options="managers"
                :multi="true"
                :only_value="true"
            ></MultiSelect>
            <div class="error" v-if="project.analysts_error">{{ project.analysts_error }}</div>
          </div>
          <div v-if="fieldShow('image_weekday')"
               :class="can_edit && checkFieldPerm('image_weekday')?'':'area-disabled'+(project.image_weekday_error ?' form-error':'')">
            <label for="name">График сбора изображений</label>
            <MultiSelect
                v-model="project.image_weekday"
                :options="weekdays"
                :only_value="true"
                :multi="true"
            >
            </MultiSelect>
            <div class="error" v-if="project.image_weekday_error">{{ project.image_weekday_error }}</div>
          </div>
          <div class="row">
            <div class="col-3">
              <div class="checkbox mb-2">
                <input :disabled="!(can_edit && checkFieldPerm('court'))" type="checkbox"
                       v-model="project.court" id="court">
                <label for="court">
                  Суд
                </label>
              </div>
              <div v-show="project.court" :class="project.court_date_error ?' form-error':''">
                <date-picker
                    :disabled="!(can_edit && checkFieldPerm('court_date'))"
                    v-model="project.court_date"
                    locale="ru"
                    placeholder="Дата суда"
                    :clearable="false"
                    format="dd.MM.yyyy"
                    model-type="yyyy-MM-dd"
                    auto-apply
                    :enable-time-picker="false"
                ></date-picker>
                <div class="error" v-if="project.court_date_error">{{ project.court_date_error }}</div>
              </div>
            </div>
            <div class="col">
              <div class="checkbox mb-2">
                <input :disabled="!(can_edit && checkFieldPerm('wiki'))" type="checkbox"
                       v-model="project.wiki" id="wiki">
                <label for="wiki">
                  Википедия
                </label>
              </div>
              <div v-show="project.wiki" :class="(project.wiki_link_error ?' form-error':'')">
                <input placeholder="Ссылка" :disabled="!(can_edit && checkFieldPerm('wiki_link'))" type="text"
                       class="form-control"
                       v-model="project.wiki_link"/>
                <div class="error" v-if="project.wiki_link_error">{{ project.wiki_link_error }}</div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-3" v-if="fieldShow('report_weekday')"
                 :class="(project.report_weekday_error ?' form-error':'')">
              <label>Отчётный день <sup>*</sup></label>
              <MultiSelect
                  v-model="project.report_weekday"
                  :options="weekdays"
                  :required="true"
                  name="Отчётный день"
                  :only_value="true"
                  :multi="false"
              >
              </MultiSelect>
              <div class="error" v-if="project.report_weekday_error">{{ project.report_weekday_error }}</div>
            </div>
            <div v-if="fieldShow('start_date')" :class="'col-3'+(project.start_date_error ?' form-error':'')">
              <label>Срок начала <sup>*</sup></label>
              <date-picker
                  :disabled="!(can_edit && checkFieldPerm('start_date'))"
                  v-model="project.start_date"
                  locale="ru"
                  :clearable="false"
                  format="dd.MM.yyyy"
                  model-type="yyyy-MM-dd"
                  auto-apply
                  :enable-time-picker="false"
              ></date-picker>
              <div class="error" v-if="project.start_date_error">{{ project.start_date_error }}</div>
            </div>
            <div v-if="fieldShow('end_date')" :class="'col-3'+(project.end_date_error ?' form-error':'')">
              <label>ДДЛ проекта <sup>*</sup></label>
              <date-picker
                  :disabled="!(can_edit && checkFieldPerm('end_date'))"
                  v-model="project.end_date"
                  locale="ru"
                  :clearable="false"
                  format="dd.MM.yyyy"
                  model-type="yyyy-MM-dd"
                  auto-apply
                  :enable-time-picker="false"
              ></date-picker>
              <div class="error" v-if="project.end_date_error">{{ project.end_date_error }}</div>
            </div>
            <div class="col month-count">
              <template v-if="project.start_date">
                <input :disabled="!(can_edit && checkFieldPerm('month_count'))" type="text" class="form-control"
                       id="month_count"
                       v-model="project.month_count">
                <label for="month_count">Количество месяцев</label>
              </template>
            </div>
          </div>

          <div class="mt-5" v-if="can_edit && load_files.length && project.project_type === 'active'">
            <MultiSelect
                v-model="active_load_data"
                :options="load_files"
                :required="true"
                field_name="title"
                field_id="name"
                name="Массовая загрузка данных"
                :only_value="false"
                :multi="false"
            >
            </MultiSelect>

            <div class="mb-3 mt-2" v-for="load in load_files">
              <template v-if="active_load_data?.name === load.name">
                <span>Файл</span>
                <input class="form-control" accept=".xlsx" @change="saveFileDict($refs[load.name], load.name)"
                       :ref="load.name" type="file"
                       :id="load.name">
              </template>
            </div>
            <label class="text-secondary">Образцы файлов<i class="bi bi-arrow-down-square ms-2"></i></label>
            <div class="template-list">
              <div v-for="load in load_files">
                <a :href="`https://804303.selcdn.ru/ra_media/media/static/temp/${load.temp}.xlsx`">{{ load.title }}</a>
              </div>
            </div>
          </div>

        </div>
      </div>
      <ModalSave v-bind:project="project" v-bind:save_errors="save_errors"></ModalSave>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn btn-warning btn-sm m-2" @click="toArchive"
                v-if="$store.getters.user.is_superuser|| (['manager'].indexOf($store.getters.user.post_type)+1)"
                type="button">
          <template v-if="project.project_type !== 'archive'">Архивировать</template>
          <template v-else>Вернуть из архива</template>
        </button>
        <button class="btn btn-primary btn-sm m-2" :disabled="save_process" v-if="can_edit" @click.prevent="saveForm"
                type="submit">
          <template v-if="project&&project.id">Сохранить</template>
          <template v-else>Создать</template>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import {ProjectApi} from "@/api/project";
import {UserApi} from "@/api/user";
import BaseInfo from "./includes/BaseInfo.vue";
import ModalSave from "./includes/ModalSave.vue";
import moment from 'moment'
import SettingMenu from '@/components/SettingMenu';
import {PfApi} from "@/api/planfix";
import validateMixin from "@/mixins/validate"

export default {
  // name: 'ProjectAdd',
  components: {
    base_info: BaseInfo, ModalSave, SettingMenu
  },
  mixins: [validateMixin],
  data() {
    return {
      types: {
        // 'draft': 'Черновик',
        'audit': 'Аудит',
        'active': 'Активный',
        'archive': 'Архив',
      },
      date_change: true,
      show_case: false,
      save_errors: [],
      states: [10, 20, 50, 100],
      save_process: false,
      project: null,
      managers: [],
      audit_exclude: ['start_date', 'end_date'],
      load_data: [
        {name: 'negative_file', title: 'Уникальные ссылки(Нец./Цель.)', temp: 'negative_temp'},
        {name: 'forecast_file', title: 'Прогноз скачивания', temp: 'forecast'},
        {name: 'search_result', title: 'Поисковая выдача', temp: 'search_result'},
        {name: 'hints_tone', title: 'Загрузка тона подсказок', temp: 'hints_tone'},
        // {name: 'image_tone', title: 'Загрузка тона для блока изображений', temp: 'image_tone'},
        {name: 'upload_backlinks', title: 'Загрузка бэклинков', temp: 'upload_backlinks'},
        {name: 'upload_inner_link', title: 'Загрузка внутренних ссылок', temp: 'upload_backlinks'},
        {name: 'upload_keywords', title: 'Загрузка запросов', temp: 'upload_keywords'},
      ],
      user_conf: {
        serm: "seo_specialists",
        manager: "project_managers",
        auditor: "auditors",
        lawyer: "lawyers",
        wiki_expert: "wiki_specialists",
        analyst: "analysts",
      },
      exclude_fields: {
        audit: ["report_weekday", "seo_specialist", "seo_specialists", "image_weekday", "end_date", "start_date"],
        active: [],
        archive: []
      },
      countries: [],
      pf_codes: [],
      active_load_data: null,
      delete_key: {name: ''},
      validate: {
        name: [],
        project_type: [],
        end_date: [{
          func: (o) => {
            return (moment(o.end_date) - moment(o.start_date)) < 0;
          }, error: 'Дата окончания не может быть меньше даты старта'
        }, {
          func: (o) => {
            return !o.end_date;
          }, error: 'Обязательное поле'
        }],
        start_date: [],
        project_managers: [{
          func: (o) => !o.project_managers.length,
          error: "Укажите менеджера проекта"
        }],
        wiki_link: [{
          func: (o) => o.wiki && !this.$func.isURL(o.wiki_link),
          error: "Некорректная ссылка на wikipedia"
        }, {
          func: (o) => o.wiki && !o.wiki_link,
          error: "Укажите ссылку на статью"
        }],
        court_date: [{
          func: (o) => o.court && !o.court_date,
          error: "Укажите дату суда"
        }],
        countries: [
          {
            func: (o) => !o.countries.length,
            error: "Обязательное поле"
          }
        ],
        keywords_data: [
          {
            func: (o) => {
              o.keywords_data.forEach((key) => {
                if (!key.name) {
                  key.name_error = "Обязательное поле";
                  return true;
                }
                let similar = o.keywords_data.filter(x => x.name.toLowerCase().trim() === key.name.toLowerCase().trim())
                if (similar.length > 1) {
                  similar.forEach(k => k.name_error = 'Одинаковые запросы')
                  return true;
                }
                delete key.name_error;
              })
              return false;
            },
            error: "Обязательное поле"
          }
        ]

      }
    }
  },
  computed: {
    can_edit() {
      return this.project.project_type !== 'archive';
    },
    weekdays() {
      return Array.apply(null, Array(7)).map(function (_, i) {
        return {'name': moment(i, 'e').startOf('week').isoWeekday(i + 1).format('dddd'), 'id': i};
      })
    },
    project_types() {
      return Object.keys(this.types).map(x => {
        return {id: x, name: this.types[x]}
      })
    },
    load_files() {
      return this.load_data.filter(x => (this.$store.getters.user.permission.load_files.indexOf(x.name) + 1))
    },
    project_countries() {
      let qs = this.project.countries, $this = this;
      qs.forEach(function (country) {
        let index = $this.project.countries.indexOf(country);
        if (!country.s_id) {
          if (country.code === 'RU') {
            country.tone = true;
            country.yandex = true;
            country.google = true;
            country.hint = true;
          } else country.google = true;
        }
        $this.project.countries[index] = country
      })
      return qs
    }
  },
  watch: {
    active_load_data(val) {
      let $this = this;
      $this.load_data.forEach(function (e) {
        if ($this.$refs[e.name]) {
          $this.$refs[e.name].value = null
        }
      });
    },
    "project.end_date"(n, o) {
      this.monthDiff()
    },
    "project.start_date"(n, o) {
      this.monthDiff()
    }
  },
  mounted: function () {
    let $this = this;
    UserApi.managers().then(response => {
      $this.managers = response.map(x => {
        return {id: x.id, name: x.fullname, post_type: x.post_type, is_active: x.is_active}
      });
      $this.getForEdit()

    });
    PfApi.get($this.$route.params.id).then(response => {
      $this.pf_codes = response;
    });
  },
  beforeMount() {
    if (!((!this.$route.params.id && this.$store.getters.user.permission.actions.indexOf('create_project') + 1) ||
        (this.$route.params.id && this.$store.getters.user.permission.actions.indexOf('project_edit') + 1))) this.$router.push({name: '404'});

  },
  methods: {
    DeleteObject(keyword) {
      let $this = this;
      ProjectApi.delete_keyword($this.project.id, keyword.id).then(response => {
        $this.$modal('hide', 'delete_object');
        $this.project.keywords_data.splice($this.project.keywords_data.indexOf(keyword), 1);
        if (!$this.project.keywords_data.length) $this.project.keywords_data.push({name: '', id: ''});
        $this.$notify({
          type: 'success ',
          text: response
        });
      }).catch(err => {
        $this.$notify({
          type: 'error ',
          text: err.response.data
        });
      });
    },
    checkFieldPerm(name) {
      return this.$store.getters.user.permission.project_fields.indexOf(name) + 1;
    },
    getForEdit() {
      let $this = this;
      ProjectApi.get_for_edit($this.$route.params.id).then(response => {
        if (!response.image_weekday || response.image_weekday.constructor === Object) {
          response.image_weekday = []
        } else if (typeof (response.image_weekday) === "string") response.image_weekday = JSON.parse(response.image_weekday)
        $this.setManager(response)
        if (!$this.project.countries) $this.project.countries = [];
        if (!$this.project.keywords_data) $this.project.keywords_data = [];
        if (!$this.project.keywords_data.length) $this.project.keywords_data.push({name: '', id: '', countries: []})

        ProjectApi.countries().then(response => {
          $this.countries = response.results;
          if (!$this.project.countries || !$this.project.countries.length) {
            $this.project.countries = $this.project.countries.concat($this.countries.filter(x => x.code === 'RU'))
          }
        });
        if (!this.project.default_state) this.project.default_state = 20
        $this.clearError()
      })
    },
    setManager(response) {
      let $this = this;
      $this.project = response;
      if ($this.project.project_type === 'archive') $this.types['archive'] = 'Архив'
      else delete $this.types['archive']
      $this.setDefaultManager()
      $this.save_process = false;
      if ($this.project.project_type && $this.project.project_type !== 'archive') {
        let keys = Object.keys($this.types)
        keys = keys.slice(keys.indexOf($this.project.project_type), keys.length)
        $this.types = Object.fromEntries(keys.map(i => [i, $this.types[i]]));
      }
    },
    setDefaultManager() {
      let $this = this;
      let user = $this.$store.getters.user;
      Object.keys(this.user_conf).forEach(user_type => {
        if (user.post_type) {
          if (!$this.project[this.user_conf[user_type]].length && user.post_type === user_type) $this.project[this.user_conf[user_type]].push(user.id);
        }
        $this.project[this.user_conf[user_type]] = $this.project[this.user_conf[user_type]].filter(x => $this.managers.find(y => y.id === x))
      })
    },
    saveFileDict(ref, name) {
      this.project[name] = ref[0].files[0];
      this.project.file_name = name;
    },
    saveForm() {
      if (!this.validateData()) {
        let $this = this;
        let formData = new FormData();
        if (this.project.project_type === 'audit') this.audit_exclude.forEach(x => delete this.project[x]);
        this.save_process = true;
        Object.keys(this.project).filter(x => x.includes("error")).forEach(x => delete this.project[x])
        if (this.project.file_name) {
          formData.append("file_name", this.project.file_name);
          formData.append(this.project.file_name, this.project[this.project.file_name]);
        }
        formData.append("project", JSON.stringify(this.project))

        ProjectApi.update(formData).then(response => {
          if (!!response.errors) {
            $this.save_errors = response.errors;
            delete response.error;
          }
          $this.$modal("show", "success_save");
          this.$notify({
            type: 'success ',
            title: 'Проект добавлен'
          });
          if (!$this.$route.params.id) {
            $this.$router.push(`/projects/${response.id}/edit/`)
            // $this.getForEdit()
            $this.project = response;
            $this.setManager(response)
          } else $this.save_process = false;
        }).catch(err => {
          console.log(err)
          for (let name in err.response.data) {
            this.$notify({
              title: "Ошибка",
              text: `${name}: ${err.response.data[name]}`
            });
            $this.project[name + "_error"] = err.response.data[name];
          }

          $this.save_process = false;
        });
      }

    },

    addToFavor() {
      let $this = this;
      UserApi.add_favorite(this.project.id).then(resp => {
        $this.project.favorite = !$this.project.favorite
        $this.$notify({
          type: 'success ',
          text: `${this.project.favorite ? 'Добавлено в избранное' : 'Удалено из избранного'}`
        });
        $this.$store.dispatch('get_user');
      })
    },
    toArchive() {
      let $this = this;
      ProjectApi.to_archive(this.project.id, this.project.project_type === 'archive' ? 'prev' : 'archive').then(response => {
        this.project.project_type = response.project_type;
        $this.$notify({
          type: 'success ',
          text: `Статус проекта изменён на "${$this.types[$this.project.project_type]}"`
        });
      })
    },
    monthDiff() {
      if (this.project.end_date && this.project.start_date) {
        let
            d1 = moment(this.project.end_date, "YYYY-MM-DD"),
            d2 = moment(this.project.start_date, "YYYY-MM-DD"),
            diff = Math.round(d1.diff(d2, 'months', true));
        this.project.month_count = diff <= 0 ? 0 : diff;

      }
    },
    fieldShow(name) {
      if (this.project.project_type) {
        return (this.exclude_fields[this.project.project_type] && this.exclude_fields[this.project.project_type].indexOf(name) < 0)
      }
      return false;
    }
  }

}

</script>

<style lang="scss">
.row:empty {
  position: absolute !important;
}

.country-cards {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .card.country {
    width: 12rem;
    display: inline-block;

    .card-body {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .checkbox input[type=checkbox] + label::before {
        top: 50%;
        transform: translateY(-50%);

      }
    }
  }

}

.keywords-row {
  gap: 30px;
  display: flex;
  flex-direction: column;

  .keyword {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .col {
    display: flex;
    gap: 5px;

    button {
      height: 20px;
      width: 20px;
      padding: 0;
      text-align: center;
    }
  }
}

.month-count {
  display: flex;
  gap: 10px;
  padding-top: 1rem;

  input {
    width: 35px;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    text-align: center;
  }
}

.template-list {
  column-count: 2;
  margin-top: 1rem;
}

.needs-validation .col-sm-6 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

input {
  height: 35px;
}

.pf-code {
  .select {
    font-size: 12px;
    padding: 0;
    width: 100%;

    .dropdown-toggle {
      padding: 5px 0;
      min-width: 100% !important;

      .value {
        display: block;
        white-space: normal;
        text-align: left;
      }
    }
  }

}

.rp {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-left: 0;
  padding-left: 0;

  li {
    display: flex;
    gap: 20px;
    align-items: center;

    label {
      width: 100px;
      flex: 0 0 auto;
    }
  }

}

</style>