<template>
  <div>


    <div class="card-body">

        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col" class="mw200">Референс</th>
              <th scope="col">Кол-во текстов</th>
              <th scope="col">Дата добавления / изменения</th>
              <th scope="col">Статус</th>
              <th scope="col"></th>
              <th scope="col">ID</th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr class="edit" v-if="object.edit">
                <td colspan="5">
                  <div class="form p-3 border rounded  border-2 border-primary-subtle">

                    <div class="form-group">
                      <label>Язык</label>
                      <select class="form-select" v-model="object.lang"
                              @change="changeValue(object)">
                        <option v-for="lang in LANG" :value="lang" :key="lang">{{ lang }}</option>
                      </select>
                    </div>
                    <div :class="object.n_questions_error?'form-group--error':''">
                      <label for="">Желаемое количество текстов <i>(от 1 до 100)</i></label>
                      <input class="form-control" type="number" v-model="object.n_questions" max="100" min="1">
                    </div>
                    <div class="error" v-if="object.n_questions_error">{{ object.n_questions_error }}</div>
                    <div :class="object.max_length_error?'form-group--error':''">
                      <label for="">Максимальное количество символов в одном тексте <i>(от 100 до 7000)</i></label>
                      <input class="form-control" @change="changeValue(object)" type="number" v-model="object.max_length"
                             max="7000" min="100">
                    </div>
                    <div class="error" v-if="object.max_length_error">{{ object.max_length_error }}</div>
                    <hr>
                    <div :class="object.article_prompt_prefix_error?'form-group--error':''">
                      <label>Префикс промпта</label>
                      <textarea class="form-control" name="" cols="5" rows="5" v-model="object.article_prompt_prefix">
                      </textarea>

                    </div>
                    <div :class="object.text_error?'form-group--error':''">

                      <label>Тема материалов</label>
                      <textarea class="form-control" name="" cols="10" rows="5"
                                v-on:keyup="$func.setValue(object, 'text_error', false)"
                                v-model="object.text"></textarea>

                    </div>
                    <div class="error" v-if="object.text_error">{{ object.text_error }}</div>
                    <div :class="object.article_prompt_postfix_error?'form-group--error':''">

                      <label>Постфикс промпта</label>
                      <textarea class="form-control" name="" cols="5" rows="5"
                                v-model="object.article_prompt_postfix"></textarea>

                    </div>
                    <hr>
                    <div :class="object.headline_prompt_prefix_error?'form-group--error':''">

                      <label>Префикс промпта заголовка</label>
                      <textarea class="form-control" name="" cols="5" rows="5"
                                v-model="object.headline_prompt_prefix"></textarea>

                    </div>
                    <hr>
                    <div class="row">
                      <div class="input-group mt-1" v-for="(person_reference, index) in object.person_references">

                        <label for="" class="input-group-text">Вставка отсылки #{{ index + 1 }} в абзац номер</label>
                        <input class="form-control" v-model="person_reference.ref_position" type="number">
                        <label for="" class="input-group-text">Отсылка к персоне #{{ index + 1 }}</label>
                        <input class="form-control" v-model="person_reference.ref_name" type="text">
                        <div class="buttons" style="width: 70px">
                          <button class="btn btn-success"
                                  @click="object.person_references.push({ref_position: index+2, ref_name: ''})">+
                          </button>
                          <button class="btn btn-danger" v-if="index"
                                  @click="object.person_references.splice(index, 1)">-
                          </button>
                        </div>
                      </div>

                    </div>
                    <hr>
                    <div class="mt-2">
                      <label for="">Отправить на Email</label>
                      <input class="form-control" type="email" v-model="object.email">
                    </div>
                    <div class="d-flex mt-3">
                      <button class="btn btn-success" v-on:click="saveNote(object)"><i
                          class="bi bi-check-circle me-2"></i>
                        <small>Сохранить</small>
                      </button>
                      <br>
                      <button class="btn btn-danger ms-2" v-on:click.prevent="endEdit(object)"><i
                          class="bi bi-x-circle me-2"></i>
                        <small>Отмена</small>
                      </button>

                    </div>
                  </div>
                </td>

              </tr>
              <tr v-else>
                <td>{{ (index + 1) + ((page - 1) * size) }}</td>

                <td class="text-area">
                  <template v-if="object.generated_content">
                    <a :href="object.generated_content" target="_blank">{{ object.text }} <i>( символов
                      {{ object.max_length }})</i>
                      <i class="bi bi-file-arrow-down ms-1"></i>
                    </a>

                  </template>
                  <template v-else>{{ object.text }} <i>( символов {{ object.max_length }})</i></template>
                </td>
                <td>{{ object.ready }}/{{ object.n_questions }}</td>
                <td>{{ object.date }}</td>
                <td>{{ object.get_status_display }}</td>
                <td>
                  <div class="d-flex" v-if="!object.status || (['new', 'error'].indexOf(object.status) + 1)">
                    <button v-if="!object.edit"
                            v-on:click.prevent="$func.setValue(object, 'edit', true);"
                            class="no-wrap btn btn-primary btn-sm"><i class="bi bi-pencil"></i>
                      <small>редактировать</small>
                    </button>

                    <button v-if="object.id" @click="sendToGenerate(object.id)"
                            class="btn btn-sm btn-success no-wrap ms-1">Создать
                      <i class="bi bi-question-circle"
                         title="После запуска данные станут недоступны для редактирования"></i>
                    </button>
                  </div>
                  <div v-if="object.generated_content && !object.generated_content.endsWith('.docx')">
                    <button class="btn btn-sm " title="Перепаковать архив" @click="recreateArchive(object)"><i class="bi bi-arrow-clockwise"></i></button>
                  </div>
                </td>
                <td>{{object.id}}</td>

              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="7">
                Не добавлено ни одной записи
              </td>
            </tr>
            <tr v-if="objects.length < max_length">
              <td colspan="7">
                <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
  </div>
</template>
<script>

import LinkDetail from "@/views/projects/detail/includes/LinkDetail.vue";
import DiffDates from "@/views/projects/detail/includes/DiffDates.vue";
import sermDetail from "@/views/projects/detail/includes/Serm.vue";
import moment from "moment";
import {ProjectApi} from "@/api/project";

export default {
  name: 'DonorService',
  props: {
    simpleProject: {type: Object, default: {}},
  },
  components: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted: function () {

  },
  methods: {


  }

}
</script>

<style scoped>

</style>
<style scoped>

</style>